import { TbVocabulary } from "react-icons/tb";

/**
 * Configuration object for question types and their corresponding input fields
 *
 * @author - Gaurav
 */
const questionPreviewConfig = {
    imageQuestion: [4, 5, 6, 13, 14, 15, 20,],
    textQuestion: [1, 2, 3, 10, 11, 12, 19, 22, 24, 25],
    textAndImageQuestion: [7, 8, 9, 16, 17, 18, 21, 23,],
    imageOption: [1, 4, 7, 10, 13, 16],
    textOption: [2, 5, 8, 11, 14, 17],
    textAndImageOption: [3, 6, 9, 12, 15, 18,],
    answerInputBox: [19, 20, 21],
    answerType: [1, 2, 3, 4],
    fillInTheBlanks: [22, 23],
    readingComprehension: [24],
    multiSelect: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    singleSelect: [10, 11, 12, 13, 14, 15, 16, 17, 18],
    spelling: ["spelling"],
    vocabulary: ["vocabulary"]
};

export default questionPreviewConfig;