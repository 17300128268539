export const CustomPagination = ({
    rowsPerPage,
    onChangeRowsPerPage,
    currentPage,
    onChangePage,
    rowCount,
    paginationRowsPerPageOptions
}) => {
    let maxPageLimitToshow = 3;
    let totalRows = rowCount;
    let page = currentPage;
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    let maxPaginationButtons = totalPages;
    let minPaginationButtons = 1;
    if (totalPages < maxPageLimitToshow) {
        maxPaginationButtons = totalPages;
    }
    else if (totalPages > maxPageLimitToshow) {
        maxPaginationButtons = (page == totalPages) ? page : page + 1
    }
    if (maxPaginationButtons == 1) {
        minPaginationButtons = 1;
    }
    else {
        minPaginationButtons = maxPaginationButtons - 1;
    }
    const paginationButtons = [];
    for (let i = minPaginationButtons; i <= maxPaginationButtons; i++) {
        paginationButtons.push(
            <button className="page-item" onClick={() => onChangePage(i)} disabled={i === currentPage}>
                {i}
            </button>);
    }
    return (
        (totalRows) ? <div className="custom-pagination">
            <div className="rows-per-page">
                <span>No. of entries:</span>
                <select value={rowsPerPage} onChange={(e) => onChangeRowsPerPage(e.target.value)}>
                    {paginationRowsPerPageOptions.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <div className="pagination-controls">
                {(minPaginationButtons !== 1) ? (
                    <button className="page-item font-bold" onClick={() => onChangePage(page - 1)}>
                        <span>&lt; Previous </span>
                    </button>
                ) : ''}

                {paginationButtons}
                {(totalPages > maxPageLimitToshow && page !== totalPages) ? (
                    <button className="page-item font-bold" onClick={() => onChangePage(page + 1)}>
                        <span>Next &gt; </span>
                    </button>
                ) : ''}
            </div>
            <div className="pagination-info">
                <span>
                    Page {page} of {totalPages}
                </span>
            </div>
        </div> : ''
    );
};

export const customStyles = {
    cells: {
        style: {
            fontWeight: '700',
        },
    },
};
