import React from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";
import closeIcon from "../../../Assests/Images/cross_icon.svg";
import {
    closeFlagModal,
    flagUnflagActions,
    setFlagComment,
    setIsFlaggedQuestion,
} from "../../../redux/slices/DatatableActionSlices/flagUnflagSlice";

function FlagModal({ flagText, DataTable }) {
    const dispatch = useDispatch();
    const { flagComment, ids, isFlag } = useSelector((state) => state.flagUnflag);
    const handleReasonChange = (event) => {
        dispatch(setFlagComment(event.target.value));
    };

    const handleSave = async () => {
        if (flagComment === "") {
            toast.error("Reason is mandatory");
            return;
        }
        const response = await dispatch(flagUnflagActions({
            ids: ids,
            text: flagComment,
            isFlag: isFlag,
            isBulkAction: true
        }))
        if (response) {
            dispatch(closeFlagModal());
            dispatch(setIsFlaggedQuestion(true))
            if(DataTable){
                DataTable();
            }
        }
    };

    const handleClose = () => {
        dispatch(closeFlagModal())
    }

    const modalContent = `Please explain briefly why this ${flagText} is being flagged. 
                          Your feedback is essential for maintaining the quality of our educational community.`;

    return (
        <Card className="import-flag-modal">
            <Card.Header className="flag-modal-header">
                <img
                    className="close-icon"
                    src={closeIcon}
                    alt="Close icon"
                    onClick={handleClose}
                />
            </Card.Header>
            <Card.Body className="import-word-modal-body">
                <Card.Title className="flag-card-title"></Card.Title>
                <Card.Text className="flag-modal-card-text">
                    {modalContent}
                    <textarea
                        className="flag-input-text"
                        placeholder={"Write your reason.."}
                        onChange={handleReasonChange}
                    />
                </Card.Text>
            </Card.Body>
            <button className="submit-flag-button" onClick={handleSave}>Submit</button>
        </Card>
    );
}

FlagModal.propTypes = {
    onSave: PropTypes.func,
};

export default React.memo(FlagModal);
