import _ from "lodash";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import closeIcon from "../../Assests/Images/closeIcon.svg";
import { fetchOrganizationsTitle } from "Utils/ApiHandler/FilterDropdownsApiCalls";
import React, { useState, useRef, useEffect } from 'react';
import Dropdown, { capitalizeFirstLetter, splitCamelCaseAndCapitalize } from "Components/FilterHeader/GenericDropdown";
import { handleStateChange, handleSelectionChange, fetchAndSetData, fetchDropdown } from "../../Utils/FilterUtils";
import {
    CloseIcon,
    CustomForm,
    CustomModal,
    ModalButton,
    CustomModalFooter,
    CustomModalHeader,
} from 'Components/Modals/AddEditModal.styled'

const filterNames = ["organizationIds", "programids", "levelIds", "domainIds", "topicIds", "skillIds", "flaggedBy",
    "qtype", "flag", "code", "difficulty", "status"];

const filterMapping = {
    organizationIds: "organization",
    code: "code",
    programIds: "program",
    levelIds: "level",
    domainIds: "domain",
    topicIds: "topic",
    skillIds: "skill",
    flag: "flag",
    qtype: "questionType",
    flaggedBy: "flaggedBy",
};

const dropdownConfig = {
    organization: { id: "organization", isSearchable: false },
    code: { id: "code", isSearchable: true },
    program: { id: "program", isSearchable: true },
    level: { id: "level", isSearchable: true },
    domain: { id: "domain", isSearchable: true, },
    topic: { id: "topic", isSearchable: true },
    skill: { id: "skill", isSearchable: true },
    questionType: { id: "questionType", isSearchable: true },
    status: { id: "status", isSearchable: false },
    flag: { id: "flag", isSearchable: false },
    complexity: { id: "complexity", isSearchable: false },
    flaggedBy: { id: "flaggedBy", isSearchable: false },
};

const MoreFilters = ({
    show,
    filter,
    pageName,
    setFilter,
    handleClose,
    dataFilterStatus,
    moreFiltersDropdown,
}) => {

    const moreFiltersRef = useRef(null);

    const dropdownRefs = {
        flag: useRef(null),
        code: useRef(null),
        level: useRef(null),
        topic: useRef(null),
        skill: useRef(null),
        status: useRef(null),
        domain: useRef(null),
        program: useRef(null),
        flaggedBy: useRef(null),
        complexity: useRef(null),
        organization: useRef(null),
        questionType: useRef(null),
    };

    const [isTyping, setIsTyping] = useState(false);
    const [resetTrigger, setResetTrigger] = useState(0);
    const [searchOptions, setSearchOptions] = useState(true);
    const [selections, setSelections] = useState({
        flag: "",
        status: "",
        code: [],
        skill: [],
        level: [],
        topic: [],
        domain: [],
        program: [],
        flaggedBy: [],
        complexity: "",
        questionType: [],
        organization: [],
    });
    const [inputs, setInputs] = useState({
        code: "",
        level: "",
        skill: "",
        topic: "",
        domain: "",
        program: "",
        flaggedBy: "",
        complexity: "",
        questionType: "",
    });
    const [titles, setTitles] = useState({
        code: [],
        level: [],
        skill: [],
        topic: [],
        domain: [],
        program: [],
        organization: [],
        questionType: [],
        flaggedBy: [
            { id: 'student', value: 'student', label: 'Sudent' },
            { id: 'parent', value: 'parent', label: 'Parent' },
            { id: 'staff', value: 'staff', label: 'Staff' },
            { id: 'teacher', value: 'teacher', label: 'Teacher' },
        ]

    });
    const [menuStates, setMenuStates] = useState({
        code: false,
        flag: false,
        level: false,
        topic: false,
        skill: false,
        domain: false,
        status: false,
        program: false,
        flaggedBy: false,
        complexity: false,
        questionType: false,
        organization: false,
    });

    useEffect(() => {
        if (!searchOptions) {
            setIsTyping(false);
        }
    }, [searchOptions])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchAndSetData(inputs, pageName, selections, dataFilterStatus, setSearchOptions, setTitles);
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [inputs, pageName, selections, dataFilterStatus]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (moreFiltersRef.current && !moreFiltersRef.current.contains(event.target) &&
                !event.target.closest('.modal-footer') && !event.target.closest('.modal-header')) {
                handleClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        fetchOrganization()
    }, []);

    useEffect(() => {
        const ignoredFilters = ['page', 'limit', 'active'];
        const appliedFilters = filter.filter(f =>
            f.value !== null &&
            f.value !== undefined &&
            f.value !== "" &&
            !(Array.isArray(f.value) && f.value.length === 0) &&
            !ignoredFilters.includes(f.name)
        );
        let values = [];
        appliedFilters.forEach(f => {
            if (Array.isArray(f.value)) {
                values.push({ name: f.name, values: f.value, type: 'array' });
            } else {
                values.push({ name: f.name, value: f.value, type: 'non-array' });
            }
        });
        const newSelections = { ...selections };
        values.forEach(({ name, values, value, type }) => {
            if (type === 'array') {
                newSelections[name] = values;
            } else {
                newSelections[name] = value;
            }
        });
        setSelections(newSelections);
    }, [filter]);

    const fetchOrganization = fetchDropdown(fetchOrganizationsTitle, "organization", setTitles);

    const handleApplyFilters = async (e) => {
        e.preventDefault();
        const isAnyFilterSelected = Object.values(selections).some(value =>
            Array.isArray(value) ? value.length > 0 : value !== ""
        );
        if (!isAnyFilterSelected) {
            toast.error("Please select at least one filter");
            return;
        }
        const filtersToUpdate = Object.entries(selections)
            .map(([key, value]) => {
                const filterName = Object.keys(filterMapping).find(fn => filterMapping[fn] === key);
                return filterName ? { name: filterName, value } : null;
            })
            .filter(Boolean);
        let updatedFilter = [...filter]; 
        filtersToUpdate.forEach(filter => {
            if (filter.value && (Array.isArray(filter.value) ? filter.value.length > 0 : filter.value !== "")) {
                updatedFilter = updateFilter(updatedFilter, filter.name, filter.value);
            }
        });
        if (!updatedFilter.some(f => f.name === "active")) {
            updatedFilter.push({ name: "active", value: dataFilterStatus });
        }
        if (!updatedFilter.some(f => f.name === "page")) {
            updatedFilter.push({ name: "page", value: 1 });
        }
        setFilter(updatedFilter);
        toast.success("Filters applied");
        handleClose();
    };

    const updateFilter = (filterArray, name, selectedOptions) => {
        const selectedValues = Array.isArray(selectedOptions)
            ? selectedOptions.map(option => option.value || option)
            : [selectedOptions.value || selectedOptions];
        const existingFilterIndex = filterArray.findIndex(f => f.name === name);
        if (existingFilterIndex !== -1) {
            const existingFilter = filterArray[existingFilterIndex];
            const updatedValues = [...new Set([...existingFilter.value, ...selectedValues])];
            if (updatedValues.length === 0) {
                filterArray.splice(existingFilterIndex, 1);
            } else {
                filterArray[existingFilterIndex].value = updatedValues;
            }
        } else {
            if (selectedValues.length > 0) {
                filterArray.push({ name, value: selectedValues });
            }
        }
        return filterArray;
    };

    const handleClearAllFilters = () => {
        const updatedFilter = filter.filter(f => !Object.keys(filterMapping).includes(f.name) && !Object.keys(filterMapping).some(key => f.name === `${key}Ids`));
        setFilter(updatedFilter);
        const updatedSelections = Object.keys(filterMapping).reduce((acc, key) => {
            const normalizedKey = filterMapping[key];
            acc[normalizedKey] = Array.isArray(selections[normalizedKey]) ? [] : "";
            return acc;
        }, {});
        setSelections(updatedSelections);
        const updatedInputs = Object.keys(filterMapping).reduce((acc, key) => {
            acc[filterMapping[key]] = "";
            acc[key] = "";
            return acc;
        }, {});
        setInputs(updatedInputs);
        const updatedMenuStates = Object.keys(filterMapping).reduce((acc, key) => {
            acc[filterMapping[key]] = false;
            acc[key] = false;
            return acc;
        }, {});
        setMenuStates(updatedMenuStates);
        setResetTrigger(prev => prev + 1);
    };

    const renderDropdown = (filterKey) => {
        const config = dropdownConfig[filterKey]
        if (!config) return null;
        return (
            <Dropdown
                isMoreFilters={true}
                id={config.id}
                dropdownRef={dropdownRefs[filterKey]}
                selections={selections}
                setSelections={setSelections}
                titles={titles[filterKey]}
                handleSelectionChange={handleSelectionChange}
                setInputs={setInputs}
                handleInputChange={handleStateChange}
                setSearchOptions={setSearchOptions}
                searchOptions={searchOptions}
                isTyping={isTyping}
                setIsTyping={setIsTyping}
                isSearchable={config.isSearchable}
                menuState={menuStates[filterKey]}
                setMenuStates={setMenuStates}
                handleMenuStateChange={handleStateChange}
                dropdownClassName={"custom-select"}
                resetTrigger={resetTrigger}
            />
        );
    };

    const renderFilterComponents = () => {
        return Object.keys(moreFiltersDropdown[0]).map((filterKey) => {
            if (!moreFiltersDropdown[0][filterKey]) return null;
            return renderDropdown(filterKey);
        });
    };

    const handleRemoveFilter = (filterKey) => {
        const filterMapping = {
            organizationIds: 'organization',
            programIds: 'program',
            levelIds: 'level',
            domainIds: 'domain',
            topicIds: 'topic',
            skillIds: 'skill',
            flaggedBy: 'flaggedBy',
            qtype: 'questionType',
            status: 'status',
            flag: 'flag',
            code: 'code',
            difficulty: 'complexity',
        };
        const normalizedKey = filterMapping[filterKey] || filterKey;
        setFilter((prevFilters) => {
            const updatedFilters = prevFilters.filter(f => !(f.name === filterKey || f.name === `${filterKey}Ids`));
            return updatedFilters;
        });
        setSelections((prevSelections) => {
            const updatedSelections = { ...prevSelections };
            if (normalizedKey in updatedSelections) {
                updatedSelections[normalizedKey] = Array.isArray(updatedSelections[normalizedKey]) ? [] : "";
            }
            if (filterKey in updatedSelections) {
                updatedSelections[filterKey] = Array.isArray(updatedSelections[filterKey]) ? [] : "";
            }
            return updatedSelections;
        });
        setInputs((prevInputs) => {
            const updatedInputs = { ...prevInputs };
            updatedInputs[normalizedKey] = "";
            updatedInputs[filterKey] = "";
            return updatedInputs;
        });
        setMenuStates((prevMenuStates) => {
            const updatedMenuStates = { ...prevMenuStates };
            updatedMenuStates[normalizedKey] = false;
            updatedMenuStates[filterKey] = false;
            return updatedMenuStates;
        });
        setResetTrigger(prev => prev + 1)
    };


    return (
        <div>
            <CustomModal show={show} onHide={handleClose}>
                <CustomModalHeader>
                    <Modal.Title>More Filters</Modal.Title>
                    <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
                </CustomModalHeader>
                <Modal.Body ref={moreFiltersRef}>
                    <div>
                        <span className="active-filters-header">Applied Filters</span>
                        <div className="active-filter-container">
                            {Object.keys(selections).map((key) => {
                                const selectionValue = selections[key];
                                if (Array.isArray(selectionValue) && selectionValue.length > 0) {
                                    const displayKey = key.replace(/Ids$/, '');
                                    return (
                                        <div key={key} className="active-filters">
                                            <span>
                                                {splitCamelCaseAndCapitalize(displayKey)}
                                                <span className="filter-cross" onClick={() => handleRemoveFilter(key)}>
                                                    &#10006;
                                                </span>
                                            </span>
                                        </div>
                                    );
                                } else if (typeof selectionValue === "string" && selectionValue !== "") {
                                    return (
                                        <div key={key} className="active-filters">
                                            <span>
                                                {capitalizeFirstLetter(key)}: {selectionValue}
                                                <span className="filter-cross" onClick={() => handleRemoveFilter(key)}>
                                                    &#10006;
                                                </span>
                                            </span>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                            {Object.values(selections).some((value) =>
                                Array.isArray(value) ? value.length > 0 : value !== ""
                            ) && (
                                    <div className="clear-all-filters" onClick={handleClearAllFilters}>
                                        Clear All Filters
                                    </div>
                                )}

                            <hr />
                        </div>
                    </div>
                    <CustomForm onSubmit={handleApplyFilters}>
                        <div className="filters-container">
                            {renderFilterComponents()}
                        </div>
                    </CustomForm>
                </Modal.Body>
                <CustomModalFooter>
                    <ModalButton isClose onClick={handleClose} style={{ width: "7.5rem" }}>
                        Cancel
                    </ModalButton>
                    <ModalButton type="submit" style={{ width: "10.75rem" }} onClick={handleApplyFilters}>
                        Apply Filters
                    </ModalButton>
                </CustomModalFooter>
            </CustomModal>
        </div>
    )
}

export default MoreFilters;