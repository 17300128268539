import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { editQuestion } from 'Utils/ApiHandler/QuestionBuilderApi';
import { addVocabularyQuestion } from 'Utils/ApiHandler/SpellAndVocabServices/QuestionsService';
import { openErrorModal } from '../../../../redux/slices/ValidationErrorSlices/validationErrorSlice';
import { openSaveModal } from '../../../../redux/slices/ModalSlice/SaveModalSlice';


export const addVocabularyQuestionThunk = createAsyncThunk(
  'vocabularyQuestions/addVocabularyQuestion',
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await addVocabularyQuestion(formData);
      if (response.success) {
        dispatch(openSaveModal())
        return response;
      } else {
        if (Array.isArray(response)) {
          const errorMessages = response.map(error => error.msg);
          dispatch(openErrorModal(errorMessages));
          return rejectWithValue(errorMessages.join(', ') || "Failed to add question");
        } else {
          dispatch(openErrorModal(["Failed to add question"]));
          return rejectWithValue("Failed to add question");
        }
      }
    } catch (error) {
      console.log("Error:", error);
      dispatch(openErrorModal([error.message]));
      return rejectWithValue(error.message);
    }
  }
);


export const editVocabularyQuestionThunk = createAsyncThunk(
  'vocabularyQuestions/editVocabularyQuestion',
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await editQuestion(formData);
      if (response.success) {
        return response;
      } else {
        if (Array.isArray(response)) {
          dispatch(openErrorModal([response]));
          return rejectWithValue(response.message || "Failed to edit question");
        } else {
          dispatch(openErrorModal(["Failed to edit question"]));
          return rejectWithValue("Failed to edit question");
        }
      }
    } catch (error) {
      dispatch(openErrorModal([error.message]));
      return rejectWithValue(error.message);

    }
  }
);


const vocabularyQuestionsSlice = createSlice({
  name: 'vocabularyQuestions',
  initialState: {
    loading: false,
    successMessage: '',
    errorMessage: '',
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(addVocabularyQuestionThunk.pending, (state) => {
        state.loading = true;
        state.successMessage = '';
        state.errorMessage = '';
      })
      .addCase(addVocabularyQuestionThunk.fulfilled, (state) => {
        state.loading = false;
        state.successMessage = 'Question added successfully';
        state.errorMessage = '';
      })
      .addCase(addVocabularyQuestionThunk.rejected, (state, action) => {
        state.loading = false;
        state.successMessage = '';
        state.errorMessage = action.payload || 'Failed to add question';
      })
      
      .addCase(editVocabularyQuestionThunk.pending, (state) => {
        state.loading = true;
        state.successMessage = '';
        state.errorMessage = '';
      })
      .addCase(editVocabularyQuestionThunk.fulfilled, (state) => {
        state.loading = false;
        state.successMessage = 'Question edited successfully';
        state.errorMessage = '';
      })
      .addCase(editVocabularyQuestionThunk.rejected, (state, action) => {
        state.loading = false;
        state.successMessage = '';
        state.errorMessage = action.payload || 'Failed to edit question';
      })

  },
});

export default vocabularyQuestionsSlice.reducer;
