import React from "react";
import DbConfig from "Constants/db";

/**
 * Logout
 *
 * Logout component that removes the token from local storage and redirects to the login page.
 *
 * @returns {JSX.Element} The rendered Logout component.
 *
 * @author - Gaurav
 */
const Logout = () => {
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("activeItem");
    deleteUserData();
    window.location.href = "/login";
  };

  const deleteUserData = async () => {
    await DbConfig.accessToken.clear();
    await DbConfig.userDetails.clear();
    await DbConfig.curriculumAccess.clear();
    await DbConfig.settingsAccess.clear();
  };

  React.useEffect(() => {
    handleLogout();
  }, []);

  return null;
};

export default Logout;
