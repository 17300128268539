/**
 * Private route
 * 
 * @param {object} props - Component properties.
 * 
 * @returns {JSX.Element} PrivateRoute component.
 * 
 * @author - Gaurav
 */
import React from "react";
import { isAuthenticated } from "../Utils/AuthUtils";
import { Navigate, Outlet } from "react-router-dom";

function PrivateRoute({ element: Element, ...rest }) {
  return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;
