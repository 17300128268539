import { useState, useEffect } from 'react';
import { getRelativeTimeElapsed } from 'Utils/Date_TimeUtilitites';

/**
 * Hook to get the relative time elapsed from a given timestamp
 * and update every minute.
 *
 * @param {number} timestamp - The timestamp to calculate the relative time from.
 * @returns {string} The relative time elapsed.
 */
const useRelativeTimeElapsed = (timestamp) => {

    const [relativeTime, setRelativeTime] = useState(getRelativeTimeElapsed(timestamp));

    useEffect(() => {
        const interval = setInterval(() => {
            setRelativeTime(getRelativeTimeElapsed(timestamp));
        }, 60000);

        return () => clearInterval(interval);
    }, [timestamp]);

    return relativeTime;
};

export default useRelativeTimeElapsed;
