import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isErrorModalOpen: false, 
    messages: [], 
};

const errorModalSlice = createSlice({
    name: 'errorModal',
    initialState,
    reducers: {
        openErrorModal: (state, action) => {
            state.isErrorModalOpen = true;
            state.messages = action.payload; 
        },
        closeErrorModal: (state) => {
            state.isErrorModalOpen = false;
            state.messages = []; 
        },
    },
});

export const { openErrorModal, closeErrorModal } = errorModalSlice.actions;

export default errorModalSlice.reducer;
