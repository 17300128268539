import React, { useState, useRef, useEffect, useCallback } from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";
import filterIcon from "../../Assests/Images/filter_1.svg";
import { FilterWrapper } from "./FilterHeader.styled";
import { TbFilterX } from "react-icons/tb";
import _debounce from "lodash/debounce";
import _ from "lodash";
import { toast } from "react-toastify";
import { fetchOrganizationsTitle } from "Utils/ApiHandler/FilterDropdownsApiCalls";
import { fetchToken } from "Constants/ApiUrls";


/**
 * FilterHeader
 *
 * Component for displaying a filter header with various filter options.
 *
 * @param {object} props - Component props
 * @param {array} props.firstDropdown - Options for the first dropdown
 * @param {array} props.secondDropdown - Options for the second dropdown
 * @param {array} props.thirdDropdown - Options for the third dropdown
 * @param {string} props.firstText - Placeholder text for the first dropdown
 * @param {string} props.secondText - Placeholder text for the second dropdown
 * @param {string} props.thirdText - Placeholder text for the third dropdown
 * @param {string} props.buttonText - Text for the button
 * @returns {JSX.Element} The rendered FilterHeader component
 *
 * @author Pranathi
 */
const FilterHeader = ({
    firstDropdown,
    secondDropdown,
    thirdDropdown,
    fourthDropdown,
    programsDropdown,
    firstText,
    secondText,
    thirdText,
    fourthText,
    showOrganizationDropdown,
    programsDropdownText,
    showDropdown,
    showMoreFilters,
    onCodeChange,
    onTitleChange,
    onInputCodeChange,
    onInputTitleChange,
    onInputProgramChange,
    onInputRoleChange,
    onRoleChange,
    filter,
    setFilter,
    resetFilter,
    handleMoreFilter,
    setDataFilterStatus,
    showCreateButton,
    handleCreateButton,
    createButtonText,
    complexityDropdown,
    complexityText,
    showComplexityDropdown,
    showTitleDropdown,
    showRoleDropdown,
    pageName,
    searchOptions,
    setSearchOptions,
    setIsActive,
    onOrganizationChange,
    onProgramChange,
    showFlagDropdown,
    showStatusDropdown,
    showQuestionTypeDropdown,
    showSkillsDropdown,
    setGoToPage,
    isPageChange
}) => {
    const [isChecked, setIsChecked] = useState(true);
    const [search, setSearchInput] = useState("");
    const [isCodeMenuOpen, setIsCodeMenuOpen] = useState(false);
    const [isLevelMenuOpen, setIsLevelMenuOpen] = useState(false);
    const [isRoleMenuOpen, setIsRoleMenuOpen] = useState(false);
    const [isOrganizationMenuOpen, setIsOrganizationMenuOpen] = useState(false);
    const [isProgramsMenuOpen, setIsProgramsMenuOpen] = useState(false);
    const [statusFilter, setStatusFilter] = useState("");
    const codeSelectRef = useRef(null);
    const titleSelectRef = useRef(null);
    const roleSelectRef = useRef(null);
    const organizationSelectRef = useRef(null);
    const programSelectRef = useRef(null);
    const [activeFilters, setActiveFilters] = useState(0);
    const [isProgramPage, setIsProgramPage] = useState(false);
    const [resetStatus, setResetStatus] = useState(false);
    const [selectedCode, setSelectedCode] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState([]);
    const [complexityFilter, setComplexityFilter] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [isUsersPage, setIsUsersPage] = useState(false);
    const [isTeamsPage, setIsTeamsPage] = useState(false);
    const [isRolesPage, setIsRolesPage] = useState(false);
    const [isOrganizationsPage, setIsOrganizationsPage] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [organizationTitle, setOrganizationTitle] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [goToPage, setGoToPageInput] = useState("");

    const token = fetchToken();
    const getOrganization =
        process.env.REACT_APP_BASE_URL + "/cms/v1/getAllOrganizationTitles";
    useEffect(() => {
        fetchOrganizationDropdown();
    }, []);

    const fetchOrganizationDropdown = _.debounce(async () => {
        const data = await fetchOrganizationsTitle();
        if (data) {
            setOrganizationTitle(data);
        } else {
            setSearchOptions(true);
        }
    }, 1000);

    useEffect(() => {
        let filterCount = 0;
        let isFilterActive = false;

        if (filter.find((el) => el.name === "organization")?.value.length) {
            filterCount++;
            isFilterActive = true;
        }
        if (filter.find((el) => el.name === "programIds")?.value.length) {
            filterCount++;
            isFilterActive = true;
        }
        if (filter.find((el) => el.name === "levelIds")?.value.length) {
            filterCount++;
            isFilterActive = true;
        }
        if (filter.find((el) => el.name === "flag" && el.value !== "")) {
            filterCount++;
            isFilterActive = true;
        }
        if (filter.find((el) => el.name === "topicIds")?.value.length) {
            filterCount++;
            isFilterActive = true;
        }
        if (filter.find((el) => el.name === "domainIds")?.value.length) {
            filterCount++;
            isFilterActive = true;
        }
        if (filter.find((el) => el.name === "qtype" && el.value !== "")) {
            filterCount++;
            isFilterActive = true;
        }
        if (filter.find((el) => el.name === "skillIds")?.value.length) {
            filterCount++;
            isFilterActive = true;
        }

        if (filter.find((el) => el.name === "team")?.value.length) {
            filterCount++;
            isFilterActive = true;
        }
        if (filter.find((el) => el.name === "organizationIds")?.value.length) {
            filterCount++;
            isFilterActive = true;
        }
        if (filter.find((el) => el.name === "search")?.value.length) isFilterActive = true;
        if (filter.find((el) => el.name === "title")?.value.length) isFilterActive = true;
        if (filter.find((el) => el.name === "code")?.value.length) isFilterActive = true;
        if (filter.find((el) => el.name === "status" && el.value !== '')) isFilterActive = true;
        if (filter.find((el) => el.name === "difficulty" && el.value !== '')) isFilterActive = true;
        if (filter.find((el) => el.name === "organizationIds")?.value.length) isFilterActive = true;
        if (filter.find((el) => el.name === "roleName")?.value.length) isFilterActive = true;
        if (filter.find((el) => el.name === "roleId")?.value.length) isFilterActive = true;
        if (filter.find((el) => el.name === "roleIds")?.value.length) isFilterActive = true;
        if (filter.find((el) => el.name === "teamIds")?.value.length) isFilterActive = true;
        if (filter.find((el) => el.name === "name")?.value.length) isFilterActive = true;
        if (filter.find((el) => el.name === "programIds")?.value.length) isFilterActive = true;
        if (goToPage.trim() !== "") {
            isFilterActive = true;
        }
        setResetStatus(isFilterActive);
        setActiveFilters(filterCount);
    }, [filter]);

    useEffect(() => {
        if (!searchOptions) {
            setIsTyping(false);
        }
    }, [searchOptions])

    useEffect(() => {
        if (isPageChange === 0) setGoToPageInput("");
    }, [isPageChange]);


    const pathName = window.location.pathname;
    useEffect(() => {
        setIsProgramPage(pathName.includes("/curriculum/program"));
        setIsUsersPage(pathName.includes("/curriculum/users"));
        setIsTeamsPage(pathName.includes("/teams"));
        setIsRolesPage(pathName.includes("/roles"));
    }, [pathName]);

    const toggleButtonClassName = isProgramPage || isUsersPage || isTeamsPage || isRolesPage || isOrganizationsPage
        ? "form-switch program-page-toggle"
        : "form-switch filter-toggle";

    const CheckboxOption = (props) => (
        <components.Option {...props}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <input
                    type={props.isMulti ? 'checkbox' : 'radio'}
                    checked={props.isSelected}
                    onChange={() => props.selectOption(props.data)}
                    style={{
                        marginRight: "5px",
                        flexShrink: 0,
                    }}
                />
                <span style={{ color: "#000" }}>{props.label}</span>
            </div>
        </components.Option>
    );

    const handleAciveInactiveData = (e) => {
        let filterCount = 0;
        setIsChecked(e.target.checked);
        let previousFilter = filter.filter(
            (f) => f.name !== "active" && f.name !== "page" && f.name !== "organizationIds"
                && f.name !== "programIds" && f.name !== "levelIds" && f.name !== "flag" && f.name !== "domainIds"
                && f.name !== "topicIds" && f.name !== "skillIds" && f.name !== "qtype" && f.name !== "difficulty"
                && f.name !== "code" && f.name !== "title" && f.name !== "search" && f.name !== "status"
                && f.name !== "team" && f.name !== "goToPage"
        );
        let updatedFilter = [
            ...previousFilter,
            {
                name: "active",
                value: e.target.checked,
            },
            {
                name: "page",
                value: 1,
            },
        ];
        setFilter(updatedFilter);
        setSearchInput("");
        setGoToPageInput("");
        setStatusFilter(null);
        setTimeout(() => {
            if (codeSelectRef.current) {
                codeSelectRef.current.setValue([]);
            }
            if (titleSelectRef.current) {
                titleSelectRef.current.setValue([]);
            }
            if (roleSelectRef.current) {
                roleSelectRef.current.setValue([]);
            }
        }, 0);
        setComplexityFilter("")
        setDataFilterStatus(e.target.checked);
        setTimeout(() => {
        }, 2000);
        setActiveFilters(filterCount);
    };

    const debouncedSearch = useCallback(
        _debounce((value, inputLabel) => {
            let previousFilter = filter.filter((f) => f.name !== inputLabel);
            let updatedFilter = [
                ...previousFilter,
                {
                    name: inputLabel,
                    value: value,
                },
            ];
            setFilter(updatedFilter);
        }, 1000),
        [filter]
    );

    const handleSearchInput = (event, setFuncName, inputLabel) => {
        setFuncName(event.target.value);
        if (inputLabel == "page") {
            setGoToPage(event.target.value);
            if (!event.target.value) {
                event.target.value = 1;
            }
        }
        else {
            setGoToPage(0);
        }
        debouncedSearch(event.target.value, inputLabel);
    };

    const handleStatus = (selectedOption) => {
        if (selectedOption !== null && selectedOption !== undefined) {
            setStatusFilter(selectedOption.value);
            let previousFilter = filter.filter((f) => f.name !== "status");
            let updatedFilter = [
                ...previousFilter,
                {
                    name: "status",
                    value: selectedOption.value,
                },
            ];
            setFilter(updatedFilter);
        } else {
            setStatusFilter(null);
            let previousFilter = filter.filter((f) => f.name !== "status");
            setFilter(previousFilter);
        }
    };


    const handleComplexity = (selectedOption) => {
        if (selectedOption) {
            setComplexityFilter(selectedOption.value);
            let previousFilter = filter.filter((f) => f.name !== "difficulty");
            let updatedFilter = [
                ...previousFilter,
                {
                    name: "difficulty",
                    value: selectedOption.value,
                },
            ];
            setFilter(updatedFilter);
        } else {
            setComplexityFilter(null);
            let previousFilter = filter.filter((f) => f.name !== "difficulty");
            setFilter(previousFilter);
        }
    };

    const formatOptionLabel = ({ label }) => <span>{label}</span>;

    const toggleMoreFilters = (e) => {
        handleMoreFilter();
    };

    const createButton = (e) => {
        handleCreateButton();
        setIsActive(true)
    };
    const handleCodeChange = (selectedOptions) => {
        setSelectedCode(selectedOptions.map((option) => option.value));
        onCodeChange(selectedOptions);
    };

    const handleTitleChange = (selectedOptions) => {
        setSelectedTitle(selectedOptions.map((option) => option.value));
        onTitleChange(selectedOptions);
    };

    const handleRoleChange = (selectedOptions) => {
        setSelectedRole(selectedOptions.map((option) => option.value));
        onRoleChange(selectedOptions);
    };

    const handleOrganizationChange = (selectedOptions) => {
        setSelectedOrganization(selectedOptions.map((option) => option.value));
        onOrganizationChange(selectedOptions);
    };

    const handleProgramChange = (selectedOptions) => {
        setSelectedProgram(selectedOptions.map((option) => option.value));
        onProgramChange(selectedOptions);
    };

    const codePlaceholderWithCount = () => {
        if (selectedCode.length === 0) {
            return firstText;
        } else {
            return `${firstText}(${selectedCode.length})`;
        }
    };
    const titlePlaceholderWithCOunt = () => {
        if (selectedTitle.length === 0) {
            return secondText;
        } else {
            return `${secondText}(${selectedTitle.length})`;
        }
    };

    const rolePlaceholderWithCOunt = () => {
        if (selectedRole.length === 0) {
            return fourthText;
        } else {
            return `${fourthText}(${selectedRole.length})`;
        }
    };
    const organizationPlaceholderWithCount = () => {
        if (selectedOrganization.length === 0) {
            return "Organizations";
        } else {
            return `${"Organizations"}(${selectedOrganization.length})`;
        }
    };

    const programsPlaceholderWithCount = () => {
        if (selectedProgram.length === 0) {
            return programsDropdownText;
        } else {
            return `${programsDropdownText}(${selectedProgram.length})`;
        }
    };

    const handleRemoveFilter = (e) => {
        //setIsChecked(true);
        setSearchInput("");
        setGoToPageInput("");
        setStatusFilter(null);
        setComplexityFilter("")
        resetFilter();
        setTimeout(() => {
            if (codeSelectRef.current) {
                codeSelectRef.current.setValue([]);
            }
            if (titleSelectRef.current) {
                titleSelectRef.current.setValue([]);
            }
            if (roleSelectRef.current) {
                roleSelectRef.current.setValue([]);
            }
            if (organizationSelectRef.current) {
                organizationSelectRef.current.setValue([]);
            }
            if (programSelectRef.current) {
                programSelectRef.current.setValue([]);
            }
        }, 0);
        setResetStatus(false);
    };

    return (
        <FilterWrapper>
            <div className="filter-container">
                {/* <div className="input-search-container">
                    <div className="input-search-wrapper">
                        <input
                            className="input-search"
                            type="number"
                            placeholder="Page"
                            min="1"
                            onChange={event => handleSearchInput(event, setGoToPageInput, 'page')}
                            value={goToPage}
                        />
                    </div>
                </div> */}
                <div className="search-container">
                    <div className="search-wrapper">
                        <i className="fas fa-search search-icon"></i>
                        <input
                            className="search"
                            type="search"
                            placeholder="Search"
                            onChange={event => handleSearchInput(event, setSearchInput, 'search')}
                            value={search}
                            styles={{
                                placeholder: (base) => ({
                                    ...base,
                                    paddingLeft: "0.25rem",
                                }),
                            }}
                        />
                    </div>
                </div>
                <div className="filter-dropdown-container">
                    <Select
                        ref={codeSelectRef}
                        className="filter-dropdown"
                        placeholder={codePlaceholderWithCount()}
                        options={isRolesPage ? organizationTitle : firstDropdown}
                        isDisabled={firstText === 'Organization'}
                        onInputChange={(inputValue, { action }) => {
                            setSearchOptions(true);
                            if (inputValue === '') {
                                setIsTyping(false);
                            } else if (action === 'input-change') {
                                setIsTyping(true);
                            } else if (action === 'menu-close' || action === 'input-blur') {
                                setIsTyping(false);
                            }

                            onInputCodeChange(inputValue);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                        onChange={handleCodeChange}
                        isMulti
                        components={{
                            Option: CheckboxOption,
                            NoOptionsMessage: () => (
                                <div style={{ textAlign: "center", padding: "0.4rem", width: "8.5rem" }}>
                                    {isRolesPage ? (
                                        <span>Please Wait...</span>
                                    ) : (
                                        <>
                                            {isTyping ? (
                                                <span style={{ color: "var(--sky-blue-color)" }}>Searching...</span>
                                            ) : (
                                                searchOptions ? (
                                                    "Type something..."
                                                ) : (
                                                    "No options"
                                                )
                                            )}
                                        </>
                                    )}
                                </div>
                            ),
                        }}
                        menuIsOpen={isCodeMenuOpen}
                        onMenuOpen={() => setIsCodeMenuOpen(true)}
                        onMenuClose={() => setIsCodeMenuOpen(false)}
                        formatOptionLabel={formatOptionLabel}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        controlShouldRenderValue={false}
                        menuPlacement="bottom"
                        styles={{
                            option: (base) => ({
                                ...base,
                                backgroundColor: "var(--white-color) !important",
                            }),
                            menu: (base) => ({
                                ...base,
                                marginTop: '-4px',
                                zIndex: 9999,
                                width: "auto",
                            }),
                            placeholder: (base) => ({
                                ...base,
                                color: selectedCode.length > 0 ? "#347bfa" : "#000",
                            }),
                        }}
                    />
                    {showTitleDropdown && (
                        <Select
                            className='filter-dropdown'
                            ref={titleSelectRef}
                            placeholder={titlePlaceholderWithCOunt()}
                            options={isTeamsPage ? organizationTitle : secondDropdown}
                            isDisabled={secondText === 'Organization'}
                            onInputChange={(inputValue, { action }) => {
                                setSearchOptions(true);
                                if (inputValue === '') {
                                    setIsTyping(false);
                                } else if (action === 'input-change') {
                                    setIsTyping(true);
                                } else if (action === 'menu-close' || action === 'input-blur') {
                                    setIsTyping(false);
                                }
                                onInputTitleChange(inputValue);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                            onChange={handleTitleChange}
                            isMulti
                            components={{
                                Option: CheckboxOption,
                                NoOptionsMessage: () => (
                                    <div style={{ textAlign: "center", padding: "0.4rem", width: "8.5rem" }}>
                                        {isTeamsPage ? (
                                            <span>Please Wait...</span>
                                        ) : (
                                            <>
                                                {isTyping ? (
                                                    <span style={{ color: "var(--sky-blue-color)" }}>Searching...</span>
                                                ) : (
                                                    searchOptions ? (
                                                        "Type something..."
                                                    ) : (
                                                        "No options"
                                                    )
                                                )}
                                            </>
                                        )}
                                    </div>
                                ),
                            }}
                            menuIsOpen={isLevelMenuOpen}
                            onMenuOpen={() => setIsLevelMenuOpen(true)}
                            onMenuClose={() => setIsLevelMenuOpen(false)}
                            formatOptionLabel={formatOptionLabel}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            controlShouldRenderValue={false}
                            menuPlacement="bottom"
                            styles={{
                                option: (base) => ({
                                    ...base,
                                    backgroundColor: "var(--white-color) !important",
                                }),
                                menu: (base) => ({
                                    ...base,
                                    marginTop: '-4px',
                                    zIndex: 9999,
                                    width: "auto",
                                }),
                                placeholder: (base) => ({
                                    ...base,
                                    color: selectedTitle.length > 0 ? "#347bfa" : "#000",
                                }),
                            }}
                        />
                    )}
                    {showDropdown && (
                        <Select
                            className="filter-dropdowns"
                            placeholder={thirdText}
                            isDisabled={thirdText === 'Organization'}
                            options={thirdDropdown}
                            value={
                                statusFilter !== null && statusFilter !== undefined
                                    ? thirdDropdown.find(option => option.value === statusFilter)
                                    : null
                            }
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                            onChange={handleStatus}
                            isClearable
                            components={{
                                Option: CheckboxOption,
                            }}
                            isSearchable={false}
                            styles={{
                                menu: (base) => ({
                                    ...base,
                                    marginTop: '-4px',
                                    zIndex: 9999,
                                }),
                                option: (base) => ({
                                    ...base,
                                    backgroundColor: "var(--white-color) !important",
                                }),
                                singleValue: (base, { data }) => ({
                                    ...base,
                                    color: data.value === statusFilter ? '#347bfa' : 'black',
                                }),
                                placeholder: (base) => ({
                                    ...base,
                                    color: "#000",
                                }),
                            }}
                        />
                    )}
                    {showOrganizationDropdown && (<Select
                        ref={organizationSelectRef}
                        className="filter-dropdown"
                        placeholder={organizationPlaceholderWithCount()}
                        options={organizationTitle}
                        isSearchable={false}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                        onChange={handleOrganizationChange}
                        isMulti
                        components={{ Option: CheckboxOption }}
                        menuIsOpen={isOrganizationMenuOpen}
                        onMenuOpen={() => setIsOrganizationMenuOpen(true)}
                        onMenuClose={() => setIsOrganizationMenuOpen(false)}
                        formatOptionLabel={formatOptionLabel}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        controlShouldRenderValue={false}
                        menuPlacement="bottom"
                        styles={{
                            option: (base) => ({
                                ...base,
                                backgroundColor: "var(--white-color) !important",
                            }),
                            menu: (base) => ({
                                ...base,
                                marginTop: '-4px',
                                zIndex: 9999,
                                width: "auto",
                            }),
                            placeholder: (base) => ({
                                ...base,
                                color: selectedOrganization.length > 0 ? "#347bfa" : "#000",
                            }),
                        }}
                    />
                    )}
                    {programsDropdownText && (<Select
                        className="filter-dropdown"
                        ref={programSelectRef}
                        placeholder={programsPlaceholderWithCount()}
                        options={programsDropdown}
                        isSearchable={true}
                        onInputChange={(inputValue, { action }) => {
                            setSearchOptions(true);
                            if (inputValue === '') {
                                setIsTyping(false);
                            } else if (action === 'input-change') {
                                setIsTyping(true);
                            } else if (action === 'menu-close' || action === 'input-blur') {
                                setIsTyping(false);
                            }
                            onInputProgramChange(inputValue);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                        onChange={handleProgramChange}
                        isMulti
                        components={{
                            Option: CheckboxOption,
                            NoOptionsMessage: () => (
                                <div style={{ textAlign: "center", padding: "0.4rem", width: "8.5rem" }}>
                                    {isTeamsPage ? (
                                        <span>Please Wait...</span>
                                    ) : (
                                        <>
                                            {isTyping ? (
                                                <span style={{ color: "var(--sky-blue-color)" }}>Searching...</span>
                                            ) : (
                                                searchOptions ? (
                                                    "Type something..."
                                                ) : (
                                                    "No options"
                                                )
                                            )}
                                        </>
                                    )}
                                </div>
                            ),
                        }}
                        menuIsOpen={isProgramsMenuOpen}
                        onMenuOpen={() => setIsProgramsMenuOpen(true)}
                        onMenuClose={() => setIsProgramsMenuOpen(false)}
                        formatOptionLabel={formatOptionLabel}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        controlShouldRenderValue={false}
                        menuPlacement="bottom"
                        styles={{
                            option: (base) => ({
                                ...base,
                                backgroundColor: "var(--white-color) !important",
                            }),
                            menu: (base) => ({
                                ...base,
                                marginTop: '-4px',
                                zIndex: 9999,
                                width: "auto",
                            }),
                            placeholder: (base) => ({
                                ...base,
                                color: selectedProgram.length > 0 ? "#347bfa" : "#000",
                            }),
                        }}
                    />
                    )}
                    {showComplexityDropdown && (
                        <Select
                            className="filter-dropdowns"
                            placeholder={complexityText}
                            options={complexityDropdown}
                            value={
                                complexityFilter
                                    ? complexityDropdown.find(
                                        (option) => option.value === complexityFilter
                                    )
                                    : null
                            }
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                            onChange={handleComplexity}
                            isClearable
                            components={{
                                Option: CheckboxOption,
                            }}
                            isSearchable={false}
                            styles={{
                                menu: (base) => ({
                                    ...base,
                                    marginTop: "0.1rem",
                                    zIndex: 9999,
                                }),
                                option: (base) => ({
                                    ...base,
                                    backgroundColor: "var(--white-color) !important",
                                }),
                                singleValue: (base, { data }) => ({
                                    ...base,
                                    color: data.value === complexityFilter ? '#347bfa' : 'black',
                                }),
                                placeholder: (base) => ({
                                    ...base,
                                    color: "#000",
                                }),
                            }}
                        />
                    )}
                    {showRoleDropdown && (
                        <Select
                            ref={roleSelectRef}
                            className="filter-dropdowns"
                            placeholder={rolePlaceholderWithCOunt()}
                            options={fourthDropdown}
                            onInputChange={(inputValue, { action }) => {
                                setSearchOptions(true);
                                if (inputValue === '') {
                                    setIsTyping(false);
                                } else if (action === 'input-change') {
                                    setIsTyping(true);
                                } else if (action === 'menu-close' || action === 'input-blur') {
                                    setIsTyping(false);
                                }
                                onInputRoleChange(inputValue);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                            onChange={handleRoleChange}
                            isMulti
                            components={{
                                Option: CheckboxOption,
                                NoOptionsMessage: () => (
                                    <div style={{ textAlign: "center", padding: "0.4rem", width: "8.5rem" }}>
                                        {isTyping ? <span style={{ color: "var(--sky-blue-color)" }}>Searching...</span>
                                            : (searchOptions ? "Type something..." : "No options")}
                                    </div>
                                ),
                            }}
                            menuIsOpen={isRoleMenuOpen}
                            onMenuOpen={() => setIsRoleMenuOpen(true)}
                            onMenuClose={() => setIsRoleMenuOpen(false)}
                            formatOptionLabel={formatOptionLabel}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            controlShouldRenderValue={false}
                            menuPlacement="bottom"
                            styles={{
                                option: (base) => ({
                                    ...base,
                                    backgroundColor: "var(--white-color) !important",
                                }),
                                menu: (base) => ({
                                    ...base,
                                    marginTop: '-4px',
                                    zIndex: 9999,
                                    width: "auto",
                                }),
                                placeholder: (base) => ({
                                    ...base,
                                    color: selectedRole.length > 0 ? "#347bfa" : "#000",
                                }),
                            }}
                        />
                    )}
                </div>
                {showMoreFilters && (
                    <div className="moreFilters-container">
                        <button
                            className={activeFilters ? "moreFilterActive" : "moreFilters"}
                            onClick={toggleMoreFilters}
                        >
                            <img className="moreFilters-icon" src={filterIcon} alt="Logo" />
                            More Filters{activeFilters ? "(" + activeFilters + ")" : ""}
                        </button>
                    </div>
                )}
                {resetStatus && (
                    <div className="reset-Filter">
                        <TbFilterX
                            style={{
                                width: "24px",
                                height: "24px",
                                color: "#7A8892",
                                cursor: "pointer",
                            }}
                            onClick={handleRemoveFilter}
                        />
                    </div>
                )}
                <div className={toggleButtonClassName}>
                    <>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={isChecked}
                            onChange={handleAciveInactiveData}
                        />
                        <label className="label" htmlFor="flexSwitchCheckDefault">
                            {isChecked ? `Active ${pageName}` : `Inactive ${pageName}`}
                        </label>
                    </>
                </div>
                <div className="create-program-button">
                    {showCreateButton && isChecked && (
                        <button className="add-program-button" onClick={handleCreateButton} data-testid="add-button">
                            {createButtonText}
                        </button>
                    )}
                </div>
            </div>
        </FilterWrapper>
    );
};

FilterHeader.propTypes = {
    thirdDropdown: PropTypes.array.isRequired,
    firstText: PropTypes.string,
    secondText: PropTypes.string,
    // thirdText: PropTypes.string,
    // buttonText: PropTypes.string.isRequired,
    onCodeChange: PropTypes.func,
    statusFilter: PropTypes.object,
    codeFilter: PropTypes.object,
    filter: PropTypes.array,
    setFilter: PropTypes.func,
};

export default FilterHeader;