import "./stylesQuestions.css";
import Options from "./Options";
import Select from "react-select";

import _ from "lodash";
import Question from "./Questions";
import DbConfig from "Constants/db";
import Loader from "Components/Loader/Loader";
import questionConfig from "./questionConfig";
import React, { useState, useEffect } from "react";
import Card from "../../../../Components/Card/Card";
import Title from "../../../../Components/Title/Title";
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorModal from "../../../../Components/Modals/ErrorModal/ErrorModal";
import { getQuestionById, publishQuestion } from "../../../../Utils/ApiHandler/QuestionApi";
import QuestionSaveModal from "../../../../Components/Modals/Questions/QuestionSaveModal";
import {
    getQuestionTypes,
    addQuestion,
    insertPreviewQuestion,
    insertPreviewQuestionRC,
    editQuestion,
    insertQuestionRC,
} from "../../../../Utils/ApiHandler/QuestionBuilderApi";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    ButtonGroup
} from 'reactstrap';
import CKEditorComponent from "./CKEditorComponent";

/**
 * AddQuestion
 *
 * Controller to add New Question
 * @returns {JSX.Element} - Rendered component
 *
 * @author - Gaurav
 */
function AddQuestion() {
    const queryParams = new URLSearchParams(window.location.search);

    const location = useLocation();
    const navigate = useNavigate();

    const allowedValues = [2, 11, 22];
    const disabledValues = [4, 5, 6, 13, 15, 14, 20];
    var title =
        location.pathname === "/curriculum/addQuestion"
            ? "Add Question"
            : "Edit Question";
    const previewQuestionFormat = {
        questionType: "",
        questionText: "",
        optionsData: [],
        questionImage: "",
        rcQuestionType: null,
        answer: "",
        answerExplanation: "",
        resource: ''
    };
    const [previewQuestions, setPreviewQuestions] = useState([
        previewQuestionFormat,
    ]);

    var questionId = queryParams.get("id");
    var skillName = queryParams.get("skillName");
    var skillId = queryParams.get("id") || queryParams.get("skillId");

    const [errors, setErrors] = useState([]);
    const [passage, setPassage] = useState("");
    const [loader, setLoader] = useState(false);
    const [solution, setSolution] = useState("");
    const [bTaxonomy, setBTaxonomy] = useState("");
    const [showMath, setShowMath] = useState(false);
    const [previewId, setPreviewId] = useState(null);
    const [difficulty, setDifficulty] = useState("");
    const [latexValue, setLatexValue] = useState([]);
    const [optionCount, setOptionCount] = useState(2);
    const [parentId, setParentId] = useState(skillId);
    const [optionsData, setOptionsData] = useState([]);
    const [showGallery, setShowGallery] = useState(true);
    const [questionText, setQuestionText] = useState("");
    const [solutionValue, setSolutionValue] = useState("");
    const [questionType, setQuestionType] = useState(null);
    const [rcQuesitonData, setRCQuestionData] = useState([]);
    const [childQuestionId, setChildQuestionId] = useState();
    const [questionImage, setQuestionImage] = useState(null);
    const [publishAccess, setPublishAccess] = useState(false);
    const [selectedBubble, setSelectedBubble] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [questionsAccess, setQuestionsAccess] = useState(false);
    const [questionFetched, setQuestionFetched] = useState(false);
    const [childQuestionData, setChildQuestionData] = useState([]);
    const [childQuestionCount, setChildQuestionCount] = useState(1);
    const [latexQuestionValue, setLatexQuestionValue] = useState("");
    const [rcCurrentQuestionId, setRcCurrentQuestionId] = useState();
    const [childQuestionParentId, setChildQuestionParentId] = useState();
    const [questionTypesOptions, setQuestionTypesOptions] = useState([]);
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
    const [selectedQuestionNumber, setSelectedQuestionNumber] = useState(0);
    const [showQuestionSaveModal, setShowQuestionSaveModal] = useState(false);
    const [
        readingComprehensionQuestionType,
        setReadingComprehensionQuestionType,
    ] = useState(null);
    const [answerExplanation, setAnswerExplanation] = useState("");
    const [isAnswerField, setIsAnswerField] = useState(false);
    const [isQuestionEditorActive, setIsQuestionEditorActive] = useState(false);
    const [isAnswerEditorActive, setIsAnswerEditorActive] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isWriting, setIsWriting] = useState(false);
    const [resource, setResource] = useState(null)
    const [isPassage,setIsPassageActive ] = useState(false)

    const showDropdownArrow = title === "Edit Question" && questionsAccess[0]?.edit && publishAccess;
    const handlePassageChange = (event) => {
        setPassage(event.target.value);
    };

    const grantAccess = () => {
        DbConfig.curriculumAccess.toArray().then(curriculumAccessData => {
            const hasPublishAccess = curriculumAccessData.some(item => item.publishCurriculum);
            setPublishAccess(hasPublishAccess);
            setQuestionsAccess(curriculumAccessData.map(item => item.questions));
        });
    };

    useEffect(() => {
        window.focus();
        window.scrollTo({
            top: 1,
        });
        grantAccess();
    }, []);

    /**
     * Fetch question types from API when component mounts
     * calls getQuestionTypes API
     *
     * @returns {Promise<void>}
     *
     * @author - Gaurav
     */
    useEffect(() => {
        async function fetchQuestionTypes() {
            try {
                const types = await getQuestionTypes();
                const questionTypesData = types.questionTypes;
                const questionTypesOptions = Object.entries(questionTypesData).map(
                    ([label, value]) => ({
                        label: label,
                        value: value,
                    })
                );
                setQuestionTypesOptions(questionTypesOptions);
            } catch (error) {
                console.error("Error fetching question types:", error);
            }
        }
        fetchQuestionTypes();
    }, []);

    useEffect(() => {
        async function fetchQuestionById() {
            if (title === "Edit Question" && !questionFetched) {
                if (questionId) {
                    try {
                        const question = await getQuestionById(questionId);
                        setRCQuestionData(question);
                        setParentId(question.parent);
                        setRcCurrentQuestionId(question._id);
                        setAnswerExplanation(question.solution);
                        setQuestionImage(question.question.image);
                        const matchedOption = questionTypesOptions.find(
                            (option) => option.value === question.qtype
                        );
                        const rcChildQuestionType = question.childQuestions.length
                            ? questionTypesOptions.find(
                                (option) =>
                                    option.value ===
                                    question.childQuestions[currentQuestionNumber].qtype
                            )
                            : "";
                        setOptionsData(question.options);
                        if (matchedOption) {
                            setQuestionType(matchedOption);
                        }
                        if (rcChildQuestionType && question.childQuestions) {
                            setChildQuestionId(
                                question.childQuestions[currentQuestionNumber]._id
                            );
                            setSolution(
                                question.childQuestions[currentQuestionNumber].answer
                            );
                            setChildQuestionParentId(
                                question.childQuestions[currentQuestionNumber].parent
                            );
                            setReadingComprehensionQuestionType(rcChildQuestionType);
                            setQuestionText(
                                question.childQuestions[currentQuestionNumber].question.text
                            );
                            setPassage(question.question.text);
                            setAnswerExplanation(
                                question.childQuestions[currentQuestionNumber].solution
                            );
                            setChildQuestionCount(question.childQuestions.length);
                            setSelectedQuestionNumber(currentQuestionNumber);
                            setChildQuestionData(question.childQuestions);
                            setOptionsData(
                                question.childQuestions[currentQuestionNumber].options
                            );
                            setDifficulty(
                                question.childQuestions[currentQuestionNumber].difficulty
                            );
                            setBTaxonomy(
                                question.childQuestions[currentQuestionNumber].bTaxonomy
                            );
                            var temp = question.childQuestions.map((data) => {
                                return {
                                    questionType: question.qtype,
                                    questionText: data.question.text,
                                    optionsData: data.options,
                                    questionImage: data.question.image,
                                    rcQuestionType: data.qtype,
                                    answerExplanation: data.solution,
                                };
                            });
                            setPreviewQuestions(temp);
                        } else {
                            setSolution(question.answer);
                           const originalText = question.question.text;
                            const encodedText = originalText.replace(
                                /(<#@blank#>)/g,
                                (match) => {
                                    return match
                                        .replace(/</g, "&lt;")
                                        .replace(/>/g, "&gt;");
                                }
                            );
                            const doubleEscapedText = encodedText.replace(
                                /&/g,
                                "&amp;"
                            );
                            setQuestionText(doubleEscapedText);
                            setAnswerExplanation(question.solution);
                            setBTaxonomy(question.bTaxonomy);
                            setDifficulty(question.difficulty);
                            setResource(question.resource)
                            question.resource ? setIsWriting(true) : setIsWriting(false);
                            setPreviewQuestions([
                                {
                                    questionText: question.question.text,
                                    questionType: question.qtype,
                                    optionsData: question.options,
                                    questionImage: question.image,
                                    rcQuestionType: null,
                                    answer: question.answer,
                                },
                            ]);
                        }
                        setQuestionFetched(true);
                    } catch (error) {
                        console.error("Error fetching question by ID:", error);
                    }
                }
            }
        }
        if (questionTypesOptions.length) {
            fetchQuestionById();
        }
    }, [questionTypesOptions, currentQuestionNumber]);

    const handleQuestionChange = (value) => {
        setQuestionText(value);
        var previousData = previewQuestions.map((data, index) => {
            if (index == currentQuestionNumber) {
                data.questionText = value;
            }
            return data;
        });
        setPreviewQuestions(previousData);
    };

    const handleSolutionChange = (value) => {
        setSolutionValue(value);
    };

    const handleOptionsChange = (optionsData) => {
        setOptionsData(optionsData);
        var previousData = previewQuestions.map((data, index) => {
            if (index == currentQuestionNumber) {
                data.optionsData = optionsData;
            }
            return data;
        });
        setPreviewQuestions(previousData);
    };

    const toggleQuestionEditor = () => {
        setIsQuestionEditorActive((prev) => !prev);
        setIsAnswerEditorActive(false);
    };
    const togglePassageEditor = () => {
        setIsPassageActive((prev) => !prev)
    }
    const handleAnswerEditorActivation = () => {
        setIsAnswerEditorActive((prev) => !prev);
        setIsQuestionEditorActive(false);
    };

    const handleQuestionTextChange = (value) => {
        setIsQuestionEditorActive(true);
        setQuestionText(value);

        var updatedQuestions = previewQuestions.map((data, index) => {
            if (index === currentQuestionNumber) {
                data.questionText = value;
            }
            return data;
        });

        setPreviewQuestions(updatedQuestions);
    };

    const handleAnswerExplanationChange = (value) => {
        setIsAnswerEditorActive(true);
        setAnswerExplanation(value);
        var updatedQuestions = previewQuestions.map((data, index) => {
            if (index === currentQuestionNumber) {
                data.answerExplanation = value;
            }
            return data;
        });

        setPreviewQuestions(updatedQuestions);
    };

    const handleBloomsTaxonomyChange = (bloomsTaxonomy) => {
        setBTaxonomy(bloomsTaxonomy);
    };
    const handleComplexityChange = (complexity) => {
        setDifficulty(complexity);
    };
    function dataURLtoFile(dataurl, filename) {
        if (!dataurl) {
            return null;
        }
        const arr = dataurl.split(",");
        if (arr.length < 2) {
            console.error("Invalid data URL format.");
            return null;
        }
        const mimeMatch = arr[0].match(/:(.*?);/);
        if (!mimeMatch) {
            console.error("MIME type not found in data URL.");
            return null;
        }
        const mime = mimeMatch[1];
        const bstr = atob(arr[1]);
        const n = bstr.length;
        const u8arr = new Uint8Array(n);

        for (let i = 0; i < n; i++) {
            u8arr[i] = bstr.charCodeAt(i);
        }

        return new File([u8arr], filename, { type: mime });
    }
    const handleSubmit = async (
        questionType,
        questionText,
        optionsData,
        bTaxonomy,
        difficulty,
        questionImage,
        addMore = false,
        parentId,
        resetPassage = true,
        answerExplanation
    ) => {
        if (
            !questionConfig.readingComprehension.includes(questionType.value) &&
            !questionConfig.solutionBox.includes(questionType.value) &&
            !questionConfig.writing.includes(questionType.value)

        ) {
            try {
                setLoader(true);
                const formData = new FormData();
                formData.append("qtype", questionType.value);
                formData.append("difficulty", difficulty);
                formData.append("bTaxonomy", bTaxonomy);
                formData.append("solution", answerExplanation);
                formData.append("solution_type", 0);
                if (questionText == null || questionText == "null") {
                    formData.append("question[text]", "");
                } else {
                    formData.append("question[text]", questionText);
                }
                if (typeof questionImage === "string" && questionImage !== "") {
                    questionImage = dataURLtoFile(questionImage, "Image-File.jpg");
                }
                formData.append("question[image]", questionImage);
                optionsData.forEach((option, index) => {
                    if (option.text) {
                        formData.append(`options[${index}][text]`, option.text);
                    }
                    if (option.image && option.image !== "") {
                        if (typeof option.image === "string") {
                            formData.append(
                                `options[${index}][image]`,
                                dataURLtoFile(option.image, `Image${index}-File.jpg`)
                            );
                        } else {
                            formData.append(`options[${index}][image]`, option.image);
                        }
                    }
                    formData.append(
                        `options[${index}][isCorrect]`,
                        option.isCorrect ? "true" : "false"
                    );
                });
                formData.append("numofqust", 1);
                formData.append("parentId", parentId);
                formData.append("atype", 1);
                if (questionId) {
                    formData.append("questionId", questionId);
                    const response = await editQuestion(formData);
                    if (!response.success) {
                        setErrors(response);
                        if (response.length > 0) {
                            setShowErrorModal(true);
                        }
                    }
                } else {
                    const response = await addQuestion(formData);
                    if (response.success) {
                        resetForm();
                        if (!addMore) {
                            setShowQuestionSaveModal(true);
                            if (title === "Add Question") {
                                handleQuestionTypeChange(questionType);
                            }
                        }
                    } else {
                        setErrors(response);
                        if (response.length > 0) {
                            setShowErrorModal(true);
                        }
                    }
                }
            } catch (error) {
                console.error("Error occurred:", error);
            } finally {
                setLoader(false);
            }
        } else if (questionConfig.solutionBox.includes(questionType.value)) {
            try {
                setLoader(true);
                const formData = new FormData();
                formData.append("qtype", questionType.value);
                formData.append("difficulty", difficulty);
                formData.append("bTaxonomy", bTaxonomy);
                formData.append("solution", answerExplanation);
                formData.append("solution_type", 0);
                const formattedQuestionText = questionText
                .replace(/&amp;lt;/g, "<")
                .replace(/&amp;gt;/g, ">")
                .replace(/&lt;#@blank#&gt;/g, "<#@blank#>");
                formData.append("question[text]", formattedQuestionText)
               
                if (typeof questionImage === "string") {
                    questionImage = dataURLtoFile(questionImage, "Image-File.jpg");
                }
                formData.append("question[image]", questionImage);
                if (solution) {
                    if (solution.includes(",")) {
                        let solutionArray = solution
                            .split(",")
                            .filter((item) => item !== "");
                        let optionsDataArray = optionsData
                            .split(",")
                            .filter((item) => item !== "");
                        if (optionsDataArray.length === 0) {
                            solutionArray.forEach((item) => {
                                formData.append("answer[]", item.trim());
                            });
                        } else {
                            optionsDataArray.forEach((item) => {
                                formData.append("answer[]", item.trim());
                            });
                        }
                    } else {
                        formData.append("answer", solution);
                    }
                } else {
                    setErrors([...errors, "Answer"]);
                }
                formData.append("numofqust", 1);
                formData.append("parentId", parentId);
                formData.append("atype", 1);
                if (questionId) {
                    formData.append("questionId", questionId);
                    const response = await editQuestion(formData);
                    if (!response.success) {
                        setErrors(response);
                        if (response.length > 0) {
                            setShowErrorModal(true);
                        }
                    }
                } else {
                    const response = await addQuestion(formData);
                    if (response.success) {
                        resetForm();
                        if (!addMore) {
                            setShowQuestionSaveModal(true);
                            if (title === "Add Question") {
                                handleQuestionTypeChange(questionType);
                            }
                        }
                    } else {
                        setErrors(response);
                        if (response.length > 0) {
                            setShowErrorModal(true);
                        }
                    }
                }
            } catch (error) {
                console.error("Error occurred:", error);
            } finally {
                setLoader(false);
            }
        } else if (questionConfig.writing.includes(questionType.value)) {
            try {
                setLoader(true);
                const formData = new FormData();
                formData.append("qtype", questionType.value);
                formData.append("question[text]", questionText);
                formData.append("parentId", parentId);
                formData.append("resource", resource);
                if (questionId) {
                    formData.append("questionId", questionId);
                    setIsWriting(true)
                    const response = await editQuestion(formData);
                    if (!response.success) {
                        setErrors(response);
                        if (response.length > 0) {
                            setShowErrorModal(true);
                        }
                    } 
                } else {
                    const response = await addQuestion(formData);
                    if (response.success) {
                        resetForm();
                        if (!addMore) {
                            setShowQuestionSaveModal(true);
                            if (title === "Add Question") {
                                handleQuestionTypeChange(questionType);
                            }
                        }
                    } else {
                        setErrors(response);
                        if (response.length > 0) {
                            setShowErrorModal(true);
                        }
                    }
                }
            } catch (error) {
                console.error("Error occurred:", error);
            } finally {
                setLoader(false);
            }
        } else {
            try {
                setLoader(true);
                var tempQuestionData = [...childQuestionData];
                tempQuestionData[currentQuestionNumber] = {
                    question: {
                        text: questionText,
                    },
                    options: optionsData,
                    difficulty: difficulty,
                    bTaxonomy: bTaxonomy,
                    qtype: readingComprehensionQuestionType.value,
                    solution: answerExplanation,
                };
                const formData = new FormData();
                var response;
                if (title === "Edit Question") {
                    formData.append("questionId", questionId);
                    formData.append("childQuestion[solution]", answerExplanation);
                    formData.append("childQuestion[solution_type]", 0);
                    formData.append("parentId", parentId);
                    formData.append("qtype", questionType.value);
                    formData.append("question[text]", passage);
                    formData.append(
                        "childQuestion[questionId]",
                        rcQuesitonData.childQuestions[currentQuestionNumber]._id
                    );
                    formData.append(
                        "childQuestion[parentId]",
                        rcQuesitonData.childQuestions[currentQuestionNumber].parent
                    );
                    formData.append(
                        "childQuestion[qtype]",
                        readingComprehensionQuestionType.value
                    );
                    formData.append("childQuestion[question][text]", questionText);
                    formData.append("question[image]", questionImage);
                    if (solution.includes(",")) {
                        let solutionArray = solution
                            .split(",")
                            .filter((item) => item !== "");
                        solutionArray.forEach((item) => {
                            formData.append("childQuestion[answer][]", item);
                        });
                    } else {
                        formData.append("childQuestion[answer]", solution);
                    }
                    if (
                        !questionConfig.solutionBox.includes(
                            readingComprehensionQuestionType.value
                        )
                    ) {
                        optionsData.forEach((option, index) => {
                            if (option.text) {
                                formData.append(
                                    `childQuestion[options][${index}][text]`,
                                    option.text
                                );
                                formData.append(
                                    `childQuestion[options][${index}][isCorrect]`,
                                    option.isCorrect
                                );
                            }
                        });
                    }
                    response = await editQuestion(formData);
                    if (!response.success) {
                        setErrors(response);
                        if (response.length > 0) {
                            setShowErrorModal(true);
                        } else {
                            setIsPassageActive(false)
                        }
                    }
                } else if (parentId === skillId) {
                    var rcAddSuccess = false;
                    questionId ?? formData.append("questionId", questionId);
                    questionId ??
                        formData.append("childQuestion[questionId]", questionId);
                    formData.append("qtype", questionType.value);
                    if (!passage) {
                        setErrors([
                            ...errors,
                            "Validation failed: Missing required fields",
                            "- Enter Passage",
                        ]);
                        setShowErrorModal(true);
                        return;
                    } else {
                        formData.append("question[text]", passage);
                    }
                    formData.append("parentId", parentId);
                    if (!readingComprehensionQuestionType) {
                        setErrors([
                            "Validation failed: Missing required fields",
                            "- Child Question Type",
                        ]);
                        setShowErrorModal(true);
                        return;
                    } else {
                        formData.append(
                            "childQuestion[qtype]",
                            readingComprehensionQuestionType.value
                        );
                    }
                    formData.append("childQuestion[question][text]", questionText);
                    formData.append("childQuestion[question][image]", questionImage);
                    if (
                        !questionConfig.solutionBox.includes(
                            readingComprehensionQuestionType.value
                        )
                    ) {
                        optionsData.forEach((option, index) => {
                            if (option.text) {
                                formData.append(
                                    `childQuestion[options][${index}][text]`,
                                    option.text
                                );
                                formData.append(
                                    `childQuestion[options][${index}][isCorrect]`,
                                    option.isCorrect
                                );
                            }
                        });
                    } else if (
                        questionConfig.solutionBox.includes(
                            readingComprehensionQuestionType.value
                        )
                    ) {
                        if (optionsData.includes(",")) {
                            let optionsDataArray = optionsData
                                .split(",")
                                .filter((item) => item !== "");
                            optionsDataArray.forEach((item) => {
                                formData.append("childQuestion[answer][]", item.trim());
                            });
                        } else {
                            formData.append("childQuestion[answer]", optionsData);
                        }
                    }
                    formData.append("childQuestion[solution]", answerExplanation);
                    formData.append("childQuestion[solution_type]", 0);
                    response = await insertQuestionRC(formData);
                    var rcAddSuccess = response.success;
                    if (response.success) {
                        setParentId(response.data.id);
                        if (!addMore) {
                            setShowQuestionSaveModal(true);
                            setIsPassageActive(false)
                            if (title === "Add Question") {
                                handleQuestionTypeChange(questionType);
                            }
                        }
                        else if (addMore) {
                            setCurrentQuestionNumber(currentQuestionNumber + 1);
                        }
                    } else {
                        setErrors(response);
                        if (response.length > 0) {
                            setShowErrorModal(true);
                            return;
                        }
                    }
                    if (resetPassage) {
                        setPassage("");
                    }
                    setIsAnswerField(true);
                } else {
                    if (!passage) {
                        setErrors([
                            "Validation failed: Missing required fields",
                            "- Enter Passage",
                        ]);
                        setShowErrorModal(true);
                        return;
                    }
                    questionId ?? formData.append("questionId", questionId);
                    formData.append("qtype", readingComprehensionQuestionType.value);
                    questionText !== null || questionText === "null"
                        ? formData.append("question[text]", questionText)
                        : formData.append("question[text]", "");
                    formData.append("parentId", parentId);
                    questionImage =
                        typeof questionImage === "string" && questionImage !== ""
                            ? dataURLtoFile(questionImage, "Image-File.jpg")
                            : questionImage;
                    formData.append("question[image]", questionImage);
                    formData.append("isSubQuestion", true);
                    if (
                        questionConfig.solutionBox.includes(
                            readingComprehensionQuestionType.value
                        )
                    ) {
                        if (solution.includes(",")) {
                            let optionsDataArray = optionsData
                                .split(",")
                                .filter((item) => item !== "");
                            optionsDataArray.forEach((item) => {
                                formData.append("answer[]", item);
                            });
                        } else {
                            formData.append("answer", optionsData);
                        }
                    } else {
                        optionsData.forEach((option, index) => {
                            if (option.text) {
                                formData.append(`options[${index}][text]`, option.text);
                            }
                            if (option.image) {
                                if (typeof option.image === "string" && questionImage !== "") {
                                    formData.append(
                                        `options[${index}][image]`,
                                        dataURLtoFile(option.image, `Image${index}-File.jpg`)
                                    );
                                } else {
                                    formData.append(`options[${index}][image]`, option.image);
                                }
                            }
                            formData.append(
                                `options[${index}][isCorrect]`,
                                option.isCorrect ? "true" : "false"
                            );
                        });
                    }
                    formData.append("solution", answerExplanation);
                    formData.append("solution_type", 0);
                    response = await addQuestion(formData);
                    var rcAddSuccess = response.success;
                    if (response.success) {
                        if (!addMore) {
                            setShowQuestionSaveModal(true);
                            setIsPassageActive(false)
                            if (title === "Add Question") {
                                handleQuestionTypeChange(questionType);
                            }  
                        }
                        setIsAnswerField(true);
                    } else {
                        setErrors(response);
                        if (response.length > 0) {
                            setShowErrorModal(true);
                        }
                    }
                }

                if (title !== "Edit Question " && rcAddSuccess && addMore) {
                    setChildQuestionData(tempQuestionData);
                    setPreviewQuestions([
                        ...previewQuestions,
                        {
                            ...previewQuestionFormat,
                            rcQuestionType: readingComprehensionQuestionType.value,
                            questionType: questionType.value,
                        },
                    ]);
                    setSelectedQuestionNumber(selectedQuestionNumber + 1);
                    setChildQuestionCount(childQuestionCount + 1);
                }
                await handlePreview(
                    questionType,
                    questionText,
                    optionsData,
                    questionImage
                );
            } catch (error) {
                console.error("Error occurred:", error);
            } finally {
                setLoader(false);
            }
        }
    };
    const handlePreviewClick = async () => {
        setLoader(true);
        var currentPreviewId = await generatePreview();
        const encodedFormData = encodeURIComponent(currentPreviewId);
        let url = `/question-preview?preview-id=${encodedFormData}&skill-skillName=${encodeURIComponent(
            skillName
        )}`;
        window.open(url, "_blank");
        setLoader(false);
    };
    const generatePreview = async () => {
        let questionPreviewId = null;
        if (questionConfig.readingComprehension.includes(questionType.value)) {
            for (const data of previewQuestions) {
                const formData = new FormData();
                formData.append("qtype", data.questionType);
                formData.append("question[text]", passage);
                formData.append("childQuestion[qtype]", data.rcQuestionType);
                formData.append("childQuestion[question][text]", data.questionText);
                formData.append("childQuestion[image]", data.questionImage);
                formData.append("childQuestion[solution]", data.answerExplanation);
                formData.append("childQuestion[solution_type]", 0);
                if (questionConfig.solutionBox.includes(data.rcQuestionType)) {
                    if (solution.includes(",")) {
                        let solutionArray = solution
                            .split(",")
                            .filter((item) => item !== "");
                        solutionArray.forEach((item) => {
                            formData.append("childQuestion[answer][]", item);
                        });
                    }
                    else if (Array.isArray(solution)) {
                        solution.forEach((item) => {
                            formData.append("childQuestion[answer][]", item.trim());
                        });
                    } else {
                        formData.append("childQuestion[answer]", solution);
                    }
                } else {
                    data.optionsData.forEach((option, index) => {
                        if (option.text) {
                            formData.append(
                                `childQuestion[options][${index}][text]`,
                                option.text
                            );
                        }
                        formData.append(
                            `childQuestion[options][${index}][isCorrect]`,
                            option.isCorrect ? "true" : "false"
                        );
                    });
                }
                formData.append("atype", 1);
                if (questionPreviewId) {
                    formData.append("previewId", questionPreviewId);
                }
                const response = await insertPreviewQuestionRC(formData);
                if (response.success) {
                    questionPreviewId = response.data.previewId;
                }
            }
        } else if (questionConfig.writing.includes(questionType.value)) {
            const formData = new FormData();
            formData.append("qtype", previewQuestions[0].questionType);
            formData.append("resource", resource);
            formData.append("atype", 1);
            formData.append("question[text]", questionText);
            if (questionPreviewId) {
                formData.append("previewId", questionPreviewId);
            }
            const response = await insertPreviewQuestion(formData);
            if (response.success) {
                questionPreviewId = response.data.previewId;
            }
        } else {
            const formData = new FormData();
            formData.append("qtype", previewQuestions[0].questionType);
            const formattedQuestionText = questionText
            .replace(/&amp;lt;/g, "<")
            .replace(/&amp;gt;/g, ">");
            formData.append("question[text]", formattedQuestionText);
            formData.append("question[image]", questionImage);
            formData.append("solution", answerExplanation);
            formData.append("solution_type", 0);
            if (
                questionConfig.solutionBox.includes(previewQuestions[0].questionType)
            ) {
                if (solution.includes(",")) {
                    let solutionArray = solution
                        .split(",")
                        .filter((item) => item !== "");
                    solutionArray.forEach((item) => {
                        formData.append("answer[]", item.trim());
                    });
                }
                else if (Array.isArray(solution)) {
                    solution.forEach((item) => {
                        formData.append("answer[]", item.trim());
                    });
                } else {
                    formData.append("answer", solution);
                }
            } else {
                if (previewQuestions.length > 0 && Array.isArray(previewQuestions[0].optionsData)) {
                    previewQuestions[0].optionsData.forEach((option, index) => {
                        if (option.text) {
                            formData.append(`options[${index}][text]`, option.text);
                        }
                        if (option.image) {
                            if (title === "Edit Question") {
                                formData.append(
                                    `options[${index}][image]`,
                                    dataURLtoFile(option.image, `Image${index}-File.jpg`)
                                );
                            } else {
                                formData.append(`options[${index}][image]`, option.image);
                            }
                        }
                        formData.append(
                            `options[${index}][isCorrect]`,
                            option.isCorrect ? "true" : "false"
                        );
                    });
                }
            }
            formData.append("parent", skillName);
            formData.append("atype", 1);
            const response = await insertPreviewQuestion(formData);
            questionPreviewId = response.data.previewId;
        }
        return questionPreviewId;
    };
    const handlePreview = async (
        questionType,
        questionText,
        optionsData,
        questionImage
    ) => {
        var questionPreviewId = null;
        if (!questionConfig.readingComprehension.includes(questionType.value)) {
            try {
                const formData = new FormData();
                formData.append("qtype", questionType.value);
                formData.append("question[text]", questionText);
                formData.append("question[image]", questionImage);
                if (questionConfig.solutionBox.includes(questionType.value)) {
                    if (optionsData.includes(",")) {
                        let optionsDataArray = optionsData.split(",");
                        optionsDataArray.forEach((item) => {
                            formData.append("answer[]", item.trim());
                        });
                    } else {
                        formData.append("answer", optionsData);
                    }
                } else {
                    optionsData.forEach((option, index) => {
                        if (option.text) {
                            formData.append(`options[${index}][text]`, option.text);
                        }
                        if (option.image) {
                            formData.append(`options[${index}][image]`, option.image);
                        }
                        formData.append(
                            `options[${index}][isCorrect]`,
                            option.isCorrect ? "true" : "false"
                        );
                    });
                }
                formData.append("parent", skillName);
                formData.append("atype", 1);
                const response = await insertPreviewQuestion(formData);
                const result = await response.json();
                if (!response.ok) {
                    console.error("Preview request failed:", response.message);
                }
                setPreviewId(result.data.previewId);
                questionPreviewId = result.data.previewId;
            } catch (error) {
                console.error("Error while adding question: ", error);
            }
        } else {
            try {
                const formData = new FormData();
                formData.append("qtype", questionType.value);
                formData.append("question[text]", passage);
                formData.append(
                    "childQuestion[qtype]",
                    readingComprehensionQuestionType.value
                );
                formData.append("childQuestion[question][text]", questionText);
                formData.append("childQuestion[image]", questionImage);
                formData.append("childQuestion[solution]", answerExplanation);
                formData.append("childQuestion[solution_type]", 0);
                if (
                    questionType.value &&
                    questionConfig.solutionBox.includes(
                        readingComprehensionQuestionType.value
                    )
                ) {
                    if (optionsData.includes(",")) {
                        let optionsDataArray = optionsData
                            .split(",")
                            .filter((item) => item !== "");
                        optionsDataArray.forEach((item) => {
                            formData.append("childQuestion[answer][]", item.trim());
                        });
                    } else {
                        formData.append("childQuestion[answer]", optionsData);
                    }
                } else {
                    optionsData.forEach((option, index) => {
                        if (option.text) {
                            formData.append(
                                `childQuestion[options][${index}][text]`,
                                option.text
                            );
                        }
                        formData.append(
                            `options[${index}][isCorrect]`,
                            option.isCorrect ? "true" : "false"
                        );
                    });
                }
                formData.append("atype", 1);
                if (previewId) {
                    formData.append("previewId", previewId);
                    questionPreviewId = previewId;
                }
                const response = await insertPreviewQuestionRC(formData);
                if (!previewId) {
                    setPreviewId(response.data.previewId);
                    questionPreviewId = response.data.previewId;
                }
                if (!response.success) {
                    throw new Error("Network response was not ok");
                }
            } catch (error) {
                console.error("Error while adding question: ", error);
            }
        }
        return questionPreviewId;
    };
    /**
     * handleQuestionTypeChange
     *
     * Handle change in question type dropdown
     *
     * @param {Object} selectedOption - Selected option object from dropdown
     *
     * @returns {void}
     *    
     * @author - Gaurav
     */
    const handleQuestionTypeChange = (selectedOption) => {
        setPassage("");
        resetForm();
        setParentId(parentId);
        setPreviewId(null);
        setShowMath(false);
        setQuestionType(selectedOption);
        setCurrentQuestionNumber(0);
        setIsQuestionEditorActive(false);
        selectedOption.value === 25 ? setIsWriting(true) : setIsWriting(false)
        setPreviewQuestions([
            { ...previewQuestionFormat, questionType: selectedOption.value },
        ]);
    };

    const handleReadingComprehensionQuestionTypeChange = (selectedOption) => {
        setIsQuestionEditorActive(false);
        setReadingComprehensionQuestionType(selectedOption);
        var previousData = previewQuestions.map((data, index) => {
            if (index == currentQuestionNumber) {
                data.rcQuestionType = selectedOption.value;
            }
            return data;
        });
        setPreviewQuestions(previousData);
    };

    const handleImageUpload = (file) => {
        setQuestionImage(file);
        var previousData = previewQuestions.map((data, index) => {
            if (index == currentQuestionNumber) {
                data.questionImage = file;
            }
            return data;
        });
        setPreviewQuestions(previousData);
    };

    const handleFileUpload = (file) => {
        setIsWriting(true)
        setResource(file);
        var previousData = previewQuestions.map((data, index) => {
            if (index == currentQuestionNumber) {
                data.resource = file;
            }
            return data;
        });
        setPreviewQuestions(previousData);
    };
    /**
     * handleQuestionSaveAction
     * Handle question save action
     *
     * @param {Event} event - The event object
     *
     * @returns {void}
     *
     * @author - Gaurav
     */
    const handleQuestionSave = async () => {
        try {
            handleSubmit(
                questionType,
                questionText,
                optionsData,
                bTaxonomy,
                difficulty,
                questionImage,
                false,
                parentId,
                true,
                answerExplanation
            );
        } catch (error) {
            console.error("Error while adding question: ", error);
        }
    };
    /**
     * navigateToQuestions
     *
     * Navigate to questions page
     *
     * @returns {void}
     *
     * @author - Gaurav
     */
    const navigateToQuestions = () => {
        navigate("/curriculum/questions");
    };
    /**
     * handleAddMore
     *
     * Reset question card and options for adding more questions
     *
     * @returns {void}
     *
     * @author - Gaurav
     */
    const handleAddMore = async () => {
        try {
            handleSubmit(
                questionType,
                questionText,
                optionsData,
                bTaxonomy,
                difficulty,
                questionImage,
                true,
                parentId,
                false,
                answerExplanation
            );
            setQuestionType(questionType);
        } catch (error) {
            console.error("Error while adding question: ", error);
        }
    };
    const resetForm = () => {
        setQuestionText("");
        setPassage('')
        setQuestionImage(null);
        setOptionsData([]);
        setBTaxonomy("");
        setDifficulty("");
        setShowErrorModal(false);
        setErrors([]);
        setSolution("");
        setChildQuestionCount(1);
        setSelectedQuestionNumber(0);
        setLatexValue([]);
        setLatexQuestionValue("");
        setChildQuestionData([]);
        setAnswerExplanation("");
        setResource('')
    };

    const handleQuestionSaveModalClose = () => {
        setShowQuestionSaveModal(false);
        if (title === "Add Question") {
            resetForm();
        }
    };

    const handleQuestionBubbleClick = (index) => {
        const questionData = childQuestionData[index];
        if (questionData) {
            const qtype = questionTypesOptions.find(
                (option) => option.value === questionData.qtype
            );
            setIsAnswerField(false);
            setReadingComprehensionQuestionType(qtype || null);
            setQuestionText(questionData?.question.text || "");
            setOptionsData(questionData?.options || []);
            setAnswerExplanation(questionData?.solution || "");
            setDifficulty(questionData?.difficulty || "");
            setBTaxonomy(questionData?.bTaxonomy || "");
            if (qtype.value === 22) {
                title === "Edit Question"
                    ? setSolution(questionData.answer)
                    : setSolution(questionData.options);
            }
        } else {
            setReadingComprehensionQuestionType(null);
            setQuestionText("");
            setOptionsData([]);
            setDifficulty("");
            setBTaxonomy("");
            setSolution("");
            setLatexQuestionValue("");
            setLatexValue([]);
            setAnswerExplanation("");
        }

        setCurrentQuestionNumber(index);
        setSelectedBubble(index);
        setSelectedQuestionNumber(index);
    };

    const handleSaveAndPublish = async () => {
        await handleQuestionSave();
        const payload = {
            questionIds: [questionId],
            status: 1
        };
        await publishQuestion(payload);
    }
    const handlePassage = (data) => {
        setPassage(data);
    };
    return (
        <div className="question-container">
            {loader ? (
                <Loader />
            ) : (
                <div>
                    <Title titleContent={title ? title : "Edit Question"} />
                    <div className="builder-container">
                        <div className="parent">
                            <div className="wrapper">
                                Skill: <span id="parent-name">{skillName}</span>
                                <div className="dropdown-headers" style={{ zIndex: '1000' }}>
                                    <Select
                                        className="basic-single parent-dropdown"
                                        placeholder="Type of the Question"
                                        options={questionTypesOptions}
                                        value={questionType}
                                        onChange={handleQuestionTypeChange}
                                    />
                                </div>
                            </div>
                            {questionType && (
                                <>
                                    {questionConfig.readingComprehension.includes(
                                        questionType?.value
                                    ) && (
                                            <>
                                                <div style={{ marginBottom: "1rem" }}>
                                                <Card style={{ margin: "0 0 1rem 0" }}>
                                                    <div className="passage-box">
                                                        Passage
                                                        <div className="question-textEditor">
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        Text
                                                                        Editor
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button
                                                                    type="button"
                                                                    className={`textEditor-toggle ${
                                                                        isClicked
                                                                            ? "clicked"
                                                                            : ""
                                                                    }`}
                                                                    onClick={
                                                                        togglePassageEditor
                                                                    }
                                                                >
                                                                    A
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        {isPassage ? (
                                                            <div className="answer-editor"
                                                            style={{ width: "35rem", marginBottom: "0.3rem" }}>
                                                                <div className="jodit-editor">
                                                            <CKEditorComponent
                                                                answerField={
                                                                    passage
                                                                }
                                                                onExplanationChange={
                                                                    handlePassage
                                                                }
                                                                isQuestionText={true}
                                                            />
                                                            </div>
                                                            </div>
                                                        ) : (
                                                            <textarea
                                                                type="text"
                                                                id="input-fields-passage"
                                                                placeholder={
                                                                    "Enter Your Passage Here ..."
                                                                }
                                                                value={passage}
                                                                onChange={
                                                                    handlePassageChange
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                    </Card>
                                                </div>
                                                <div className="rc-question-bubble-container">
                                                    <span style={{ marginTop: "3px", color: "#000" }}>
                                                        Questions:
                                                    </span>
                                                    {Array.from({ length: childQuestionCount }).map(
                                                        (_, i) => (
                                                            <span
                                                                key={i + 1}
                                                                className={`rc-question-bubble 
                                                                ${i === selectedQuestionNumber ? "selected" : ""}`}
                                                                onClick={() => handleQuestionBubbleClick(i)}
                                                            >
                                                                {i + 1}
                                                            </span>
                                                        )
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    <Card className="card-container">
                                        <div className="card-content">
                                            {questionType && (
                                                <>
                                                    {questionConfig.readingComprehension.includes(
                                                        questionType?.value
                                                    ) && (
                                                            <>
                                                                <Select
                                                                    className="basic-single-2"
                                                                    placeholder="Select Question Type"
                                                                    options={questionTypesOptions.filter((option) =>
                                                                        allowedValues.includes(option.value)
                                                                    )}
                                                                    value={readingComprehensionQuestionType}
                                                                    onChange={
                                                                        handleReadingComprehensionQuestionTypeChange
                                                                    }
                                                                    styles={{
                                                                        control: (provided) => ({
                                                                            ...provided,
                                                                            color: "var(--black-color)",
                                                                            fontFamily: "var(--font-family)",
                                                                            fontSize: "var(--font-size-small)",
                                                                            fontStyle: "normal",
                                                                            fontWeight: "500",
                                                                            lineHeight: "normal",
                                                                        }),
                                                                        indicatorContainer: (provided) => ({
                                                                            ...provided,
                                                                            width: "3rem",
                                                                        }),
                                                                        menu: (provided) => ({
                                                                            ...provided,
                                                                            width: "34.75rem",
                                                                        }),
                                                                    }}
                                                                />
                                                                <div className="question-div">
                                                                    Question
                                                                    <div className="question-textEditor">
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip>Text Editor</Tooltip>
                                                                            }
                                                                        >
                                                                            <button
                                                                                type="button"
                                                                                className={`textEditor-toggle ${isClicked
                                                                                    ? "clicked" : ""}`}
                                                                                onClick={toggleQuestionEditor}
                                                                                disabled={readingComprehensionQuestionType?.value === 22}
                                                                            >
                                                                                A
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <Question
                                                                        questionType={
                                                                            readingComprehensionQuestionType
                                                                        }
                                                                        showMath={showMath}
                                                                        onQuestionChange={handleQuestionChange}
                                                                        onImageUpload={handleImageUpload}
                                                                        questionFields={questionText}
                                                                        questionImage={questionImage}
                                                                        setQuestionImage={setQuestionImage}
                                                                        allowedValues={allowedValues}
                                                                        showGallery={showGallery}
                                                                        latexQuestionValue={latexQuestionValue}
                                                                        setLatexQuestionValue={setLatexQuestionValue}
                                                                        onAnswerExplanationChange={
                                                                            handleQuestionTextChange
                                                                        }
                                                                        answerField={answerExplanation}
                                                                        isAnswerField={isAnswerField}
                                                                        isQuestionEditorActive={
                                                                            isQuestionEditorActive
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="question-div">
                                                                    Options
                                                                    <Options
                                                                        parentQuestionType={questionType}
                                                                        skillName={skillName}
                                                                        questionType={
                                                                            readingComprehensionQuestionType?.value
                                                                        }
                                                                        showMath={showMath}
                                                                        setShowMath={setShowMath}
                                                                        onAnswerExplanationChange={
                                                                            handleAnswerExplanationChange
                                                                        }
                                                                        onOptionsChange={handleOptionsChange}
                                                                        onImageUpload={handleImageUpload}
                                                                        onBloomsTaxonomyChange={
                                                                            handleBloomsTaxonomyChange
                                                                        }
                                                                        onComplexityChange={handleComplexityChange}
                                                                        onPreview={handlePreviewClick}
                                                                        optionsData={optionsData}
                                                                        solution={solution}
                                                                        bTaxonomy={bTaxonomy}
                                                                        difficulty={difficulty}
                                                                        resetForm={resetForm}
                                                                        setSolution={setSolution}
                                                                        handleSolutionChangeProp={
                                                                            handleSolutionChange
                                                                        }
                                                                        setShowGallery={setShowGallery}
                                                                        showGallery={showGallery}
                                                                        latexValue={latexValue}
                                                                        setLatexValue={setLatexValue}
                                                                        answerField={answerExplanation}
                                                                        isAnswerField={isAnswerField}
                                                                        isAnswerEditorActive={isAnswerEditorActive}
                                                                        handleAnswerEditoActivation={
                                                                            handleAnswerEditorActivation
                                                                        }
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    {!questionConfig.readingComprehension.includes(
                                                        questionType?.value
                                                    ) && (
                                                            <>
                                                                {/* Render Question */}
                                                                <div className="question-div">
                                                                    Question
                                                                    <div className="question-textEditor">
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            className="tooltip"
                                                                            overlay={<Tooltip>Text Editor</Tooltip>}
                                                                        >
                                                                            <button
                                                                                type="button"
                                                                                className={`textEditor-toggle ${isClicked ? "clicked" : ""
                                                                                    }`}
                                                                                onClick={toggleQuestionEditor}
                                                                                disabled={disabledValues.includes(questionType.value)}
                                                                            >
                                                                                A
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <Question
                                                                        questionType={questionType}
                                                                        showMath={showMath}
                                                                        onQuestionChange={handleQuestionChange}
                                                                        onImageUpload={handleImageUpload}
                                                                        questionFields={questionText}
                                                                        questionImage={questionImage}
                                                                        setQuestionImage={setQuestionImage}
                                                                        showGallery={showGallery}
                                                                        latexQuestionValue={latexQuestionValue}
                                                                        setQuestionValue={setQuestionText}
                                                                        setLatexQuestionValue={setLatexQuestionValue}
                                                                        onAnswerExplanationChange={
                                                                            handleQuestionTextChange
                                                                        }
                                                                        answerField={questionText}
                                                                        isAnswerField={isAnswerField}
                                                                        isQuestionEditorActive={
                                                                            isQuestionEditorActive
                                                                        }
                                                                    />
                                                                </div>
                                                                {/* Render Options */}
                                                                <div className="question-div">
                                                                    {questionConfig.solutionBox.includes(questionType?.value) ? "Answer"
                                                                        : questionType?.value === 25 ? "" : "Options"}
                                                                    <Options
                                                                        skillName={skillName}
                                                                        questionType={questionType?.value}
                                                                        optionCount={optionCount}
                                                                        showMath={showMath}
                                                                        setShowMath={setShowMath}
                                                                        onOptionsChange={handleOptionsChange}
                                                                        onAnswerExplanationChange={
                                                                            handleAnswerExplanationChange
                                                                        }
                                                                        onImageUpload={handleImageUpload}
                                                                        onBloomsTaxonomyChange={
                                                                            handleBloomsTaxonomyChange
                                                                        }
                                                                        onResourceChange={handleFileUpload}
                                                                        onComplexityChange={handleComplexityChange}
                                                                        onPreview={handlePreviewClick}
                                                                        optionsData={optionsData}
                                                                        bTaxonomy={bTaxonomy}
                                                                        difficulty={difficulty}
                                                                        resetForm={resetForm}
                                                                        solution={solution}
                                                                        setSolution={setSolution}
                                                                        handleSolutionChangeProp={
                                                                            handleSolutionChange
                                                                        }
                                                                        setShowGallery={setShowGallery}
                                                                        showGallery={showGallery}
                                                                        latexValue={latexValue}
                                                                        resource={resource}
                                                                        setResource={setResource}
                                                                        setLatexValue={setLatexValue}
                                                                        answerField={answerExplanation}
                                                                        isAnswerField={isAnswerField}
                                                                        isAnswerEditorActive={isAnswerEditorActive}
                                                                        handleAnswerEditoActivation={
                                                                            handleAnswerEditorActivation
                                                                        }
                                                                        isWriting={isWriting}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    {questionConfig.readingComprehension.includes(
                                                        questionType?.value
                                                    ) &&
                                                        title !== "Edit Question" && (
                                                            <button
                                                                className="add-question-button"
                                                                onClick={handleAddMore}
                                                            >
                                                                + Add Question
                                                            </button>
                                                        )}
                                                </>
                                            )}
                                        </div>
                                    </Card>
                                    <div className="container-footer">
                                        {!questionConfig.readingComprehension.includes(
                                            questionType?.value
                                        ) && (
                                                <div className="cancel">
                                                    <button
                                                        type="button"
                                                        className="add-more"
                                                        onClick={handleAddMore}
                                                    >
                                                        + Add More
                                                    </button>
                                                </div>
                                            )}
                                        <div className="submit">
                                            <div className="form-action-buttons">
                                                <ButtonGroup>
                                                    <Button className={`save-question ${showDropdownArrow ? ''
                                                        : 'save-question-rounded'}`} onClick={handleQuestionSave} >
                                                        {title === "Edit Question" ? "Save Changes" : "Save Question"}
                                                    </Button>
                                                    {showDropdownArrow && (<UncontrolledDropdown direction="up"
                                                    >
                                                        <DropdownToggle
                                                            caret
                                                            className="save-question-toggle"
                                                        />
                                                        <DropdownMenu className="dropdown-menu-save-publish">
                                                            <DropdownItem onClick={handleSaveAndPublish}>
                                                                <span className="save-pubish-btn" >Save & Publish</span>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>)}
                                                </ButtonGroup>
                                                <QuestionSaveModal
                                                    showModal={showQuestionSaveModal}
                                                    onClose={handleQuestionSaveModalClose}
                                                    onClick={navigateToQuestions}
                                                />
                                            </div>
                                        </div>
                                        {showErrorModal && (
                                            <ErrorModal
                                                showModal={showErrorModal}
                                                onClose={() => setShowErrorModal(false)}
                                                errors={errors}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {loader && <Loader />}
                </div>
            )}
        </div>
    );
}

export default AddQuestion;