import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const TitleWrapper = styled.div`
  .title-container {
     //background: var(--light-gray-color);
    box-sizing: border-box;
    height: 4rem;
    transition: 0.3s ease;
    position: fixed;
    top: 4rem;
    width: 100% ;
    z-index: 100;
    position: fixed;
  }
  
  .title-wrapper {
    display: flex;
    justify-content:flex-start ;
    color: var(--black-color);
    font-size: var(--font-size-large);
    font-weight: 600;
    margin-left: 4.4rem;
    padding-top: 1.13rem;
    padding-bottom: 1.13rem;
    line-height: 1.75rem;
    transition: 0.3s ease;
  }

  .back-arrow-icon{
   margin-right:1rem;  
   cursor: pointer;     
  }
  
  @media screen and (max-width:1440px) {
    .title-wrapper{
      margin-left: 3.3rem;
    }

    @media screen and (max-width:1024px) {
      .title-wrapper{
        margin-left: 2.3rem;
      }
  }

  .add-button-title {
    display: inline-flex;
    padding: 0.6875rem 1.1875rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: #016dd9;
    border: none;
    color: #fff;
    font-family: Inter;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: none;
    margin-right: 2.5rem;
  }
`;