import React from "react";
import backArrow from "../../Assests/Images/backArrow.svg";
import PropTypes from "prop-types";
import { TitleWrapper } from "./Title.styled";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * Title Component
 *
 * Renders a title element with customizable content.
 *
 * @param { Object } props - The properties passed to the Input component.
 * @param { string } props.titleContent - The content to be displayed as the title.
 *
 * @returns { JSX.Element } - JSX element representing the title.
 *
 * @author - Gaurav
 */
const Title = ({ titleContent, showAddButton, onAddButtonClick, buttonText,navigateUrl }) => {
  const location = useLocation(); 
  const navigate = useNavigate();

  
  const showBackArrow = [
    "/spellandvocab/spelling-add",
    "/spellandvocab/spelling-edit",
    "/spellandvocab/vocabulary-add",
    "/spellandvocab/vocabulary-edit",
     "/questionBuilder-preview",
    "/curriculum/addQuestion"
  ].includes(location.pathname); 

  const handleArrow = () => {
     navigate(-1)
  }
  return (
    <TitleWrapper>
    <div className="title-container">
      <div className="title-wrapper">
        {showBackArrow && (
          <FaArrowLeft
            onClick={handleArrow} 
            className="back-arrow-icon"
          />
        )}
        <span className="title-content">{titleContent}</span>
        {showAddButton && (
          <button className="add-button-title" onClick={onAddButtonClick}>
            + {buttonText}
          </button>
        )}
      </div>
    </div>
  </TitleWrapper>

  );
};

Title.propTypes = {
  // titleContent: PropTypes.string.isRequired,
  showAddButton: PropTypes.bool,
  onAddButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
};

Title.defaultProps = {
  // titleContent: "Title",
  showAddButton: false,
  buttonText: "Add",
};

export default Title;