import "mathlive";
import Select from "react-select";
import Eye from "Assests/Images/eye.png";
import questionConfig from "./questionConfig";
import gallery from "Assests/Images/gallery.svg";
import addIcon from "Assests/Images/add-icon.png";
import TrashCan from "Assests/Images/trash-can.png";
import React, { useState, useEffect, useRef } from "react";
import CKEditorComponent from "./CKEditorComponent";
import { ImAttachment } from "react-icons/im";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/**
 * Component for rendering options based on question type
 *
 * @param {Object} props - Component props
 * @param {string} props.questionType - Type of the question
 * @param {number} props.optionCount - Number of options
 *
 * @returns {JSX.Element} - Rendered component
 *
 * @author - Gaurav
 */
const Options = ({
    solution,
    showMath,
    onPreview,
    bTaxonomy,
    resetForm,
    difficulty,
    showGallery,
    setShowMath,
    optionsData,
    setSolution,
    questionType,
    setShowGallery,
    onOptionsChange,
    onComplexityChange,
    onBloomsTaxonomyChange,
    latexValue,
    setLatexValue,
    parentQuestionType,
    answerField,
    onAnswerExplanationChange,
    isAnswerEditorActive,
    onResourceChange,
    isWriting,
    resource,
    setResource
}) => {
    const fileInputRefs = useRef({});
    const latexInputReference = useRef([]);
    const optionInputReference = useRef([]);

    const [options, setOptions] = useState([]);
    const [complexity, setComplexity] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [fileDetails, setFileDetails] = useState(null);
    const [answerExplanation, setAnswerExplanation] = useState(answerField);
    const optionStructure = { text: "", image: "", isCorrect: false };

    const bloomsTaxonomyOptions = [
        { value: 1, label: "Remembering" },
        { value: 2, label: "Understanding" },
        { value: 3, label: "Applying" },
        { value: 4, label: "Analyzing" },
        { value: 5, label: "Creating" },
        { value: 6, label: "Evaluating" },
    ];
    const complexityOptions = [
        { value: "l", label: "Low" },
        { value: "m", label: "Medium" },
        { value: "h", label: "High" },
        { value: "c", label: "Challenging" },
    ];

    useEffect(() => {
        if (questionConfig.optionImage.includes(questionType)) {
            return;
        }
        if (showMath && optionInputReference.current) {
            let prevLatexValue = [...latexValue];
            optionInputReference.current.forEach((element, index) => {
                let latexFound = false;
                const cursorPosition = element.selectionStart;
                const tagRegex = new RegExp(
                    "<math-field>(.*?)</math-field>",
                    "g"
                );
                let match;
                while ((match = tagRegex.exec(options[index].text)) !== null) {
                    const tagStartIndex = match.index;
                    const tagEndIndex = tagStartIndex + match[0].length;
                    if (
                        cursorPosition >= tagStartIndex &&
                        cursorPosition <= tagEndIndex
                    ) {
                        latexFound = true;
                        prevLatexValue[index] = match[1];
                        latexInputReference.current[index].executeCommand(
                            "moveToMathfieldEnd"
                        );
                    }
                }
                if (!latexFound) {
                    prevLatexValue[index] = "";
                }
            });
            setLatexValue(prevLatexValue);
        }
    }, [showMath]);

    useEffect(() => {
        setAnswerExplanation(answerField);
    }, [answerField]);

    /**
     * optionChecked
     *
     * Function to check if an option is correct
     *
     * @param {index} props.index - Index number of option
     *
     * @returns {boolean} - True if the option is correct, false otherwise
     *
     * @author - Gaurav
     */
    const optionChecked = (index) => {
        return options.find((option, idx) => index == idx).isCorrect;
    };

    /**
     * handleAddOption
     *
     * Function to add a new option
     *
     * @author - Gaurav
     */
    const handleAddOption = () => {
        const newOption = { text: "", image: "", isCorrect: false };
        const updatedOptions = [...options, newOption];
        setOptions(updatedOptions);
        onOptionsChange(updatedOptions);
    };

    /**
     * handleGalleryClick
     *
     * Function to handle gallery click event
     *
     * @author - Gaurav
     */
    const handleGalleryClick = (index) => {
        const fileInput = fileInputRefs.current[index];
        if (fileInput) {
            fileInput.click();
        }
    };

    /**
     * handleUploadImage
     *
     * Function to handle the upload of an image for a specific option.
     *
     * @param {Event} e - The input change event
     *
     * @param {number} index - The index of the option for which the image is being uploaded
     *
     * @returns {el} - The uploaded image is rendered in the UI
     *
     * @author - Gaurav
     */
    const handleUploadImage = (e, index) => {
        const file = e.target.files[0];
        const imageType = /^image\//;
        if (file && imageType.test(file.type)) {
            const img = new Image();
            img.onload = () => {
                if (
                    (img.width === 150 && img.height === 150) ||
                    (img.width === 400 && img.height === 400)
                ) {
                    const updatedOptions = options.map((el, idx) => {
                        if (idx === index) {
                            // return { ...el, image: file };
                            return {
                                ...el,
                                image: file,
                                resolution: {
                                    width: img.width,
                                    height: img.height,
                                },
                            };
                        }
                        return el;
                    });
                    setOptions(updatedOptions);
                    onOptionsChange(updatedOptions);
                    fileInputRefs.current[index].value = null;
                } else {
                    setErrorMessage(
                        "Image must be of 150px*150px or 400px*400px resolution"
                    );
                }
            };
            img.src = URL.createObjectURL(file);
        } else {
            setErrorMessage("Please select a valid image file.");
        }
    };

    /**
     * handleBloomsTaxonomyChange
     *
     * Function to handle Blooms Taxonomy Change
     *
     * @author - Gaurav
     */
    const handleBloomsTaxonomyChange = (selectedOption) => {
        const selectedBloomsTaxonomy = selectedOption.value;
        onBloomsTaxonomyChange(selectedBloomsTaxonomy);
    };

    /**
     * handleComplexityChange
     *
     * Function to handle Complexity Change
     *
     * @author - Gaurav
     */
    const handleComplexityChange = (selectedOption) => {
        const selectedComplexity = selectedOption.value;
        setComplexity(selectedComplexity);
        onComplexityChange(selectedComplexity);
    };

    /**
     * handleCorrectAnswer
     *
     * Function to handle Correct Answer
     *
     * @author - Gaurav
     */
    const handleCorrectAnswer = (e, index) => {
        const isChecked = e.target.checked;
        let updatedOptions;
        if (!isChecked) {
            updatedOptions = options.map((option, idx) => {
                return idx === index ? { ...option, isCorrect: false } : option;
            });
        } else {
            updatedOptions = options.map((option, idx) => {
                if (idx === index) {
                    return { ...option, isCorrect: true };
                } else {
                    if (!questionConfig.multiSelect.includes(questionType)) {
                        return { ...option, isCorrect: false };
                    }
                    return option;
                }
            });
        }
        setOptions(updatedOptions);
        onOptionsChange(updatedOptions);
    };

    /**
     * handleMathToggle
     *
     * Function to handle Math Latex Keyword
     *
     * @author - Gaurav
     */
    const handleMathToggle = () => {
        setShowMath(!showMath);
        setShowGallery(!showGallery);
    };

    /**
     * handleDeleteImage
     *
     * Function to handle delete image
     *
     * @author - Gaurav
     */
    const handleDeleteImage = (index, event) => {
        event.stopPropagation();
        const newOptions = [...options];
        if (fileInputRefs.current[index]) {
            fileInputRefs.current[index].value = null;
        }
        newOptions[index].image = "";
        setOptions(newOptions);
        onOptionsChange(newOptions);
    };

    /**
     * Effect hook to update options when option count changes
     *
     * @returns {Promise<void>}
     *
     * @author - Gaurav
     */
    useEffect(() => {
        if (Array.isArray(optionsData) && optionsData.length > 0) {
            setOptions(optionsData);
        } else {
            setOptions([...Array(2).fill(optionStructure)]);
        }
    }, [optionsData]);

    /**
     * handleDeleteOption
     *
     * Function to handle delete optoin
     *
     * @author - Gaurav
     */
    const handleDeleteOption = (index, event) => {
        event.stopPropagation();
        if (options.length <= 2) {
            setErrorMessage("At least two options must be present.");
            return;
        }
        const updatedOptions = [...options];
        updatedOptions.splice(index, 1);
        setOptions(updatedOptions);
        setErrorMessage("");
        onOptionsChange(updatedOptions);
    };

    /**
     * Effect hook to handle click outside error message
     *
     * @returns {Promise<void>}
     *
     * @author - Gaurav
     */
    useEffect(() => {
        const handleClickOutside = () => {
            setErrorMessage("");
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    /**
     * handleOptionChange
     *
     * Function to handle the change of text for a specific option.
     *
     * @param {Event} e - The input change event
     * @param {number} index - The index of the option for which the text is being changed
     *
     * @returns {void}
     *
     * @author - Gaurav
     */
    const handleOptionChange = (e, index) => {
        const updatedText = e.target.value;
        setOptions(
            options.map((el, idx) => {
                if (idx === index) {
                    return { ...el, text: updatedText };
                }
                return el;
            })
        );
        onOptionsChange(
            options.map((el, idx) => {
                if (idx === index) {
                    return { ...el, text: updatedText };
                }
                return el;
            })
        );
    };

    const handleLatexChange = (e, index) => {
        const value = e.target.value;
        var updatedValue = "";
        let previousOptions = JSON.parse(JSON.stringify(options));
        let prevLatexOption = [...latexValue];
        var latexFound = false;
        const cursorPosition =
            optionInputReference.current[index].selectionStart;
        const tagRegex = new RegExp("<math-field>(.*?)</math-field>", "g");
        let match;
        while ((match = tagRegex.exec(previousOptions[index].text)) !== null) {
            const tagStartIndex = match.index;
            const tagEndIndex = tagStartIndex + match[0].length;
            if (
                cursorPosition >= tagStartIndex &&
                cursorPosition <= tagEndIndex
            ) {
                latexFound = true;
                updatedValue =
                    previousOptions[index].text.substring(0, tagStartIndex) +
                    "<math-field>" +
                    value +
                    "</math-field>" +
                    previousOptions[index].text.substring(tagEndIndex);
            }
        }
        if (!latexFound) {
            updatedValue =
                previousOptions[index].text.substring(0, cursorPosition) +
                "<math-field>" +
                value +
                "</math-field>" +
                previousOptions[index].text.substring(cursorPosition);
        }
        previousOptions[index].text = updatedValue;
        prevLatexOption[index] = value;
        setLatexValue(prevLatexOption);
        setOptions(previousOptions);
        onOptionsChange(previousOptions);
    };

    function handleSolutionChange(event) {
        const newSolution = event.target.value;
        setSolution(newSolution);
        onOptionsChange(newSolution);
    }

    function handleExplanationChange(data) {
        setAnswerExplanation(data);
        onAnswerExplanationChange(data);
    }

    const isValidUrl = (string) => {
        try {
          new URL(string);
          return true;
        } catch (_) {
          return false;
        }
      };

    const handleAttachFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type === "application/pdf") {
                setResource(file)
                onResourceChange(file);
            } else {
                toast.error("Please select a PDF");
            }
        }
    };

    const handleAttachFileClick = () => {
        if (fileInputRefs.current) {
            fileInputRefs.current.click();
        }
    };

    const handleRemoveFile = () => {
        setResource(null);
        fileInputRefs.current.value = null;
    };
    return (
        <div>
            {errorMessage && (
                <div className="error-message">{errorMessage}</div>
            )}
            {!isWriting ? (
                <>
                    {questionConfig.solutionBox.includes(questionType) ? (
                        <>
                            <input
                                type="text"
                                id="input-fields"
                                placeholder="Answer"
                                value={solution || ""}
                                onInput={(e) => handleSolutionChange(e)}
                            />
                        </>
                    ) : (
                        options.map((option, index) => (
                            <div key={index} className="option">
                                <>
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        id={`checkbox-${index}`}
                                        checked={
                                            optionsData[index]?.isCorrect ||
                                            optionChecked(index)
                                        }
                                        onChange={(e) =>
                                            handleCorrectAnswer(e, index)
                                        }
                                    />
                                    {questionConfig.optionInput.includes(
                                        questionType
                                    ) &&
                                        !questionConfig.solutionBox.includes(
                                            questionType
                                        ) && (
                                            <input
                                                type="text"
                                                id="input-fields"
                                                placeholder={`Option ${
                                                    index + 1
                                                }`}
                                                ref={(element) =>
                                                    (optionInputReference.current[
                                                        index
                                                    ] = element)
                                                }
                                                value={
                                                    optionsData &&
                                                    optionsData[index]
                                                        ? optionsData[index]
                                                              .text
                                                        : option.text
                                                }
                                                onChange={(e) =>
                                                    handleOptionChange(e, index)
                                                }
                                            />
                                        )}
                                    {!showMath &&
                                        showGallery &&
                                        questionConfig.optionImage.includes(
                                            questionType
                                        ) && (
                                            <div
                                                className="option-gallery"
                                                onClick={() =>
                                                    handleGalleryClick(index)
                                                }
                                            >
                                                {!questionConfig.optionInput.includes(
                                                    questionType
                                                ) && (
                                                    <>
                                                        <input
                                                            type="text"
                                                            id="input-fields"
                                                            placeholder={`Upload Image ${
                                                                index + 1
                                                            }`}
                                                            readOnly
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                    </>
                                                )}
                                                <img
                                                    src={gallery}
                                                    alt="Gallery"
                                                    className="gallery-icon-img"
                                                />
                                            </div>
                                        )}
                                </>
                                <img
                                    src={TrashCan}
                                    className="trash-can"
                                    onClick={(event) =>
                                        handleDeleteOption(index, event)
                                    }
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                />
                                  <input
                            id={`file-input-${index}`}
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleUploadImage(e, index)}
                            ref={(el) => {
                                // Ensure fileInputRefs is initialized and valid before setting ref
                                if (!fileInputRefs.current) fileInputRefs.current = {};
                                if (el && index >= 0 && index < options.length) {
                                    fileInputRefs.current[index] = el;
                                }
                            }}
                            style={{ display: "none" }}
                        />

                                {options.length > 0 && options[index].image ? (
                                    <>
                                        {typeof options[index].image ===
                                        "string" ? (
                                            options[index].image.startsWith(
                                                "data:image"
                                            ) ? (
                                                <div className="image-container">
                                                    <img
                                                        src={
                                                            options[index].image
                                                        }
                                                        alt={`Pre-Uploaded Image ${
                                                            index + 1
                                                        }`}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="image-container">
                                                    <img
                                                        src="#"
                                                        alt={
                                                            options[index].image
                                                        }
                                                    />
                                                </div>
                                            )
                                        ) : options[index].image instanceof
                                          File ? (
                                            <div className="image-container">
                                                <img
                                                    src={URL.createObjectURL(
                                                        options[index].image
                                                    )}
                                                    alt={`Pre-Uploaded Image ${
                                                        index + 1
                                                    }`}
                                                />
                                            </div>
                                        ) : null}

                                        <img
                                            src={TrashCan}
                                            className="trash-can"
                                            onClick={(event) =>
                                                handleDeleteImage(index, event)
                                            }
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        />
                                    </>
                                ) : (
                                    option.image && (
                                        <>
                                            <div className="image-container">
                                                <img
                                                    src={URL.createObjectURL(
                                                        option.image
                                                    )}
                                                    alt={`Uploaded Image ${
                                                        index + 1
                                                    }`}
                                                />
                                            </div>
                                            <img
                                                src={TrashCan}
                                                className="trash-can"
                                                onClick={(event) =>
                                                    handleDeleteImage(
                                                        index,
                                                        event
                                                    )
                                                }
                                            />
                                        </>
                                    )
                                )}
                                {showMath && (
                                    <div className={`equationIcon-${index}`}>
                                        <math-field
                                            className="math-field"
                                            placeholder={`Enter equation ${
                                                index + 1
                                            }`}
                                            onInput={(e) =>
                                                handleLatexChange(e, index)
                                            }
                                            ref={(element) =>
                                                (latexInputReference.current[
                                                    index
                                                ] = element)
                                            }
                                        >
                                            {latexValue[index]}
                                        </math-field>
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                    <div className="dropdown-headers">
                        <button
                            className="add-option"
                            onClick={handleAddOption}
                        >
                            <img src={addIcon} id="add-icon" alt="Add Icon" />{" "}
                            Add Option
                        </button>
                        <Select
                            className="basic-single bloomsTaxonomy"
                            placeholder={
                                bTaxonomy
                                    ? bloomsTaxonomyOptions.find(
                                          (option) => option.value === bTaxonomy
                                      ).label
                                    : "Blooms Taxonomy"
                            }
                            options={bloomsTaxonomyOptions.map((option) => ({
                                value: option.value,
                                label: <span>{option.label}</span>,
                            }))}
                            styles={{
                                placeholder: (base) => ({
                                    ...base,
                                    color: "#000",
                                }),
                                singleValue: (base) => ({
                                    ...base,
                                    color: "#006cd7",
                                }),
                            }}
                            value={bTaxonomy}
                            onChange={handleBloomsTaxonomyChange}
                            isDisabled={parentQuestionType !== undefined}
                        />
                        <Select
                            className="basic-single complexity"
                            placeholder={
                                difficulty
                                    ? complexityOptions.find(
                                          (option) =>
                                              option.value === difficulty
                                      ).label
                                    : "Complexity"
                            }
                            options={complexityOptions.map((option) => ({
                                value: option.value,
                                label: <span>{option.label}</span>,
                            }))}
                            styles={{
                                placeholder: (base) => ({
                                    ...base,
                                    color: "#000",
                                }),
                                singleValue: (base) => ({
                                    ...base,
                                    color: "#006cd7",
                                }),
                            }}
                            value={complexity}
                            onChange={handleComplexityChange}
                            style={{ width: "8.94rem !important" }}
                            isDisabled={parentQuestionType !== undefined}
                        />
                    </div>

                    <hr className="hr-separator" />
                    <div className="answer-editor" style={{ width: "36rem" }}>
                        Answer Explanation
                        <div className="jodit-editor">
                            <CKEditorComponent
                                answerField={answerField}
                                onExplanationChange={handleExplanationChange}
                            />
                        </div>
                    </div>
                    <hr className="hr-separator" />
                    <div className="footer">
                        <button
                            type="button"
                            className="btn-preview"
                            onClick={onPreview}
                        >
                            <div id="preview-container">
                                <img src={Eye} id="preview-eye" alt="preview" />
                                <span className="preview-button">Preview</span>
                            </div>
                        </button>
                        <div className="form-switch form-switch-question">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                onClick={handleMathToggle}
                                checked={showMath}
                            />
                            <label
                                className="maths-label"
                                htmlFor="flexSwitchCheckDefault"
                            >
                                Math
                            </label>
                            <img
                                src={TrashCan}
                                className="footer-trash-can"
                                alt="trash can"
                                onClick={resetForm}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div>
                    {resource && (
                        <div className="file-container">
                            <span className="attached-text">Attached File</span>
                            
                            <IoIosCloseCircleOutline
                                className="close-icon"
                                size={20}
                                onClick={handleRemoveFile}
                                data-toggle="tooltip" data-placement="top" title="Remove the PDF"
                            />
                            <div className="file-input">
                                <div className="file-info">
                                    <BsFileEarmarkPdf
                                        size={20}
                                        className="pdf-icon"
                                    />
                                    <span className="file-text">
                                    {isValidUrl(resource) ? "View" : resource.name || "View"}
                                    </span>
                                </div>
                                <div className="file-size">
                                    {isValidUrl(resource) ? "" :(resource.size / 1024).toFixed(2)} KB
                                </div>
                            </div>
                        </div>
                    )}
                    <hr className="hr-separator" />
                    <div className="footer">
                        <button
                            type="button"
                            className="btn-preview"
                            onClick={onPreview}
                            style={{ marginTop: "2rem" }}
                        >
                            <div id="preview-container">
                                <img src={Eye} id="preview-eye" alt="preview" />
                                <span className="preview-button">Preview</span>
                            </div>
                        </button>
                        <div className="form-switch form-switch-question">
                            <div
                                className={`attach-file-icon ${resource ? 'disabled' : ''}`} 
                                onClick={!resource ? handleAttachFileClick : (e) => e.preventDefault()}
                            >
                                <ImAttachment />
                            </div>
                        </div>
                        <div
                            className="attach-file"
                            onClick={!resource ? handleAttachFileClick : (e) => e.preventDefault()}
                        >
                            Attach File
                            <input
                                type="file"
                                ref={fileInputRefs}
                                onChange={handleAttachFile}
                                style={{ display: "none" }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Options;
