import styled from "styled-components";
import { Modal} from "react-bootstrap";

const FlagInappropriateStyledModal = styled(Modal)`
  .modal-content {
    width: 28.4375rem;
    height: 18.5rem;
    flex-shrink: 0;
    border-radius: 0.75rem;
    background: var(--white-color); 
  }
 
  label {
    color: var(--balck-color);
    font-family: var(--font-family);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    padding : 0rem 3rem 0rem 1.5rem;
  }

  textarea {
    margin: 1rem 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid #E6E6E6;
    background: #FBFBFB;
    color: #6A6A6A;
    font-family: var(--font-family);
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    overflow-x: hidden;
    width: 24.1875rem;
    height: 4.3125rem;
    flex-shrink: 0;
    padding:10px;
    resize: none;
    outline: none;
    ::-webkit-scrollbar {
      width: 0;
    }
    scrollbar-width: none; 
    }
  }

  .flag-buttons {
    display:flex;
    justify-content:right;
    padding: 0.5rem 1rem 1rem 0rem;
  }
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  margin-left: 25.4rem;
  margin-top: 1rem;
`;
export const FlagInappropriateButton = styled.div`
  cursor: pointer;
  width: 24.1875rem;
  display: flex;
  justify-content: center;
  height: 3rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #016DD9;
  color: var(--white-color);
  align-items: center;
  text-align: center;
  margin: 1rem 1.5rem 1.5rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  font-family: var(--font-family);
`;

export { FlagInappropriateStyledModal };
