import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import _ from "lodash";
import DbConfig from "Constants/db";
import Title from "../../Components/Title/Title";
import Loader from "../../Components/Loader/Loader";
import logo from "../../Assests/Images/empty-box.svg";
import DataTable from "../../Components/DataTable/DataTable";
import FilterHeader from "../../Components/FilterHeader/FilterHeader";
import EditTeamModal from "../../Components/Modals/Teams/EditTeamModal";
import DeleteModal from "../../Components/Modals/DeleteModal/DeleteModal";
import CreateTeamModal from "../../Components/Modals/Teams/CreateTeamModal";
import AddUsersToTheTeamModal from "Components/Modals/Teams/AddUsersToTheTeam";
import RemoveUsersFromTheTeamModal from "../../Components/Modals/Teams/RemoveUsersFromTheTeamModal";
import { getAllTeams, deleteTeams, organizationTitlesUrl, userTitlesUrl, teamsTitlesUrl, getOrganizationTitles, getTeamsTitles, getUserTitles } from "../../Utils/ApiHandler/TeamsApi";

function Teams({ }) {
  const { page } = useParams();
  const navigate = useNavigate();

  var inputOrganization;

  const [isBlur, setIsBlur] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [addUser, showAddUser] = useState(false);
  const [inputTeam, setInputTeam] = useState([]);
  const [inputUser, setInputUser] = useState("");
  const [actionData, setActionData] = useState('');
  const [editTeam, showEditTeam] = useState(false);
  const [deleteRole, setDeleteRole] = useState('');
  const [teamTitles, setTeamTitles] = useState([]);
  const [userTitles, setUserTitles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [removeUser, showRemoveUser] = useState(false);
  const [createTeam, showCreateTeam] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [teamsAccess, setTeamsAccess] = useState(false);
  const [deleteModel, showDeleteModel] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [searchOptions, setSearchOptions] = useState(true);
  const [dataFilterStatus, setDataFilterStatus] = useState(true);
  const [organizationTitles, setOrganizationTitles] = useState([]);
  const [deleteActiveUserCount, setDeleteActiveUserCount] = useState(0);
  const columns = ["Team", "Organization", "Description", "Active Users"];
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
  const [goToPage, setGoToPage] = useState(0);
  const [filter, setFilter] = useState([
    { name: "page", value: 1 },
    { name: "limit", value: 10 },
    { name: "active", value: true },
    { name: "search", value: "" },
    { name: "team", value: [] },
    { name: "organizationIds", value: [] },
  ]);
  const settingsAccess = () => {
    DbConfig.settingsAccess.toArray().then(settingsAccessData => {
      setTeamsAccess(settingsAccessData.map(item => item.teams));
    });
  }

  useEffect(() => {
    settingsAccess();
    fetchOrganizationTitles();
  }, []);

  useEffect(() => {
    if (goToPage > 1) {
      setCurrentPage(goToPage);
    }
    else {
      setCurrentPage(1);
    }
    refreshDatatable();
    navigate(`/teams/${currentPage}`)
  }, [filter, goToPage])

  useEffect(() => {
    if (inputUser) {
      fetchUserTitles(dataFilterStatus);
    }
    if (inputTeam) {
      fetchTeamTitles(dataFilterStatus);
    }
  }, [inputUser, inputTeam, dataFilterStatus]);

  const fetchTeamTitles = _.debounce(async (isActive) => {
    if (inputTeam.length === 0) return;
    const url = `${teamsTitlesUrl}${inputTeam}?active=${isActive}`
    try {
      const result = await getTeamsTitles(url);
      result.length > 0 ? setTeamTitles(result) : setSearchOptions(false);
    } catch (error) {
      throw new Error(error);
    }
  }, 1000);

  const fetchUserTitles = _.debounce(async (isActive) => {
    if (inputUser.length === 0) return;
    const url = `${userTitlesUrl}${inputUser}?active=${isActive}`
    try {
      const result = await getUserTitles(url);
      result.length > 0 ? setUserTitles(result) : setSearchOptions(false);
    } catch (error) {
      throw new Error(error);
    }
  }, 1000);

  const fetchOrganizationTitles = _.debounce(async (isActive) => {
    const url = organizationTitlesUrl
    try {
      const result = await getOrganizationTitles(url);
      result.length > 0 ? setOrganizationTitles(result) : setSearchOptions(false);
    } catch (error) {
      throw new Error(error);
    }
  }, 1000);

  const handleTeamAction = (type) => {
    const actionType = type.toLowerCase();
    const actionMap = {
      create: showCreateTeam,
      edit: showEditTeam,
      delete: showDeleteModel,
    };
    const showActionModel = actionMap[actionType];
    if (!showActionModel) return;
    const action = actions.find(action => action.name === `${actionType}team` || action.name === "delete");
    if (action) {
      const team = action.data;
      if (actionType === "delete") {
        const data = [
          {
            title: team.team,
            teamIds: [team._id]
          }
        ];
        setActionData(data);
        const activeUserCount = team?.activeUsers || 0;
        setDeleteRole(activeUserCount > 0 ? "TeamWithActiveUsers" : "Team");
        setDeleteActiveUserCount(activeUserCount);
      } else {
        setActionData(team);
      }
    }
    setIsBlur(true);
    showActionModel(true);
  };

  const handleUserAction = (type) => {
    const actionType = type.toLowerCase();
    const actionMap = {
      add: showAddUser,
      remove: showRemoveUser,
    };
    const showActionModel = actionMap[actionType];
    if (!showActionModel) return;
    const action = actions.find(action => action.name === `${actionType}User`);
    if (action) {
      setActionData(action.data._id);
    }
    setIsBlur(true);
    showActionModel(true);
  };

  const handleDeleteAction = async () => {
    const [teamIds] = actionData.map(item => item.teamIds);
    const data = {
      id: teamIds[0],
      confirmAction: "yes",
    };
    await deleteTeams(data);
    showDeleteModel(false);
    refreshDatatable();
  };

  const handlePageChange = (currentPage, itemsPerPage) => {
    setCurrentPage(currentPage);
    setGoToPage(0);
    const updatedFilter = filter
      .filter(f => f.name !== "page" && f.name !== "limit")
      .concat(
        { name: "page", value: currentPage },
        { name: "limit", value: itemsPerPage }
      );
    setFilter(updatedFilter);
  };

  const actions = [
    {
      Label: "Add user",
      name: "addUser",
      clickAction: () => handleUserAction("add"),
      access: teamsAccess[0]?.edit
    }, {
      Label: "Remove user",
      name: "removeUser",
      clickAction: () => handleUserAction("remove"),
      access: teamsAccess[0]?.edit
    }, {
      Label: "Edit team",
      name: "editteam",
      clickAction: () => handleTeamAction("edit"),
      access: teamsAccess[0]?.edit
    }, {
      Label: "Delete team",
      name: "delete",
      clickAction: () => handleTeamAction("delete"),
      class: "text-delete",
      access: teamsAccess[0]?.delete
    }
  ];

  const refreshDatatable = _.debounce(() => {
    setIsLoading(true)
    getAllTeams(filter).then((data) => {
      setTableData(data.teams.map(extractFields));
      setTotalPageCount(data.totalPages);
      if (data.teams && data.teams.length > 0) {
        setDataFetched(true);
      }
      setIsBlur(false);
      setIsLoading(false);
    });
  }, 1000);

  const handleInputOrganizationChange = _.debounce((input) => {
    inputOrganization = input ? input : '';
  }, 1000);

  const handleInputTeamChange = _.debounce((input) => {
    if (!input) {
      setInputTeam("");
    } else {
      setInputTeam(input);
    }
  }, 1000);

  const handleInputUserChange = _.debounce((input) => {
    if (!input) {
      setInputUser("");
    } else {
      setInputUser(input);
    }
  }, 1000);

  const handleFilterChange = (selectedOptions, filterName, valueKey) => {
    const values = Object.values(selectedOptions).map(option => option[valueKey]);
    const updatedFilter = filter
      .filter(f => f.name !== filterName && f.name !== "page")
      .concat(
        { name: filterName, value: values },
        { name: "page", value: 1 }
      );
    setFilter(updatedFilter);
  };

  const handleResetFilter = () => {
    const defaultFilterValues = {
      page: 1,
      limit: 10,
      active: dataFilterStatus,
      flag: "",
      search: "",
      title: [],
      code: [],
      organizationIds: [],
      program: [],
      level: []
    };
    const updatedFilter = Object.entries(defaultFilterValues).map(([name, value]) => ({
      name,
      value
    }));
    setFilter(updatedFilter);
    setOrganizationTitles([]);
    setTeamTitles([]);
  };

  const extractFields = (obj) => {
    const organizationTitle = obj.organization && obj.organization.title ? obj.organization.title : '';
    const organizationId = obj.organization && obj.organization._id ? obj.organization._id : '';
    return {
      team: obj.teamName,
      organization: organizationTitle,
      description: obj.description,
      activeUsers: obj.activeUsers,
      organizationId: organizationId,
      _id: obj._id
    };
  };

  const closeModal = (modal) => {
    setIsBlur(false);
    modal(false);
  }

  return (
    <>
      <Title titleContent="Teams" />
      {
        dataFetched ? (
          <>
            <div className={`settings-Container ${isBlur ? 'blur' : ''}`}>
              <FilterHeader
                firstDropdown={teamTitles}
                secondDropdown={organizationTitles}
                firstText="Team"
                secondText="Organization"
                buttonText="+ Add new Domain"
                onInputTitleChange={handleInputOrganizationChange}
                onTitleChange={selectedOptions => handleFilterChange(selectedOptions, "organizationIds", "id")}
                onInputCodeChange={handleInputTeamChange}
                onCodeChange={selectedOptions => handleFilterChange(selectedOptions, "team", "label")}
                filter={filter}
                setFilter={setFilter}
                resetFilter={handleResetFilter}
                setDataFilterStatus={setDataFilterStatus}
                showMoreFilters={false}
                showTitleDropdown={true}
                pageName="Teams"
                showCreateButton={teamsAccess[0]?.add}
                handleCreateButton={() => handleTeamAction("create")}
                createButtonText="Create team"
                searchOptions={searchOptions}
                setSearchOptions={setSearchOptions}
                setGoToPage={setGoToPage}
                isPageChange={goToPage}
              />
              <DataTable
                showCheckbox={false}
                columns={columns}
                data={tableData}
                actions={actions}
                totalCount={totalPageCount}
                filter={filter}
                setFilter={setFilter}
                handlePageChange={handlePageChange}
                setGoToPage={setGoToPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
              {createTeam && <CreateTeamModal
                show={showCreateTeam}
                handleClose={() => closeModal(showCreateTeam)}
                parentIdentifier={actionData}
                onSave={refreshDatatable}
              />}
              {addUser && <AddUsersToTheTeamModal
                show={showAddUser}
                handleClose={() => closeModal(showAddUser)}
                parentIdentifier={actionData}
                onSave={refreshDatatable}
                teamIds={actionData}
                onUserInputChange={handleInputUserChange}
                userTitles={userTitles}
                onUserChange={selectedOptions => handleFilterChange(selectedOptions, "userName", "id")}
                placeholderText="Search with name or email"
                searchOptions={searchOptions}
                setSearchOptions={setSearchOptions}
              />}
              {removeUser && <RemoveUsersFromTheTeamModal
                show={removeUser}
                handleClose={() => closeModal(showRemoveUser)}
                teamId={actionData}
                parentIdentifier={actionData}
                onSave={refreshDatatable}
              />}
              {editTeam && <EditTeamModal
                show={editTeam}
                handleClose={() => closeModal(showEditTeam)}
                initialData={actionData}
                onSave={refreshDatatable}
              />}
              {deleteModel && <DeleteModal showModal={deleteModel}
                role={deleteRole}
                onClick={handleDeleteAction}
                onClose={() => closeModal(showDeleteModel)}
                onSave={refreshDatatable}
                deleteData={actionData}
                activeUserCount={deleteActiveUserCount}
                teamName={actionData[0].title}
              />
              }
            </div>
          </>
        ) : !isLoading && (
          <div className="empty-Container">
            <img src={logo} alt="Logo" style={{ display: "block", margin: "0 auto" }} />
            <p className="text-center" style={{ color: "green" }}>You haven't created any teams yet.!</p>
            <p className="sub-text">Click below button to create your first team.</p>
            <button onClick={() => handleTeamAction("create")} type="button" className="btn btn-primary">+ Create Team</button>
            {createTeam && (
              <CreateTeamModal
                show={showCreateTeam}
                handleClose={() => closeModal(showCreateTeam)}
                parentIdentifier={actionData}
                onSave={refreshDatatable}
              />
            )}
          </div>
        )}
      {isLoading && (<div className="spinner-overlay"><Loader /></div>)}
    </>
  );
}

export default Teams;
