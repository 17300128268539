import styled from 'styled-components';

const commonFontStyle = {
  color: 'var(--black-color)',
  fontFamily: 'Inter',
  fontSize: 'var(--font-size-medium)',
  fontStyle: 'normal',
  lineHeight: 'normal',
};

export const DataTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
 // overflow: auto;  

  .form-check-input{
    flex-shrink: 0;
    display: flex;
    width: 18px;
    height: 18px;
    padding: 3px;
    justify-content: center;
    align-items: center;
    border:2px solid #DEDEDE;
  }
  .input-check-head{
    width: 20px;
    height: 20px;
  }
  .multi-checkbox{
    display:flex;
    align-items:center;
  }

  .down-arrow {
    color:#C0C0C0 !important;
    z-index: 999;
  }

  .bulk-dropdown {
    background: opaque;
    background-color: var(--white-color);
    position: absolute;
    width: 12rem;
    border: 1px solid #D9DBE1;;
    border-radius: 4px;
    box-shadow: 0px 2px 18px 1px rgba(0, 0, 0, 0.12);
    padding:0.8rem  0rem 0.3rem 0rem;
  }
  
  .bulk-dropdown-option {
    background: opaque;
    padding: 0.6rem 1.5rem;
    cursor: pointer;
    background-color: white;
    ${commonFontStyle};
    font-weight:500;
    color:#2A2E34 !important;
  }
  .bulk-dropdown-option:hover {
    background-color: #f0f0f0;
  }
  .bulk-last-child {
    border-top: 1px solid #dee2e6;
  }

  .menu-option:hover {
    background-color: #f0f0f0;
  }
  .text-delete {
    color: var(--highlight-red-color) !important;
  }
  
    @media (max-width: 1440px) and (min-width: 1024px) {
      top:25%;
    }
    @media (max-width: 1025px) and (min-width: 769px) {
      top:30%;
    }
    @media (width: 768px) {
      top:38%;
    }
    @media (max-width: 767px) and (min-width: 319px) {
      top:7rem;
    }
  }
`;

export const TableLayout = styled.div`
  display: flex;
  margin-bottom:1%;
  justify-content: left;
  overflow: visible;
  margin-left:3.5%;

  .tableParent td:hover:before {
    content: attr(data-tooltip);
    max-width: 36.4375rem;
    width: auto;
    position: absolute;
    color: #000;
    border-radius: 0.5rem;
    border: 1px solid #E3E3E3; 
    background: var(--white-color);  
    box-shadow: 0px 2px 10px 4px rgba(0, 0, 0, 0.08);    
    font-family: var(--font-family);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5625rem;
    margin-right: 5rem;
    margin-left: -11.9rem;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 5px;
    white-space: nowrap;
    white-space: pre-line;
    z-index: 999;
  }
  
  .tableParent td:hover:not([data-tooltip]):before {
    display: none;
  }
  
  .tableParent td {
    pointer-events: auto;
  }

  .tableParent {
    width: 96%;
  }

  th {
    background-color: var(--subtle-white-color);
    font-weight: 600;
    line-height: 16px !important;
  }

  td {
    ${commonFontStyle};
    font-size: var(--font-size-small) !important;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
    cursor: pointer;
  }

  tbody tr {
    border: 1px double var(--subtle-white-color);
    border-width: 5px 1px;
    border-style: double solid double solid !important;
    background-color:var(--white-color);
  }

  tbody tr:first-child {
    border-style: none solid double solid !important;
    border-width: 1px !important;
  }
   .flag-column {
  padding:0px !important;
}
  td:nth-child(2) {
    padding-right: 0rem !important;
}
  tbody tr:last-child {
    border-style: double solid solid solid !important;
    border-width: 1px !important;
  }

  tbody tr:hover {
    background-color: var(--subtle-white-color);
  }

  td, th {
    padding: 10px 1.5rem 10px 10px;
    ${commonFontStyle};
  }
  thead.tableHeader tr th:first-child {
    border-radius: 8px 0 0 0;
  }
  thead.tableHeader tr th:last-child {
      border-radius: 0 8px 0 0;
  }
`;

export const PaginatorWrapper = styled.div`
  ${commonFontStyle}
  display: flex;
  justify-content: left;
  color:#6C6C6C !important;
  font-weight:500;
  margin-right:0;
  font-size: var(--font-size-small) !important;

  button {
    border: none;
    width: auto;
    text-align: center;
    padding: 0.2rem 0.5rem;
    margin: 0.2rem;
  }

  button.bold {
    color:var(--black-color) !important;
    
    font-weight: 700;
  }
  @media screen and (max-width: 576px) {
    button {
      padding: 0.15rem 0.3rem; 
      margin: 0.15rem; 
    }
`;
export const PaginatorLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  width:93%;
  margin-left: 3.5% ;
  margin-bottom:3rem;
  

  .pageCount {
    ${commonFontStyle};
    display: flex; 
    align-items: center; 
    font-weight: 500 !important;
    color: #7A7A7A !important;
    margin-right:5px;
    font-size: var(--font-size-small) !important;
  }

  .pageCount > div {
    margin-right: 5px; 
  }

   .input-search-container {
    width: 8rem;
    height: 2.5rem;
    flex-shrink: 0;
    color: #a8a8a8;
    margin-right: 16px;
  }

  .input-search-wrapper {
      position: relative;
  }

  .input-search {
      width: 5rem;
      padding: 0.65rem 0.25rem 0.5625rem 0.5rem;
      border-radius: 4px;
      border-radius: 0.5rem;
      border: 1px solid #d8d8d8;
      color: #a8a8a8;
      font-family: Inter;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 500;
  }

  .input-search:focus {
      border: 2px solid var(--blue-color);
      outline: none;
  }
`;

export const MenuIcon = styled.div`
  cursor: pointer;
  position: absolute;

  .meatballs-menu {
    position: relative;
    margin-top:-0.5rem;
    margin-left:1rem;
  }

  .menu-toggle {
    cursor: pointer;
  }

  .menu-options {
  background: opaque;
  background-color: var(--white-color);
  position: absolute;
  width: 12rem;
  top: 100%;
  right: 1.2rem;
  border: 1px solid #D9DBE1;
  border-radius: 4px;
  box-shadow: 0px 2px 18px 1px rgba(0, 0, 0, 0.12);
  padding: 0.8rem 0rem 0.3rem 0rem;
  z-index: 1000;
}

  .menu-option {
    background: opaque;
    padding: 0.6rem 1.5rem;
    cursor: pointer;
    background-color: var(--white-color);
    ${commonFontStyle};
    font-weight:500;
    color:#2A2E34 !important;
  }

  .menu-option.disabled {
    text-decoration: line-through;
    color: gray !important;
    cursor: not-allowed !important;
  }

  .menu-option:hover {
    background-color: #f0f0f0;
  }

  .last-option {
    border-bottom: 1px solid #dee2e6;
  }
  .text-delete {
    color: var(--highlight-red-color) !important;
  }
  
  
  
`;

export const PageFilterWrapper = styled.div`
  display: flex;
  justify-content:space-between;
  align-items:center;
  ${commonFontStyle};
  font-size: var(--font-size-small) !important;
  color:#7A7A7A;
  
  .filter-dropdowns {
    border-radius: 5px;
    width: auto;
    margin-left: 0.25rem;
    margin-top: -1.4rem;
    font-size: 12px;
    border: 1px solid #DFE3EB;
    height: 0.5rem
  }
  }
`;
export const Flag = styled.img`
  width: 1.5rem; 
  height: 1.2rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  margin-top:-0.1rem;
`;