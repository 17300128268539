import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from 'react-toastify';
import {
  DeleteStyledModal,
  ModalFooter,
  CloseIcon,
} from "../Modal.styled";
import closeIcon from "../../../Assests/Images/Vector.svg";


/**
 * DeleteModal
 *
 * Component for displaying a delete confirmation modal.
 *
 * @param {object} props - Component props
 * @param {boolean} props.showModal - Flag indicating whether the modal should be displayed.
 * @param {string} props.role - Role for which the deletion is being confirmed
 * @param {function} props.onClose - Function to call when the modal is closed
 * @param {function} props.onSave - Function to call when the deletion is confirmed
 * @returns {JSX.Element} The rendered DeleteModal
 *
 * @author Pranathi
 */

function DeleteModal({ showModal, role, onClose, onSave, deleteData, onClick, activeUserCount, teamName }) {
  let modalTitle, modalContent;
  let count = 0;
  let name;
  if (Array.isArray(deleteData)) {
    deleteData.forEach(data => {
      count = data.child ?? 0;
      name = data.title ?? '';
    });
  }
  const createModalContent = (mainText, actionText) => (
    <>
      {mainText}
      <br />
      <div style={{fontWeight:"500"}}>{actionText}</div>
    </>
  );
  switch (role) {
    case "Program":
      modalTitle = ` Do you want to remove this ${role}?`;
      if (count > 1) {
        modalContent = createModalContent(
          `This action will automatically delete ${count} levels and all associated content. This action cannot be undone.`,
          `Are you sure you want to proceed?`
        );
      } else if (count === 1) {
        modalContent = createModalContent(
          `This action will automatically delete the level and all associated content. This action cannot be undone.`,
          `Are you sure you want to proceed?`
        );
      } else {
        modalContent = `You are about to remove the ${role} "${name}". This action can't be undone.`;
      }
      break;
    case "Level":
      modalTitle = ` Do you want to remove this ${role}?`;
      if (count > 1) {
        modalContent = createModalContent(
          `This action will automatically delete ${count} domains and all associated content. This action cannot be undone.`,
          `Are you sure you want to proceed?`
        );
      } else if (count === 1) {
        modalContent = createModalContent(
          `This action will automatically delete the domain and all associated content. This action cannot be undone.`,
          `Are you sure you want to proceed?`
        );
      } else {
        modalContent = `You are about to remove the ${role} "${name}". This action can't be undone.`;
      }
      break;
    case "Domain":
      modalTitle = ` Do you want to remove this ${role}?`;
      if (count > 1) {
        modalContent = createModalContent(
          `This action will automatically delete ${count} topics and all associated content. This action cannot be undone.`,
          `Are you sure you want to proceed?`
        );
      } else if (count === 1) {
        modalContent = createModalContent(
          `This action will automatically delete the topic and all associated content. This action cannot be undone.`,
          `Are you sure you want to proceed?`
        );
      } else {
        modalContent = `You are about to remove the ${role} "${name}". This action can't be undone.`;
      }
      break;
    case "Topic":
      modalTitle = ` Do you want to remove this ${role}?`;
      if (count > 1) {
        modalContent = createModalContent(
          `This action will automatically delete ${count} skills and all associated content. This action cannot be undone.`,
          `Are you sure you want to proceed?`
        );
      } else if (count === 1) {
        modalContent = createModalContent(
          `This action will automatically delete the skill and all associated content. This action cannot be undone.`,
          `Are you sure you want to proceed?`
        );
      } else {
        modalContent = `You are about to remove the ${role} "${name}". This action can't be undone.`;
      }
      break;
    case "Skill":
      modalTitle = ` Do you want to remove this ${role}?`;
      if (count > 1) {
        modalContent = createModalContent(
          `This action will automatically delete ${count} questions and all associated content. This action cannot be undone.`,
          `Are you sure you want to proceed?`
        );
      } else if (count === 1) {
        modalContent = createModalContent(
          `This action will automatically delete the question and all associated content. This action cannot be undone.`,
          `Are you sure you want to proceed?`
        );
      } else {
        modalContent = `You are about to remove the ${role} "${name}". This action can't be undone.`;
      }
      break;
    case "Question":
    case "Organization":
    case "Team":
    case "Role":
    case "User":
      
      modalTitle = ` Do you want to remove this ${role}?`;
      modalContent = `You are about to remove the ${role} "${name}". This action can't be undone.`;
      break;
    case "MultipleLevels":
      modalTitle = `Do you want to remove these levels?`;
      modalContent = `You are about to remove multiple levels. This action can't be undone.`;
      break;
    case "MultipleDomains":
      modalTitle = `Do you want to remove these domains?`;
      modalContent = `You are about to remove multiple domains. This action can't be undone.`;
      break;
    case "MultipleTopics":
        modalTitle = `Do you want to remove these Topics?`;
        modalContent = `You are about to remove multiple topics. This action can't be undone.`;
      break;  
    case "MultipleSkills":
      modalTitle = `Do you want to remove these skills?`;
      modalContent = `You are about to remove multiple skills. This action can't be undone.`;
      break;
    case "MultipleTeams":
      modalTitle = `Do you want to remove these teams?`;
      modalContent = `You are about to remove multiple teams. This action can't be undone.`;
      break;
    case "MultiplePrograms":
      modalTitle = `Do you want to remove these Programs?`;
      modalContent = `You are about to remove multiple programs. This action can't be undone.`;
      break;
    case "MultipleQuestions":
      modalTitle = `Do you want to remove these Questions?`;
      modalContent = `You are about to remove multiple questions. This action can't be undone.`;
      break;
      case "TeamWithActiveUsers":
      modalContent = `There are ${activeUserCount} active users still associated with this Team "${teamName}". Do you want to remove them and delete the team?`;
      break;    
    case "MultipleRoles":
      modalTitle = `Do you want to remove these Role?`;
      modalContent = `You are about to remove multiple Roles. This action can't be undone.`;
      break;
      case "MultipleUsers":
        modalTitle = `Do you want to remove these User?`;
        modalContent = `You are about to remove multiple Users. This action can't be undone.`;
    default:
      modalTitle = "Default Title";
      modalContent = "Default Content";
  }

  return (
    <DeleteStyledModal show={showModal} onHide={onClose} role='dialog' centered>
      <Modal.Title>{modalTitle}</Modal.Title>
      <CloseIcon src={closeIcon} alt="Close" onClick={onClose} />
      <Modal.Body>{modalContent}</Modal.Body>
      <ModalFooter>
        <Button variant="secondary" color="red" onClick={onClick} style={{width:'auto'}}>
          Yes, Remove
        </Button>
        <Button variant="secondary" onClick={onClose}>
          No, Cancel
        </Button>
      </ModalFooter>
    </DeleteStyledModal>
  );
}

DeleteModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

export default React.memo(DeleteModal);