import React from 'react';
import { Accordion } from "react-bootstrap";

import { capitalizeString } from "Utils/StringUtilities";
import { formatUpdatedAction, formatActionDetails } from "Utils/VersionHistoryUtils";

/**
 * useAccordionItems is a hook that takes an array of objects with properties
 * "action", "featureType", "actionDetails", "user", and "timestamp". It returns
 * a function that takes this array and returns an array of AccordionItems that
 * can be used to display the history of a featureType. The items in the array
 * are sorted by timestamp in descending order. The function also formats the
 * actionDetails and timestamp properties of each item for display in the
 * AccordionItems.
 *
 * @param {Object[]} historyItems - An array of objects with properties
 * "action", "featureType", "actionDetails", "user", and "timestamp".
 * @returns {Function} A function that takes historyItems and returns an array
 * of AccordionItems.
 */
const useAccordionItems = () => {
    return (historyItems = [], groupIndex, pageTitle, rowCode) => {
        if (!Array.isArray(historyItems)) return [];
        return historyItems.map((item, itemIndex) => {
            const formattedAction = capitalizeString(item.action);
            const formattedFeatureType = item.featureType;
            const formattedTimestamp = new Date(parseInt(item.timestamp)).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                second: undefined
            });
            const details = {
                newTitle: item.actionDetails?.title?.new || '',
                code: rowCode || '',
                newDescription: item.actionDetails?.description?.new || '',
                oldDescription: item.actionDetails?.description?.old || '',
                oldStatus: item.actionDetails?.status?.old || '',
                newStatus: item.actionDetails?.status?.new || '',
            };
            const actionTemplates = {
                created: formatActionDetails(formattedAction, formattedFeatureType, details, pageTitle).created,
                updated: formatUpdatedAction(formattedAction, formattedFeatureType, item.actionDetails, rowCode),
                deleted: formatActionDetails(formattedAction, formattedFeatureType, details).deleted,
                published: formatActionDetails(formattedAction, formattedFeatureType, details).published,
                unpublished: formatActionDetails(formattedAction, formattedFeatureType, details).unpublished,
                reactivated: formatActionDetails(formattedAction, formattedFeatureType, details).reactivated,
                flagged: formatActionDetails(formattedAction, formattedFeatureType, details).flagged,
                unflagged: formatActionDetails(formattedAction, formattedFeatureType, details).unflagged,
            };
            try {
                return (
                    <Accordion.Item eventKey={`${groupIndex}-${itemIndex}`}
                        key={`${groupIndex}-${itemIndex}`} className="mb-3 mt-2 version-history-items">
                        <Accordion.Header className="nowrap-text">
                            <span style={{ fontWeight: '600' }}>
                                {item.user && item.user.name ? item.user.name : 'Unknown User'}
                            </span>
                            <span>&nbsp;{item.action} {pageTitle.charAt(0).toLowerCase() + pageTitle.slice(1)} at {formattedTimestamp}</span>
                        </Accordion.Header>
                        <Accordion.Body className="version-history-body">
                            <p dangerouslySetInnerHTML={{ __html: actionTemplates[item.action] || '' }} />
                        </Accordion.Body>
                    </Accordion.Item>
                );
            } catch (error) {
                console.error(error);
                return null;
            }
        }).filter(Boolean);
    };
};

export default useAccordionItems;