import { toast } from "react-toastify";
import { baseUrl, fetchToken } from "Constants/ApiUrls";

export const bulkImport = async (file, endPoint, currentSelectedLevel) => {
    try {
        const url = `${baseUrl}/cms/${endPoint}`;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("parentId", currentSelectedLevel);
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${fetchToken()}`,
            },
            body: formData,
        });
        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error during bulk import:", error);
        toast.error('Something went wrong!');
        throw new Error(error);
    }
};
