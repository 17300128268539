import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Offcanvas, Tab, Nav, Navbar } from 'react-bootstrap';

import "./styles.css";
import CommentsTab from "./CommentsTab";
import VersionHistoryTab from "./VersionHistoryTab";

const CommentsAndVersionHistory = ({ show, handleClose, onSave, id, access, pageTitle, rowCode }) => {
    const showCommentsTab = pageTitle.toLowerCase() !== 'level' && pageTitle.toLowerCase() !== 'program';
    const [selectedTab, setSelectedTab] = useState(showCommentsTab ? 'comments' : 'versionHistory');

    return (
        <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            backdrop="static"
            keyboard={false}
            className="comments-version-history-offcanvas"
        >
            <Offcanvas.Header closeButton>
                <Navbar className="align-items-start justify-content-between gap-3">
                    <Nav>
                        {showCommentsTab && (
                            <Nav.Link
                                className={`nav-link ${selectedTab === 'comments' ? 'active' : ''}`}
                                onClick={() => setSelectedTab('comments')}
                            >
                                Comments
                            </Nav.Link>
                        )}
                        <Nav.Link
                            className={`nav-link ${selectedTab === 'versionHistory' ? 'active' : ''}`}
                            onClick={() => setSelectedTab('versionHistory')}
                        >
                            Version History
                        </Nav.Link>
                    </Nav>
                </Navbar>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Tab.Container activeKey={selectedTab} onSelect={(tab) => setSelectedTab(tab)}>
                    <Tab.Content>
                        <CommentsTab
                            id={id}
                            pageTitle={pageTitle}
                            access={access}
                            onSave={onSave}
                            activeTab={selectedTab}
                        />
                        <VersionHistoryTab
                            id={id}
                            rowCode={rowCode}
                            pageTitle={pageTitle}
                            activeTab={selectedTab}
                        />
                    </Tab.Content>
                </Tab.Container>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CommentsAndVersionHistory;
