import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

export const ErrorModalStyled = styled(Modal)`
  
.modal-content {
  padding: 1rem 0.5rem ;
    display: flex;
    justify-content: center;
    min-width: 25rem;
    width: auto;
    height: auto;
    min-height: 10rem;
    max-height: 60rem; /* Adjust as needed */
    overflow: hidden; /* Hide the scrollbar initially */
    flex-shrink: 0;
  }

  .modal-body {
    margin-top: 0.75rem;
    font-size: 1rem;
    max-height: 10rem; /* Adjust as needed */
    overflow-y: auto;
    font-style: normal;
    font-weight: 400;
    font-family: var(--font-family);
    padding-right: 15px; /* Add a right padding to ensure scrollbar visibility */
  }
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 0.75969rem;
  height: 0.75969rem;
  flex-shrink: 0;
  margin-left: 0.75rem;
  // position: absolute;
  right: 1.5rem;
`;
