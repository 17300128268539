import _ from "lodash";
import { getTopicCodes } from "Utils/ApiHandler/TopicApi";
import { getLevelCodes } from "Utils/ApiHandler/LevelApi";
import { getDomainCodes } from "Utils/ApiHandler/DomainApi";
import { getProgramCodes } from "Utils/ApiHandler/ProgramApi";
import { getSkillCodes, skillCodesUrl } from "Utils/ApiHandler/SkillsApi";
import { getQuestionCodes, questionCodeUrl } from "Utils/ApiHandler/QuestionApi";
import { domainCodesUrl, levelCodesUrl, programCodeUrl, topicCodesUrl } from "Constants/ApiUrls";
import {
    fetchCodes, fetchLevelTitle, fetchSkillTitle, fetchTopicTitle, fetchDomainTitle, fetchProgramTitle
} from "Utils/ApiHandler/FilterDropdownsApiCalls";
import { Slide } from "react-toastify";

const pageCodeMappings = {
    Programs: { url: programCodeUrl, getCodeFn: getProgramCodes },
    Levels: { url: levelCodesUrl, getCodeFn: getLevelCodes },
    Domains: { url: domainCodesUrl, getCodeFn: getDomainCodes },
    Topics: { url: topicCodesUrl, getCodeFn: getTopicCodes },
    Skills: { url: skillCodesUrl, getCodeFn: getSkillCodes },
    Questions: { url: questionCodeUrl, getCodeFn: getQuestionCodes },
};

export const fetchAndSetData = async (inputs, pageName, selections, dataFilterStatus, setSearchOptions, setTitles) => {
    let key;
    if (inputs.program) key = 'program';
    if (inputs.level) key = 'level';
    if (inputs.domain) key = 'domain';
    if (inputs.topic) key = 'topic';
    if (inputs.skill) key = 'skill';
    if (inputs.questionType) key = 'questionType';
    if (inputs.code) key = 'code';
    const response = await fetchData(inputs, pageName, selections, dataFilterStatus, key, setSearchOptions);
    if (response.length > 0) {
        setTitles(prev => ({ ...prev, [key]: response }));
        setSearchOptions(true);
    }
};

export const fetchData = async (inputs, pageName, selections, dataFilterStatus, key, setSearchOptions) => {
    if (!key) return false;
    if (key !== 'code') {
        const value = await fetchTitleDropdown(inputs, selections, setSearchOptions);
        if (value) return value;
    }
    if (inputs.code && pageCodeMappings[pageName]) {
        const { url: pageCodeUrl, getCodeFn } = pageCodeMappings[pageName];
        const value = await fetchPageSpecificCodeDropdowns(inputs.code, pageCodeUrl, getCodeFn, dataFilterStatus,
            setSearchOptions);
        if (value) return value;
    }
    return false;
};

export const fetchDropdown = (fetchFunction, key, setTitles) => {
    return _.debounce(async () => {
        const data = await fetchFunction();
        setTitles((prev) => ({ ...prev, [key]: data }));
    }, 1000);
};

const fetchTitleDropdown = async (inputs, selections, setSearchOptions) => {
    setSearchOptions(true);
    const fetchFunctions = fetchFunctionsMap(inputs, selections);
    const key = Object.keys(fetchFunctions).find(k => inputs[k]);
    if (key) {
        const response = await fetchFunctions[key]();
        if (response) {
            setSearchOptions(false);
            return response;
        }
    }
    return null;
};

const fetchFunctionsMap = (inputs, selections) => {
    const organizationId = (selections.organization && selections.organization.length > 0)
        ? [selections.organization[0].id || selections.organization[0].value]
        : [];
    const combinedOrganizations = [...organizationId, ...(selections.organizationIds || [])];
    const programId = (selections.program && selections.program.length > 0)
        ? [selections.program[0].id || selections.program[0].value]
        : [];
    const combinedPrograms = [...programId, ...(selections.programIds || [])];
    const levelId = (selections.level && selections.level.length > 0)
        ? [selections.level[0].id || selections.level[0].value]
        : [];
    const combinedLevels = [...levelId, ...(selections.levelIds || [])];
    const domainId = (selections.domain && selections.domain.length > 0)
        ? [selections.domain[0].id || selections.domain[0].value]
        : [];
    const combinedDomains = [...domainId, ...(selections.domainIds || [])];
    const topicId = (selections.topic && selections.topic.length > 0)
        ? [selections.topic[0].id || selections.topic[0].value]
        : [];
    const combinedTopics = [...topicId, ...(selections.topicIds || [])];

    return {
        program: () => fetchProgramTitle(combinedOrganizations, inputs.program),
        level: () => fetchLevelTitle(combinedOrganizations, combinedPrograms, inputs.level),
        domain: () => fetchDomainTitle(combinedOrganizations, combinedPrograms, combinedLevels, inputs.domain),
        topic: () => fetchTopicTitle(combinedOrganizations, combinedPrograms, combinedLevels, combinedDomains, inputs.topic),
        skill: () => fetchSkillTitle(combinedOrganizations, combinedPrograms, combinedLevels, combinedDomains, combinedTopics, inputs.skill),
    };
};



const fetchPageSpecificCodeDropdowns = async (inputCode, pageCodeUrl, getPageCodes, dataFilterStatus,
    setSearchOptions) => {
    setSearchOptions(true);
    const response = await fetchCodes(inputCode, pageCodeUrl, getPageCodes, dataFilterStatus);
    if (response) {
        setSearchOptions(false);
        return response;
    }
};

export const handleSelectionChange = (key, value, setSelections, onChangeHandler) => {
    setSelections((prev) => {
        const newSelections = { ...prev };
        newSelections[key] = ['status', 'flag', 'questionType'].includes(key)
            ? value : Array.isArray(value)
                ? value : [value];
        return newSelections;
    });
    if (onChangeHandler) onChangeHandler(value);
};

export const handleStateChange = (key, value, setState) => {
    setState((prev) => ({ ...prev, [key]: value }));
};

export const filterDebounce = (stateVar, fetchFunc) => {
    const timeoutId = setTimeout(() => {
        if (stateVar.length) {
            fetchFunc();
        }
    }, 1000);
    return () => clearTimeout(timeoutId);
}

export const onChangeUpdateFilters = (filter, setFilter, labelIds, setLabelFunction, selectedOptions) => {
    let filterWithOutlabelIds = filter.filter((f) => f.name !== labelIds);
    const existingLabelFilter = filter.find((f) => f.name === labelIds);
    let updatedFilter = existingLabelFilter ? filterWithOutlabelIds : [...filter];
    if (selectedOptions.length > 0 && existingLabelFilter) {
        setLabelFunction(selectedOptions);
        existingLabelFilter.value = [
            ...new Set([...existingLabelFilter.value, ...selectedOptions]),
        ];
        updatedFilter = [...filter];
    } else {
        setLabelFunction(selectedOptions);
        updatedFilter = [...filterWithOutlabelIds];
    }
    if (!_.isEqual(filter, updatedFilter)) {
        setFilter(updatedFilter);
    }
}