import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Form } from "react-bootstrap";
import closeIcon from "../../../Assests/Images/closeIcon.svg";
import { FaAsterisk } from "react-icons/fa";
import {
  ModalButton,
  CustomModalHeader,
  CustomModalFooter,
  CustomForm,
  CustomModal,
  CloseIcon,
} from "../AddEditModal.styled";
import { addProgram } from "../../../Utils/ApiHandler/ProgramApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import _debounce from "lodash/debounce";
import Select, { components } from "react-select";
import { fetchToken } from "Constants/ApiUrls";

/**
 * CreateProgramModal Component
 *
 * This component represents a modal for creating a new program.
 *
 * @param {boolean} show - Flag indicating whether the modal should be displayed.
 * @param {function} handleClose - Function to handle closing the modal.
 * @param {function} handleCreate - Function to handle creating a new program.
 *
 * @returns {JSX.Element} - The rendered CreateProgramModal component.
 *
 * @author - Pranathi
 */
const CreateProgramModal = ({ show, handleClose, onSave }) => {
  const [formData, setFormData] = useState({
    organizationId: "",
    programName: "",
    description: "",
  });
  const getOrganizationTitles =
    process.env.REACT_APP_BASE_URL + "/cms/v1/getAllOrganizationTitles";
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [organizationTitles, setOrganizationTitles] = useState([]);
  const [isOrganizationMenuOpen, setIsOrganizationMenuOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const token = fetchToken();

  const CheckboxOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="radio"
          checked={props.isSelected}
          onChange={() => props.selectOption(props.data)}
          style={{
            marginRight: "5px",
            flexShrink: 0,
          }}
        />
        <span style={{ color: "Black" }}>{props.label}</span>
      </div>
    </components.Option>
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    fetchOrganizationTitles();
  }, []);

  const handleOnchangeOrganization = (selectedOption) => {
    setSelectedOrganization(selectedOption);

    setFormData((prevFormData) => ({
      ...prevFormData,
      organizationId: selectedOption ? selectedOption.id : "",
    }));
  };

  const fetchOrganizationTitles = _.debounce(async () => {
    try {
      const url = getOrganizationTitles
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (!response.ok) {
        console.error(response);
        toast.error(result.message);
        return;
      }
      if (result.success && result.data.organizationTitles.length > 0) {
        const organizationTitles = result.data.organizationTitles.map(
          (title) => ({
            value: title._id,
            label: title.title,
            id: title._id,
          })
        );
        setOrganizationTitles(organizationTitles);
      }
    } catch (error) {
      toast.error("Error fetching Organization titles ", error);
      throw new Error(error);
    }
  }, 1000);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.organizationId) {
        toast.error("Please select an Organization");
        return;
      }
      if (!formData.programName.trim()) {
        toast.error("Please enter a Program name");
        return;
      }
      if (!formData.description.trim()) {
        toast.error("Please enter Description");
        return;
      }
      const createData = {
        title: formData.programName,
        description: formData.description,
        parentId: formData.organizationId,
      };
      await addProgram(createData);
      onSave();
      handleClose();
    } catch (error) {
      console.error("Error adding program:", error);
    }
  };
  const formatOptionLabel = ({ label }) => <span>{label}</span>;

  return (
    <CustomModal show={show} onHide={handleClose}>
      <CustomModalHeader>
        <Modal.Title>Create Program</Modal.Title>
        <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
      </CustomModalHeader>
      <Modal.Body>
        <CustomForm onSubmit={handleSubmit}>
          <Form.Group controlId="organization">
            <Form.Label style={{ marginBottom: "1.49rem" }}>
              Organization
              <FaAsterisk style={{
                color: "#E62753",
                width: "0.4rem",
                height: "0.4rem",
                margin: "0rem 0rem 0.5rem 0.25rem"
              }} />
            </Form.Label>
            <Select
              className="custom-select"
              placeholder="Select Organization"
              menuIsOpen={isOrganizationMenuOpen}
              options={organizationTitles}
              onChange={handleOnchangeOrganization}
              value={selectedOrganization}
              isClearable
              components={{
                Option: CheckboxOption,
                NoOptionsMessage: () => (
                  <div style={{ textAlign: "center", padding: "0.4rem" }}>
                    {isTyping ? (
                      <span style={{ color: "var(--sky-blue-color)" }}>
                        Searching...
                      </span>
                    ) : organizationTitles.length === 0 ? (
                      "Please wait..."
                    ) : (
                      "No options"
                    )}
                  </div>
                ),
              }}
              onMenuOpen={() => setIsOrganizationMenuOpen(true)}
              onMenuClose={() => setIsOrganizationMenuOpen(false)}
              formatOptionLabel={formatOptionLabel}
              closeMenuOnSelect={false}
              menuPlacement="bottom"
              styles={{
                menu: (base) => ({
                  ...base,
                  marginTop: "-1.1rem",
                  height: "auto",
                  width: "22.9375rem",
                }),
                width: "1.5rem",
                option: (base) => ({
                  ...base,
                  backgroundColor: "var(--white-color) !important",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "var(--dark-gray-color)",
                  paddingLeft: "1px",
                  fontWeight: "400",
                  fontSize: "var(--font-size-small)",
                }),
                control: (base) => ({
                  ...base,
                  borderColor: "#d8d8d8",
                  width: "22.9375rem",
                  height: "3rem",
                  bottom: "1rem",
                }),
              }}
            />
          </Form.Group>
          <Form.Group controlId="programName">
            <Form.Label>Program
              <FaAsterisk style={{
                color: "#E62753",
                width: "0.4rem",
                height: "0.4rem",
                margin: "0rem 0rem 0.5rem 0.25rem"
              }} />
            </Form.Label>
            <Form.Control
              className="editable"
              placeholder="Enter Program Name"
              name="programName"
              value={formData.programName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description
              <FaAsterisk style={{
                color: "#E62753",
                width: "0.4rem",
                height: "0.4rem",
                margin: "0rem 0rem 0.5rem 0.25rem"
              }} />
            </Form.Label>
            <Form.Control
              className="editable textareaStyle"
              as="textarea"
              placeholder="Enter Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </CustomForm>
      </Modal.Body>
      <CustomModalFooter>
        <ModalButton isClose onClick={handleClose} style={{ width: "7.5rem" }}>
          Cancel
        </ModalButton>
        <ModalButton
          type="submit"
          style={{ width: "10.75rem", paddingLeft: "39px" }}
          onClick={handleSubmit}
        >
          Add Program
        </ModalButton>
      </CustomModalFooter>
    </CustomModal>
  );
};

CreateProgramModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
};

export default CreateProgramModal;
