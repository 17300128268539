import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    answer: "",
    errorMessage: '',
    options: [],
}

const optionSlice = createSlice({
    name:"options",
    initialState,
    reducers:{
        setSolution:(state,action) => {
            state.answer = action.payload
        },
        clearSolution:(state) => {
            state.answer = ''
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        clearErrorMessage: (state) => {
            state.errorMessage = "";
        },
        setOptions:(state,action) => {
            state.options = action.payload;
        },
        addOption(state) {
            state.options.push({ text: '', isCorrect: false });
        },
        updateOption(state, action) {
            const { index, option } = action.payload;
            state.options[index] = option;
        },
        clearOptions(state) {
            state.options = [];
        },
        setDefaultOptions(state, action) {
            state.options = action.payload; 
        },
    }
})

export const { setSolution,  setErrorMessage, clearErrorMessage,setOptions,addOption,updateOption,clearOptions,setDefaultOptions,clearSolution } = optionSlice.actions;
export default optionSlice.reducer;
