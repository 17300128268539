import PropTypes from "prop-types";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal, Form } from "react-bootstrap";
import SaveModal from "../SaveModal/SaveModal";
import Select, { components } from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { FaAsterisk } from "react-icons/fa";
import closeIcon from "../../../Assests/Images/closeIcon.svg";
import {
  CustomModalHeader,
  CustomModalFooter,
  CustomForm,
  ModalButton,
  CustomModal,
  CloseIcon,
} from "../AddEditModal.styled";
import { addOrganization } from "../../../Utils/ApiHandler/OrganizationsApi";
/**
 * AddOrganizationModal Component
 *
 * This component represents a modal for adding a new organization.
 *
 * @param {boolean} show - Flag indicating whether the modal should be displayed.
 * @param {function} handleClose - Function to handle closing the modal.
 *
 * @returns {JSX.Element} - The rendered AddOrganizationModal component.
 *
 * @author - Gaurav
 */
const AddDomainModal = ({ show, handleClose, parentIdentifier, onSave }) => {
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [formData, setFormData] = useState({
    code: "",
    organizationName: "",
    description: "",
    city: "",
    zipCode: "",
    state: "",
    country: "",
  });
  const countries = [
    { value: 'USA', label: 'USA' },
  ];
  const citiesByState = {
    Alabama: ['Birmingham', 'Montgomery', 'Mobile'],
    Alaska: ['Anchorage', 'Fairbanks', 'Juneau'],
    Arizona: ['Phoenix', 'Tucson', 'Mesa'],
    Arkansas: ['Little Rock', 'Fort Smith', 'Fayetteville'],
    California: ['Los Angeles', 'San Francisco', 'San Diego'],
    Colorado: ['Denver', 'Colorado Springs', 'Aurora'],
    Connecticut: ['Bridgeport', 'New Haven', 'Hartford'],
    Delaware: ['Wilmington', 'Dover', 'Newark'],
    Florida: ['Miami', 'Orlando', 'Tampa'],
    Georgia: ['Atlanta', 'Savannah', 'Augusta'],
    Hawaii: ['Honolulu', 'Hilo', 'Kailua'],
    Idaho: ['Boise', 'Idaho Falls', 'Nampa'],
    Illinois: ['Chicago', 'Springfield', 'Peoria'],
    Indiana: ['Indianapolis', 'Fort Wayne', 'Evansville'],
    Iowa: ['Des Moines', 'Cedar Rapids', 'Davenport'],
    Kansas: ['Wichita', 'Kansas City', 'Topeka'],
    Kentucky: ['Louisville', 'Lexington', 'Bowling Green'],
    Louisiana: ['New Orleans', 'Baton Rouge', 'Shreveport'],
    Maine: ['Portland', 'Bangor', 'Lewiston'],
    Maryland: ['Baltimore', 'Annapolis', 'Frederick'],
    Massachusetts: ['Boston', 'Worcester', 'Springfield'],
    Michigan: ['Detroit', 'Grand Rapids', 'Lansing'],
    Minnesota: ['Minneapolis', 'Saint Paul', 'Rochester'],
    Mississippi: ['Jackson', 'Gulfport', 'Hattiesburg'],
    Missouri: ['Kansas City', 'Saint Louis', 'Springfield'],
    Montana: ['Billings', 'Missoula', 'Great Falls'],
    Nebraska: ['Omaha', 'Lincoln', 'Bellevue'],
    Nevada: ['Las Vegas', 'Reno', 'Henderson'],
    'New Hampshire': ['Manchester', 'Nashua', 'Concord'],
    'New Jersey': ['Newark', 'Jersey City', 'Trenton'],
    'New Mexico': ['Albuquerque', 'Santa Fe', 'Las Cruces'],
    'New York': ['New York City', 'Buffalo', 'Rochester'],
    'North Carolina': ['Charlotte', 'Raleigh', 'Greensboro'],
    'North Dakota': ['Fargo', 'Bismarck', 'Grand Forks'],
    Ohio: ['Columbus', 'Cleveland', 'Cincinnati'],
    Oklahoma: ['Oklahoma City', 'Tulsa', 'Norman'],
    Oregon: ['Portland', 'Salem', 'Eugene'],
    Pennsylvania: ['Philadelphia', 'Pittsburgh', 'Allentown'],
    'Rhode Island': ['Providence', 'Warwick', 'Cranston'],
    'South Carolina': ['Charleston', 'Columbia', 'Greenville'],
    'South Dakota': ['Sioux Falls', 'Rapid City', 'Aberdeen'],
    Tennessee: ['Nashville', 'Memphis', 'Knoxville'],
    Texas: ['Houston', 'Dallas', 'San Antonio'],
    Utah: ['Salt Lake City', 'Provo', 'Orem'],
    Vermont: ['Burlington', 'Essex', 'South Burlington'],
    Virginia: ['Virginia Beach', 'Richmond', 'Norfolk'],
    Washington: ['Seattle', 'Spokane', 'Tacoma'],
    'West Virginia': ['Charleston', 'Huntington', 'Parkersburg'],
    Wisconsin: ['Milwaukee', 'Madison', 'Green Bay'],
    Wyoming: ['Cheyenne', 'Casper', 'Laramie']
  };
  const states = [
    { value: 'Alabama', label: 'Alabama' },
    { value: 'Alaska', label: 'Alaska' },
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Arkansas', label: 'Arkansas' },
    { value: 'California', label: 'California' },
    { value: 'Colorado', label: 'Colorado' },
    { value: 'Connecticut', label: 'Connecticut' },
    { value: 'Delaware', label: 'Delaware' },
    { value: 'Florida', label: 'Florida' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Hawaii', label: 'Hawaii' },
    { value: 'Idaho', label: 'Idaho' },
    { value: 'Illinois', label: 'Illinois' },
    { value: 'Indiana', label: 'Indiana' },
    { value: 'Iowa', label: 'Iowa' },
    { value: 'Kansas', label: 'Kansas' },
    { value: 'Kentucky', label: 'Kentucky' },
    { value: 'Louisiana', label: 'Louisiana' },
    { value: 'Maine', label: 'Maine' },
    { value: 'Maryland', label: 'Maryland' },
    { value: 'Massachusetts', label: 'Massachusetts' },
    { value: 'Michigan', label: 'Michigan' },
    { value: 'Minnesota', label: 'Minnesota' },
    { value: 'Mississippi', label: 'Mississippi' },
    { value: 'Missouri', label: 'Missouri' },
    { value: 'Montana', label: 'Montana' },
    { value: 'Nebraska', label: 'Nebraska' },
    { value: 'Nevada', label: 'Nevada' },
    { value: 'New Hampshire', label: 'New Hampshire' },
    { value: 'New Jersey', label: 'New Jersey' },
    { value: 'New Mexico', label: 'New Mexico' },
    { value: 'New York', label: 'New York' },
    { value: 'North Carolina', label: 'North Carolina' },
    { value: 'North Dakota', label: 'North Dakota' },
    { value: 'Ohio', label: 'Ohio' },
    { value: 'Oklahoma', label: 'Oklahoma' },
    { value: 'Oregon', label: 'Oregon' },
    { value: 'Pennsylvania', label: 'Pennsylvania' },
    { value: 'Rhode Island', label: 'Rhode Island' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
    { value: 'Tennessee', label: 'Tennessee' },
    { value: 'Texas', label: 'Texas' },
    { value: 'Utah', label: 'Utah' },
    { value: 'Vermont', label: 'Vermont' },
    { value: 'Virginia', label: 'Virginia' },
    { value: 'Washington', label: 'Washington' },
    { value: 'West Virginia', label: 'West Virginia' },
    { value: 'Wisconsin', label: 'Wisconsin' },
    { value: 'Wyoming', label: 'Wyoming' },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleCityChange = (selectedOption) => {
    const cityValue = selectedOption ? selectedOption.value : "";
    setFormData({ ...formData, city: cityValue });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSaveModal(true);
  };

  const handleSaveConfirm = () => {
    try {
      if (!formData.organizationName.trim()) {
        toast.error("Please enter organization name");
        return;
      }
      if (!formData.city.trim()) {
        toast.error("Please enter city");
        return;
      }
      if (!formData.zipCode.trim()) {
        toast.error("Please enter zip code");
        return;
      }
      if (!formData.state.trim()) {
        toast.error("Please enter state");
        return;
      }
      if (!formData.country.trim()) {
        toast.error("Please enter country");
        return;
      }
      if (!formData.description.trim()) {
        toast.error("Please enter  description");
        return;
      }
      const insertData = {
        title: formData.organizationName,
        description: formData.description,
        city: formData.city,
        zipCode: formData.zipCode,
        state: formData.state,
        country: formData.country,
        // parent: parentIdentifier,
      };
      addOrganization(insertData);
      setShowSaveModal(false);
      onSave();
      handleClose();
    } catch (error) {
      console.error("Error in Adding Organization: ", error);
    }
  };
  const handleCountryChange = (selectedOption) => {
    const countryValue = selectedOption ? selectedOption.value : "";
    setFormData({ ...formData, country: countryValue });
  };

  const handleStateChange = (selectedOption) => {
    const stateValue = selectedOption ? selectedOption.value : "";
    setFormData({ ...formData, state: stateValue });
  };

  const getCitiesOptions = () => {
    // Get the array of cities based on the selected state
    const cities = citiesByState[formData.state] || [];
    // Map the cities array to options format required by react-select
    return cities.map(city => ({ value: city, label: city }));
  };

  return (
    <>
      <CustomModal show={show} onHide={handleClose} aria-labelledby="add-organization-title" role="dialog" aria-modal="true">
        <CustomModalHeader>
          <Modal.Title id="add-organization-title">Add Organization</Modal.Title>
          <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
        </CustomModalHeader>
        <Modal.Body style={{ padding: "0 0 0 1rem " }}>
          <CustomForm onSubmit={handleSubmit}>
            <Form.Group controlId="organizationName">
              <Form.Label>Organization
                <FaAsterisk style={{
                  color: "#E62753",
                  width: "0.4rem",
                  height: "0.4rem",
                  margin: "0rem 0rem 0.5rem 0.25rem"
                }} />
              </Form.Label>
              <Form.Control
                type="text"
                className="editable"
                name="organizationName"
                value={formData.organizationName}
                onChange={handleChange}
                placeholder="Enter Organization Name"
                required
              />
            </Form.Group>
            <Form.Group controlId="country">
              <Form.Label id="country-label">Country
                <FaAsterisk style={{
                  color: "#E62753",
                  width: "0.4rem",
                  height: "0.4rem",
                  margin: "0rem 0rem 0.5rem 0.25rem"
                }} />
              </Form.Label>
              <Select
                aria-labelledby="country-label"
                value={countries.find(option => option.value === formData.country)}
                onChange={handleCountryChange}
                options={countries}
                placeholder="Select Country"
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '23.1rem',
                    height: '3rem',
                    paddingLeft: ' 2px !important',
                    borderRadius: '0.25rem',
                    fontSize: "var(--font-size-small)",
                    fontWeight: '400',
                    border: '1px solid #d9d9d9',
                    marginBottom: '20px',
                    color: "var(--dark-gray-color)",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: '23.1rem'
                  }),
                }}
                required
              />
            </Form.Group>
            <Form.Group controlId="state">
              <Form.Label id="state-label">State
                <FaAsterisk style={{
                  color: "#E62753",
                  width: "0.4rem",
                  height: "0.4rem",
                  margin: "0rem 0rem 0.5rem 0.25rem"
                }} />
              </Form.Label>
              <Select
                aria-labelledby="state-label"
                value={states.find(option => option.value === formData.state)}
                onChange={handleStateChange}
                options={states}
                placeholder="State"
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '23.1rem',
                    height: '3rem',
                    paddingLeft: ' 2px !important',
                    borderRadius: '0.25rem',
                    fontWeight: '400',
                    fontSize: "var(--font-size-small)",
                    border: '1px solid #d9d9d9',
                    marginBottom: '20px',
                    color: "var(--dark-gray-color)",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: '23.1rem'
                  }),
                }}
                required
              />
            </Form.Group>
            <Form.Group controlId="city">
              <Form.Label id="city-label">City
                <FaAsterisk style={{
                  color: "#E62753",
                  width: "0.4rem",
                  height: "0.4rem",
                  margin: "0rem 0rem 0.5rem 0.25rem"
                }} />
              </Form.Label>
              <Select
                aria-labelledby="city-label"
                value={getCitiesOptions().find(option => option.value === formData.city)}
                onChange={handleCityChange}
                options={getCitiesOptions()}
                placeholder="City"
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '23.1rem',
                    height: '3rem',
                    paddingLeft: ' 2px !important',
                    borderRadius: '0.25rem',
                    fontFamily: 'Inter !important',
                    fontWeight: '400',
                    fontSize: "var(--font-size-small)",
                    border: '1px solid #d9d9d9',
                    marginBottom: '20px',
                    color: "var(--dark-gray-color)",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: '23.1rem'
                  }),
                }}
                required
              />
            </Form.Group>{" "}
            <Form.Group controlId="zipCode">
              <Form.Label>Zip Code
                <FaAsterisk style={{
                  color: "#E62753",
                  width: "0.4rem",
                  height: "0.4rem",
                  margin: "0rem 0rem 0.5rem 0.25rem"
                }} />
              </Form.Label>
              <Form.Control
                className="editable"
                type="text"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                placeholder="Enter Zip Code"
                maxLength={6}
                required
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description
                <FaAsterisk style={{
                  color: "#E62753",
                  width: "0.4rem",
                  height: "0.4rem",
                  margin: "0rem 0rem 0.5rem 0.25rem"
                }} />
              </Form.Label>
              <Form.Control
                className="editable"
                as="textarea"
                style={{ height: "5rem", overflow: "hidden", lineHeight: "2rem" }}
                placeholder="Enter Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </CustomForm>
        </Modal.Body>
        <CustomModalFooter
          style={{
            height: "6rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "0rem",
          }}
        >
          <ModalButton
            // isClose
            onClick={handleClose}
            style={{
              width: "6.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Cancel
          </ModalButton>
          <ModalButton
            type="submit"
            style={{
              width: "12.4rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleSubmit}
          >
            Add Organization
          </ModalButton>
        </CustomModalFooter>
      </CustomModal>
      {showSaveModal && (
        <SaveModal
          showModal={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          onSave={handleSaveConfirm}
        />
      )}
    </>
  );
};

AddDomainModal.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default AddDomainModal;
