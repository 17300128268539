import styled from "styled-components";
import { Modal } from 'react-bootstrap';

export const ModalButton = styled.button`
  display: inline-flex;
  height: 3rem;
  padding: 0.65rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  border: 0px solid #D8D8D8;
  background: var(--blue-color);
  color: var(--white-color);
  margin-bottom: 0.8rem;
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 0.8rem;
  height: 0.8rem;
  flex-shrink: 0;
  margin-left:auto !important;
  margin-right:-36rem !important;
  margin-top: 0.5rem;
  
  @media (max-width:1024px){
    margin-left: 38rem !important;
    margin-top: 0.5rem;  
  }

  @media (max-width:768px){
    margin-left: 27rem !important;
    margin-top: 0.5rem;  
  }
`;

export const CustomModal = styled(Modal)`
  & .modal-dialog {
    height: auto;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .modal-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    width:65.4375rem;
  }

  & .modal-body {
    overflow-y: auto;
    max-height: 100%;
  }

  & .modal-footer {
    padding: 10px 25px 0px 0px;
  }

  &.sidebarModal .modal-dialog {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 2560px) {
    & .modal-dialog {
      width: 50%;
    }
    &.sidebarModal .modal-dialog {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 1440px) {
    & .modal-dialog {
      width: auto;
    }
  }

  @media (max-width: 1024px) {
    & .modal-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-radius: 0.25rem;
      width:54rem;
      margin-left: 3rem;
    }

    & .modal-dialog {
      width: auto;
    }
  }

  @media (max-width: 768px) {
    & .modal-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-radius: 0.25rem;
      width:43rem;
      margin-left: 3rem;
    }

    & .modal-dialog {
      width: auto;
    }
    &.sidebarModal .modal-dialog {
      margin-left: auto;
      margin-right: auto;
    }

    & .modal-footer {
      padding: 20px;
    }
  }

  @media (max-width: 425px) {
    & .modal-dialog {
      width: auto;
    }
    &.sidebarModal .modal-dialog {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

CustomModal.defaultProps = {
  backdrop: true,
};

export const CustomModalHeader = styled(Modal.Header)`
  color: var(--black-color);
  font-family: var(--font-family);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 435px;
  height: 63px;
  padding: 30px;
  flex-shrink: 0;
  border: none;
  border-radius: 0;
  .modal-title {
  color: var(--black-color);
  }
  }
`;

export const CustomModalFooter = styled(Modal.Footer)`
  height: 5.1875rem;
`;

export const CustomTable = styled.table`
  width: 61.375rem !important;
  border-radius: 0.25rem;
  
  @media (max-width:1440px){
    width: 61.375rem !important;
  }

  @media (max-width:1024px){
    width: 52rem !important;
  }

  @media (max-width:768px){
    width: 40rem !important;
  }
`;

export const TableHeader = styled.th`
  border: 1px solid #dee2e6;
  height: 2.9375rem;
  border: none;
  padding: 1rem;
  background-color: var(--light-gray-color);
  color: var(--deep-blue-color);
  font-family: var(--font-family);
  font-size: var(--font-size-medium);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.0175rem;

  &:last-child {
    padding-right: 2rem;
    text-align: right;
  }
  `;

export const TableCell = styled.td`
  height: 4rem;
  padding: 1rem;
  border: 1px solid #F1F1F1;
  background: var(--white-color);  
  color: var(--dark-charcoal-color);
  font-family: Inter;
  font-size: var(--font-size-regular);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;

  &:first-child {
    border-right: 1px solid transparent;
    width: 4%;
    white-space: nowrap;
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    width: 25%;
  }

  &:nth-child(5) {
    position: relative;
    padding-right: 3rem;
    text-align: right;
    width: 11%;
  }
  `;

export const UsersProfile = styled.img`
  width: 1.5rem; 
  height: 1.5rem;
  border-radius: 50%;
  background-image: url(${props => props.src});
  background-size: cover;
`;

export const TrashIcon = styled.img`
  position: absolute;
  right : 2rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  @media (max-width: 768px) {
    left : 3rem;
  }
`;
