import React, { useState, useRef } from "react";
import { ImportExportQuestionWrapper, CloseIcon } from "./ImportExportQuestion.styled";
import closeIcon from "../../../Assests/Images/Vector.svg";
import Button from "react-bootstrap/Button";
import * as XLSX from 'xlsx';
import { BsFileEarmarkPlusFill } from "react-icons/bs";
import { bulkReadingComprehensionUpload, bulkUpload } from "Utils/ApiHandler/SkillsApi";

const ImportExportQuestions = ({ showModal, onClose, onSave, skillName, setUploadError,
    setShowBulkUploadError
}) => {
    const [selectedFile, setSelectedFile] = useState();
    const [selected, setSelected] = useState();
    const inputFile = useRef(null);
    const [fileSize, setFileSize] = useState(0);
    const [onClickStatus, setOnClickStatus] = useState(true);
    const [uploadQuestion, setUploadQuestions] = useState(true);

    const onExport = () => {
        let headings = [];
        if (uploadQuestion) {
            headings = [
                "Question Type",
                "Complexity",
                "Blooms Taxonomy",
                "Tags",
                "Question",
                "Question Image",
                "Option - A",
                "Option - A Image",
                "Option - B",
                "Option - B Image",
                "Option - C",
                "Option - C Image",
                "Option - D",
                "Option - D Image",
                "Answer",
                "Answer - Text Explanation"
            ];
        } else {
            headings = [
                "Paragraph",
                "Question-1",
                "Question-1 Image",
                "Question-1 Type",
                "Question-1 Option-A",
                "Question-1 Option-A Image",
                "Question-1 Option-B",
                "Question-1 Option-B Image",
                "Question-1 Option-C",
                "Question-1 Option-C Image",
                "Question-1 Option-D",
                "Question-1 Option-D Image",
                "Question-1 Answer",
                "Question-1 Text Explanation",
                "Question-1 Topic Code",
                "Question-2",
                "Question-2 Image",
                "Question-2 Type",
                "Question-2 Option-A",
                "Question-2 Option-A Image",
                "Question-2 Option-B",
                "Question-2 Option-B Image",
                "Question-2 Option-C",
                "Question-2 Option-C Image",
                "Question-2 Option-D",
                "Question-2 Option-D Image",
                "Question-2 Answer",
                "Question-2 Text Explanation",
                "Question-2 Topic Code"
            ];
        }

        const worksheet = XLSX.utils.aoa_to_sheet([headings]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, 'Questions');
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'sample_file.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    };

    const uploadDocument = () => {
        inputFile.current.click();

    }

    const handleFileUpload = e => {
        const { files } = e.target;
        if (files && files.length) {
            const fileSizeInBytes = files[0].size;
            const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
            const roundedFileSizeInMB = fileSizeInMB.toFixed(2)

            setSelectedFile(files[0].name);
            setSelected(files[0]);
            setFileSize(roundedFileSizeInMB);
        }
    };

    const handleSubmit = async () => {
        await setOnClickStatus(false);
        try {
            const formData = new FormData();
            formData.append('excel', selected);
            let response = '';
            if (uploadQuestion) {
                formData.append('skill', skillName);
                response = await bulkUpload(formData);
            } else {
                formData.append('skillId', skillName);
                response = await bulkReadingComprehensionUpload(formData);
            }
            if (response.status) {
                onSave();
                onClose();
            }else {
                setUploadError(response);
                setShowBulkUploadError(true);
                onClose();
            }
        }
        catch (error) {
            console.error(error)
        }
    }

    const handleUploadToggle = (type) => {
        if (type === "question") {
            setUploadQuestions(true);
        } else {
            setUploadQuestions(false);
        }
    }


    return (
        <>
            <ImportExportQuestionWrapper show={showModal} onHide={onClose} centered >
                <div className="title-placement"><label>Bulk Upload</label>
                    <CloseIcon src={closeIcon} alt="Close" onClick={onClose} /></div>
                <div className="uploadtoggle" >
                    <div className={"uploadtoggleElement" + (uploadQuestion ? " uploadtoggleHighlight" : "")}
                        onClick={() => handleUploadToggle("question")}>
                        <div>Questions</div></div>
                    <div className={"uploadtoggleElement" + (uploadQuestion ? " " : " readingtoggleHighlight")} >
                        <div onClick={() => handleUploadToggle("reading")}>
                            Reading Comprehension</div></div>
                </div>
                <div className="uploadWrapper">
                    <div className="insideWrapper"><BsFileEarmarkPlusFill
                        style={{
                            width: "1.75rem",
                            height: "1.75rem",
                            color: "#016DC9",
                            marginRight: "0.25rem",
                            marginBottom: "0.5rem"
                        }} />
                        Drop files to begin Upload or <div className="redirectLink" onClick={uploadDocument}>Browse files</div></div>
                    <div className="belowContent">Supported file format : csv</div></div>
                <div className="uploadedSection">Uploaded Documents</div>
                {selectedFile && <div className="selectedSection">
                    <div className="insidescondWrapper">
                        <div style={{ marginRight: "0.5rem" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.49064 23.0962C16.0216 24.5758 24.5527 24.526 33.0836 23.0962V11.236C33.0836 11.1009 33.0399 10.9695 32.9588 10.8615L27.3408 3.37083C27.2229 3.21368 27.0379 3.12109 26.8415 3.12109H9.3633C8.86667 3.12109 8.39026 3.3184 8.0391 3.66956C7.68794 4.02087 7.49064 4.49712 7.49064 4.99375V19.3509C7.49064 20.278 7.27326 21.0637 7.27326 21.6901C7.27326 22.556 7.49064 23.0962 7.49064 23.0962Z" fill="#D8DFE3" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.74532 21.4779V34.9563C3.74532 35.4529 3.94262 35.9293 4.29378 36.2804C4.64494 36.6316 5.12135 36.8289 5.61798 36.8289H34.3321C34.8288 36.8289 35.305 36.6316 35.6563 36.2804C36.0075 35.9293 36.2048 35.4529 36.2048 34.9563C36.2048 32.5643 36.2048 27.3608 36.2048 24.9688C36.2048 24.4722 36.0075 23.9958 35.6563 23.6446C35.305 23.2935 34.8288 23.0962 34.3321 23.0962H6.24225C5.44584 23.0962 3.74532 21.4779 3.74532 21.4779Z" fill="#1FB35B" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.61798 23.0963C5.04809 23.0963 4.63206 22.8651 4.32584 22.5805C4.03775 22.3126 3.74532 21.8405 3.74532 21.2236C3.74532 20.8277 3.94262 20.2506 4.29378 19.8994C4.64494 19.5483 5.12135 19.351 5.61798 19.351H7.49064V23.0963C7.49064 23.0963 7.65109 23.0963 5.61798 23.0963Z" fill="#198043" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8415 3.12109V9.36334C26.8415 9.85997 27.0388 10.3364 27.39 10.6875C27.7411 11.0387 28.2174 11.236 28.7142 11.236H33.0836C33.0836 11.1009 33.0399 10.9695 32.9588 10.8615L27.3408 3.37083C27.2229 3.21368 27.0379 3.12109 26.8415 3.12109Z" fill="#AFBDC7" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1086 11.8602H21.2235C21.5679 11.8602 21.8476 11.5805 21.8476 11.236C21.8476 10.8914 21.5679 10.6117 21.2235 10.6117H13.1086C12.764 10.6117 12.4843 10.8914 12.4843 11.236C12.4843 11.5805 12.764 11.8602 13.1086 11.8602Z" fill="#1FB35B" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1086 15.6056H27.4656C27.8102 15.6056 28.0899 15.3259 28.0899 14.9813C28.0899 14.6368 27.8102 14.3571 27.4656 14.3571H13.1086C12.764 14.3571 12.4843 14.6368 12.4843 14.9813C12.4843 15.3259 12.764 15.6056 13.1086 15.6056Z" fill="#1FB35B" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1086 19.351H27.4656C27.8102 19.351 28.0899 19.0713 28.0899 18.7267C28.0899 18.3821 27.8102 18.1024 27.4656 18.1024H13.1086C12.764 18.1024 12.4843 18.3821 12.4843 18.7267C12.4843 19.0713 12.764 19.351 13.1086 19.351Z" fill="#1FB35B" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.4529 27.0733L25.9498 33.3155C26.0445 33.5525 26.2742 33.7079 26.5293 33.7079C26.7846 33.7079 27.0141 33.5525 27.1089 33.3155L29.6058 27.0733C29.7338 26.7534 29.578 26.3898 29.258 26.2619C28.9381 26.134 28.5745 26.2898 28.4466 26.6096L26.5293 31.4029L24.612 26.6096C24.484 26.2898 24.1204 26.134 23.8006 26.2619C23.4807 26.3898 23.3249 26.7534 23.4529 27.0733Z" fill="#D8DFE3" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7772 26.2173H13.4205C11.6968 26.2173 10.2995 27.6146 10.2995 29.3383V30.5869C10.2995 32.3106 11.6968 33.7079 13.4205 33.7079H13.7772C14.8908 33.7079 15.902 33.0583 16.3649 32.0456C16.4295 31.9045 16.4422 31.8778 16.4837 31.7858C16.6472 31.423 16.4499 31.0917 16.2156 30.974C15.8243 30.7773 15.4692 30.9953 15.3483 31.2667C15.3116 31.3491 15.2295 31.5266 15.2295 31.5266C14.9696 32.0949 14.4022 32.4595 13.7772 32.4595H13.4205C12.3862 32.4595 11.5479 31.621 11.5479 30.5869V29.3383C11.5479 28.3042 12.3862 27.4657 13.4205 27.4657H13.7772C14.4022 27.4657 14.9696 27.8303 15.2295 28.3986C15.2295 28.3986 15.3056 28.569 15.3483 28.6585C15.5359 29.0516 15.9793 29.1075 16.2598 28.9253C16.4568 28.7971 16.6428 28.4874 16.4837 28.1394C16.3649 27.8796 16.3649 27.8796 16.3649 27.8796C15.902 26.8669 14.8908 26.2173 13.7772 26.2173Z" fill="#D8DFE3" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4782 31.5271V31.8365C17.4782 32.3328 17.6754 32.8089 18.0262 33.1598C18.3772 33.5108 18.8532 33.7079 19.3495 33.7079H20.7566C21.2529 33.7079 21.7288 33.5108 22.0798 33.1598C22.4309 32.8089 22.628 32.3328 22.628 31.8365C22.628 31.7526 22.628 31.6693 22.628 31.5881C22.628 30.8224 22.1618 30.1338 21.4508 29.8493L19.181 28.9415C18.9065 28.8317 18.7266 28.5659 18.7266 28.2703V28.0899C18.7266 27.9244 18.7924 27.7656 18.9094 27.6486C19.0265 27.5314 19.1852 27.4657 19.3507 27.4657H20.7554C20.9209 27.4657 21.0797 27.5314 21.1967 27.6486C21.3137 27.7656 21.3795 27.9244 21.3795 28.0899V28.3983C21.3795 28.5744 21.4574 28.7338 21.5357 28.8147C21.6575 28.953 21.8325 29.0263 22.0037 29.0263C22.3076 29.0263 22.628 28.7917 22.628 28.3983V28.0899C22.628 27.5933 22.4307 27.1169 22.0795 26.7657C21.7282 26.4146 21.252 26.2173 20.7554 26.2173H19.3509C18.8541 26.2173 18.3778 26.4146 18.0267 26.7657C17.6755 27.1169 17.4782 27.5933 17.4782 28.0899V28.2703C17.4782 29.0763 17.969 29.8013 18.7173 30.1006L20.9871 31.0086C21.2241 31.1033 21.3795 31.3329 21.3795 31.5881V31.8365C21.3795 32.0017 21.3139 32.1602 21.197 32.2771C21.0803 32.3939 20.9218 32.4595 20.7566 32.4595H19.3495C19.1843 32.4595 19.0259 32.3939 18.9091 32.2771C18.7922 32.1602 18.7266 32.0017 18.7266 31.8366C18.7266 31.8365 18.7266 31.8365 18.7266 31.5269C18.7266 31.1153 18.391 30.8986 18.1133 30.8986C17.8499 30.8986 17.4782 31.1028 17.4782 31.5232V31.5271Z" fill="#D8DFE3" />
                            </svg></div>
                        <div className="fileName">{selectedFile}
                            <div className="fileSize">{fileSize}MB</div></div></div></div>}
                <div style={{ display: "flex" }}>
                    <div className="redirectLink" style={{ marginTop: "5rem", marginLeft: "2rem", fontSize: "0.8125rem" }} onClick={onExport}>Download sample file</div>
                    {selectedFile && <div className="flag-buttons" style={{ marginLeft: "auto" }}>
                        <Button style={{
                            marginRight: '10px',
                            backgroundColor: "white",
                            color: "#016DD9"
                        }} onClick={onClose}>Cancel</Button>
                        <Button onClick={onClickStatus && handleSubmit}
                            disabled={!onClickStatus}
                        >Import Data</Button>
                    </div>}
                </div>
                <input
                    style={{ display: "none" }}
                    ref={inputFile}
                    onChange={handleFileUpload}
                    type="file"

                />
            </ImportExportQuestionWrapper>
        </>

    )
}
export default ImportExportQuestions;