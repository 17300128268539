import { createSlice } from '@reduxjs/toolkit';

const commentsSlice = createSlice({
  name: 'comments',
  initialState: {
    formData: {
      id: '',
      text: '',
      isFlag: false,
    },
    comments: [],
    isFlagged: false,
    isCommentModal:false
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setComments : (state, action) => {
      state.comments = action.payload;
    },
    setIsFlagged : (state, action) => {
      state.isFlagged = action.payload;
    },
    addComment: (state, action) => {
      state.comments.push(action.payload);
    },
    clearFormData: (state) => {
      state.formData.text = '';
    },
    openCommentsModal: (state) => {
      state.isCommentModal = true;
    },
    closeCommentsModal: (state) => {
      state.isCommentModal = false;
    }
    
  }
});

export const { setFormData, setComments, setIsFlagged, addComment, clearFormData,openCommentsModal,closeCommentsModal } = commentsSlice.actions;
export default commentsSlice.reducer;
