import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    programDetails: [],
    activeProgramDetails: []
};

const globalDataSlice = createSlice({
    name: 'globalData',
    initialState,
    reducers: {
        setProgramDetails: (state, action) => {
            state.programDetails = action.payload;
        },
        setActiveProgramDetails: (state, action) => {
            state.activeProgramDetails = action.payload;
        },
    },
});

export const { setProgramDetails, setActiveProgramDetails } = globalDataSlice.actions;

export default globalDataSlice.reducer;
