import "./Layout.css";
import React from "react";
import Header from "../../Components/Header/Header";
import { Outlet } from "react-router-dom";

function Layout() {
    return (
        <div>
            <Header />
            <div className="container-fluid">
                <main><Outlet  /></main>
            </div>
        </div>
    );
}

export default Layout;
