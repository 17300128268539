import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import closeIcon from "../../../Assests/Images/closeIcon.svg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaAsterisk } from "react-icons/fa";
import {
  CustomModalHeader,
  CustomModalFooter,
  CustomForm,
  ModalButton,
  CustomModal,
  CloseIcon
} from '../AddEditModal.styled';
import { addTopic } from '../../../Utils/ApiHandler/DomainApi';

/**
 * AddTopicModal Component
 * 
 * This component represents a modal for adding a new topic.
 * 
 * @param {boolean} show - Flag indicating whether the modal should be displayed.
 * @param {function} handleClose - Function to handle closing the modal.
 * @param {function} handleAddTopic - Function to handle adding a new topic.
 * 
 * @returns {JSX.Element} - The rendered AddTopicModal component.
 * 
 * @author - Devisri
 */
const AddTopicModal = ({ show, handleClose, parentIdentifier,
  onSave }) => {
  const [formData, setFormData] = useState({
    code: '',
    topicName: '',
    description: ''
  });

  // Function to handle changes in form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.topicName.trim()) {
        toast.error("Please enter a Topic name");
        return;
      }

      if (!formData.description.trim()) {
        toast.error("Please enter  description");
        return;
      }
      const insertData = {
        title: formData.topicName,
        description: formData.description,
        parentId: parentIdentifier._id
      };
      const response = addTopic(insertData);
      onSave();
      handleClose();

    } catch (error) {
      console.error("Error in updating data: ", error);
    }

  };
  return (
    <CustomModal show={show} onHide={handleClose}>
      <CustomModalHeader>
        <Modal.Title>Add Topic</Modal.Title>
        <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
      </CustomModalHeader>
      <Modal.Body>
        <CustomForm onSubmit={handleSubmit}>
        <div >
          <div style={{fontSize:"0.95rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>Domain : </div>
            <p >{parentIdentifier.domain}</p></div>
          <Form.Group controlId="topicName">
            <Form.Label>Topic
              <FaAsterisk style={{color:"#E62753",
                  width:"0.4rem",
                  height:"0.4rem",
                  margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Form.Control
              className="editable"
              type="text"
              name="topicName"
              placeholder="Enter topic"
              value={formData.topicName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description
              <FaAsterisk style={{color:"#E62753",
                width:"0.4rem",
                height:"0.4rem",
                margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Form.Control
              className="editable textareaStyle"
              as="textarea"
              placeholder="Enter Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </CustomForm>
      </Modal.Body>
      <CustomModalFooter>
        <ModalButton isClose onClick={handleClose} style={{ width: "7.5rem" }}>
          Cancel
        </ModalButton>
        <ModalButton type="submit" style={{ width: "10.75rem", paddingLeft: "39px" }} onClick={handleSubmit}>
          Add Topic
        </ModalButton>
      </CustomModalFooter>
    </CustomModal>
  );
};

AddTopicModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAddTopic: PropTypes.func.isRequired,
};

export default AddTopicModal;
