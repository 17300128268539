import React from "react";
import { Error404Wrapper, Cont404 } from "./Error404.styled";
import error404 from "../../Assests/Images/error404.jpg";

/**
 * Error404 component
 *
 * Represents Not Found or Error 404 page.
 *
 * @returns { JSX.Element } - A JSX element representing the svg image
 *
 * @author Gaurav
 */
const Error404 = () => (
  <Error404Wrapper>
    <Cont404>
      <div>
        <img src={error404} alt="error-404" className="img-404" />
      </div>
    </Cont404>
      <div className="btn">
        <button type="button" className="btn btn-primary">
          Go to Home
        </button>
      </div>
  </Error404Wrapper>
);

Error404.propTypes = {};

Error404.defaultProps = {};

export default Error404;
