import { toast } from "react-toastify";
import { fetchToken } from "Constants/ApiUrls";

var token = fetchToken();
const baseUrl = process.env.REACT_APP_BASE_URL;
const fetchData = async (url, options = {}) => {
  try {
    const response = await fetch(url, {
      method: options.method || "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...options.headers,
      },
      body: options.body ? JSON.stringify(options.body) : undefined,
    });
    const result = await response.json();
    if (!response.ok || !result.success) {
      toast.error(result.message || 'Something went wrong!');
      return null;
    }
    if(!result.data){
      return result.message;
    }
    return result.data;
  } catch (error) {
    toast.error('Something went wrong!');
    throw new Error(error);
  }
};

const buildFilterParams = (filterData) => {
  token = fetchToken();
  return filterData.map((filter) => {
    if (Array.isArray(filter.value) && filter.value.length > 0) {
      const values = filter.value.map((value) => 
        `${filter.name}[]=${filter.name === "organizationIds" ? value.id : value}`
      );
      return values.join("&");
    }
    return `${filter.name}=${filter.value}`;
  });
};

export const userTitlesUrl = `${baseUrl}/cms/v1/getUserTitles/`;
export const teamsTitlesUrl = `${baseUrl}/cms/v1/getTeamTitles/`;
export const roleAndTemplateTitlesUrl = `${baseUrl}/cms/v1/getRoleAndTemplateTitles/`;

export const getTeamsTitles = async (url) => {
  const data = await fetchData(url);
  if (data) {
    return data.teamTitles.map(({ _id, teamName }) => ({
      value: _id,
      label: teamName,
      id: _id,
    }));
  }
};

export const getRolesTitles = async (url) => {
  const data = await fetchData(url);
  if (data) {
    return data.roleAndTemplateTitles.map(({ _id, roleName }) => ({
      value: _id,
      label: roleName,
      id: _id,
    }));
  }
};

export const getUserTitles = async (url) => {
  const data = await fetchData(url);
  if (data) {
    return data.userTitles.map(({ _id, fullName }) => ({
      value: _id,
      label: fullName,
      id: _id,
    }));
  }
};

export const getAllUsers = async (filterData) => {
  const filterParams = buildFilterParams(filterData).join("&");
  const url = `${baseUrl}/cms/v1/getAllUsers?${filterParams}`;
  const data = await fetchData(url);
  if (data) {
    return { users: data.users, totalPages: data.totalPages };
  }
  return { users: [], totalPages: 0 };
};

export const deleteUser = async (data) => {
  const url = `${baseUrl}/cms/v1/deleteUser`;
  const result = await fetchData(url, { method: "POST", body: data });
  if (result) {
    toast.success(result);
    return result;
  }
  return false;
};

export const createUser = async (data) => {
  const url = `${baseUrl}/cms/v1/insertUser`;
  const result = await fetchData(url, { method: "POST", body: data });
  if (result) {
    toast.success("User added successfully");
    return result;
  }
  return false;
};

export const updateUser = async (data) => {
  const url = `${baseUrl}/cms/v1/updateUser`;
  const result = await fetchData(url, { method: "POST", body: data });
  if (result) {
    toast.success("User updated successfully");
    return result;
  }
  return false;
};
