import questionConfig from "Controller/Curriculum/Questions/QuestionBuilder/questionConfig";
import React, { useRef } from "react";
import { setQuestionValue } from "../../../../redux/slices/SpellAndVocab/Spelling/questionSlice";
import { useSelector, useDispatch } from "react-redux";

function QuestionComponent({ onQuestionChange }) {
    const questionInputReference = useRef(null);
    const dispatch = useDispatch();
    const questionValue = useSelector((state) => state.question.questionValue);

    const handleQuestionChange = (e) => {
        const value = e.target.value;
        dispatch(setQuestionValue(value));
        onQuestionChange(value);
    };


    return (
        <div className="question-field">
            <>
                    <div>
                        <input
                            type="text"
                            id="input-fields"
                            placeholder={"Add Question"}
                            ref={questionInputReference}
                            value={questionValue}
                            onChange={handleQuestionChange}
                        />
                    </div>
            </>
        </div>
    );
}

export default QuestionComponent;
