import React from 'react'
import "./studentDashboardStyle.css";
import Header from "Components/Header/Header.js";
import Gradient from "../../Assests/Images/Gradient.svg";

const StudentDashboard = () => {
    return (
        <div className="student-dashboard">
                      <Header />
            <div className="dashboard-header">Welcome Back, Hari</div>
            <div className="dashboard-subheader">Hey Hari, start your topic where you left.</div>
            <div class="grid-container">
                <div class="box box1">Student Velocity</div>
                <div class="box box2">Time Spent</div>
                <div class="box box3">Skills Mastered</div>
                <div class="box box4">Overall Progress</div>
                <div class="box box5">Level & Proficiency</div>
                <div class="box box6">
                    <div>Level 8</div>
                    <div>Level 7</div>
                    <div>Level 6</div>
                    <div>Level 5</div>
                    <div>Level 4</div>
                    <div>Level 3</div>
                    <div>Level 2</div>
                    <div>Level 1</div>
                </div>
                <div class="box box7">
                    <span>Hey Hari, start from where you left</span>
                    <table>
                        <thead>
                            <tr>
                                <th>Column 1</th>
                                <th>Column 2</th>
                                <th>Column 3</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Row 1, Cell 1</td>
                                <td>Row 1, Cell 2</td>
                                <td>Row 1, Cell 3</td>
                            </tr>
                            <tr>
                                <td>Row 2, Cell 1</td>
                                <td>Row 2, Cell 2</td>
                                <td>Row 2, Cell 3</td>
                            </tr>
                            <tr>
                                <td>Row 3, Cell 1</td>
                                <td>Row 3, Cell 2</td>
                                <td>Row 3, Cell 3</td>
                            </tr>
                            <tr>
                                <td>Row 4, Cell 1</td>
                                <td>Row 4, Cell 2</td>
                                <td>Row 4, Cell 3</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default StudentDashboard