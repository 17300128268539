import styled from "styled-components";
import { Modal} from "react-bootstrap";

export const UploadErrorStyledModal = styled(Modal)`
  .modal-content {
    width: 35rem;
    min-height: 10rem;
    max-height: 38rem;
    height: auto;
    flex-shrink: 0;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
  }
`;

export const CustomModalHeader = styled(Modal.Header)`
    appearance:none;
    background-color: var(--highlight-red-color);
    color: white;
    position: sticky;
    top: 0;
    z-index: 1;
    display:flex !important;
`;

export const CustomModalBody = styled(Modal.Body)`
    overflow-y: auto;
    flex-grow: 1;
`;

export const CustomModalFooter = styled(Modal.Footer)`
    position: sticky;
    bottom: 0;
    z-index: 1;
    padding: 8px !important;
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 1.125rem;
  height: 1.125rem;
  flex-shrink: 0;
  margin-left: auto;
  // margin-top: 1rem;
`;