import React from 'react';
import '../DataTable/PaginationStyles.css';

const ModalPagination = ({ currentModalPage, totalModalPages, handleModalPagination }) => {
    const paginationNumbers = [];

    for (let i = 1; i <= totalModalPages; i++) {
        paginationNumbers.push(i);
    }

    return (
        <div className='modal-pagination-container'>
            {paginationNumbers.map((pageNumber) => (
                <button
                    key={pageNumber}
                    className={currentModalPage === pageNumber ? 'page-item active' : 'page-item'}
                    onClick={() => handleModalPagination(pageNumber)}
                >
                    {pageNumber}
                </button>
            ))}
        </div >
    );
};

export default ModalPagination;