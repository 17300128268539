import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

const SaveStyledModal = styled(Modal)`
  .modal-content {
    width: 27.125rem;
    height: 12.25rem;
    flex-shrink: 0;
    border-radius: 0.25rem;
    background: var(--white-color); 

    @media (max-width: 425px) {
      width: 90%;
      height: auto;
    }
  }
  .modal-body {
    height: 3.25rem;
    width: auto;
    color: var(--black-color);
    font-family: var(--font-family);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.625rem;
    left: 1.125rem;

    @media (max-width: 62rem) { 
      .modal-content {
        width: 80%;
      }
      .modal-title {
        padding: 1.25rem 0.625rem;
      }
    }

    @media (max-width: 48rem) { 
      .modal-content {
        width: 90%;
      }
    }

    @media (max-width: 425px) {
      width: 90%;
      bottom: 2rem;
      left: 1.5rem;  
    }

    @media (max-width: 375px) {
      width: 80%;
    }

    @media (max-width: 320px) {
      width: 90%;
      left: 0.125rem;
    }
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: ${(props) => props.contentJustify || "space-around"};
  margin-bottom: 0;

  button {
    max-width: 6.437rem; 
    margin-right: 0.3125rem; 
    margin-bottom: 1.875rem; 
    margin-left: 2.5rem; 
    cursor: pointer;
    border-radius: 0.25rem; 
    gap: 0.625rem; 
    border: 0.0625rem solid #cbd6e2; 
    flex-direction: column;
    gap: 0.625rem;
    font-family: var(--font-family);
    font-size: var(--font-size-normal);
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
    border-radius: 0.25rem;
    border: 0px solid #D8D8D8;
    height: 2.5rem; 
    width: 8.6875rem; 
    transition: background-color 0.3s ease;
    
    &:hover {
      border: none;
    }

    &:active {
      background-color: #EAF0F6;
    }

    &:last-child {
      margin-left: 1.25rem;
      max-width: 7.562rem; 
    }

    @media (max-width: 768px) {
      max-width: 6.437rem; 
    }

    @media (max-width: 320px) {
      margin-left: 1rem;

      &:last-child {
        margin-left: 0.75rem;
        max-width: 7.562rem; 
      }
      }
  }

  button:first-child {
    background-color: var(--blue-color);
    color: var(--white-color);
  }

  button:last-child {
    margin-right:3rem;
    background-color: #CBD6E2;
    color: var(--blue-color);
  }
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 0.75969rem;
  height: 0.75975rem;
  flex-shrink: 0;
  margin-left: 25rem;
  margin-top: 1.5rem;

  @media (max-width: 425px) {
    margin-left: 21rem;
  }

  @media (max-width: 375px) {
    margin-left: 18rem;
  }

  @media (max-width: 325px) {
    margin-left: 15.5rem;
  }
`;

export { SaveStyledModal, ModalFooter };
