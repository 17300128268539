import styled from "styled-components";

const FilterWrapper = styled.div`
  .filter-container {
    margin-left: 3.5%;
    display: flex;
    margin-top: 8.1rem;
    z-index: 1001;
    max-width: 91%;
    margin-bottom:15px;
    justify-content: space-between;
    position: relative !important;
  }

  .search-container {
    width: 12.4375rem;
    height: 2.5rem;
    flex-shrink: 0;
    color: #a8a8a8;
    margin-right: 16px;
  }
  .search-wrapper {
    position: relative;
  }
  .search {
    width:12.4375rem;
    padding: 0.5625rem 0.25rem 0.5625rem 2rem;
    border-radius: 0.5rem;
    border: 1px solid #d8d8d8;
    color: #a8a8a8;
    font-family: Inter;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    &:focus {
      border: 2px solid var(--blue-color);
      outline: none;
    }
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .filter-dropdown-container {
    margin-left: 16px;
    gap: 0.25rem;
    display: flex;
    width: auto;
  }

  .filter-dropdowns {
    width: auto;
    height: 50px;
    font-size: var(--font-size-small) !important;
    font-family: var(--font-family) !important;
    font-weight: 00 !important;
    padding: 0 0 0 0.25rem;
    border-radius: 0.5rem;
    color:#000000 !important;
  }

  .filter-dropdown{
    min-width:8rem;
    height: 50px;
    font-size: var(--font-size-small) !important;
    font-family: var(--font-family) !important;
    font-weight: 500 !important;
    padding: 0 0 0 0.25rem;
    border-radius: 0.5rem;
    color:#000000 !important;
  }
  .moreFilters {
    width: 118px;
    height: 38px;
    margin-left: 0.5rem;
    padding: 12px, 16px, 12px, 16px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    font-size: var(--font-size-small) !important;
    font-family: var(--font-family) !important;
    font-weight: 500;

    &:focus {
      border: 2px solid #347bfa;
      outline: none;
    }
  }
  .moreFilterActive {
    width: 139px;
    height: 38px;
    color: #347bfa;
    margin-left: 0.5rem;
    padding: 12px, 16px, 12px, 16px;
    background-color: var(--white-color);
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    font-size: var(--font-size-small);
    font-family: Inter;
    font-weight: 600;

    &:focus {
      border: 2px solid var(--blue-color);
      outline: none;
    }
  }
  .moreFilters-icon {
    margin-right:0.4rem;
  }
  .filter-toggle {
    margin-top: 8px;
    flex-shrink: 0;
    gap: 0.5rem;
    margin-left: auto;
  }
  .reset-Filter {
    width: 1.5rem;
    height: 1.5rem;
    justify-content: center;
    margin-top: 6px;
    margin-left: 0.63rem;
    flex-shrink: 0;
  }

  .program-page-toggle {
     //position:relative;
    margin-left:auto;
    margin-top: 9px;
    
  }

  .add-program-button{
    width: 8.5rem;
    height:2.4rem;
    gap: 0.625rem;
    margin-top:0.1rem;
    border-radius: 0.5rem;
    background: #016dd9;
    border: none;
    color: #fff;
    font-family: var(--font-family);
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: none;
    margin-left:1.5rem;
    margin-right:-1.5rem;
   
  }

  .input-search-container {
    width: 5rem;
    height: 2.5rem;
    flex-shrink: 0;
    color: #a8a8a8;
    margin-right: 16px;
  }

  .input-search-wrapper {
      position: relative;
  }

  .input-search-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
  }

  .input-search {
      width: 5rem;
      padding: 0.65rem 0.25rem 0.5625rem 0.5rem;
      border-radius: 4px;
      border-radius: 0.5rem;
      border: 1px solid #d8d8d8;
      color: #a8a8a8;
      font-family: Inter;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 500;
  }

  .input-search:focus {
      border: 2px solid var(--blue-color);
      outline: none;
  }

  .label {
    margin-left: 0.3rem;
    display:inline !important;
    font-weight:400;
    color: #33475B;
    font-family: var(--font-family);
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  @media (min-width: 1024px) {
    .filter {
      width: calc(70% - 10rem); /* Reduce filter width for laptop size */
    }

    .btn {
      width: 10rem; 
    }
  }

  
  @media (max-width: 1023px) {
    .filter {
      width: calc(100% - 12rem); 

    .btn {
      width: 10rem; 
    }
  }

  @media (min-width: 2560px) {
    .program-page-toggle{
      margin-left:44rem;
    }
  
  .form-check-input {
    margin-right: 0.5rem;
  }
 
`;

export { FilterWrapper };
