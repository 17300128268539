import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getSpellAndVocab } from "Utils/ApiHandler/SpellAndVocabServices/spellAndVocabServices";

const initialState = {
    vocabulary: [],
    error: null,
    loading: false
}

export const fetchAllVocabulary = createAsyncThunk('vocabulary/fetchAll', async (params, { rejectWithValue }) => {
    try {
        const response = await getSpellAndVocab(params);
        if (response.questions.length > 0) {
            return response.questions;
        }
        return [];
        } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const vocabularySlice = createSlice({
    name: 'vocabulary',
    initialState: initialState,
    reducers: {
        object1: (state) => {}
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllVocabulary.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllVocabulary.fulfilled, (state, action) => {
                state.loading = false;
                state.vocabulary = action.payload;
                state.error = null;
            })
            .addCase(fetchAllVocabulary.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong!';
            });
    },
});

export const { object1 } = vocabularySlice.actions;

export default vocabularySlice.reducer;