import addIcon from "Assests/Images/add-icon.png";
import React, { useEffect } from "react";
import Eye from "../../../../Assests/Images/eye.png";
import {
    setSolution,
    setErrorMessage,
    clearErrorMessage,
    setOptions,
    addOption,
    clearOptions,
    setDefaultOptions,
} from "../../../../redux/slices/SpellAndVocab/Spelling/optionSlice";
import { useSelector, useDispatch } from "react-redux";
import TrashCan from "../../../../Assests/Images/trash-can.png";

function OptionsComponent({ onSolutionChange, onOptionsChange, onPreview }) {
    const dispatch = useDispatch();
    const answer = useSelector((state) => state.options.answer);
    const errorMessage = useSelector((state) => state.options.errorMessage);
    const options = useSelector((state) => state.options.options);
    const qtype = useSelector((state) => state.qtype.qtype);

    const optionStructure = { text: "", isCorrect: false };
    var optionsCount = 2;

    useEffect(() => {
        if (options.length === 0) {
            const defaultOptions = Array.from(
                { length: optionsCount },
                () => optionStructure
            );
            dispatch(setDefaultOptions(defaultOptions));
        }
    }, [options.length, dispatch]);

    useEffect(() => {
        const handleClickOutside = () => {
            dispatch(clearErrorMessage());
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    function handleSolutionChange(event) {
        const newSolution = event.target.value;
        dispatch(setSolution(newSolution));
        onSolutionChange(newSolution);
    }

    const handleOptionChange = (event, index) => {
        const newOptions = options.map((option) => ({ ...option }));
        newOptions[index].text = event.target.value;
        dispatch(setOptions(newOptions));
        onOptionsChange(newOptions);
    };

    const handleCorrectAnswer = (event, index) => {
        const newOptions = options.map((option, idx) => ({
            ...option,
            isCorrect: idx === index ? event.target.checked : false,
        }));
        dispatch(setOptions(newOptions));
        onOptionsChange(newOptions);
    };

    const handleAddOption = () => {
        dispatch(addOption());
    };

    const handleDeleteOption = (index, event) => {
        event.stopPropagation();
        if (options.length <= 2) {
            dispatch(setErrorMessage("At least two options must be present."));
            return;
        }
        const updatedOptions = [...options];
        updatedOptions.splice(index, 1);
        dispatch(setOptions(updatedOptions));
        dispatch(clearErrorMessage());
        onOptionsChange(updatedOptions);
    };

    return (
        <div>
            {errorMessage && (
                <div className="error-message">{errorMessage}</div>
            )}
            <>
                {qtype.label === "Spelling" && (
                    <input
                        type="text"
                        id="input-fields"
                        placeholder="Answer"
                        value={answer || ""}
                        onInput={(e) => handleSolutionChange(e)}
                    />
                )}
                {qtype.label === "Vocabulary" &&
                    options.map((option, index) => (
                        <div key={index} className="option">
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={option.isCorrect}
                                onChange={(e) => handleCorrectAnswer(e, index)}
                            />
                            <input
                                type="text"
                                id={`input-fields`}
                                placeholder={`Option ${index + 1}`}
                                value={option.text}
                                onChange={(e) => handleOptionChange(e, index)}
                            />
                            <img
                                src={TrashCan}
                                className="trash-can"
                                onClick={(event) =>
                                    handleDeleteOption(index, event)
                                }
                            />
                        </div>
                    ))}
                {qtype.label === "Vocabulary" && (
                    <div className="dropdown-headers">
                        <button
                            className="add-option"
                            onClick={handleAddOption}
                        >
                            <img src={addIcon} id="add-icon" alt="Add Icon" />
                            Add Option
                        </button>
                    </div>
                )}
                <hr className="hr-separator" />
                <div className="footer">
                    <button
                        type="button"
                        className="btn-preview"
                        onClick={onPreview}
                    >
                        <div id="preview-container">
                            <img src={Eye} id="preview-eye" alt="preview" />
                            <span className="preview-button">Preview</span>
                        </div>
                    </button>
                </div>
            </>
        </div>
    );
}

export default OptionsComponent;
