import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  width: 43rem;
  // height: 30.5rem;
  top: 1rem;
  gap: 0px;
  border-radius: 0.75rem;
  opacity: 0px;
  border: 1px solid #d8d8d8;
  flex-shrink: 0;
  background: var(--white-color);
  box-shadow: 0px 2px 10px 0px rgba(117, 117, 117, 0.1);
  animation: ${fadeIn} 0.7s ease-in-out;

  @media only screen and (max-width: 768px) {
    width: 35rem;
  }
`;

