import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { QuestionSaveStyledModal, CloseIcon, ModalFooter } from "./QuestionSaveModal.styled";
import closeIcon from "../../../Assests/Images/Vector.svg";
import Button from "react-bootstrap/Button";

/**
 * QuestionSaveModal
 * 
 * Modal component for saving a question
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.showModal - Flag to control modal visibility
 * @param {function} props.onClose - Function to handle modal close event
 * @param {function} props.onClick - Function to handle button click event
 * 
 * @returns {JSX.Element} - Rendered component
 * 
 * @author - Gaurav
 */
function QuestionSaveModal({ showModal, onClose, onClick }) {
  return (
    <QuestionSaveStyledModal show={showModal} onHide={onClose} centered>
      <CloseIcon src={closeIcon} alt="Close" onClick={onClose} />
      <Modal.Body>A new question has been added successfully.!</Modal.Body>
      <ModalFooter>
        <Button onClick={onClick}>Go to questions</Button>
      </ModalFooter>
    </QuestionSaveStyledModal>
  );
}

QuestionSaveModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default QuestionSaveModal;
