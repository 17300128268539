import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Form } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import closeIcon from "../../../Assests/Images/closeIcon.svg";
import SaveModal from "../SaveModal/SaveModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateUser } from "Utils/ApiHandler/UsersApi";
import { FaAsterisk } from "react-icons/fa";
import {
  CustomModalHeader,
  CustomModalFooter,
  CustomForm,
  ModalButton,
  CustomModal,
  CloseIcon,
  DisabledInput,
} from "../AddEditModal.styled";
import _ from "lodash";
import _debounce from "lodash/debounce";
import Select, { components } from "react-select";
import { fetchToken } from "Constants/ApiUrls";

/**
 * EditUserModal Component
 *
 * This component represents a modal for editing an existing Program.
 *
 * @param {boolean} show - Flag indicating whether the modal should be displayed.
 * @param {function} handleClose - Function to handle closing the modal.
 * @param {object} initialData - Initial data of the Program being edited.
 * @param {function} handleEditUser - Function to handle editing the User.
 *
 * @returns {JSX.Element} - The rendered EditProgramModal component.
 *
 * @author - Pranathi
 */
const EditUserModal = ({ show, handleClose, initialData, onSave }) => {
  const [formData, setFormData] = useState({
    organization: "",
    organizationId: "",
    firstName: "",
    lastName: "",
    workEmail: "",
    teamIds: [],
    roleIds: [],
  });

  const token = fetchToken();
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [inputTeam, setInputTeam] = useState("");
  const [teamTitles, setTeamTitles] = useState([]);
  // const [isTeamMenuOpen, setIsTeamMenuOpen] = useState(false);
  const [inputRole, setInputRole] = useState("");
  const [roleTitles, setRoleTitles] = useState([]);
  // const [isRoleMenuOpen, setIsRoleMenuOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const getTeamTitles =
    process.env.REACT_APP_BASE_URL + "/cms/v1/getTeamTitles/";
  const getRoleTitles =
    process.env.REACT_APP_BASE_URL + "/cms/v1/getRoleAndTemplateTitles/";
    let defaultRoles = [];
    let defaultTeams = [];

    if (initialData.roles && initialData.roles.trim() !== '') {
      defaultRoles = initialData.roles.split(',').map((role, index) => ({
        label: role,
        value: role,
        id: initialData.roleIds[index] 
      }));
    }
   
    if (initialData.teams && initialData.teams.trim() !== '') {
      defaultTeams = initialData.teams.split(',').map((team, index) => ({
        label: team,
        value: team,
        id: initialData.teamIds[index] 
      }));
    }
  
    const[selectedRole,setSelectedRole]=useState(defaultRoles)
    const [selectedTeam, setSelectedTeam] = useState(defaultTeams);

  const CheckboxOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => props.selectOption(props.data)}
          style={{
            marginRight: "5px",
            flexShrink: 0,
          }}
        />
        <span style={{ color: "Black" }}>{props.label}</span>
      </div>
    </components.Option>
  );

  const RadioboxOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="radio"
          checked={props.isSelected}
          onChange={() => {
            props.selectOption(props.data); 
          }}
          style={{
            marginRight: "5px",
            flexShrink: 0,
          }}
        />
        <span style={{ color: "Black" }}>{props.label}</span>
      </div>
    </components.Option>
  );
  // const formatOptionLabel = ({ label }) => <span>{label}</span>;
  
  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData, ]);
  useEffect(() => {
    fetchTeamTitles();
  }, [inputTeam]);

  useEffect(() => {
    fetchRoleTitles();
  }, [inputRole]);

  const fetchTeamTitles = _.debounce(async () => {
    if (inputTeam.length > 0) {
      try {
        const url = inputTeam
          ? `${getTeamTitles}${inputTeam}`
          : `${getTeamTitles}`;
        if (!inputTeam) {
          return;
        }
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const teamTitles = result.data.teamTitles.map((teamName) => ({
          value: teamName.teamName,
          label: teamName.teamName,
          id: teamName._id,
        }));
        setTeamTitles(teamTitles);
      } catch (error) {
        console.error("Error fetching Team titles: ", error);
      }
    }
  }, 1000);

  const fetchRoleTitles = _.debounce(async (isActive) => {
    if (inputRole.length > 0) {
      try {
        const url = inputRole
          ? `${getRoleTitles}${inputRole}`
          : `${getRoleTitles}`;
        if (!inputRole) {
          return;
        }
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const roleTitles = result.data.roleAndTemplateTitles.map(
          (roleName) => ({
            value: roleName.roleName,
            label: roleName.roleName,
            id: roleName._id,
          })
        );
        setRoleTitles(roleTitles);
      } catch (error) {
        console.error("Error fetching role titles: ", error);
      }
    }
  }, 1000);

  const handleInputTeamChange = _.debounce((input) => {
    if (!input) {
      setInputTeam("");
    } else {
      setInputTeam(input);
    }
  },1000);

  const handleInputRoleChange = _.debounce((input) => {
    if (!input) {
      setInputRole("");
    } else {
      setInputRole(input);
    }
  },1000);

  const handleOnchangeTeam = (selectedOptions) => {
    setSelectedTeam(selectedOptions);
    const ids = selectedOptions.map((option) => option.id);
    setFormData((prevState) => ({
      ...prevState,
      teamIds: ids,
    }));
  };

  // const handleOnchangeRole = (selectedOptions) => {
  //   const optionsArray = Array.isArray(selectedOptions)
  //     ? selectedOptions
  //     : [selectedOptions];
  //   setSelectedRole(optionsArray);
  //   const ids = optionsArray.map((option) => option.id);
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     roleIds: ids,
  //   }));
  // };

  const handleOnchangeRole = (selectedOptions) => {
    if (!selectedOptions) {
      setSelectedRole([]);
      setFormData((prevState) => ({
        ...prevState,
        roleIds: [],
      }));
      return;
    }
    
    const optionsArray = Array.isArray(selectedOptions)
      ? selectedOptions
      : [selectedOptions];

    setSelectedRole(optionsArray);
    
    const ids = optionsArray.map((option) => option.id);
    setFormData((prevState) => ({
      ...prevState,
      roleIds: ids,
    }));
  };
  
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSaveModal(true);
  };

  const handleSaveConfirm = () => {
    if (formData.firstName === "") {
      toast.error("FirstName is required");
      return;
    }
    if (formData.lastName === "") {
      toast.error("LastName is required");
      return;
    }
    if (!formData.roleIds || formData.roleIds.length === 0) {
      toast.error("Role is required");
      return;
    }
    try {
      const updateData = {
        userId: formData._id,
        organizationId: formData.organizationId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        workEmail: formData.workEmail,
        teamIds: formData.teamIds,
        roleIds: formData.roleIds,
      };
      updateUser(updateData);
      setShowSaveModal(false);
      onSave();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CustomModal show={show} onHide={handleClose}>
        <CustomModalHeader>
          <Modal.Title>Edit User</Modal.Title>
          <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
        </CustomModalHeader>
        <Modal.Body>
          <CustomForm onSubmit={handleSubmit}>
            <Form.Group controlId="organization">
              <Form.Label>Organization</Form.Label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>This can't be edited</Tooltip>}
              >
                <DisabledInput
                  name="organization"
                  value={formData.organization}
                  onChange={handleChange}
                  required
                  disabled
                />
              </OverlayTrigger>
            </Form.Group>
            <Form.Group controlId="firstName">
              <Form.Label>First Name
                <FaAsterisk style={{color:"#E62753",
                  width:"0.4rem",
                  height:"0.4rem",
                  margin:"0rem 0rem 0.5rem 0.25rem"}}/>
              </Form.Label>
              <Form.Control
                className="editable"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name
                <FaAsterisk style={{color:"#E62753",
                  width:"0.4rem",
                  height:"0.4rem",
                  margin:"0rem 0rem 0.5rem 0.25rem"}}/>
              </Form.Label>
              <Form.Control
                className="editable"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="workEmail">
              <Form.Label>WorkEmail</Form.Label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>This can't be edited</Tooltip>}
              >
                <DisabledInput
                  type="email"
                  placeholder="Enter Email"
                  name="workEmail"
                  value={formData.workEmail}
                  onChange={handleChange}
                  required
                  disabled
                />
              </OverlayTrigger>
            </Form.Group>
            <Form.Group controlId="teamIds">
              <Form.Label style={{ marginBottom: "0.7rem" }}>Team
              </Form.Label>
              <Select
                className="custom-select"
               onInputChange={(inputValue, { action }) => {
                if (action === 'input-change') {
                  setIsTyping(true);
                } else if (action === 'menu-close' || action === 'input-blur') {
                  setIsTyping(false);
                }
                handleInputTeamChange(inputValue);
              }} 
               options={teamTitles}
                onChange={handleOnchangeTeam}
                value={selectedTeam}
                placeholder="Select Teams"
                isMulti
                isClearable
                components={{
                  Option: CheckboxOption,
                  NoOptionsMessage: () => (
                    <div style={{ textAlign: "center", padding:"0.4rem" ,}}>
                        {isTyping ? <span style={{ color: "var(--sky-blue-color)" }}>Searching...</span> : (teamTitles.length === 0 ? "Type something..." : "No options")}
                    </div>
                  ),
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={{
                  menu: (base) => ({
                    ...base,
                    marginTop: "0.7rem",
                    width: "22.9375rem",
                  }),
                  width: "1.5rem",
                  option: (base) => ({
                    ...base,
                    backgroundColor: "var(--white-color) !important",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "var(--dark-gray-color)",
                    paddingLeft:"1px",
                    fontWeight:"400",
                    fontSize:"var(--font-size-small)"
                  }),
                  control: (base) => ({
                    ...base,
                    borderColor: "#d8d8d8",
                    minWidth: "22.9375rem",
                    minHeight: "3rem",
                  }),
                }}
              />
            </Form.Group>
             <Form.Group controlId="roleIds">
              <Form.Label style={{ marginTop: "1.49rem" }}>Role
                <FaAsterisk style={{color:"#E62753",
                  width:"0.4rem",
                  height:"0.4rem",
                  margin:"0rem 0rem 0.5rem 0.25rem"}}/>
              </Form.Label>
              <Select
                className="custom-select"
               onInputChange={(inputValue, { action }) => {
                if (action === 'input-change') {
                  setIsTyping(true);
                } else if (action === 'menu-close' || action === 'input-blur') {
                  setIsTyping(false);
                }
                handleInputRoleChange(inputValue);
              }} 
               options={roleTitles}
                onChange={handleOnchangeRole}
                value={selectedRole}
                placeholder="Select Roles"
                isClearable
                components={{
                  Option: RadioboxOption,
                  NoOptionsMessage: () => (
                    <div style={{ textAlign: "center", padding:"0.4rem" }}>
                        {isTyping ? <span style={{ color: "var(--sky-blue-color)" }}>Searching...</span> : (roleTitles.length === 0 ? "Type something..." : "No options")}
                    </div>
                  ),
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={{
                  menu: (base) => ({
                    ...base,
                    marginTop: "0.7rem",
                    width: "22.9375rem",
                  }),
                  width: "1.5rem",
                  option: (base) => ({
                    ...base,
                    backgroundColor: "var(--white-color) !important",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "var(--dark-gray-color)",
                    paddingLeft:"1px",
                    fontWeight:"400",
                    fontSize:"var(--font-size-small)"
                  }),
                  control: (base) => ({
                    ...base,
                    borderColor: "#d8d8d8",
                    minWidth: "22.9375rem",
                    minHeight: "3rem",
                  }),
                }}
              />
            </Form.Group>
          </CustomForm>
        </Modal.Body>
        <CustomModalFooter>
          <ModalButton
            isClose
            onClick={handleClose}
            style={{ width: "7.5rem" }}
          >
            Cancel
          </ModalButton>
          <ModalButton
            type="submit"
            style={{ width: "10.75rem" }}
            onClick={handleSubmit}
          >
            Save changes
          </ModalButton>
        </CustomModalFooter>
      </CustomModal>
      {showSaveModal && (
        <SaveModal
          showModal={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          onSave={handleSaveConfirm}
        />
      )}
    </>
  );
};

EditUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialData: PropTypes.object.isRequired,
};

export default EditUserModal;
