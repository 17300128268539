import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import closeIcon from "../../../Assests/Images/closeIcon.svg";
import { FaAsterisk } from "react-icons/fa";
import { 
  CustomForm, 
  CustomModalHeader,
  CustomModalFooter,
  ModalButton,
  CustomModal,
  CloseIcon 
} from '../AddEditModal.styled';
import { addLevel } from '../../../Utils/ApiHandler/ProgramApi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * AddLevelModal Component
 * 
 * This component represents a modal for adding a new level.
 * 
 * @param {boolean} show - Flag indicating whether the modal should be displayed.
 * @param {function} handleClose - Function to handle closing the modal.
 * @param {function} handleAddLevel - Function to handle adding a new level.
 * 
 * @returns {JSX.Element} - The rendered AddLevelModal component.
 * 
 * @author - Pranathi
 */
const AddLevelModal = ({ show, handleClose, parentIdentifier,onSave }) => {
  const [formData, setFormData] = useState({
    code:'',
    levelName: '',
    description: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   try{
    if (!formData.levelName.trim()) {
      toast.error("Please enter a Level name");
      return;
    }

    if (!formData.description.trim()) {
      toast.error("Please enter  Description");
      return;
    }
    const insertData = {
      title:formData.levelName,
      description:formData.description,
      parentId:parentIdentifier._id
    }
    const response = addLevel(insertData);
      onSave();
      handleClose();
   }catch (error) {
    console.error("Error in updating data: ", error);
  }
  };

  return (
    <CustomModal show={show} onHide={handleClose}>
    <CustomModalHeader >
      <Modal.Title>Add Level</Modal.Title>
      <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
    </CustomModalHeader>
    <Modal.Body>
      <CustomForm onSubmit={handleSubmit}>
        <div>
      <div style={{fontSize:"0.95rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>Program : </div>
        <p >{parentIdentifier.program}</p></div>
        <div className="input-fields" >
        <Form.Group controlId="levelName">
          <Form.Label style={{fontSize: "0.95rem"}}>Level
            <FaAsterisk style={{color:"#E62753",
                width:"0.4rem",
                height:"0.4rem",
                margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
          <Form.Control
          className="editable"
            type="text"
            name="levelName"
            value={formData.levelName}
            onChange={handleChange}
            placeholder="Enter Level name"
            required
            style={{padding:"0.2rem 0.1rem"}}
          />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label style={{fontSize: "0.95rem"}}>Description
            <FaAsterisk style={{color:"#E62753",
                width:"0.4rem",
                height:"0.4rem",
                margin:"0rem 0rem 0.5rem 0.25rem"}}/>
          </Form.Label>
          <Form.Control
          className="editable textareaStyle"
            as="textarea"
            placeholder="Enter Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          />
        </Form.Group>
        </div>
      </CustomForm>
    </Modal.Body>
    <CustomModalFooter>
          <ModalButton isClose onClick={handleClose} style={{ width: "7.5rem" }}>
            Cancel
          </ModalButton>
          <ModalButton type="submit" style={{ width: "10.75rem" ,paddingLeft:"39px" }} onClick={handleSubmit}>
            Add Level
          </ModalButton>
        </CustomModalFooter>
  </CustomModal>
  );
};

AddLevelModal.propTypes = {
  onSave:PropTypes.func.isRequired,
};

export default AddLevelModal;
