import { escapeHtml } from "./StringUtilities";
import { questionTypes, bTaxonomy, complexity } from "./QuestionBuilderUtils";

const isValidValue = (value) => value !== undefined && value !== null && value !== "undefined" && value !== "null";

const handleMapping = (key, value) => {
    if (key === 'difficulty') return { key: 'complexity', value: { new: complexity[value.new], old: complexity[value.old] } };
    if (key === 'bTaxonomy') return { key: 'blooms taxonomy', value: { new: bTaxonomy[value.new], old: bTaxonomy[value.old] } };
    return { key, value };
};

const handleOptionChanges = (updatedKey, value, changes) => {
    const match = updatedKey.match(/options\.(\d+)\.(text|image|isCorrect)/);
    if (match) {
        const optionIndex = parseInt(match[1], 10) + 1;
        const field = match[2];
        const fieldValue = value.new !== undefined ? value.new : value.old;
        if (field === "text" || field === "image") {
            const action = value.new !== null && value.new !== undefined ? 'added' : 'removed';
            changes.push(`${action} <b>Option ${optionIndex} ${field}</b> with value <b>"${fieldValue}"</b>`);
        } else if (field === "isCorrect") {
            handleCorrectAnswerChange(optionIndex, value, changes);
        }
    }
    const optionMatch = updatedKey.match(/options\.(\d+)$/);
    if (optionMatch) {
        const optionIndex = parseInt(optionMatch[1], 10) + 1;
        const optionData = value.new;
        if (optionData) {
            let changeDescription = '';
            if (optionData.text !== undefined) {
                changeDescription += optionData.text ? `added text <b>"${optionData.text}"</b>, ` : `removed text <b>"${optionData.oldText}"</b>, `;
            }
            if (optionData.image !== undefined) {
                changeDescription += optionData.image ? `added image <b>"${optionData.image}"</b>, ` : `removed image <b>"${optionData.oldImage}"</b>, `;
            }
            if (optionData.isCorrect !== undefined) {
                changeDescription += optionData.isCorrect ? `added as correct answer, ` : `removed from being the correct answer, `;
            }
            changeDescription = changeDescription.replace(/, $/, "");
            if (changeDescription) {
                changes.push(`Option ${optionIndex} has been ${changeDescription}`);
            }
        }
    }
};

const handleCorrectAnswerChange = (optionIndex, value, changes) => {
    if (value.new !== undefined && value.old === undefined) {
        changes.push(`added Option ${optionIndex} as the correct answer.`);
    } else if (value.old !== undefined && value.new === undefined) {
        changes.push(`removed Option ${optionIndex} from being the correct answer.`);
    } else if (value.old !== value.new) {
        if (value.old === false && value.new === true) {
            changes.push(`Option ${optionIndex} is now marked as the correct answer.`);
        } else if (value.old === true && value.new === false) {
            changes.push(`Option ${optionIndex} is no longer the correct answer.`);
        }
    }
};

const handleStatusChanges = (value, changes) => {
    const oldStatus = value.old === 1 || value.old === "publish" ? "publish" : "unpublish";
    const newStatus = value.new === 1 || value.new === "publish" ? "publish" : "unpublish";
    if (value.old !== value.new) {
        changes.push(`Status changed from <b>"${oldStatus}"</b> to <b>"${newStatus}"</b>`);
        return changes;
    }
    return `No status changes recorded.`
};

export const formatActionDetails = (action, featureType, details = {}, pageTitle) => {
    const { newTitle = '', newDescription = '', oldStatus = '', newStatus = '', code = '' } = details;
    const statusValues = { old: oldStatus, new: newStatus };
    const formatMessage = (template, replacements) =>
        template?.replace(/\{\{(\w+)\}\}/g, (_, key) => replacements[key] || '');
    const getRecordType = (action) => action.toLowerCase() === 'deleted' ? 'active' : 'inactive';
    const templates = {
        created: pageTitle !== "Question"
            ? `<b>{{action}}</b> a new {{featureType}} <b>"{{newTitle}}"</b> with the description <b>"{{newDescription}}"</b>`
            : `<b>{{action}}</b> a new {{featureType}} with code: <b>"${code}"</b>`,
        recordAction: `<b>{{action}}</b> the {{featureType}} <b>"{{code}}"</b> from {{recordType}} records.`,
        statusChange: `${handleStatusChanges(statusValues, [])}`,
        flagAction: `<b>{{action}}</b> the {{featureType}} <b>"{{code}}"</b>`
    };
    return {
        created: formatMessage(templates.created, { action, featureType, newTitle, newDescription }),
        deleted: formatMessage(templates.recordAction, { action: 'Deleted', featureType, recordType: getRecordType('deleted'), code }),
        reactivated: formatMessage(templates.recordAction, { action: 'Reactivated', featureType, recordType: getRecordType('reactivated'), code }),
        published: formatMessage(templates.statusChange, { action: 'Published', featureType, oldStatus, newStatus, code }),
        unpublished: formatMessage(templates.statusChange, { action: 'Unpublished', featureType, oldStatus, newStatus, code }),
        flagged: formatMessage(templates.flagAction, { action: 'Flagged', featureType, code }),
        unflagged: formatMessage(templates.flagAction, { action: 'Unflagged', featureType, code })
    };
};

export const formatUpdatedAction = (action, featureType, details, rowCode) => {
    let updateString = `<b>${action}</b> the ${featureType} <b>${details?.childChanges?.code || rowCode || ''}</b>`;
    let changes = [];
    if (details?.qtype?.old !== undefined && details?.qtype?.new !== undefined) {
        const { old: oldQType, new: newQType } = details.qtype;
        return `<b>${action}</b> the ${featureType} <b>${rowCode || ''}</b> from:<br> <b>${questionTypes[oldQType]}</b><br> to<br> <b>${questionTypes[newQType]}</b>`;
    }
    for (let key in details) {
        const value = details[key];
        const { key: updatedKey, value: updatedValue } = handleMapping(key, value);
        if (updatedKey.startsWith('childChanges')) {
            for (let childKey in value) {
                const oldValue = value[childKey]?.old;
                const newValue = value[childKey]?.new;
                if (childKey === 'code') continue;
                const parts = childKey.split('.');
                const baseKey = parts[0];
                const middlePart = parts[1];
                const indexPart = parseInt(middlePart, 10);
                if (oldValue !== undefined && newValue !== undefined) {
                    if (!isNaN(indexPart)) {
                        changes.push(`Child change for <b>${baseKey} ${indexPart + 1}</b> from <b>"${escapeHtml(oldValue)}"</b> to <b>"${escapeHtml(newValue)}"</b>`);
                    } else {
                        changes.push(`Child change for <b>${baseKey} ${middlePart}</b> from <b>"${escapeHtml(oldValue)}"</b> to <b>"${escapeHtml(newValue)}"</b>`);
                    }
                } else {
                    if (!isNaN(indexPart)) {
                        changes.push(`Child change for <b>${baseKey} ${indexPart + 1}</b>: No change detected`);
                    } else {
                        changes.push(`Child change for <b>${baseKey} ${middlePart}</b>: No change detected`);
                    }
                }
            }
        }
        if (!isValidValue(value?.new) || key === 'updatedAt' || key === 'atype') continue;
        if (updatedKey.startsWith("question.")) {
            if (updatedKey.includes("text")) {
                changes.push(`updated <b>Question Text</b> from <b>"${escapeHtml(updatedValue.old)}"</b> <br>to<br> <b>"${escapeHtml(updatedValue.new)}"</b>`);
            } else if (updatedKey.includes("image")) {
                changes.push(`updated <b>Question Image</b>`);
            }
        } else if (updatedKey.startsWith("options.")) {
            handleOptionChanges(updatedKey, value, changes);
        } else if (updatedKey === "solution") {
            changes.push(`updated <b>${updatedKey}</b> from <b>${escapeHtml(updatedValue.old)}</b> to <b>${escapeHtml(updatedValue.new)}</b>`);
        } else if (updatedKey === "status") {
            handleStatusChanges(value, changes);
        } else if (updatedValue && value.old === undefined && value.new === "") {
            changes.push(`removed <b>${updatedKey}</b>`);
        } else if (updatedValue && value.old !== undefined && value.new !== undefined && value.old !== value.new) {
            changes.push(`updated <b>${updatedKey}</b> from <b>"${updatedValue.old}"</b> to <b>"${updatedValue.new}"</b>`);
        } else if (updatedValue && value.old === undefined) {
            changes.push(`updated <b>${updatedKey}</b> to <b>"${updatedValue.new}"</b>`);
        }
    }
    updateString += changes.length > 0
        ? `:<ol>${changes.map(change => `<li>${change}</li>`).join("")}</ol>`
        : `<b>:</b><br>No changes detected for this ${featureType}.`;

    return updateString;
};