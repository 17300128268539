import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getStatusCountCall } from 'Utils/ApiHandler/SpellAndVocabServices/statusService';

const statusOptions = [
    { label: "Published", value: "published", id: 1 },
    { label: "Unpublished", value: "unpublished", id: -1 },
    { label: "In Review", value: "in-review", id: 0 },
    { label: "In Active", value: "inActive", id: 9999 },
];

const initialState = {
    currentStatus: null,
    // currentStatus: statusOptions[0]?.id,
    isActive: true,
    searchKey: '',
    statusOptions: statusOptions,
    statusCountArray: {}
};

export const statusCountAction = createAsyncThunk(
    'statusCountCall/statusCount',
    async (params, { rejectWithValue }) => {
        try {
            const result = await getStatusCountCall(params);
            const countsObj = await result.counts;
            return countsObj;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const statusSlice = createSlice({
    name: 'statusCount',
    initialState,
    reducers: {
        setCurrentStatus: (state, action) => {
            state.currentStatus = action.payload;
            state.searchKey = "";
        },
        toggleActiveInactive: (state, action) => {
            state.isActive = action.payload;
            state.searchKey = "";
        },
        updateSearchKey: (state, action) => {
            state.searchKey = action.payload;
        },
        updateStatusCount: (state, action) => {
            state.statusCountArray = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(statusCountAction.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(statusCountAction.fulfilled, (state, action) => {
                state.loading = false;
                state.statusCountArray = action.payload;
                state.error = null;
            })
            .addCase(statusCountAction.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong!';
            });
    },
});

export const { setCurrentStatus, toggleActiveInactive, updateSearchKey, updateStatusCount } = statusSlice.actions;

export default statusSlice.reducer;
