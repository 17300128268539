import React, { useState } from "react";
import {Form}  from "react-bootstrap";
import { CustomForm,DisabledInput} from "../AddEditModal.styled";
import Select,{ components } from "react-select";
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaAsterisk } from "react-icons/fa";

function RoleInfo({
    edit,
    handleNext,
    organization,
    role,
    description,
    setOrganization,
    setRole,
    setDescription,
    organizationOptions,
    handleInputOrganizationChange
}) {
    
    const [selectedOrganization, setSelectedOrganization] = useState(organization);
    const [roleName, setRoleName] = useState(role);
    const [roleDescription, setRoleDescription] = useState(description);
   
      const CheckboxOption = (props) => (
        <components.Option {...props}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              checked={props.isSelected}
              onChange={() => props.selectOption(props.data)}
              style={{
                marginRight: "5px",
                flexShrink: 0,
              }}
            />
            <span style={{ color: "Black" }}>{props.label}</span>
          </div>
        </components.Option>
      );
    
    const handleOrganaiztionChange = (selectedOptions) => {
        const selected = selectedOptions.id;
        setSelectedOrganization(selected);
        setOrganization(selected);
    
    }
    
    const handleRoleNameChange = (event) => {
        setRoleName(event.target.value);
        setRole(event.target.value);
    }

    const handleRoleDescriptionChange = (event) => {
        setRoleDescription(event.target.value);
        setDescription(event.target.value);
    }

    const validateFields = () => {
      if (!selectedOrganization || !roleName || !roleDescription) {
          toast.error("Please fill all the fields");
        return false;
      }
      return true;
    };
  
    const onNextClick = () => {
      if (validateFields()) {
        handleNext();
      }
    };

    return (
        <div>
            <div className="sub-title">Role Information</div>
            <div>
                <CustomForm style={{paddingLeft:"0px"}}>
                    <div className="flex-content" style={{marginTop:"1rem"}}>
                    <Form.Group controlId="organization">
                    <Form.Label className="subTitleLabel">Organization
                      <FaAsterisk style={{color:"#E62753",
                          width:"0.4rem",
                          height:"0.4rem",
                          margin:"0rem 0rem 0.5rem 0.25rem"}}/>
                    </Form.Label>
                    {edit ? (
                      <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>This can't be edited</Tooltip>}>
                    <DisabledInput
                      type="text"
                      value={selectedOrganization}
                      required
                      style={{width:"17.0625rem",marginRight:"2.19rem"}}
                      disabled />
                    </OverlayTrigger>
                    ) : (
                        <Select  placeholder="Organizations"
                        options={organizationOptions}
                        onInputChange={handleInputOrganizationChange} 
                        onChange = {handleOrganaiztionChange}
                        value={organizationOptions.find(option => option.id === selectedOrganization)}
                        components={{
                            Option: CheckboxOption,
                            NoOptionsMessage: () => (
                              <div style={{ textAlign: "center" }}>
                                {organizationOptions.length === 0
                                  ? "Type something..."
                                  : "No options"}
                              </div>
                            ),
                          }}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            zIndex: 9999,
                            width: "17.0625rem",
                          }),
                            control: (base) => ({
                            ...base,
                            width:"17.0625rem",
                            height:"3rem",
                            backgroundColor:"#F6F8FA",
                            marginRight:"2.19rem"
                          }),}
                        }
                        />
                      )}
                    </Form.Group>
                    <div>
                        <div className="subTitleLabel">Role Name
                          <FaAsterisk style={{color:"#E62753",
                            width:"0.4rem",
                            height:"0.4rem",
                            margin:"0rem 0rem 0.5rem 0.25rem"}}/></div>
                        <input type="text" name="skillName" className="roleInfoName"
                            placeholder="Enter role name"
                            value = {roleName}
                            onChange = {handleRoleNameChange}
                            required
                        />
                    </div>
                    </div>
                    <div style={{marginTop:"1rem"}}>
                        <div className="subTitleLabel">Description
                          <FaAsterisk style={{color:"#E62753",
                            width:"0.4rem",
                            height:"0.4rem",
                            margin:"0rem 0rem 0.5rem 0.25rem"}}/></div>
                        <textarea value = {roleDescription} className="roleInfoDescription"
                        onChange={handleRoleDescriptionChange}
                        placeholder="Add description here...."
                        name="description"
                        required
                        />
                    </div>
                    <button type="button" className="buttonRole infoButtonRole" 
                    onClick={onNextClick}>Next</button>
                </CustomForm>

            </div>

        </div>
    );
}

export default RoleInfo;