import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from "../../Components/Loader/Loader";
import { loginVerifiedUser } from "../../Utils/AuthUtils";
import DbConfig from 'Constants/db';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const portalBaseUrl = process.env.REACT_APP_98_BASE_URL;
const clientId = process.env.REACT_APP_98_CLIENT_ID;
const clientSecret = process.env.REACT_APP_98_CLIENT_SECRET;
const redirectUri = process.env.REACT_APP_REDIRECT_URI;

const fetchAccessToken = async (url, formData) => {
    const response = await fetch(url, {
        method: 'POST',
        body: formData,
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
};

const fetchProfileData = async (url, accessToken) => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch profile: ${response.statusText}`);
    }

    return await response.json();
};

const exchangeAuthorizationCode = async (code) => {
    const formData = new FormData();
    formData.append('grant_type', 'authorization_code');
    formData.append('client_id', clientId);
    formData.append('client_secret', clientSecret);
    formData.append('redirect_uri', redirectUri);
    formData.append('code', code);

    return await fetchAccessToken(`${portalBaseUrl}/oauth/token`, formData);
};

const CallbackPage = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [authInitiated, setAuthInitiated] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        let debounceTimeout;

        const handleCallback = async () => {
            try {
                if (authInitiated) return;
                setAuthInitiated(true);

                const urlParams = new URLSearchParams(window.location.search);
                const code = urlParams.get('code');
                if (!code) {
                    throw new Error('Authorization code not found');
                }

                const tokenData = await exchangeAuthorizationCode(code);
                localStorage.setItem('accessToken', tokenData.access_token);

                const profileData = await fetchProfileData(`${portalBaseUrl}/api/profile`, tokenData.access_token);

                const userResponse = await loginVerifiedUser(profileData.data.email, profileData.data.first_name, profileData.data.name, profileData.data.timezone);

                await Promise.all([
                    DbConfig.accessToken.clear(),
                    DbConfig.accessToken.add({ accessToken: userResponse.data.access_token }),
                    DbConfig.userDetails.clear(),
                    DbConfig.userDetails.add({
                        firstName: userResponse.data.first_name,
                        lastName: userResponse.data.last_name,
                        email: userResponse.data.email,
                        roleName: userResponse.data.role_name,
                    }),
                    DbConfig.curriculumAccess.clear(),
                    DbConfig.curriculumAccess.add(userResponse.data.userAccess.curriculum[0]),
                    DbConfig.settingsAccess.clear(),
                    DbConfig.settingsAccess.add(userResponse.data.userAccess.settings[0]),
                    DbConfig.programDetails.clear(),
                    DbConfig.programDetails.add({ programDetails: userResponse.data.programDetails })
                ]);

                navigate('/curriculum/program');
            } catch (error) {
                setError(error);
                setIsLoading(false);
                if (error instanceof TypeError && error.message === "Failed to fetch") {
                    toast.error('Network error. Please check your internet connection.');
                    navigate('/login');
                } else if (error.message === 'Authorization code not found') {
                    toast.error('Authorization code is missing.');
                    navigate('/login');
                } else if (error.response && error.response.status === 401) {
                    toast.error('Unauthorized access. Please check your credentials.');
                    navigate('/login');
                } else if (error.response && error.response.status === 500) {
                    const errorData = await error.response.json();
                    toast.error(errorData.message || 'An unexpected error occurred.');
                    navigate('/login');
                } else {
                    toast.error('User not found or is inactive. Please check your email or username and try again.');
                    console.error(error);
                    navigate('/login');
                }
            }
        };

        debounceTimeout = setTimeout(() => {
            handleCallback();
        }, 2000);

        return () => clearTimeout(debounceTimeout);
    }, [authInitiated, navigate]);

    return (
        <div className="spinner">
            {isLoading && (
                <>
                    <p className="authClass">Hang tight! We're securely verifying your credentials.</p>
                    <Loader />

                </>
            )}
        </div>
    );
};

export default CallbackPage;
