import React from 'react'
import "./routesCss.css";
import Teams from '../Controller/Teams/Teams';
import Users from '../Controller/Users/Users';
import Login from "../Controller/Login/Login";
import Roles from '../Controller/Roles/Roles';
import Logout from "../Controller/Logout/Logout";
import Layout from "../Controller/Layout/Layout";
import Error404 from "../Components/Error404/Error404";
import PrivateRoute from "../Controller/PrivateRoutes";
import CreateRole from '../Controller/Roles/CreateRole';
import CallbackPage from '../Controller/Login/CallbackPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Organization from "../Controller/Organization/Organizations";
import StudentDashboard from 'Controller/Dashboard/StudentDashboard';
import CurriculumAllTopics from "../Controller/Curriculum/Topics/CurriculumAllTopics";
import CurriculumAllLevels from "../Controller/Curriculum/Levels/CurriculumAllLevels";
import CurriculumAllSkills from "../Controller/Curriculum/Skills/CurriculumAllSkills";
import CurriculumAllDomains from "../Controller/Curriculum/Domains/CurriculumAllDomains";
import AddQuestion from "../Controller/Curriculum/Questions/QuestionBuilder/AddQuestion";
import CurriculumAllPrograms from "../Controller/Curriculum/Programs/CurriculumAllPrograms";
import CurriculumAllQuestions from "../Controller/Curriculum/Questions/CurriculumAllQuestions";
import QuestionPreview from "../Controller/Curriculum/Questions/QuestionPreviewBuilder/QuestionPreview";
import SpellingAndVocabulary from "../Controller/Programs/SpellingAndVocabulary/SpellingAndVocabulary";
import QuestionBuilder from 'Controller/Programs/SpellingAndVocabulary/Questions/QuestionBuilder';
import PreviewQuestion from 'Controller/Programs/SpellingAndVocabulary/Questions/PreviewQuestion';

const routes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<PrivateRoute />}>
                    <Route path="/" element={<Layout />}>
                        <Route exact path="/" element={<CurriculumAllPrograms />} />
                        <Route exact="true" path="/teams/:page?" element={<Teams />} />
                        <Route exact="true" path="/users/:page?" element={<Users />} />
                        <Route exact="true" path="/roles/:page?" element={<Roles />} />
                        <Route exact="true" path="/create-new-role" element={<CreateRole />} />
                        <Route exact="true" path="/edit-role" element={<CreateRole />} />
                        <Route exact="true" path="/organization/:page?" element={<Organization />} />
                        <Route exact="true" path="/curriculum" element={<CurriculumAllPrograms />} />
                        <Route exact="true" path="/curriculum/addQuestion" element={<AddQuestion />} />
                        <Route exact="true" path="/curriculum/editQuestion" element={<AddQuestion />} />
                        <Route exact="true" path="/curriculum/level/:page?/:id?/:filterKey?" element={<CurriculumAllLevels />} />
                        <Route exact="true" path="/curriculum/topic/:page?/:id?/:filterKey?" element={<CurriculumAllTopics />} />
                        <Route exact="true" path="/curriculum/skills/:page?/:id?/:filterKey?" element={<CurriculumAllSkills />} />
                        <Route exact="true" path="/curriculum/domain/:page?/:id?/:filterKey?" element={<CurriculumAllDomains />} />
                        <Route exact="true" path="/curriculum/program/:page?" element={<CurriculumAllPrograms />} />
                        <Route exact="true" path="/curriculum/questions/:page?" element={<CurriculumAllQuestions />} />
                        <Route exact="true" path="/spellandvocab/" element={<SpellingAndVocabulary />} />
                        <Route exact="true" path="/curriculum/questions/:page?/:id?/:filterKey?" element={<CurriculumAllQuestions />} />
                        <Route exact="true" path="/spellandvocab/spelling-add" element={<QuestionBuilder />} />
                        <Route exact="true" path="/spellandvocab/spelling-edit" element={<QuestionBuilder />} />
                        <Route exact="true" path="/spellandvocab/vocabulary-edit" element={<QuestionBuilder />} />
                        <Route exact="true" path="/spellandvocab/vocabulary-add" element={<QuestionBuilder />} />
                    </Route>
                    <Route exact="true" path="/logout" element={<Logout />} />

                </Route>
                <Route path="*" element={<Error404 />} />
                <Route path="/auth/callback" element={<CallbackPage />} />
                <Route path="/question-preview" element={<QuestionPreview />} />
                <Route path="/questionBuilder-preview" element={<PreviewQuestion />} />

                <Route path="/student-dashboard" element={<StudentDashboard />} />
            </Routes>
        </BrowserRouter>
    )
}

export default routes;