import { toast } from "react-toastify";
import { baseUrl, fetchToken, activityLogBaseUrl } from "Constants/ApiUrls";

export const publishUnpublishAction = async (params) => {
    const { status, ids } = params;
    try {
        let url = status === 1
            ? `${baseUrl}/cms/v1/publishQuestion`
            : `${baseUrl}/cms/v1/unPublishQuestion`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
            body: JSON.stringify({ status, questionIds: Array.isArray(ids) ? ids : [ids] }),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to update the status.");
            return false;
        }
        const toastMessage = status === 1 ? "Question published successfully." : "Question unpublished successfully."
        toast.success(toastMessage);
        return result;
    } catch (error) {
        toast.error("Something went wrong!");
        console.error("Error in publishUnpublishAction:", error);
        throw new Error(error);
    }
};

export const flagUnflagAction = async (params) => {
    const { ids, isFlag, text, id, isBulkAction } = params;
    if (isBulkAction) {
        bulkFlagUnflagAction(params);
        return;
    }
    try {
        let url = isFlag ? `${baseUrl}/cms/v2/addCommentToQuestion?flag=true` : `${baseUrl}/cms/v1/questionUnflag`;
        let idKey = isFlag ? "ids" : "id";
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
            body: JSON.stringify({ [idKey]: ids || id, text: text }),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to update the status.");
            return false;
        }
        const toastMessage = isFlag ? "Question flagged successfully." : "Question unflagged successfully."

        toast.success(toastMessage);
        return result;
    } catch (error) {
        toast.error("Something went wrong!");
        console.error("Error in publishUnpublishAction:", error);
        throw new Error(error);
    }
};

export const bulkFlagUnflagAction = async (params) => {
    const { ids, isFlag, text, id } = params;
    try {
        let url = `${baseUrl}/cms/v2/${isFlag ? 'addCommentToQuestion?flag=true' : 'questionUnflag'}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
            body: JSON.stringify({ ids: ids || id, text: text }),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to update the status.");
            return false;
        }
        const toastMessage = isFlag ? "Question flagged successfully." : "Question unflagged successfully."
        toast.success(toastMessage);
        return result;
    } catch (error) {
        toast.error("Something went wrong!");
        console.error("Error in publishUnpublishAction:", error);
        throw new Error(error);
    }
};

export const deleteAction = async (params) => {

    const { key, itemId, endPoint } = params;

    if (!key || !itemId || !endPoint) {
        toast.error("Missing required parameters.");
        return false;
    }

    try {
        const url = `${baseUrl}/cms/v1/${endPoint}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
            body: JSON.stringify({ [key]: itemId }),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to delete.");
            return false;
        }
        toast.success("Deleted successfully.");
        return result;
    } catch (error) {
        console.error("Delete action failed:", error);
        toast.error("An error occurred while deleting.");
        return false;
    }
};

export const reactivateAction = async (params) => {
    const { key, itemId, endPoint } = params;
    if (!key || !itemId || !endPoint) {
        toast.error("Missing required parameters.");
        return false;
    }
    try {
        const url = `${baseUrl}/cms/v1/${endPoint}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
            body: JSON.stringify({ [key]: itemId }),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to reactivate.");
            return false;
        }
        toast.success("Reactivated successfully.");
        return result;
    } catch (error) {
        console.error("Reactivate action failed:", error);
        toast.error("An error occurred while reactivating.");
        return false;
    }
};

export const addCommentsToSpelling = async (data) => {
    const url = `${baseUrl}/cms/v1/addCommentToQuestion`;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!result.success || !response.ok) {
            return false;
        } else {
            toast.success("Comment Added.");
        }
        return response;
    } catch (error) {
        toast.error('Something went wrong!')
        throw new Error(error);
    }
}

export const getAllCommentsForSpelling = async (questionId, isFlagged) => {
    try {
        let url = "";
        if (isFlagged) {
            url = `${baseUrl}/cms/v1/getAllQuestionComments/${questionId}?flag=${isFlagged}`;
        } else {
            url = `${baseUrl}/cms/v1/getAllQuestionComments/${questionId}`;
        }
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
        });
        const result = await response.json();
        if (!response.ok) {
            toast.error(response.statusText);
            return [];
        }
        if (!result.success) {
            console.error(`API error: ${result.message || 'Unknown error'}`);
            toast.error(result.message || "Error fetching comments");
            return [];
        }
        return result.data;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const getQuestionById = async (id) => {
    const url = `${baseUrl}/cms/v2/getQuestionById/${id}`;
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to fetch question.");
            return null;
        }
        return result.data;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const getpreviewQuestionById = async (previewId) => {
    try {
        const url = `${baseUrl}/cms/v1/getpreviewQuestionById/${previewId}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Preview Loaded Successfully");
        return responseData;
    } catch (error) {
        toast.error("Error previewing question: ", error);
        throw new Error(error);
    }
};

export const insertPreviewQuestionSpelling = async (formData) => {
    try {
        const url = `${baseUrl}/cms/v1/insertPreviewQuestion`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${fetchToken()}`,
            },
            body: formData,
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Preview Loaded Successfully");
        return responseData;
    } catch (error) {
        toast.error("Error add question: ", error);
        throw new Error(error);
    }
};

export const getAllComments = async (questionId, isFlagged, title) => {
    try {
        let url = `${baseUrl}/cms/v1/getAll${title}Comments/${questionId}`;
        url += isFlagged ? `?flag=${isFlagged}` : "";
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            throw new Error(`Failed to fetch comments: ${result.statusText}`);
        }
        return result.data;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const insertComments = async (data, title) => {
    try {
        const url = `${baseUrl}/cms/v1/addCommentTo${title}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(response.message);
            return false;
        }
        toast.success("Comment added successfully");
        return result;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const getAllActivities = async (params) => {
    const { featureId } = params;
    try {
        let url = `${activityLogBaseUrl}/at/v1/activities?featureId=${featureId}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            throw new Error(`Failed to fetch activities: ${result.statusText}`);
        }
        return result.data;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};
