import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import closeIcon from "../../../Assests/Images/closeIcon.svg";
import SaveModal from '../SaveModal/SaveModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaAsterisk } from "react-icons/fa";
import { updateTeam } from '../../../Utils/ApiHandler/TeamsApi';
import {
  CustomModalHeader,
  CustomModalFooter,
  CustomForm,
  ModalButton,
  CustomModal,
  CloseIcon,
  DisabledInput
} from '../AddEditModal.styled';

/**
 * EditTeamModal Component
 * 
 * This component represents a modal for editing an existing team.
 * 
 * @param {boolean} show - Flag indicating whether the modal should be displayed.
 * @param {function} handleClose - Function to handle closing the modal.
 * @param {object} initialData - Initial data of the team being edited.
 * @returns {JSX.Element} - The rendered EditTeamModal component.
 * 
 * @author - Devisri
 */
const EditTeamModal = ({ show, handleClose, initialData, onSave }) => {
  const [formData, setFormData] = useState(initialData);

  const [showSaveModal, setShowSaveModal] = useState(false);

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSaveModal(true);
  };

  const handleSaveConfirm = () => {
    if (!formData.team ) {
      toast.error("Team name is required");
      return;
    }

    if (formData.description == '') {
      toast.error("Description is required");
      return;
    }

    try {
      const updateData = {
        organizationId: formData.organizationId,
        id: formData._id,
        teamName: formData.team,
        description: formData.description,
      };
      const response = updateTeam(updateData);
      setShowSaveModal(false);
      onSave();
      handleClose();
    }
    catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <CustomModal show={show} onHide={handleClose}>
        <CustomModalHeader >
          <Modal.Title>Edit Team</Modal.Title>
          <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
        </CustomModalHeader>
        <Modal.Body>
          <CustomForm onSubmit={handleSubmit}>
            <Form.Group controlId="organization">
              <Form.Label>Organization</Form.Label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>This can't be edited</Tooltip>}
              >
                <DisabledInput
                  name="organization"
                  value={formData.organization}
                  onChange={handleChange}
                  required
                  disabled />

              </OverlayTrigger>
            </Form.Group>
            <Form.Group controlId="team">
              <Form.Label>Team Name
                <FaAsterisk style={{color:"#E62753",
                  width:"0.4rem",
                  height:"0.4rem",
                  margin:"0rem 0rem 0.5rem 0.25rem"}}/>
              </Form.Label>
              <Form.Control
                className="editable"
                name="team"
                value={formData.team}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description
                <FaAsterisk style={{color:"#E62753",
                  width:"0.4rem",
                  height:"0.4rem",
                  margin:"0rem 0rem 0.5rem 0.25rem"}}/>
              </Form.Label>
              <Form.Control
                className="editable textareaStyle"
                as="textarea"
                placeholder="Enter Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </CustomForm>
        </Modal.Body>
        <CustomModalFooter>
          <ModalButton  onClick={handleClose} style={{ width: "7.5rem" }}>
            Cancel
          </ModalButton>
          <ModalButton type="submit" style={{ width: "10.75rem" }} onClick={handleSubmit}>
            Save changes
          </ModalButton>
        </CustomModalFooter>
      </CustomModal>
      {showSaveModal && (
        <SaveModal
          showModal={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          onSave={handleSaveConfirm}
        />
      )}
    </>
  );
};

EditTeamModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialData: PropTypes.object.isRequired,
};

export default EditTeamModal;
