import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import closeIcon from "../../../Assests/Images/Vector.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { SaveStyledModal, ModalFooter, CloseIcon } from "../SaveModal/SaveModal.styled";

function RolePermissionReview({ roleName,
    description,
    settingsAccess,
    curriculmAccess,
    handleInsertRole,
    edit }) {
    const navigate = useNavigate();
    const [show, set] = useState(false);
    const handleCancelButton = () => {
        navigate(`/roles`)
    }
    const handleSave = async () => {
        const response = await handleInsertRole();
        if (response) {
            set(true);
        }
    }

    return (
        <div>
            <div className="sub-title">Review Role Info & Permissions</div>
            <div>
                <div className="roleReviewHeading">Role info</div>
                <div style={{ display: "flex" }}>
                    <div className="roleInfoCard">
                        <div className="roleInfoCardTitle">Role name</div>
                        <div className="roleInfoCardContent">{roleName}</div>
                    </div>
                    <div className="roleInfoCard"
                        style={{ width: "27.375rem", borderLeft: "none" }}>
                        <div className="roleInfoCardTitle">Description</div>
                        <div className="roleInfoCardContent"
                            style={{ fontSize: "0.8125rem", fontWeight: "500" }}>{description}
                        </div>
                    </div>
                </div>
                <div className="roleReviewHeading"> Permissions</div>
                <div className="roleInfoCard reviewAccessedOptions"
                    style={{}}>
                    <div
                        className="radioOptionsLabel AccessesOptionView">View</div>
                    <div className="radioOptionsLabel AccessesOptionAdd">
                        Add</div>
                    <div className="radioOptionsLabel AccessesOptionAdd">
                        Edit</div>
                    <div className="radioOptionsLabel AccessesOptionAdd">
                        Delete</div>
                </div>
                <div className="accessCards" style={{ borderTop: "none" }}>
                    <div className="accessTypes organizationReview" >Organizations</div>
                    <div className="verticalLine"></div>
                    {settingsAccess?.organizations?.view ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {settingsAccess?.organizations?.add ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {settingsAccess?.organizations?.edit ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {settingsAccess?.organizations?.delete ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }

                </div>
                <div className="accessCards" >
                    <div className="accessTypes usersReview">Users</div>
                    <div className="verticalLine"></div>
                    {settingsAccess?.users?.view ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {settingsAccess?.users?.add ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {settingsAccess?.users?.edit ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {settingsAccess?.users?.delete ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                </div>
                <div className="accessCards">
                    <div className="accessTypes teamsReview">Teams</div>
                    <div className="verticalLine"></div>
                    {settingsAccess?.teams?.view ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {settingsAccess?.teams?.add ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {settingsAccess?.teams?.edit ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {settingsAccess?.teams?.delete ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                </div>
                <div className="accessCards">
                    <div className="accessTypes rolesReview" >Roles</div>
                    <div className="verticalLine"></div>
                    {settingsAccess?.roles?.view ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {settingsAccess?.roles?.add ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {settingsAccess?.roles?.edit ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {settingsAccess?.roles?.delete ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                </div>
                <div className="accessCards">
                    <div className="accessTypes programsReview">Programs</div>
                    <div className="verticalLine"></div>
                    {curriculmAccess?.programs?.view ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.programs?.add ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.programs?.edit ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.programs?.delete ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                </div>
                <div className="accessCards">
                    <div className="accessTypes levelsReview">Levels</div>
                    <div className="verticalLine"></div>
                    {curriculmAccess?.levels?.view ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.levels?.add ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.levels?.edit ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.levels?.delete ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                </div>
                <div className="accessCards">
                    <div className="accessTypes domainsReview">Domains</div>
                    <div className="verticalLine"></div>
                    {curriculmAccess?.domains?.view ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.domains?.add ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.domains?.edit ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.domains?.delete ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                </div>
                <div className="accessCards">
                    <div className="accessTypes topicsReview">Topics</div>
                    <div className="verticalLine"></div>
                    {curriculmAccess?.topics?.view ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.topics?.add ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.topics?.edit ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.topics?.delete ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                </div>
                <div className="accessCards">
                    <div className="accessTypes skillsReview">Skills</div>
                    <div className="verticalLine"></div>
                    {curriculmAccess?.skills?.view ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.skills?.add ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.skills?.edit ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.skills?.delete ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                </div>
                <div className="accessCards">
                    <div className="accessTypes questionReview" >Questions</div>
                    <div className="verticalLine"></div>
                    {curriculmAccess?.questions?.view ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.questions?.add ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.questions?.edit ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.questions?.delete ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                </div>
                <div className="accessCards">
                    <div className="accessTypes flaggingReview">Flagging</div>
                    <div className="verticalLine"></div>
                    {curriculmAccess?.flagging?.view ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.flagging?.add ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.flagging?.edit ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.flagging?.delete ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                </div>
                <div className="accessCards">
                    <div className="accessTypes addCommentReview" >Add Comment</div>
                    <div className="verticalLine"></div>
                    {curriculmAccess?.comments?.view ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.comments?.add ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.comments?.edit ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.comments?.delete ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                </div>
                <div className="accessCards">
                    <div className="accessTypes assignmentReview" >Assignments</div>
                    <div className="verticalLine"></div>
                    {curriculmAccess?.assignments?.view ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.assignments?.add ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.assignments?.edit ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                    <div className="verticalLine"></div>
                    {curriculmAccess?.assignments?.delete ?
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#008085" }}>Yes</div>) :
                        (<div className="reviewAccessOptions" style={{ backgroundColor: "#E4434A" }}>No</div>)
                    }
                </div>
                {/* <div className="accessCards">
                        <div className="accessTypes assignmentReview" >Assignments</div>
                        {curriculmAccess.assignments?.assign && curriculmAccess.assignments?.unAssign ? (
                            <div className="reviewAccessText" >Can assign & can unassign</div>
                        ) : curriculmAccess.assignments?.assign ? (
                            <div className="reviewAccessText" style={{marginLeft:"6.5rem"}}>Can assign</div>
                        ) : curriculmAccess.assignments?.unAssign ? (
                            <div className="reviewAccessText" style={{marginLeft:"5.5rem"}}>Can unassign</div>
                        ) : (
                            <div className="reviewAccessText">Cannot assign or unassign</div>
                        )}
                    </div> */}
                {/* <div className="accessCards">
                        <div className="accessTypes testsReview" >Tests</div>
                        {curriculmAccess.tests?.assign && curriculmAccess.tests?.unAssign ? (
                            <div className="reviewAccessText">Can assign & can unassign</div>
                        ) : curriculmAccess.tests?.assign ? (
                            <div className="reviewAccessText" style={{marginLeft:"6rem"}}>Can assign</div>
                        ) : curriculmAccess.tests?.unAssign ? (
                            <div className="reviewAccessText" style={{marginLeft:"5.5rem"}}>Can unassign</div>
                        ) : (
                            <div className="reviewAccessText">Cannot assign or unassign</div>
                        )}
                    </div> */}
                <div className="accessCards" style={{ borderBottom: "1px solid #F1F1F1" }}>
                    <div className="accessTypes publishReview">Publish</div>
                    {curriculmAccess.publishCurriculum === true ?
                        (<div className="reviewAccessText" style={{ marginLeft: "1rem" }}>Can Publish</div>) :
                        (<div className="reviewAccessText" >Cannot Publish</div>)
                    }
                </div>
                <div className="accessCards" style={{ borderBottom: "1px solid #F1F1F1" }}>
                    <div className="accessTypes publishReview">Unpublish</div>
                    {curriculmAccess.unPublishCurriculum === true ?
                        (<div className="reviewAccessText" style={{ marginLeft: "-1rem" }}>Can Unpublish</div>) :
                        (<div className="reviewAccessText" style={{ marginLeft: "-2.7rem" }}>Cannot Unpublish</div>)
                    }
                </div>
                <div className="accessCards" style={{ borderBottom: "1px solid #F1F1F1" }}>
                    <div className="exportAccessTypes publishReview">Export</div>
                    {curriculmAccess.exportCurriculum === true ?
                        (<div className="reviewAccessText" style={{ marginLeft: "-1rem" }}>Can Export</div>) :
                        (<div className="reviewAccessText" style={{ marginLeft: "-2.7rem" }}>Cannot Export</div>)
                    }
                </div>
            </div>
            <div className="flex-content">
                <button type="button" className=" buttonRole cancelReviewButton" style={{}} onClick={handleCancelButton}>Cancel</button>
                <button type="button" className=" btn btn-primary saveReviewButton" style={{}}
                    onClick={handleSave}>Save Role</button>
            </div>
            {show && <ConfirmModel showConfirmModel={show} setShowConfirmModel={set}
                onButtonClick={handleCancelButton} edit={edit} />}
        </div>
    )
}


const ConfirmModel = ({ showConfirmModel, setShowConfirmModel, onButtonClick, edit }) => {
    let msg = '';
    if (edit) {
        msg = 'Updated SuccessFully..!';
    } else {
        msg = 'A new Role has been added successfully..!';
    }
    return (
        <SaveStyledModal
            show={showConfirmModel}
            onHide={() => setShowConfirmModel(false)}
            centered
            backdrop="static"
        >
            <CloseIcon
                src={closeIcon}
                alt="Close"
                onClick={() => setShowConfirmModel(false)}
            />
            <Modal.Body style={{ width: "20rem", paddingTop: "1rem" }}>{msg}</Modal.Body>

            <ModalFooter>
                <Button
                    variant="secondary"
                    onClick={onButtonClick}
                    style={{
                        backgroundColor: "#016DD9",
                        color: "white",
                        padding: " 0.65rem 1rem",
                        borderRadius: "0.25rem",
                        marginLeft: "2rem",
                        border: "0px solid #D8D8D8",
                    }}
                >
                    Go to roles
                </Button>
            </ModalFooter>
        </SaveStyledModal>
    );
}

export default RolePermissionReview;