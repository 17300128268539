import React, { useState, useEffect } from 'react';
import Select, { components } from "react-select";
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import closeIcon from "../../../Assests/Images/closeIcon.svg";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SaveModal from '../SaveModal/SaveModal';
import { toast } from 'react-toastify';
import { FaAsterisk } from "react-icons/fa";
import {
  CustomModalHeader,
  CustomModalFooter,
  CustomForm,
  ModalButton,
  CustomModal,
  CloseIcon,
  DisabledInput
} from '../AddEditModal.styled';
import { getById, updateSkill } from '../../../Utils/ApiHandler/SkillsApi';

/**
 * EditModal Component
 * 
 * This component represents a modal for editing a skill.
 * 
 * @param {boolean} show - Flag indicating whether the modal should be displayed.
 * @param {function} handleClose - Function to handle closing the modal.
 * @param {object} initialData - Initial data for pre-filling the form.
 * @param {function} handleEditSkill - Function to handle editing a skill.
 * 
 * @returns {JSX.Element} - The rendered EditModal component.
 * 
 *@author - Devisri
 */
const EditSkillModal = ({ show, handleClose, initialData, handleEditSkill }) => {
  const [formData, setFormData] = useState({
    organization: '',
    program: '',
    level: '',
    domain: '',
    topic: '',
    code: '',
    skillName: '',
    status: '',
    description: ''
  });

  const [showSaveModal, setShowSaveModal] = useState(false);

  const statusOptions =
    [
      { value: -1, label: "Unpublish" },
      { value: 1, label: "Publish" },
    ]

  const RadioOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="radio"
          checked={props.isSelected}
          onChange={() => props.selectOption(props.data)}
          style={{
            marginRight: "5px",
            flexShrink: 0,
          }}
        />
        <span style={{ color: "Black" }}>{props.label}</span>
      </div>
    </components.Option>
  );

  useEffect(() => {
    if (initialData) {
      getById(initialData._id).then((data) => {
        const reqFields = data.map(each => ({
          organization: each.organization,
          program: each.program,
          level: each.level,
          domain: each.domain,
          topic: each.topic,
          code: each.code,
          skillName: each.title,
          status: each.status,
          description: each.description,
          id: each._id,
          parentId: each.parent,
        }));
        setFormData(reqFields[0]);
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, status: e.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSaveModal(true);
  };

  const handleSaveConfirm = async() => {  
      if (formData.skillName === '') {
        toast.error("Skill name is required");
        return;
      }
      if (formData.description === '') {
        toast.error("Description is required");
        return;
      }
      try {
      const updateData = {
        skillId: formData.id,
        title: formData.skillName,
        description: formData.description,
        parentId: formData.parentId,
        status: formData.status
      };
      await updateSkill(updateData);
      setShowSaveModal(false);
      handleEditSkill();
      handleClose();
    }
    catch (error) {
      console.log(error)
    }

  };

  return (
    <>
    <CustomModal show={show} onHide={handleClose}>
      <CustomModalHeader >
        <Modal.Title>Edit Skill</Modal.Title>
        <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
      </CustomModalHeader>
      <Modal.Body>
        <CustomForm onSubmit={handleSubmit}>
          <Form.Group controlId="organization">
            <Form.Label>Organization</Form.Label>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>This can't be edited</Tooltip>}>
              <DisabledInput
                type="text"
                name="organization"
                value={formData.organization}
                required
                disabled />
            </OverlayTrigger>
          </Form.Group>
          <Form.Group controlId="program">
            <Form.Label>Program</Form.Label>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>This can't be edited</Tooltip>}>
              <DisabledInput
                type="text"
                name="program"
                value={formData.program}
                required
                disabled />
            </OverlayTrigger>
          </Form.Group>
          <Form.Group controlId="level">
            <Form.Label>Level</Form.Label>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>This can't be edited</Tooltip>}>
              <DisabledInput
                type="text"
                name="program"
                value={formData.level}
                required
                disabled />
            </OverlayTrigger>
          </Form.Group>
          <Form.Group controlId="domain">
            <Form.Label>Domain</Form.Label>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>This can't be edited</Tooltip>}>
              <DisabledInput
                type="text"
                name="domain"
                value={formData.domain}
                required
                disabled />
            </OverlayTrigger>
          </Form.Group>
          <Form.Group controlId="topic">
            <Form.Label>Topic</Form.Label>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>This can't be edited</Tooltip>}>
              <DisabledInput
                type="text"
                name="topic"
                value={formData.topic}
                required
                disabled />
            </OverlayTrigger>
          </Form.Group>
          <Form.Group controlId="code">
            <Form.Label>Code</Form.Label>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>This can't be edited</Tooltip>}>
              <DisabledInput
                type="text"
                name="code"
                value={formData.code}
                required
                disabled />
            </OverlayTrigger>
          </Form.Group>
          <Form.Group controlId="skillName">
            <Form.Label>Skill
              <FaAsterisk style={{color:"#E62753",
                  width:"0.4rem",
                  height:"0.4rem",
                  margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Form.Control
              className='editable'
              name="skillName"
              value={formData.skillName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="status">
            <Form.Label>Status
              <FaAsterisk style={{color:"#E62753",
                  width:"0.4rem",
                  height:"0.4rem",
                  margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Select
              className="editable"
              placeholder="Select Status"
              value={{ value: formData.status, label: formData.status === 1 ? "Publish" : "Unpublish" }}
              onChange={handleChange}
              required
              options={statusOptions}
              components={{
                Option: RadioOption,
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  width: "22.9375rem",
                  height: "3rem",
                  marginBottom: "1rem",
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "var(--white-color) !important",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "#000000",
                }),
              }}
            />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description
            <FaAsterisk style={{color:"#E62753",
                    width:"0.4rem",
                    height:"0.4rem",
                    margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Form.Control
              className='editable textareaStyle'
              as="textarea"
              placeholder="Enter Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </CustomForm>
      </Modal.Body>
      <CustomModalFooter>
        <ModalButton isClose onClick={handleClose} style={{ width: "7.5rem" }}>
          Cancel
        </ModalButton>
        <ModalButton type="submit" onClick={handleSubmit} style={{ width: "10.75rem" }}>
          Save Changes
        </ModalButton>
      </CustomModalFooter>
    </CustomModal>
    {showSaveModal && (
      <SaveModal
        showModal={showSaveModal}
        onClose={() => setShowSaveModal(false)}
        onSave={handleSaveConfirm}
      />
    )}
  </>
  );
};

EditSkillModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialData: PropTypes.object.isRequired,
  handleEditSkill: PropTypes.func.isRequired,
};

export default EditSkillModal;
