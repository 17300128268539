import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import questionConfig from "Controller/Curriculum/Questions/QuestionBuilder/questionConfig";
import QuestionComponent from "./QuestionComponent";
import Card from "../../../../Components/Card/Card";
import Title from "../../../../Components/Title/Title";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    ButtonGroup,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import OptionsComponent from "./OptionsComponent";
import {
    setQuestionValue,
    clearQuestionValue,
} from "../../../../redux/slices/SpellAndVocab/Spelling/questionSlice";
import {
    clearOptions,
    clearSolution,
    setOptions,
    setSolution,
} from "../../../../redux/slices/SpellAndVocab/Spelling/optionSlice";
import {
    addVocabularyQuestionThunk,
    editVocabularyQuestionThunk,
} from "../../../../redux/slices/SpellAndVocab/Questions/vocabularyQuestionsSlice";
import {
    getQuestionById,
    insertPreviewQuestionSpelling,
} from "Utils/ApiHandler/DatatableActionServices/DatatableActionServices";
import {
    addSpellingQuestionThunk,
    editSpellingQuestionThunk,
} from "../../../../redux/slices/SpellAndVocab/Questions/spellingQuestionsSlice";
import ValidationErrorModal from "Controller/Programs/Modals/ValidationErrorModal/ValidationErrorModal";
import { closeSaveModal } from "../../../../redux/slices/ModalSlice/SaveModalSlice";
import QuestionSaveModal from "Controller/Programs/Modals/SaveModal/QuestionSaveModal";
import Loader from "Components/Loader/Loader";
import { publishUnpublishActions } from "../../../../redux/slices/DatatableActionSlices/publishUnpublishActionSlice";
function QuestionBuilder() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [isClosing, setIsClosing] = useState(false);
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
    const [previewId, setPreviewId] = useState(null);
    const [levelTitle, setLevelTitle] = useState('');
    const previewQuestionFormat = {
        questionType: "",
        questionText: "",
        optionsData: [],
        answer: "",
        answerExplanation: "",
    };
    const [previewQuestions, setPreviewQuestions] = useState([
        previewQuestionFormat,
    ]);
    const queryParams = new URLSearchParams(window.location.search);
    const questionId = queryParams.get("question-id");
    const levelId = queryParams.get("level-id");
    const levelName = queryParams.get("level-name");
    const title =
        location.pathname === "/spellandvocab/spelling-add"
            ? "Add Word"
            : location.pathname === "/spellandvocab/spelling-edit"
            ? "Edit Word"
            : location.pathname === "/spellandvocab/vocabulary-add"
            ? "Add Word"
            : location.pathname === "/spellandvocab/vocabulary-edit"
            ? "Edit Word"
            : "";
    const showDropdownArrow = title === "Edit Word";
    const { isErrorModalOpen } = useSelector(
        (state) => state.validationErrorModal
    );
    const questionValue = useSelector((state) => state.question.questionValue);
    const optionsData = useSelector((state) => state.options.options);
    const answer = useSelector((state) => state.options.answer);
    const qtype = useSelector((state) => state.qtype.qtype);
    const showSaveModal = useSelector((state) => state.saveModal.showSaveModal);
    const [loading, setLoading] = useState(true);
    const handleQuestionChange = (value) => {
        dispatch(setQuestionValue(value));
        var previousData = previewQuestions.map((data, index) => {
            if (index == currentQuestionNumber) {
                data.questionText = value;
            }
            return data;
        });
        setPreviewQuestions(previousData);
    };

    const handleSoultionChange = (value) => {
        dispatch(setSolution(value));
        var previousData = previewQuestions.map((data, index) => {
            if (index == currentQuestionNumber) {
                data.answer = value;
            }
            return data;
        });
        setPreviewQuestions(previousData);
    };

    const handleOptionsChange = (optionsData) => {
        dispatch(setOptions(optionsData));
        var previousData = previewQuestions.map((data, index) => {
            if (index == currentQuestionNumber) {
                data.optionsData = optionsData;
            }
            return data;
        });
        setPreviewQuestions(previousData);
    };

    const handleCloseSaveModal = () => {
        dispatch(closeSaveModal());
    };

    const resetForm = () => {
        dispatch(clearQuestionValue());
        dispatch(clearOptions());
        dispatch(clearSolution());
    };

    const handleAddMore = () => {
        resetForm();
    };


    const handleQuestionSave = async () => {
        setLoading(true);
        if (qtype.label == "Vocabulary") {
            try {
                const formData = new FormData();
                if (questionValue == null || questionValue == "null") {
                    formData.append("question[text]", "");
                } else {
                    formData.append("question[text]", questionValue);
                }
                formData.append("qtype", qtype.value);
                formData.append("skipBloomAndDiff", true);
                optionsData.forEach((option, index) => {
                    if (option.text) {
                        formData.append(`options[${index}][text]`, option.text);
                    }
                    formData.append(
                        `options[${index}][isCorrect]`,
                        option.isCorrect ? "true" : "false"
                    );
                });
                formData.append("parentId", levelId);
                if (questionId) {
                    formData.append("questionId", questionId);
                    await dispatch(editVocabularyQuestionThunk(formData));
                } else {
                   await dispatch(addVocabularyQuestionThunk(formData));
                }
            } catch (error) {
                console.log(error);
            } finally{
                setLoading(false)
            }
        } else if (qtype.label == "Spelling") {
            try {
               
                const formData = new FormData();
                if (questionValue == null || questionValue == "null") {
                    formData.append("question[text]", "");
                } else {
                    formData.append("question[text]", questionValue);
                }
                formData.append("qtype", qtype.value);
                formData.append("skipBloomAndDiff", true);
                formData.append("answer", answer);
                formData.append("parentId", levelId);
                if (questionId) {
                    formData.append("questionId", questionId);
                    await dispatch(editSpellingQuestionThunk(formData));
                } else {
                    await dispatch(addSpellingQuestionThunk(formData));
                }
            } catch (error) {
                console.log(error);
            } finally{
                setLoading(false)
            }
        }
    };

    useEffect(() => {
        async function fetchQuestionById() {
            try {
                if (title === "Edit Word") {
                    if (questionId) {
                        const question = await getQuestionById(questionId);
                        dispatch(setQuestionValue(question.question.text));
                        dispatch(setOptions(question.options));
                        dispatch(setSolution(question.answer));
                        setLevelTitle(question.skill)
                    }
                }
            } catch (error) {
                console.error("Error fetching question:", error);
            } finally {
                setLoading(false)
            }
        }
        fetchQuestionById();
    }, [questionId]);

    const handleSaveAndPublish = async () => {
        await handleQuestionSave();
        dispatch(publishUnpublishActions({
            status :1,
            ids:questionId
        }))
    }

    useEffect(() => {
      resetForm()
    },[showSaveModal])

    const handlePreviewClick = async () => {
        var currentPreviewId = await generatePreview();
        const encodedFormData = encodeURIComponent(currentPreviewId);
        let previewUrl = "";
        const searchParams = new URLSearchParams(location.search);
        const questionId = searchParams.get("question-id");
        if (location.pathname.includes("add")) {
            previewUrl = `/questionBuilder-preview?preview-id=${encodedFormData}&level-levelName=${levelName}&mode=add&question-id=${questionId}`;
        } else if (location.pathname.includes("edit")) {
            previewUrl = `/questionBuilder-preview?preview-id=${encodedFormData}&level-levelName=${levelTitle}&mode=edit`;
        }

        window.open(previewUrl, "_blank");
    };
    const generatePreview = async () => {
        let questionPreviewId = null;
        if (qtype.label == "Spelling") {
            const formData = new FormData();
            formData.append("qtype", qtype.value);
            formData.append("question[text]", questionValue);
            formData.append("answer", answer);

            formData.append("atype", 1);
            if (questionPreviewId) {
                formData.append("previewId", questionPreviewId);
            }
            const response = await insertPreviewQuestionSpelling(formData);
            if (response.success) {
                questionPreviewId = response.data.previewId;
            }
        }
        if (qtype.label == "Vocabulary") {
            const formData = new FormData();
            formData.append("qtype", qtype.value);
            formData.append("atype", 1);
            formData.append("question[text]", questionValue);
            optionsData.forEach((option, index) => {
                if (option.text) {
                    formData.append(`options[${index}][text]`, option.text);
                }
                formData.append(
                    `options[${index}][isCorrect]`,
                    option.isCorrect ? "true" : "false"
                );
            });
            if (questionPreviewId) {
                formData.append("previewId", questionPreviewId);
            }
            const response = await insertPreviewQuestionSpelling(formData);
            if (response.success) {
                questionPreviewId = response.data.previewId;
            }
        }
        return questionPreviewId;
    };

    return (
        <div className="question-container">
        <div>
          {loading && <Loader />}
          {!loading && (
            <>
              <Title titleContent={title} />
              <div className="builder-container">
                <div className="parent">
                  <div className="wrapper">
                    Level: <span id="parent-name">{title === "Add Word" ? levelName : levelTitle}</span>
                  </div>
                  <>
                    <Card className="card-container">
                      <div className="card-content">
                        <>
                          {questionConfig.vocabulary.includes("vocabulary") && (
                            <>
                              <div className="question-div">
                                {qtype.label === "Spelling" ? "Audio Sentence" : "Word"}
                                <QuestionComponent onQuestionChange={handleQuestionChange} />
                              </div>
      
                              <div className="question-div">
                                {qtype.label === "Spelling" ? "Word" : "Options"}
                                <OptionsComponent
                                  onSolutionChange={handleSoultionChange}
                                  onOptionsChange={handleOptionsChange}
                                  onPreview={handlePreviewClick}
                                />
                              </div>
                            </>
                          )}
                        </>
                      </div>
                    </Card>
                    <div className="container-footer">
                      {title === "Add Word" && (
                        <div className="cancel">
                          <button type="button" className="add-more" onClick={handleAddMore}>
                            + Add More
                          </button>
                        </div>
                      )}
                      <div className="submit" style={{ marginLeft: "1.8rem" }}>
                        <div className="form-action-buttons">
                          <ButtonGroup>
                            <Button
                              onClick={handleQuestionSave}
                              className={`save-question ${
                                showDropdownArrow ? "" : "save-question-rounded"
                              }`}
                            >
                              {title === "Edit Word" ? "Save Changes" : "Save Question"}
                            </Button>
                            {showDropdownArrow && (
                              <UncontrolledDropdown direction="up">
                                <DropdownToggle caret className="save-question-toggle" />
                                <DropdownMenu className="dropdown-menu-save-publish">
                                  <DropdownItem onClick={handleSaveAndPublish}>
                                    <span className="save-pubish-btn">Save & Publish</span>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            )}
                          </ButtonGroup>
                        </div>
                      </div>
                      {isErrorModalOpen && (
                        <div className={`modal-overlay ${isClosing ? "fade-out" : ""}`}>
                          <ValidationErrorModal />
                        </div>
                      )}
                      {showSaveModal && <QuestionSaveModal />}
                    </div>
                  </>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )    
}

export default QuestionBuilder;
