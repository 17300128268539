import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader } from "react-bootstrap";
import { ErrorModalStyled, CloseIcon } from "./ErrorModal.styled";
import closeIcon from "../../../Assests/Images/Vector.svg";

function ErrorModal({ showModal, onClose, errors }) {
    const errorList = typeof errors === 'string' ? ['Validation failed: Missing required fields', errors] : errors;
    return (
        <ErrorModalStyled show={showModal} onHide={onClose} centered style={{ padding: '1rem 0 1rem 1rem' }}>
            <ModalHeader style={{ border: 'none', color: 'red', padding: '0.5rem 1.5rem 0 1.5rem', fontSize: "1.1rem" }}>
                {errorList[0]}
                <CloseIcon src={closeIcon} alt="Close" onClick={onClose} />
            </ModalHeader>
            <Modal.Body style={{ padding: '0 0 1rem 1.5rem' }}>
                {errorList.length > 1 && errorList.slice(1).map((error, index) => (
                    <p key={index} style={{ marginBottom: '0', fontSize: '1rem' }}>{index + 1}.{error}</p>
                ))}
                {errorList.length === 0 && <p>An error occured trying to figure it out...!</p>}
            </Modal.Body>
        </ErrorModalStyled>
    );
}

ErrorModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ErrorModal;
