import Vocabulary from "Controller/Programs/SpellingAndVocabulary/Vocabulary";

/**
 * Configuration object for question types and their corresponding input fields
 * "questionTypes": {
            "Multi Select - Text Question with Image Answer": 1,
            "Multi Select - Text Question with Text Answer": 2,
            "Multi Select - Text Question with Text & Image Answer": 3,
            "Multi Select - Image Question with Image Answer": 4,
            "Multi Select - Image Question with Text Answer": 5,
            "Multi Select - Image Question with Text & Image Answer": 6,
            "Multi Select - Text & Image Question with Image Answer": 7,
            "Multi Select - Text & Image Question with Text Answer": 8,
            "Multi Select - Text & Image Question with Text & Image Answer": 9,
            "Single Select - Text Question with Image Answer": 10,
            "Single Select - Text Question with Text Answer": 11,
            "Single Select - Text Question with Text & Image Answer": 12,
            "Single Select - Image Question with Image Answer": 13,
            "Single Select - Image Question with Text Answer": 14,
            "Single Select - Image Question with Text & Image Answer": 15,
            "Single Select - Text & Image Question with Image Answer": 16,
            "Single Select - Text & Image Question with Text Answer": 17,
            "Single Select - Text & Image Question with Text & Image Answer": 18,
            "Text Answer - Text Question": 19,
            "Text Answer - Image Question": 20,
            "Text Answer - Text & Image Question": 21,
            "Text Question Fill in the blank": 22,
            "Text & Image Question Fill in the blank": 23,
            "Reading Comprehension": 24
        }
  *
 * @author - Gaurav
 */
const questionConfig = {
  answerInputBox: [22, 23],
  answerType: [1, 2, 3, 4],
  readingComprehension: [24],
  solutionBox: [19, 20, 21, 22, 23],
  multiSelect: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  singleSelect: [10, 11, 12, 13, 14, 15, 16, 17, 18],
  optionImage: [1, 3, 4, 6, 7, 9, 10, 12, 13, 15, 16, 18],
  questionImage: [4, 5, 6, 7, 8, 9, 13, 14, 15, 16, 17, 18, 20, 21, 23],
  questionInput: [1, 2, 3, 7, 8, 9, 10, 11, 12, 16, 17, 18, 19, 21, 22, 23,25],
  optionInput: [2, 3, 5, 6, 8, 9, 11, 12, 14, 15, 17, 18, 19, 20, 22, 23],
  writing:[25],
  spelling: ["spelling"],
  vocabulary: ["vocabulary"],
};

export default questionConfig;