/**
 * Utility function to get the formatted date in the 'en-GB' locale.
 * @param {Date} date - The date object to format.
 * @param {Object} options - Formatting options for Intl.DateTimeFormat.
 * @returns {string} Formatted date string.
 */
const formatDateIntl = (date, options) => new Intl.DateTimeFormat("en-GB", options).format(date);

/**
 * Utility function to get an ordinal suffix for a number.
 * @param {number} n - The number to get the ordinal suffix for.
 * @returns {string} Ordinal suffix (e.g., "st", "nd", "rd", "th").
 */
const getOrdinalSuffix = (n) => {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return s[(v - 20) % 10] || s[v] || s[0];
};

/**
 * Returns the current date and time formatted as a string.
 * The date is formatted in the "en-GB" locale as DD/MM/YYYY and the time as HH:MM:SS.
 * The date and time are separated by an underscore.
 *
 * @returns {string} The formatted date and time string.
 */
export const getFormattedCurrentDateTime = () => {
    const now = new Date();
    const date = formatDateIntl(now, { day: "2-digit", month: "2-digit", year: "numeric" });
    const time = formatDateIntl(now, { hour: "2-digit", minute: "2-digit", second: "2-digit" });
    return `${date}_${time}`;
};

/**
 * Converts the current UTC date and time to a formatted string in the form "Wed-07-Aug-2020, 10:30 pm".
 * @returns {string} A string representation of the current date and time in the user's locale.
 */
export const convertUTCToFormattedLocaleDateTime = () => {
    const now = new Date();
    const weekday = formatDateIntl(now, { weekday: "short" });
    const date = formatDateIntl(now, { day: "2-digit", month: "short", year: "numeric" });
    const time = formatDateIntl(now, { hour: "2-digit", minute: "2-digit", hour12: true });
    return `${weekday}-${date.replace(" ", "-")}, ${time.toLowerCase()}`;
};

/**
 * Formats a given timestamp into a string with the day including ordinal suffix, month, year, and time.
 * Example: "7th Aug, 2020 at 10:30 AM".
 *
 * @param {number} timestamp - A Unix timestamp.
 * @returns {string} Formatted string.
 */
export const formatDateWithOrdinal = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const dayWithOrdinal = `${day}${getOrdinalSuffix(day)}`;
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const time = date.toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    return `${dayWithOrdinal} ${month}, ${year} at ${time}`;
};

/**
 * Returns a relative time string between the given commentTime and the current time.
 * Example: "just now", "1 minute ago", "2 hours ago", "3 days ago".
 * @param {number} commentTime - A timestamp in milliseconds.
 * @returns {string} Relative time string.
 */
export const getRelativeTimeElapsed = (commentTime) => {
    const currentTime = new Date();
    const timeDifference = currentTime - new Date(commentTime);
    const seconds = Math.floor(timeDifference / 1000);

    if (seconds < 60) return "just now";
    if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    }
    if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    }
    const days = Math.floor(seconds / 86400);
    return `${days} day${days > 1 ? "s" : ""} ago`;
};
