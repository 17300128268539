import _ from "lodash";
import "./stylesOrganizations.css";
import DbConfig from "Constants/db";
import _debounce from "lodash/debounce";
import Title from "../../Components/Title/Title";
import Loader from "../../Components/Loader/Loader";
import pencil from "../../Assests/Images/pencil.svg";
import React, { useState, useEffect, useRef } from "react";
import deleteIcon from "../../Assests/Images/delete.svg";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "../../Components/DataTable/DataTable";
import EmptyDatatable from "Components/DataTable/EmptyDatatable";
// import NewFilterHeader from "Components/FilterHeader/NewFilterHeader";
import DeleteModal from "../../Components/Modals/DeleteModal/DeleteModal";
import ReactivateModal from "../../Components/Modals/ReactivateModal/ReactivateModal";
import AddOrganizationModal from "../../Components/Modals/Organizations/AddOrganizationModal";
import EditOrganizationModal from "../../Components/Modals/Organizations/EditOrganizationModal";
import { deleteOrganization, getAllOrganization, reactivateOrganization, getOrganizationCodes, getOrganizationTitles } from "../../Utils/ApiHandler/OrganizationsApi";

const pageFilters = [
  { name: "page", value: 1 },
  { name: "limit", value: 10 },
  { name: "active", value: true },
  { name: "title", value: [] },
  { name: "code", value: [] },
  { name: "search", value: "" },
  { name: "country", value: [] },
  { name: "state", value: [] },
  { name: "city", value: [] },
];
const Organizations = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const componentLoaded = useRef(false);
  const [pageCount, setPageCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [actionData, setActionData] = useState("");
  const [deleteRole, setDeleteRole] = useState("");
  const [filter, setFilter] = useState(pageFilters);
  const [isLoading, setIsLoading] = useState(true);
  const [organization, setOrganization] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [deleteModel, showDeleteModel] = useState(false);
  const [reactivateRole, setReactivateRole] = useState("");
  const [addOrganization, showAddOrganization] = useState(false);
  const [reactivateModel, showReactivateModel] = useState(false);
  const [editOrganization, showEditOrganization] = useState(false);
  const [organizationExists, setOrganizationExists] = useState(false);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
  const [showAddOrganizationButton, setShowAddOrganizationButton] = useState(true);
  const [organizationAccess, setOrganizationAccess] = useState(false);
  const [dataFilterStatus, setDataFilterStatus] = useState(true)
  const columns = ["Code", "Name", "Description", "City", "State", "Country", "ZipCode"];

  const handleAddOrganization = () => {
    showAddOrganization(true);
  }

  useEffect(() => {
    const fetchSettingsAccess = async () => {
      const settingsAccessData = await DbConfig.settingsAccess.toArray();
      if (settingsAccessData.length > 0) {
        setOrganizationAccess(settingsAccessData[0].organizations);
      }
    };
    fetchSettingsAccess();
  }, []);
  const filterStructure = {
    filters: [
      { name: 'code', label: 'Code', type: 'multi-select', inputHandler: getOrganizationCodes },
      { name: 'title', label: 'Name', type: 'multi-select', inputHandler: getOrganizationTitles },
    ],
    moreFilters: [
      { name: 'country', label: 'Country', type: 'multi-select' },
      { name: 'state', label: 'State', type: 'multi-select' },
      { name: 'city', label: 'City', type: 'mulit-select' },
    ]
  }


  const handlePageChange = (currentPage, itemsPerPage) => {
    setCurrentPage(currentPage);
    setItemsPerPage(itemsPerPage);
    setIsLoading(true);

    let previousFilter = filter.filter(
      (f) => f.name !== "page" && f.name !== "limit"
    );
    let updatedFilter = [
      ...previousFilter,
      {
        name: "page",
        value: currentPage,
      },
      {
        name: "limit",
        value: itemsPerPage,
      },
    ];
    setFilter(updatedFilter);
  };

  useEffect(() => {
    setCurrentPage(1);
    navigate(`/curriculum/organization/${currentPage}`)
  }, [filter]);

  const extractFields = (obj) => {
    return {
      code: obj.code,
      title: obj.title,
      description: obj.description,
      city: obj.city,
      state: obj.state,
      country: obj.country,
      zipCode: obj.zipCode,
      _id: obj._id,
      isActive: obj.isActive,
      // staus: obj.status
    };
  };


  const refreshDatatable = _.debounce(() => {
    setIsLoading(true);
    getAllOrganization(filter).then((data) => {
      setTableData(data.organizations.map(extractFields));
      setPageCount(data.totalPages);
      setIsLoading(false);
      if (data.organizations && data.organizations.length > 0) {
        setOrganizationExists(true);
      }
    });
  }, 1000)

  const handleDeleteAction = async (e) => {
    try {
      const data = {
        organizationId: actionData.organizationIds ?? actionData[0].organizationIds
      }
      await deleteOrganization(data);
      showDeleteModel(false);
      refreshDatatable();
    } catch (error) {
      console.error("Error deleting organization:", error);
    }
  };
  const handleEditOrganization = () => {
    showEditOrganization(true);
    actions.map((action) => {
      if (action.name == "editOrganization") {
        setActionData(action.data);
      }
    });
  };
  const handleDelete = () => {
    showDeleteModel(true);
    actions.map((action) => {
      if (action.name === "delete") {
        const data = [
          {
            title: action.data.title,
            organizationIds: action.data._id,
          },
        ];
        setActionData(data);
      }
    });
    setDeleteRole("Organization");
  };
  const handleActions = [
    {
      Label: (
        <>
          <img src={pencil} alt="Edit Icon" className="action-icon" />
          Edit
        </>
      ),
      name: "editOrganization",
      clickAction: handleEditOrganization,
      access: organizationAccess.edit
    },
    {
      Label: (
        <>
          <img src={deleteIcon} alt="Delete Icon" className="action-icon" />
          Delete
        </>
      ),
      name: "delete",
      clickAction: handleDelete,
      class: "text-delete",
      access: organizationAccess.edit
    }
  ];

  const actions = handleActions.map(action => ({
    Label: action.Label,
    name: action.name,
    clickAction: action.clickAction,
    access: action.access,
    class: action.class ?? ''
  }));

  useEffect(() => {
    if (!componentLoaded.current) {
      componentLoaded.current = true;
      refreshDatatable();
    }
  });
  useEffect(() => {
    refreshDatatable();
  }, [filter]);

  return (
    <>
      <Title titleContent="Organizations" className="title-container" showAddButton={false} />
      <div className="curriculum-all-topics">

        {organizationExists ? (
          <>
            {/* <NewFilterHeader
              filter={filter}
              setFilter={setFilter}
              filterStructure={filterStructure}
              filterDefaults={pageFilters}
              pageName="Organizations"
              createButtonText="Create organization"
              showCreateButton={organizationAccess.add}
              handleCreateButton={handleAddOrganization}
            // style={{marginLeft: "1rem"}}
            /> */}
            <DataTable
              columns={columns}
              data={tableData}
              actions={actions}
              totalCount={pageCount}
              filter={filter}
              setFilter={setFilter}

              dataStatus={false}
              handlePageChange={handlePageChange}
            />
            {addOrganization && (
              <AddOrganizationModal
                show={addOrganization}
                handleClose={() => showAddOrganization(false)}
                initialData={actionData}
                onSave={refreshDatatable}
              />
            )}
            {editOrganization && (
              <EditOrganizationModal
                show={editOrganization}
                handleClose={() => showEditOrganization(false)}
                initialData={actionData}
                onSave={refreshDatatable}
              />
            )}
            {deleteModel && (
              <DeleteModal
                showModal={deleteModel}
                role={deleteRole}
                onClose={() => showDeleteModel(false)}
                onSave={refreshDatatable}
                deleteData={actionData}
                onClick={handleDeleteAction}
              />
            )}
          </>
        ) : !isLoading && (<EmptyDatatable label="Organization" />)}
      </div>
      {isLoading && (<div className="spinner-overlay"><Loader /></div>)}
    </>

  );

}

export default Organizations