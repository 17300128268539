import { toast } from "react-toastify";
import { baseUrl, fetchToken } from "Constants/ApiUrls";

export const getSpellAndVocab = async (params) => {
    try {
        const { getApiEndPoint, active, status, levelId, searchKey, page, limit } = params;
        if (!levelId) return;
        const queryParams = new URLSearchParams();
        if (typeof active !== "undefined" && !active) queryParams.append("active", active);
        if (status !== undefined && active) queryParams.append("status", status);
        if (searchKey) queryParams.append("search", searchKey)
        if (page > 0) queryParams.append("page", page)
        if (limit > 0) queryParams.append("limit", limit)
        const url = `${baseUrl}/cms/v1/${getApiEndPoint}/${levelId}?${queryParams.toString()}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            // toast.error(result.message[0].msg || 'Failed to fetch data.');
            return false;
        }
        const { data } = result;
        return data;
    } catch (e) {
        toast.error('Something went wrong while fetching spellings!');
        throw new Error("An error occurred while fetching spellings: ", e);
    }
};
