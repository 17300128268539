import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import questionPreviewConfig from "Controller/Curriculum/Questions/QuestionPreviewBuilder/questionPreviewConfig";
import speaker from "../../../../Assests/Images/speaker.svg";
import { parseLatex, parseLatexText } from "Utils/LatexUtils";
import activeSpeaker from "../../../../Assests/Images/active-speaker.svg";
import { Card } from "react-bootstrap";

function PreviewOptionsComponent() {
    const qtype = useSelector((state) => state.qtype.qtype);
    const optionText = useSelector((state) => state.options.options);
    const answer = useSelector((state) => state.options.answer);
    const [speakingIndex, setSpeakingIndex] = useState(null);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [rate, setRate] = useState(1);
    const [pitch, setPitch] = useState(1);
    var voice = speechSynthesis.getVoices()[3];

    const handleSpeak = (option, index) => {
        if ("speechSynthesis" in window) {
            const stripHTMLTags = (text) => {
                const div = document.createElement("div");
                div.innerHTML = text;
                return div.textContent || div.innerText || "";
            };
            const cleanOption = stripHTMLTags(option).replace(
                /<#@blank#>/g,
                "blank"
            );
            const utterance = new SpeechSynthesisUtterance(
                questionPreviewConfig.fillInTheBlanks.includes(19)
                    ? parseLatexText(cleanOption.replace(/_/g, "dash"))
                    : parseLatexText(cleanOption)
            );
            utterance.voice = voice;
            utterance.pitch = pitch;
            utterance.rate = rate;
            if (speechSynthesis.speaking) {
                speechSynthesis.cancel();
                setSpeakingIndex(null);
            } else {
                setSpeakingIndex(index);
                speechSynthesis.speak(utterance);
                utterance.onend = () => setSpeakingIndex(null);
            }
        }
    };

    const handleOptionSelect = (index) => {
        setSelectedOptionIndex(index);
    };

    useEffect(() => {
        const preselectedOptions = optionText
            .map((option, index) => (option.isCorrect ? index : null))
            .filter((index) => index !== null);
        setSelectedOptions(preselectedOptions);
    }, [optionText]);

    const capitalizeFirstForTrueFalse = (text) => {
        if (typeof text === "string") {
            const trimmedText = text.trim();
            const lowerText = trimmedText.toLowerCase();
            if (lowerText === "true" || lowerText === "false") {
                return lowerText.toUpperCase();
            }
            return trimmedText;
        }
        return text;
    };

    return (
        <Card className="border-0">
            <Card.Title className="option-container" >
                <Card.Text>
                    {qtype.label === 'Spelling' && (
                        <span
                            className="preview-options"
                            style={{ marginLeft: "3rem" }}
                        >
                            <input
                                type="text"
                                className="text-options"
                                placeholder="Enter answer"
                                value={answer}
                                readOnly
                            />
                        </span>
                    )}

                    <Card.Title>
                        {qtype.label === 'Vocabulary' &&
                            optionText.map((option, index) => (
                                <span
                                    key={index}
                                    className="speaker-before-option"
                                    style={{
                                        marginLeft: "1.9rem",
                                        marginTop: "0.5rem",
                                    }}
                                >
                                    <Card.Text className="text-and-speaker">
                                        <span
                                            className="speaker-icon"
                                            onClick={() =>
                                                handleSpeak(option.text, index)
                                            }
                                        >
                                            <img
                                                src={
                                                    speakingIndex === index
                                                        ? activeSpeaker
                                                        : speaker
                                                }
                                                alt="Speaker"
                                                style={{ marginTop: "1rem" }}
                                            />
                                        </span>
                                        <span
                                            className={`text-options ${selectedOptionIndex === index
                                                    ? "selected"
                                                    : ""
                                                }`}
                                            style={{ marginLeft: "0.7rem" }}
                                            onClick={() =>
                                                handleOptionSelect(index)
                                            }
                                        >
                                            <input
                                                type="radio"
                                                className={`text-content ${selectedOptions.includes(
                                                    index
                                                )
                                                        ? "selected"
                                                        : ""
                                                    }`}
                                                name="singleSelectOption"
                                                onChange={() =>
                                                    handleOptionSelect(index)
                                                }
                                                checked={selectedOptions.includes(
                                                    index
                                                )}
                                            />
                                            <span className="text-content">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: parseLatex(
                                                            capitalizeFirstForTrueFalse(
                                                                option.text
                                                            )
                                                        ),
                                                    }}
                                                    style={{
                                                        marginLeft: "1rem",
                                                    }}
                                                />
                                            </span>
                                        </span>
                                    </Card.Text>
                                </span>
                            ))}
                    </Card.Title>
                </Card.Text>
            </Card.Title>
        </Card>
    );
}

export default PreviewOptionsComponent;
