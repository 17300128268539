import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";

import _ from "lodash";
import DbConfig from "Constants/db";
import Title from "../../Components/Title/Title";
import Loader from "../../Components/Loader/Loader";
import pencil from "../../Assests/Images/pencil.svg";
import logo from "../../Assests/Images/empty-box.svg";
import deleteIcon from "../../Assests/Images/delete.svg";
import DataTable from "../../Components/DataTable/DataTable";
import FilterHeader from "../../Components/FilterHeader/FilterHeader";
import DeleteModal from "../../Components/Modals/DeleteModal/DeleteModal";
import { getAllRoles, getRoles, handleDeleteRole, getOrganizationTitles } from "../../Utils/ApiHandler/RolesApi";
import { organizationTitlesUrl, getRolesUrl } from "Constants/ApiUrls";

const columns = ["Role", "Organization", "Description", "Active users", "Access"];

function Roles() {

  const { page } = useParams();
  const navigate = useNavigate();

  const [roles, setRoles] = useState([]);
  const [isBlur, setIsBlur] = useState(false);
  const [deleteRole, setDeleteRole] = useState();
  const [tableData, setTableData] = useState([]);
  const [inputRole, setInputRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [actionData, setActionData] = useState('');
  const [dataFetched, setDataFetched] = useState(true);
  const [rolesAccess, setRolesAccess] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [searchOptions, setSearchOptions] = useState(true);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [inputOrganization, setInputOrganization] = useState('');
  const [dataFilterStatus, setDataFilterStatus] = useState(true);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
  const [goToPage, setGoToPage] = useState(0);
  const [filter, setFilter] = useState([
    { name: "page", value: currentPage },
    { name: "limit", value: 10 },
    { name: "active", value: true },
    { name: "search", value: "" },
    { name: "organizationIds", value: [] },
    { name: "roleId", value: [] }
  ]);
  const settingsAccess = async () => {
    const settingsAccessData = await DbConfig.settingsAccess.toArray();
    setRolesAccess(settingsAccessData.map(item => item.roles));
  };

  useEffect(() => {
    settingsAccess();
  }, []);

  useEffect(() => {
    refreshDatatable();
  }, [filter]);

  useEffect(() => {
    if (goToPage > 1) {
      setCurrentPage(goToPage);
    }
    else {
      setCurrentPage(1);
    }
    navigate(`/roles/${currentPage}`)
  }, [filter, goToPage])

  useEffect(() => {
    var url;
    if (inputOrganization) {
      url = `${organizationTitlesUrl}`
      fetchData('organization', url);
    }
    if (inputRole) {
      url = `${getRolesUrl}${inputRole}`
      fetchData('roles', url, `?active=${dataFilterStatus}`);
    }
  }, [inputRole, inputOrganization, dataFilterStatus]);

  const handleActions = (type) => {
    const action = actions.find(action => action.name === type);
    if (!action) return;
    if (type === "delete") {
      setIsBlur(true);
      setShowDeleteModel(true);
      const data = [{
        title: action.data.role,
        roleIds: [action.data._id],
      }];
      setActionData(data);
      setDeleteRole("Role");
    }
    if (type === "edit") {
      setActionData(action.data);
      const { access, role, organization, organizationId, description, curriculum, settings, _id } = action.data;
      const encodedData = encodeURIComponent(JSON.stringify({
        access, role, organizationName: organization, organization: organizationId, description, curriculum, settings, roleId: _id,
      }));
      navigate(`/edit-role?data=${encodedData}`);
    }
  };

  const actions = [
    {
      Label: (
        <><img src={pencil} alt="Edit Icon" className="action-icon" />Edit</>
      ),
      name: "edit",
      clickAction: (() => handleActions("edit")),
      access: rolesAccess[0]?.edit
    }, {
      Label: (
        <><img src={deleteIcon} alt="Delete Icon" className="action-icon" />Delete</>
      ),
      name: "delete",
      class: "text-delete",
      clickAction: (() => handleActions("delete")),
      access: rolesAccess[0]?.delete
    }
  ]

  const refreshDatatable = _.debounce(() => {
    if (filter.search !== "") {
      setIsLoading(true);
      setIsBlur(false);
      getAllRoles(filter).then((data) => {
        setTableData(data.rolesAndTemplates.map(extractFields));
        setTotalPageCount(data.totalPages);
        setIsLoading(false);
        if (data.rolesAndTemplates && data.rolesAndTemplates.length > 0) {
          setDataFetched(true);
        }
      });
    }
  }, 1000);

  const handleInputChange = useCallback(
    _.debounce((input, type) => {
      if (type === 'organization') {
        setInputOrganization(input || "");
      } else if (type === 'role') {
        setInputRole(input || "");
      }
    }, 1000), []);

  const fetchData = useCallback(
    _.debounce(async (type, url, queryParam) => {
      if (type === 'organization') {
        const organizationTitles = await getOrganizationTitles(url);
        setOrganizations(organizationTitles);
        setSearchOptions(false);
      } else if (type === 'roles') {
        if (!queryParam) return;
        const roleUrl = `${url}${queryParam}`;
        const result = await getRoles(roleUrl);
        setRoles(result);
        setSearchOptions(false);
      }
    }, 1000), []);

  const handleFilterChange = (type, selectedOptions) => {
    const value = selectedOptions.map(option => option.id || option.value);
    const updatedFilter = filter
      .filter(f => f.name !== type)
      .concat({ name: type, value });
    setFilter(updatedFilter);
  };

  const handleDeleteAction = async () => {
    const roleIds = actionData.roleIds || actionData[0]?.roleIds;
    if (!roleIds) return;
    await handleDeleteRole({ roleIds });
    setShowDeleteModel(false);
    setIsLoading(true);
    refreshDatatable();
  };

  const handlePageChange = (currentPage, itemsPerPage) => {
    setIsLoading(true);
    setIsBlur(true);
    setCurrentPage(currentPage);
    setGoToPage(0);
    const updatedFilter = filter
      .filter(f => f.name !== "page" && f.name !== "limit")
      .concat(
        { name: "page", value: currentPage },
        { name: "limit", value: itemsPerPage }
      );
    setFilter(updatedFilter);
  };

  const handleResetFilter = () => {
    let updatedFilter = [
      { name: "page", value: 1 },
      { name: "limit", value: 10 },
      { name: "active", value: dataFilterStatus },
      { name: "search", value: "" },
      { name: "organizationIds", value: [] },
      { name: "roleId", value: [] }
    ];
    setFilter(updatedFilter);
    setOrganizations([]);
    setRoles([]);
    setIsLoading(true);
  };

  const closeModal = (showModal) => {
    setIsBlur(false);
    showModal(false);
  };

  const extractFields = (obj) => {
    return {
      role: obj.roleName,
      organization: obj.organization?.title ?? '',
      description: obj.description,
      activeUsers: obj.activeUsers,
      access: obj.access,
      _id: obj._id,
      settings: obj.settings,
      curriculum: obj.curriculum,
      organizationId: obj.organization?._id ?? ''
    };
  };
  return (
    <>
      <div className={`container-fluid ${isBlur ? 'blur' : ''}`}>
        {dataFetched ? (
          <>
            <Title titleContent="Roles" className="title-container" />
            <div className="settings-Container">
              <FilterHeader
                firstDropdown={organizations}
                secondDropdown={roles}
                firstText="Organization"
                secondText="Roles"
                onInputCodeChange={(input) => handleInputChange(input, 'organization')}
                onInputTitleChange={(input) => handleInputChange(input, 'role')}
                onCodeChange={(selectedOptions) => handleFilterChange("organizationIds", selectedOptions)}
                resetFilter={handleResetFilter}
                onTitleChange={(selectedOptions) => handleFilterChange("roleId", selectedOptions)}
                filter={filter}
                setFilter={setFilter}
                showTitleDropdown={true}
                setDataFilterStatus={setDataFilterStatus}
                showCreateButton={rolesAccess[0]?.add}
                handleCreateButton={() => navigate(`/create-new-role`)}
                createButtonText="Create role"
                pageName="Roles"
                searchOptions={searchOptions}
                setSearchOptions={setSearchOptions}
                setGoToPage={setGoToPage}
                isPageChange={goToPage} />
              <DataTable
                columns={columns}
                data={tableData}
                actions={actions}
                totalCount={totalPageCount}
                filter={filter}
                setFilter={setFilter}
                setGoToPage={setGoToPage}
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
              {showDeleteModel && <DeleteModal
                showModal={showDeleteModel}
                role={deleteRole}
                onClose={() => closeModal(setShowDeleteModel)}
                onSave={refreshDatatable}
                deleteData={actionData}
                onClick={handleDeleteAction} />}
            </div>
          </>
        ) : !isLoading && (
          <div className="empty-container">
            <img src={logo} alt="Logo" style={{ display: "block", margin: "0 auto" }} />
            <p className="text">You haven't added any roles yet.!</p>
            <p className="sub-text">Click below button to add your first role.</p>
            <button type="button" className="add-button">+ Add New Role</button>
          </div>
        )}
      </div>
      {isLoading && (<div className="spinner-overlay"><Loader /></div>)}
    </>
  );
}

export default Roles;