import styled from "styled-components";

export const ErrorMessageWrapper = styled.div`
  position: relative;
  top: -5.5rem;
  color: #E4434A;
  text-shadow: 0 0 5px black, 0 0 25px black, 0 0 50px black, 0 0 200px black;
  font-size: 1.2rem;
  font-weight: 600;
`;
