import styled, { keyframes } from "styled-components";
import { Modal, Form } from "react-bootstrap";

export const ModalButton = styled.button`
  width: 100%;
  height: 3rem;
  border-radius: 0.25rem;
  background-color: ${(props) => (props.isClose ? "var(--white-color)" : "var(--blue-color)")};
  color: ${(props) => (props.isClose ? "var(--blue-color)" : "var(--white-color)")};
  margin: 2px 5px 5px 25px;
  border: ${(props) =>
    props.isClose ? "1px solid var(--blue-color);" : "0px solid #D8D8D8;"};
  font-size: 1rem
  font-weight: 600;
  line-height: 1.125rem;
  margin-right: 20px;
  opacity: 1;
  font-weight: bold;
  display: inline-flex;
  padding: 0.9375rem 2rem;
  gap: 0.625rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;  
  flex-shrink: 0;
  white-space: nowrap;
`;

const slideInAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  margin-left: auto;
`;

export const CustomModal = styled(Modal)`
  & .modal-dialog {
    width: 435px;
    margin: 0;
    left: auto;
    right: 0;
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    animation: ${slideInAnimation} 0.3s ease-in-out;
  }

  & .modal-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 0;
  }

  & .modal-body {
    overflow-y: auto;
    max-height: 100%;
  }

  & .modal-footer {
    padding: 10px 25px 0px 0px;
  }

  &.sidebarModal .modal-dialog {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 2560px) {
    & .modal-dialog {
      width: 50%;
    }
    &.sidebarModal .modal-dialog {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 1024px) {
    & .modal-dialog {
      width: auto;
    }
    &.sidebarModal .modal-dialog {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 728px) {
    & .modal-dialog {
      width: auto;
    }
    &.sidebarModal .modal-dialog {
      margin-left: auto;
      margin-right: auto;
    }

    & .modal-footer {
      padding: 20px;
    }
  }

  @media (max-width: 425px) {
    & .modal-dialog {
      width: auto;
    }
    &.sidebarModal .modal-dialog {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

CustomModal.defaultProps = {
  backdrop: false,
};

export const CustomModalHeader = styled(Modal.Header)`
  background-color: var(--blue-color);
  color: var(--white-color);
  font-family: var(--font-family);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 435px;
  height: 63px;
  padding: 30px;
  flex-shrink: 0;
  border: none;
  border-radius: 0;
  .modal-title {
  color: var(--white-color);
  }
  }
`;

export const CustomModalFooter = styled(Modal.Footer)`
  height: 8.1875rem;
`;

export const CustomForm = styled(Form)`
  color: var(--black-color);
  font-family: var(--font-family);
  font-size: var(--font-size-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 1rem;

  & .form-control {
    font-size: var(--font-size-small);
    width: 22.9375rem;
    height: 3rem;
    border-radius: 0.25rem;
    border: px solid #d9d9d9;
    background: var(--light-gray-color);
    margin-bottom: 20px;
    font-family: var(--font-family);
    padding-left:10px !important;
    padding-top:0.5rem !important;
   
  }
  
  & .form-control::placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-small);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 8px;
    padding-left:0px;
    margin-left:0px;
    color: var(--dark-gray-color);
    text-align:left !important
    vertical-align: left !important; 
  }
  
  & .form-select {
    font-size: var(--font-size-small);
    position: relative;
    width: 22.9375rem;
    height: 3rem;
    margin-bottom: 20px;
    background-color: var(--light-gray-color);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  & .form-control:focus {
    border-color: var(--sky-blue-color);
    border-style: solid;
    border-width: 2px;
    box-shadow: none; 
  }

  .textareaStyle {
    border-radius: 0.25rem;
    border: 1px solid #E6E6E6;
    background: #FBFBFB;
    font-family: var(--font-family);
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
    overflow-x: hidden;
    width: 22.9375rem;
    height: 4.3125rem;
    flex-shrink: 0;
    padding:10px;
    resize: none;
    outline: none;
    ::-webkit-scrollbar {
      width: 0;
    }
    scrollbar-width: none; 
    }
  }

  & .form-select::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--black-color);
  }

  & .form-select option {
    background-color: #ffffff;
    width: 40rem; !important;
    height: 20rem;
    padding: 10rem !important;
    color: #000000;
    font-size: 14px;
  }
  
  & .form-select option:hover {
    padding: 10rem !important;
    background-color: #f0f0f0;
    color: #000000;
  }
  
  & .form-select option:selected {
    padding: 10rem !important;
    background-color: #007bff;
    color: #ffffff;
  }
  
`;

export const DisabledInput = styled(Form.Control)`
  cursor: not-allowed;

  &:hover {
    cursor: not-allowed !important;
    background-color: #f8f9fa;
  }
`;