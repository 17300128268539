import { bulkImport } from 'Utils/ApiHandler/BulkImport';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { reactivateAction } from 'Utils/ApiHandler/DatatableActionServices/DatatableActionServices';

const initialState = {
    key: null,
    error: null,
    endPoint: null,
    loading: false,
    itemName: null,
    itemTitle: null,
    isReactivateModalOpen: false,
};

export const reactivateActionCall = createAsyncThunk(
    'reactivateAction/reactivate',
    async (params, { rejectWithValue }) => {
        try {
            const result = await reactivateAction(params);
            return result.success
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
const reactivateActionSlice = createSlice({
    name: 'reactivateAction',
    initialState,
    reducers: {
        openReactivateModal: (state) => {
            state.isReactivateModalOpen = true;
        },
        closeReactivateModal: (state) => {
            state.isReactivateModalOpen = false;
        },
        passReactivateItemData: (state, action) => {
            state.itemTitle = action.payload.title;
            state.itemName = action.payload.name;
            state.itemId = action.payload.id;
            state.key = action.payload.key;
            state.endPoint = action.payload.endPoint;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(reactivateActionCall.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(reactivateActionCall.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(reactivateActionCall.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});
export const { openReactivateModal, closeReactivateModal, passReactivateItemData } = reactivateActionSlice.actions;

export default reactivateActionSlice.reducer;
