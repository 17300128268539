import React from 'react'
import PropTypes from 'prop-types';
import logo from "Assests/Images/empty-box.svg";

const EmptyDatatable = ({ label, action }) => {
    return (
        <div className="empty-container">
            <img src={logo} alt="Logo" style={{ height: "7.5rem", width: "7.5rem", display: "block", margin: "1rem auto" }} />
            <p className="text-center" style={{ color: "#014751", fontFamily: "Inter", fontSize: "1.25rem", fontWeight: "600", lineHeight: "normal" }}> You haven't added any {label}s yet.!</p>
            <p className="text-center" style={{ color: "#5B5B5B", fontFamily: "Inter", fontSize: "1rem", fontWeight: "400", lineHeight: "normal" }}>Click add {label} button to add your first {label}.</p>
        </div>
    )
}

EmptyDatatable.propTypes = {
    label: PropTypes.string.isRequired,
    action: PropTypes.func
};
EmptyDatatable.defaultProps = {
    label: 'Level',
    action: () => { console.log('add button clicked!') }
};
export default React.memo(EmptyDatatable);