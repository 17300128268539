import { fetchToken } from "Constants/ApiUrls";
import { toast } from "react-toastify";

var token = fetchToken();
const baseUrl = process.env.REACT_APP_BASE_URL;

export const skillCodesUrl = `${baseUrl}/cms/v1/getSkillCodes/`;
export const skillTitlesUrl = `${baseUrl}/cms/v2/getSkillTitles/`;

export const getAllSkills = async (filterData) => {
    token = fetchToken();
    const filterParams = filterData.map((filter) => {
        if (typeof filter.value == "object" && filter.value.length > 0) {
            let values = "";
            if (filter.name === "title" || filter.name === "code" || filter.name === "domainIds"
                || filter.name === "levelIds" || filter.name === "flag" || filter.name === "status" 
                || filter.name === "limit" || filter.name === "topicIds" || filter.name === "programIds" 
                || filter.name ==="organizationIds") {
                values = filter.value.map(
                    (value) => `${filter.name}[]=${value}`
                );
            } else {
                values = filter.value.map(
                    (value) => `${filter.name}[]=${value.id}`
                );
            }
            return values.join("&");
        } else {
            return `${filter.name}=${filter.value}`;
        }
    });
    const url = `${baseUrl}/cms/v2/getAllSkills` + "?" + filterParams.join("&");
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message);
        return { levels: [], totalPages: 0 };
    } else {
        let responseData = await response.json();
        return responseData.data;
    }
};

export const deleteSkill = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/deleteSkill`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Deleted successfully");
        return responseData.data;
    } catch (error) {
        throw new Error(error);
    }
};

export const addFlag = async (data, flag = true) => {
    try {
        const url = `${baseUrl}/cms/v1/addCommentToSkill?flag=${flag}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Flag added successfully");
        return responseData.data;
    } catch (error) {
        throw new Error(error);
    }
};

export const addFlagForBulkForSkill = async (data, flag = true) => {
    try {
        const url = `${baseUrl}/cms/v2/addCommentToSkill?flag=${flag}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Bulk Flagged Succesfully.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!')
        throw new Error(error);
    }
};

export const getById = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/getSkillById/${data}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        return responseData.data;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const updateSkill = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/updateSkill`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Updated Successfully");
        return result;
    } catch (error) {
        throw new Error(error);
    }
};

export const unflagSkill = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/skillUnflag`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Flag removed successfully");
        return result;
    } catch (error) {
        throw new Error(error);
    }
};

export const unFlagSkillForBulk = async (data) => {
    try {
        const url = `${baseUrl}/cms/v2/skillUnflag`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Flag removed successfully");
        return result;
    } catch (error) {
        throw new Error(error);
    }
};

export const updateBulkStatus = async (publish, data) => {
    try {
        let url = "";
        if (publish) {
            url = `${baseUrl}/cms/v1/publishSkill`;
        } else {
            url = `${baseUrl}/cms/v1/unPublishSkill`;
        }
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Status updated successfully");
        return responseData.data;
    } catch (error) {
        throw new Error(error);
    }
};

export const reactivateSkill = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/reactivateSkill`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Reactivated successfully");
        return responseData.data;
    } catch (error) {
        throw new Error(error);
    }
};

export const bulkUpload = async (data) => {
    try {
        const url = `${baseUrl}/cms/v2/bulkUploadQuestion`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        const responseMsg = {
            msg: "",
            status: true,
        };
        const result = await response.json();
        if (!response.ok || !result.success) {
            if (Array.isArray(result.message)) {
                return result.message;
            } else {
                toast.error(result.message);
                return;
            }
        }
        responseMsg.msg = "success";
        responseMsg.status = true;
        toast.success("Uploaded successfully");
        return responseMsg;
    } catch (error) {
        throw new Error(error);
    }
};

export const bulkReadingComprehensionUpload = async (data) => {
    try {
        const url = `${baseUrl}/cms/v2/bulkUploadReadingComprehension`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        const responseMsg = {
            msg: "",
            status: true,
        };
        const result = await response.json();
        if (!response.ok || !result.success) {
            if (Array.isArray(result.message)) {
                return result.message;
            } else {
                toast.error(result.message);
                return;
            }
        }
        responseMsg.msg = "success";
        responseMsg.status = true;
        toast.success("Uploaded successfully");
        return responseMsg;
    } catch (error) {
        throw new Error(error);
    }
};

export const getSkillCodes = async (url) => {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        if (result.success) {
            const data = result.data.skillCodes.map((skill) => ({
                label: skill.code,
                id: skill._id,
                value: skill.code,
            }));
            return data;
        } else {
            toast.error(result.message);
            return;
        }
    } catch (error) {
        throw new Error(error);
    }
};

export const getSkillTitles = async (url) => {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        if (result.success) {
            return result;
        } else {
            toast.error(result.message);
            return;
        }
    } catch (error) {
        throw new Error(error);
    }
};

export const videoUpload = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/uploadExlapinatonVideo`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        const responseMsg = {
            msg: "",
            status: true,
        };
        const result = await response.json();
        if (!response.ok || !result.success) {
            let msgArr = [];
            console.log(result.errors);
            if (Array.isArray(result.errors)) {
                let errors = result.errors;
                errors.forEach((error) => {
                    msgArr.push(error.msg)
                });
            } else {
                msgArr = ["Something went wrong"];
            }
            responseMsg.msg = msgArr;
            responseMsg.status = false;
        }
        else {
            responseMsg.status = true;
            toast.success("Video uploaded successfully");
        }
        return responseMsg;
    } catch (error) {
        throw new Error(error);
    }
};

export const publishSkills = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/publishSkill`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Skill Published.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
}

export const unPublishSkills = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/unPublishSkill`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Skill Unpublished.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
}
