import React, { useState, useEffect } from "react";
import closeIcon from "../../../Assests/Images/closeIcon.svg";
import { Button, Modal} from "react-bootstrap";
import { UploadErrorStyledModal, CloseIcon, CustomModalHeader,
    CustomModalBody,CustomModalFooter
 } from "./QuestionsBulkUploadErrorModal.styled";

const QuestionsBulkUploadErrorModal = ({showModal, onClose,error,setIsBlur} ) => {
    const [bodyContent,setBodyContent] = useState(null);

    useEffect(() => {
      try {
        if(error) {
          setIsBlur(true)
          const content = error.map((eachError, index) => (
            <div key={index} style={{ fontSize: "1rem" }}>
              {eachError}
            </div>
          ));
          setBodyContent(content);
        } else {
            const content = <div style={{ fontSize: "1rem" }}>
              Something went wrong, Please try again after some time.
            </div>
          setBodyContent(content);
        }
        
        
      } catch (e) {
        console.error("Error while rendering error messages:", e);
        setBodyContent(<div>{error}</div>);
      }
    }, []);

    return(
        <> 
        <UploadErrorStyledModal show={showModal} onHide={onClose} centered>
          <CustomModalHeader >
                    <Modal.Title style={{fontSize:"1.125rem", fontWeight:"600"}}>Error While Uploading</Modal.Title>
                    <CloseIcon src={closeIcon} alt="Close" onClick={onClose} />
                </CustomModalHeader>
                <CustomModalBody>
          {bodyContent}
        </CustomModalBody>
            <CustomModalFooter>
                <Button style={{backgroundColor:"#E62753",
                  borderColor:"#E62753", 
                  fontSize:"1.125rem", 
                  fontWeight:"500"}}
                    onClick={onClose}>ok</Button>
            </CustomModalFooter>
          </UploadErrorStyledModal>
        </>
    );
}

export default QuestionsBulkUploadErrorModal;