import questionConfig from './questionConfig';
import React, { useState, useEffect, useRef } from 'react';
import TrashCan from "Assests/Images/trash-can.png";
import gallery from "../../../../Assests/Images/gallery.svg";
import { TbChevronsDownLeft } from 'react-icons/tb';
import CKEditorComponent from './CKEditorComponent';

/**
 * Question 
 * 
 * Component for rendering the question input field based on question type
 * 
 * @param {Object} props - Component props
 * @param {string} props.questionType - Type of the question
 * 
 * @returns {JSX.Element} - Rendered component
 * 
 * @author - Gaurav
 */
const Question = ({ questionType, showMath, onQuestionChange, onImageUpload, questionFields, questionImage, setQuestionImage, showGallery, latexQuestionValue, setLatexQuestionValue,  answerField,
    onAnswerExplanationChange, isQuestionEditorActive , isAnswerField}) => {
    const fileInputRef = useRef(null);
    const latexInputReference = useRef(null);
    const questionInputReference = useRef(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [questionValue, setQuestionValue] = useState(questionFields);
    const [mathFieldAdded, setMathFieldAdded] = useState(false);
    useEffect(() => {
        if (showMath && !mathFieldAdded) {
            setMathFieldAdded(true);
        }
    }, [showMath, mathFieldAdded]);

    useEffect(() => {
        if (mathFieldAdded && latexInputReference.current) {
            const handleFocusOut = (evt) => window.mathVirtualKeyboard.hide();
            latexInputReference.current.addEventListener("focusout", handleFocusOut);
        
            return () => {
                if (latexInputReference.current) {
                    latexInputReference.current.removeEventListener("focusout", handleFocusOut);
                }
            };
        }
    }, [mathFieldAdded, latexInputReference]);
    

    useEffect(() => {
        setQuestionValue(questionFields);
   }, [onQuestionChange]);


    useEffect(() => {
        if (questionConfig.questionImage.includes(questionType)) {
            return;
        }
        if (showMath && questionInputReference.current) {
            let latexFound = false;
            const cursorPosition = questionInputReference.current.selectionStart;
            const tagRegex = new RegExp('<math-field>(.*?)</math-field>', 'g');
            let match;
            while ((match = tagRegex.exec(questionValue)) !== null) {
                const tagStartIndex = match.index;
                const tagEndIndex = tagStartIndex + match[0].length;
                if (cursorPosition >= tagStartIndex && cursorPosition <= tagEndIndex) {
                    latexFound = true;
                    setLatexQuestionValue(match[1]);
                    latexInputReference.current.executeCommand('moveToMathfieldEnd');
                }
            }
            if (!latexFound) {
                setLatexQuestionValue('');
            }
        }
    }, [showMath, questionConfig.questionImage, questionType, questionValue]);

    /**
     * Function to handle gallery click event
     * 
     * @author - Gaurav
     */
    const handleGalleryClick = () => {
        const fileInput = document.getElementById(`file-input`);
        if (fileInput) {
            fileInput.click();
        }
    };

    /**
     * Function to handle file input change event
     * 
     * @param {Event} event - The input change event
     * 
     * @author - Gaurav
     */
    const handleUploadImage = (e) => {
        const file = e.target.files[0];
        if (!file) {
            setErrorMessage("Please select an image file.");
            return;
        }
        const imageType = /^image\//;
        if (!imageType.test(file.type)) {
            setErrorMessage("Please select a valid image file.");
            return;
        }
        const image = new Image();
        image.onload = function () {
            const validSizes = [
                { width: 150, height: 150 },
                { width: 400, height: 400 }
            ];
            const isValidSize = validSizes.some(
                (size) => this.width === size.width && this.height === size.height
            );
            if (!isValidSize) {
                setErrorMessage(`Image must be of 150px*150px or 400px*400px resolution`);
                return;
            }
            fileInputRef.current.value = null;
            setQuestionImage(file);
            onImageUpload(file);
            setErrorMessage("");
        };
        image.src = URL.createObjectURL(file);
    };




    /**
 * Function to handle question input change
 * 
 * @param {Event} e - The input change event
 * @author - Gaurav
 */
    const handleQuestionChange = (e) => {
        const value = e.target.value;
        let updatedValue = value;
        setQuestionValue(updatedValue);
        onQuestionChange(updatedValue);
    };
    const handleLatexChange = (e) => {
        const value = e.target.value;
        var updatedValue = '';
        var latexFound = false;
        const cursorPosition = questionInputReference.current.selectionStart;
        const tagRegex = new RegExp('<math-field>(.*?)</math-field>', 'g');
        let match;
        while ((match = tagRegex.exec(questionValue)) !== null) {
            const tagStartIndex = match.index;
            const tagEndIndex = tagStartIndex + match[0].length;
            if (cursorPosition >= tagStartIndex && cursorPosition <= tagEndIndex) {
                latexFound = true;
                updatedValue = questionValue.substring(0, tagStartIndex) +
                    '<math-field>' + value + '</math-field>' +
                    questionValue.substring(tagEndIndex);

            }
        }
        if (!latexFound) {
            updatedValue = questionValue.substring(0, cursorPosition) +
                '<math-field>' + value + '</math-field>' +
                questionValue.substring(cursorPosition);
        }
        setLatexQuestionValue(value);
        setQuestionValue(updatedValue);
        onQuestionChange(updatedValue);
    };

    /**
     * handleDeleteImage
     * 
     * Function to handle delete image
     * 
     * @param {Event} event - The click event
     * 
     * @author - Gaurav
    */
    const handleDeleteImage = (event) => {
        event.stopPropagation();
        setQuestionImage(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const handleDeleteQuestion = (event) => {
        event.stopPropagation();
        let updatedValue = '';
        setQuestionValue(updatedValue);
        onQuestionChange(updatedValue);
    };

    function handleExplanationChange(data) {
        setQuestionValue(data);
        onAnswerExplanationChange(data) 
    }

    const autoResize = (e) => {
        const textarea = e.target;
        textarea.style.height = 'auto'; 
        textarea.style.height = textarea.scrollHeight + 'px'; 
      };
    
    useEffect(()=> {
        setLatexQuestionValue(questionFields);
    },[isQuestionEditorActive])

    return (
        <div className="question-field">
    {errorMessage && <div className="error-message">{errorMessage}</div>}
    {!isQuestionEditorActive ? (
      <>
        {questionConfig.questionInput.includes(questionType?.value) && (
          <div>
            {questionType?.value === 25 ? (
              <textarea
                id="input-fields"
                className="multi-line"
                type="text"
                placeholder={"Add Question"}
                ref={questionInputReference}
                value={questionFields ? questionFields : ""}
                onChange={handleQuestionChange}
                onInput={autoResize}
              />
            ) : (
              <div>
                <input
                  type="text"
                  id="input-fields"
                  placeholder={"Add Question"}
                  ref={questionInputReference}
                  value={questionFields ? questionFields : ""}
                  onChange={handleQuestionChange}
                />
                <img
                  src={TrashCan}
                  className="trash-can-2"
                  style={{ marginLeft: "2.5rem" }}
                  onClick={(e) => handleDeleteQuestion(e)}
                />
              </div>
            )}
          </div>
        )}
        {!showMath &&
          showGallery &&
          questionConfig.questionImage.includes(questionType?.value) && (
            <div
              className="option-gallery"
              onClick={() => handleGalleryClick()}
              style={{ display: "flex" }}
            >
              {!questionConfig.questionInput.includes(questionType?.value) && (
                <div>
                  <input
                    type="text"
                    id="input-fields"
                    placeholder="Upload Image"
                    readOnly
                  />
                  <img
                    src={gallery}
                    alt="Gallery"
                    className="gallery-icon-img"
                  />
                </div>
              )}
              {questionConfig.questionInput.includes(questionType?.value) && (
                <img
                  src={gallery}
                  alt="Gallery"
                  className="gallery-icon-img"
                  style={{ marginTop: "0.25rem", marginLeft: "-5.1rem" }}
                />
              )}
            </div>
          )}
        {showMath && (
          <div className="equationIcon">
            <math-field
              className="math-field"
              style={{ top: "34px", left: "0px" }}
              onInput={(e) => handleLatexChange(e)}
              placeholder="Enter equation"
              ref={latexInputReference}
            >
              {latexQuestionValue}
            </math-field>
          </div>
        )}
        <input
          type="file"
          id="file-input"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(e) => handleUploadImage(e)}
        />
        {questionImage &&
          questionConfig.questionImage.includes(questionType?.value) && (
            <div
              style={{
                marginLeft: "13rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="image-container">
                {typeof questionImage === "string" ? (
                  questionImage.startsWith("data:image") ? (
                    <div
                      className="image-container"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={questionImage} alt="Pre-Uploaded Image" />
                    </div>
                  ) : (
                    <div>
                      <img src="#" alt={questionImage} />
                    </div>
                  )
                ) : questionImage instanceof File ? (
                  <div
                    className="image-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={URL.createObjectURL(questionImage)}
                      alt="Pre-Uploaded Image"
                    />
                  </div>
                ) : null}

                {/* <img src={URL.createObjectURL(questionImage)} alt={`Uploaded Image`} /> */}
              </div>
              <img
                src={TrashCan}
                className="trash-can-2"
                onClick={(e) => handleDeleteImage(e)}
                style={{ marginLeft: "14.9rem" }}
              />
            </div>
          )}
      </>
    ) : (
      <div
        className="answer-editor"
        style={{ width: "35rem", marginBottom: "0.3rem" }}
      >
        <div className="jodit-editor">
          <CKEditorComponent
            answerField={questionFields}
            onExplanationChange={handleExplanationChange}
            isQuestionText={true}
          />
        </div>
      </div>
    )}
  </div>
)

};

export default Question;