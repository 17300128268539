import { toast } from 'react-toastify';
import { baseUrl } from "Constants/ApiUrls";

/**
 * @description
 * This function takes in pageName, selectedFields and filters. It sends a POST request to the server
 * to fetch the data and returns the data in json format.
 *
 * @param {string} pageName name of the cms page
 * @param {Array<string>} selectedFields array of fields which you want to fetch
 * @param {Object} filters object which contains the filters which you want to apply to the data
 * @returns {Promise<JsonData>}
 * Returns a promise which resolves to the data in json format
 */
export const exportDatatableRecord = async (pageName, selectedFields, filters) => {
    const filterParams = buildFilterParams(filters)
    const token = localStorage.getItem('token');
    try {
        const url = `${baseUrl}/cms/v1/get${pageName}WithStats?${filterParams}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                selectedFields: selectedFields
            }),
        });
        if (!response.ok) {
            toast.error('Failed to export data. Server error.');
            return false;
        }
        const result = await response.json();
        if (result.success) {
            return result.data;
        }
        toast.error('Export failed: ' + result.message || 'Something went wrong!');
        return false;
    } catch (error) {
        toast.error('Failed to export data.');
        throw new Error(error);
    }
};

/**
 * This function takes in an array of filter objects and returns a string
 * containing the filter parameters. Each filter parameter is separated by &
 * and the value of the filter is separated from the name by =. If the value
 * of the filter is an array, it is converted to a string with each value
 * separated by & and the parameter name is suffixed with [] to indicate
 * it is an array. If the value of the filter is a boolean, it is converted
 * to a string and directly used as the value of the filter parameter.
 * @param {Array<Object>} filterData an array of filter objects
 * @returns {string} a string containing the filter parameters
 */
const buildFilterParams = (filterData) => {
    return filterData.map((filter) => {
        if (Array.isArray(filter.value) && filter.value.length > 0) {
            let values = filter.value.map((value) => `${filter.name}[]=${value.id || value}`);
            return values.join("&");
        } else if (typeof filter.value === 'boolean') {
            return `${filter.name}=${filter.value}`;
        } else {
            return `${filter.name}=${filter.value}`;
        }
    }).join("&");
};