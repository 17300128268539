import { convertLatexToSpeakableText } from 'mathlive';

// export const parseLatex = (questionText) => {
//     const tagRegex = new RegExp('<math-field>(.*?)</math-field>', 'g');
//     let renderedElement = [];
//     let lastIndex = 0;
//     let match;
//     let remainingText = questionText;
//     while ((match = tagRegex.exec(questionText)) !== null) {
//         const tagStartIndex = match.index;
//         const tagEndIndex = tagStartIndex + match[0].length;
//         renderedElement.push(
//             <span>
//                 {questionText.substring(lastIndex, tagStartIndex)}
//             </span>
//         );
//         renderedElement.push(
//             <math-field
//                 class="readOnly-mathField"
//                 readOnly={true}
//             >
//                 {match[1]}
//             </math-field>
//         );
//         lastIndex = tagEndIndex;
//         remainingText = questionText.substring(tagEndIndex);
//     }
//     if (remainingText) {
//         renderedElement.push(remainingText);
//     }
//     return renderedElement.map(elem => elem);
// }

export const parseLatex = (questionText) => {
    const tagRegex = new RegExp('<math-field>(.*?)</math-field>', 'g');
    let renderedHtml = '';
    let lastIndex = 0;
    let match;
    let remainingText = questionText;
    while ((match = tagRegex.exec(questionText)) !== null) {
        const tagStartIndex = match.index;
        const tagEndIndex = tagStartIndex + match[0].length;
        renderedHtml += questionText.substring(lastIndex, tagStartIndex);
        renderedHtml += `<math-field class="readOnly-mathField" readOnly="true">${match[1]}</math-field>`;
        lastIndex = tagEndIndex;
        remainingText = questionText.substring(tagEndIndex);
    }
    if (remainingText) {
        renderedHtml += remainingText;
    }
    return renderedHtml;
};

export const parseLatexText = (latexText) => {
    const tagRegex = new RegExp('<math-field>(.*?)</math-field>', 'g');
    let parsedText = '';
    let lastIndex = 0;
    let match;
    let remainingText = latexText;
    while ((match = tagRegex.exec(latexText)) !== null) {
        const tagStartIndex = match.index;
        const tagEndIndex = tagStartIndex + match[0].length;
        parsedText += latexText.substring(lastIndex, tagStartIndex);
        parsedText += convertLatexToSpeakableText(match[1]);
        remainingText = latexText.substring(tagEndIndex);
        lastIndex = tagEndIndex;
    }
    if (remainingText) {
        parsedText += remainingText;
    }
    return parsedText;
}
