import "./styles.css";
import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, Tab } from 'react-bootstrap';

import Spelling from "./Spelling";
import DbConfig from "Constants/db";
import Vocabulary from "./Vocabulary";
import Loader from "Components/Loader/Loader";
import StatusHeader from "./StatusHeader.jsx";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setQtype } from "../../../redux/slices/SpellAndVocab/qtypeSlice";
import { clearQuestionValue } from "../../../redux/slices/SpellAndVocab/Spelling/questionSlice";
import { setActiveProgramDetails, setProgramDetails } from "../../../redux/slices/globalDataSlice";
import { clearOptions, clearSolution } from "../../../redux/slices/SpellAndVocab/Spelling/optionSlice";
import { setCurrentStatus, statusCountAction } from "../../../redux/slices/SpellAndVocab/statusSlice";
import { closeSaveModal } from "../../../redux/slices/ModalSlice/SaveModalSlice";
import {questionConfig} from "../../../Utils/questionConfig.js"


function SpellingAndVocabulary() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const qtype = useSelector((state) => state.qtype.qtype);
    const { currentSelectedLevel, title } = useSelector((state) => state.level);
    const { programDetails, activeProgramDetails } = useSelector((state) => state.globalData);

    const [isLoading, setIsLoading] = useState(false);

    const activeProgramId = activeProgramDetails?._id;

    const statusOptions = [
        { label: "Published", value: "published" },
        { label: "Unpublished", value: "unpublished" },
        { label: "In Review", value: "in-review" },
        { label: "In Active", value: "in-active" },
    ];

    useEffect(() => {
        const fetchProgramDetails = async () => {
            try {
                const details = await DbConfig.programDetails.toArray();
                dispatch(setProgramDetails(details));
            } catch (error) {
                console.error("Failed to fetch program details:", error);
            }
        };
        fetchProgramDetails();
    }, [dispatch]);

    useEffect(() => {
        const fetchAndSetData = async () => {
            try {
                if (Object.values(qtype).length === 0) {
                    dispatch(setQtype({ "label": "Spelling", "value": 26 }));
                }
                if (qtype.label && programDetails.length > 0) {
                    const detailsArray = programDetails[0]?.programDetails || [];
                    const matchedProgram = detailsArray.find(
                        (program) => program.title === qtype.label
                    );
                    dispatch(setActiveProgramDetails(matchedProgram));
                }
            } catch (error) {
                console.error("Failed to set qtype or program details:", error);
            }
        };
        fetchAndSetData();
    }, [qtype, programDetails, dispatch]);

    useEffect(() => {
        const runSequentialActions = async () => {
            if (activeProgramId) {
                try {
                    setIsLoading(true);
                    await dispatch(statusCountAction(activeProgramId));
                    await dispatch(setCurrentStatus(1));
                    setIsLoading(false);
                } catch (error) {
                    console.error("Error fetching data:", error);
                    setIsLoading(false);
                }
            }
        };
        runSequentialActions();
    }, [dispatch, activeProgramId]);

    const handleTabSelect = (key) => {
        const selectedTab = questionConfig.find((item) => item.label === key);
        if (selectedTab) {
            dispatch(setQtype(selectedTab));
            dispatch(setCurrentStatus(null));
        }
    };

    useEffect(() => {
        dispatch(clearQuestionValue());
        dispatch(clearOptions());
        dispatch(clearSolution());
        dispatch(closeSaveModal());
    }, [dispatch])

    const handleAddWord = () => {
        if (qtype == "Spelling") {
            const modalUrl = `/spellandvocab/spelling-add?level-id=${currentSelectedLevel}&level-name=${title}`;
            navigate(modalUrl);
        } else {
            const modalUrl = `/spellandvocab/vocabulary-add?level-id=${currentSelectedLevel}&level-name=${title}`;
            navigate(modalUrl);
        }
    };

    const renderTabContent = (Component) => (
        <>
            <div className="status-header">
                <StatusHeader statusOptions={statusOptions} handleAddWord={handleAddWord} />
            </div>
            {isLoading ? <Loader /> : <Component />}
        </>
    );

    return (
        <Row className="spell-vocab-container">
            <Col>
                <Tabs
                    activeKey={qtype.label}
                    onSelect={handleTabSelect}
                    className="mb-3 bg-white px-5 py-1"
                    variant="underline"
                >
                    <Tab eventKey="Spelling" title="Spelling" className="px-5">
                        {qtype.label === "Spelling" && renderTabContent(Spelling)}
                    </Tab>
                    <Tab eventKey="Vocabulary" title="Vocabulary" className="px-5">
                        {qtype.label === "Vocabulary" && renderTabContent(Vocabulary)}
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    );
}

export default SpellingAndVocabulary;
