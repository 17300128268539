import { toast } from "react-toastify";
import { baseUrl, fetchToken } from "Constants/ApiUrls";

export const getLevelsAndQuestions = async (params) => {
    try {
        const { programId, status, active } = params;
        const queryParams = new URLSearchParams();
        if (typeof active !== "undefined" && !active) queryParams.append("active", active);
        if (status !== undefined && active) queryParams.append("status", status);

        const url = `${baseUrl}/cms/v1/getLevelsAndQuestions/${programId}?${queryParams.toString()}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken() }`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to fetch levels and question counts.");
            return false;
        }
        return result.data;
    } catch (e) {
        toast.error('Something went wrong while fetching levels!');
        throw new Error("An error occurred while fetching levels: " + e.message);
    }
};
