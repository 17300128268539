export const capitalizeString = (fullName) => {
    if (!fullName) return '';
    const words = fullName.split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    return capitalizedWords.join(' ');
};

export const escapeHtml = (str) => {
    if (typeof str !== 'string') return str || '';
    return str.replace(/[&<>"'`]/g, function (char) {
        return {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#39;',
            '`': '&#96;',
        }[char];
    });
}

export const balanceHtmlTags = (str) => {
    if (typeof str !== "string") return str || "";
    const tagRegex = /<\/?(\w+)>/g;
    const openingTags = [];
    const closingTags = [];
    let match;

    while ((match = tagRegex.exec(str)) !== null) {
        const [fullTag, tagName] = match;
        if (fullTag.startsWith("</")) {
            if (
                openingTags.length > 0 &&
                openingTags[openingTags.length - 1] === tagName
            ) {
                openingTags.pop();
            } else {
                closingTags.push(tagName);
            }
        } else {
            openingTags.push(tagName);
        }
    }

    let result = str;
    while (openingTags.length > 0) {
        const tag = openingTags.pop();
        result += `</${tag}>`;
    }

    while (closingTags.length > 0) {
        const tag = closingTags.shift();
        result = `<${tag}>` + result;
    }
    return result;
};