import { fetchToken } from "Constants/ApiUrls";
import { toast } from "react-toastify";

var token = fetchToken();
const baseUrl = process.env.REACT_APP_BASE_URL;
const fetchData = async (url, options = {}) => {
  try {
    const response = await fetch(url, {
      method: options.method || "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...options.headers,
      },
      body: options.body ? JSON.stringify(options.body) : undefined,
    });
    const result = await response.json();
    if (!response.ok || !result.success) {
      toast.error(result.message || 'Something went wrong!');
      return result.success;
    }
    return result.data;
  } catch (error) {
    toast.error('Something went wrong!');
    throw new Error(error);
  }
};
const buildFilterParams = (filterData) => {
  token = fetchToken();
  return filterData.map((filter) => {
    if (typeof filter.value == "object" && filter.value.length > 0) {
      let values = filter.value.map((value) => `${filter.name}[]=${value}`);
      return values.join("&");
    } else {
      return `${filter.name}=${filter.value}`;
    }
  });
};

export const userTitlesUrl = `${baseUrl}/cms/v1/getUserTitles/`;

export const teamsTitlesUrl = `${baseUrl}/cms/v1/getTeamTitles/`;

export const organizationTitlesUrl = `${baseUrl}/cms/v1/getAllOrganizationTitles`;

export const getTeamsTitles = async (url) => {
  const data = await fetchData(url);
  return data.teamTitles.map(({ _id, teamName }) => ({
    value: _id,
    label: teamName,
    id: _id,
  }));
}

export const getUserTitles = async (url) => {
  const data = await fetchData(url);
  return data.userTitles.map(({ _id, fullName }) => ({
    value: _id,
    label: fullName,
    id: _id,
  }));
}

export const getOrganizationTitles = async (url) => {
  token = fetchToken();
  const data = await fetchData(url);
  return data.organizationTitles.map(({ _id, title }) => ({
    value: _id,
    label: title,
    id: _id,
  }));
}

export const getAllTeams = async (filterData) => {
  const filterParams = buildFilterParams(filterData).join("&");
  const url = `${baseUrl}/cms/v1/getAllTeams?${filterParams}`;
  const data = await fetchData(url);
  if (data) {
    return data;
  }
  return { teams: [], totalPages: 0 };
};

export const updateTeam = async (data) => {
  const url = `${baseUrl}/cms/v1/updateTeam`;
  const result = await fetchData(url, {
    method: "POST",
    body: data
  });
  if (result) {
    toast.success("Team updated successfully");
    return result;
  }
  return false;
};

export const createTeams = async (data) => {
  const url = `${baseUrl}/cms/v1/insertTeam`;
  const result = await fetchData(url, {
    method: "POST", body: data
  });
  if (result) {
    toast.success("Team added successfully");
    return result;
  }
  return false;
};

export const deleteTeams = async (data) => {
  const url = `${baseUrl}/cms/v1/deleteTeam`;
  const result = await fetchData(url, {
    method: "POST",
    body: data
  });
    toast.success("Team deleted successfully");
};

export const removeUsersFromTeam = async (teamId, userIds) => {
  const url = `${baseUrl}/cms/v1/removeUsersFromTeam`;
  const result = await fetchData(url, {
    method: "POST",
    body: { teamId, userIds }
  });
  if (result) {
    toast.success('Users removed from team successfully');
    return result;
  }
  return false;
};

export const getActiveUsersForTeam = async (teamId, currentModalPage = 1) => {
  const url = `${baseUrl}/cms/v1/getAllUsers?page=${currentModalPage}&limit=20&active=true&teamIds=${teamId}`;
  const data = await fetchData(url);
  return data;
};

export const addUsersToTeam = async (data) => {
  const url = `${baseUrl}/cms/v1/addUsersToTeam`;
  const result = await fetchData(url, {
    method: "POST",
    body: data
  });
  return result;
};