import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { publishUnpublishAction } from "Utils/ApiHandler/DatatableActionServices/DatatableActionServices";

const initialState = {
    error: null,
    loading: false,
}

export const publishUnpublishActions = createAsyncThunk('publishUnpublishActions/publishUnpublish', async (params, { rejectWithValue }) => {
    try {
        const result = await publishUnpublishAction(params);
        return result.success
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const publishUnpublishActionSlice = createSlice({
    name: 'publishUnpublishActionSlice',
    initialState: initialState,
    extraReducers: (builder) => {
        builder
            .addCase(publishUnpublishActions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(publishUnpublishActions.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(publishUnpublishActions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong!';
            });
    },
});

export default publishUnpublishActionSlice.reducer; 