import { thunk } from 'redux-thunk';
import { configureStore } from "@reduxjs/toolkit"
import globalDataSlice from './slices/globalDataSlice';
import levelSlice from "./slices/SpellAndVocab/levelSlice"
import qtypeSlice from './slices/SpellAndVocab/qtypeSlice';
import statusReducer from "./slices/SpellAndVocab/statusSlice"
import FlagModalSlice from "./slices/ModalSlice/FlagModalSlice"
import optionSlice from "./slices/SpellAndVocab/Spelling/optionSlice"
import CommentsModalSlice from "./slices/ModalSlice/CommentsModalSlice"
import fileUploadReducer from "./slices/FileUploadSlice/fileUploadSlice"
import questionSlice from "./slices/SpellAndVocab/Spelling/questionSlice"
import spellingSlice from "./slices/SpellAndVocab/Spelling/spellingSlice"
import vocabularySlice from "./slices/SpellAndVocab/Vocabulary/vocabularySlice"
import deleteActionSlice from './slices/DatatableActionSlices/deleteActionSlice';
import flagUnflagActionSlice from './slices/DatatableActionSlices/flagUnflagSlice';
import errorModalReducer from "./slices/ValidationErrorSlices/validationErrorSlice"
import reactivateActionSlice from './slices/DatatableActionSlices/reactivateActionSlice';
import vocabularyQuestionsSlice from './slices/SpellAndVocab/Questions/vocabularyQuestionsSlice';
import publishUnpublishActionSlice from './slices/DatatableActionSlices/publishUnpublishActionSlice';
import SaveModalSlice from './slices/ModalSlice/SaveModalSlice';

export const store = configureStore({
    reducer: {
        qtype: qtypeSlice,
        level: levelSlice,
        options: optionSlice,
        status: statusReducer,
        spelling: spellingSlice,
        question: questionSlice,
        delete: deleteActionSlice,
        flagModal: FlagModalSlice,
        globalData: globalDataSlice,
        vocabulary: vocabularySlice,
        comments: CommentsModalSlice,
        fileUpload: fileUploadReducer,
        //deleteAction: deleteActionSlice,
        flagUnflag: flagUnflagActionSlice,
        validationErrorModal: errorModalReducer,
        publishUnpublish: publishUnpublishActionSlice,
        vocabularyQuestions: vocabularyQuestionsSlice,
        reactivate: reactivateActionSlice,
        saveModal: SaveModalSlice

    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
})