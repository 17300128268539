import { fetchToken } from "Constants/ApiUrls";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;
const addQuestionSuccess = "Question Added Successfully";
const editQuestionSuccess = "Question Updated Successfully";

/**
 * getQuestionTypes
 *
 * Function to fetch all question types
 *
 * @returns {Array|Object} - Array containing all question types or an object
 * with empty domains and totalPages if fetch fails
 *
 * @author - Gaurav
 */
export const getQuestionTypes = async () => {
  const token = fetchToken();
  const url = `${baseUrl}/cms/v1/getQuestionTypes`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  let responseData = await response.json();
  if (!response.ok) {
    console.error(response);
    toast.error(responseData.message);
    return { questions: [], totalPages: 0 };
  } else {
    return responseData.data;
  }
};

/**
 * addQuestion
 *
 * Function to add a new question
 *
 * @param {Object} data - Data object containing the question details
 *
 * @returns {boolean|Response} - True if question addition is successful, false
 * otherwise
 *
 * @author - Gaurav
 */
export const addQuestion = async (formData) => {
  try {
    const token = fetchToken();
    const url = `${baseUrl}/cms/v2/insertQuestion`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const result = await response.json();
    if (!response.ok || !result.success) {
      if (Array.isArray(result.message)) {
        return result.message;
      } else {
        toast.error(result.message);
        return false;
      }
    }
    toast.success(addQuestionSuccess);
    return result;
  } catch (error) {
    console.error("Error add question: ", error);
    return false;
  }
};

/**
 * addQuestion
 *
 * Function to add a new question
 *
 * @param {Object} data - Data object containing the question details
 *
 * @returns {boolean|Response} - True if question addition is successful, false
 * otherwise
 *
 * @author - Gaurav
 */
export const insertPreviewQuestion = async (formData) => {
  try {
    const token = fetchToken();
    const url = `${baseUrl}/cms/v1/insertPreviewQuestion`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const responseData = await response.json();
    if (!response.ok) {
      toast.error(responseData.message);
      return false;
    }
    if (!responseData.success) {
      toast.error(responseData.message);
      return false;
    }
    toast.success("Preview Loaded Successfully");
    return responseData;
  } catch (error) {
    toast.error("Error add question: ", error);
    throw new Error(error);
  }
};

export const insertPreviewQuestionRC = async (formData) => {
  try {
    const token = fetchToken();
    const url = `${baseUrl}/cms/v1/insertPreviewQuestion`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const responseData = await response.json();
    if (!response.ok) {
      toast.error(responseData.message);
      return false;
    }
    if (!responseData.success) {
      toast.error('Failed to load preview.')
      toast.error(responseData.message);
      return false;
    }
    return responseData;
  } catch (error) {
    toast.error("Error add question: ", error);
    throw new Error(error);
  }
};

export const insertQuestionRC = async (formData) => {
  try {
    const token = fetchToken();
    const url = `${baseUrl}/cms/v2/insertQuestion`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const result = await response.json();
    if (!response.ok) {
      console.error("Response error:", result);
      toast.error(
        result.message || "An error occurred while adding the question."
      );
      return false;
    }
    if (!result.success) {
      if (Array.isArray(result.message)) {
        return result.message;
      } else {
        toast.error(result.message);
        return false;
      }
    }
    toast.success(addQuestionSuccess);
    return result;
  } catch (error) {
    toast.error("An unexpected error occurred while adding the question.");
    throw new Error(error);
  }
};

/**
 * addQuestion
 *
 * Function to add a new question
 *
 * @param {Object} data - Data object containing the question details
 *
 * @returns {boolean|Response} - True if question addition is successful, false
 * otherwise
 *
 * @author - Gaurav
 */
export const getpreviewQuestionById = async (previewId) => {
  try {
    const token = fetchToken();
    const url = `${baseUrl}/cms/v1/getpreviewQuestionById/${previewId}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      toast.error(responseData.message);
      return false;
    }
    if (!responseData.success) {
      toast.error(responseData.message);
      return false;
    }
    toast.success("Preview Loaded Successfully");
    return responseData;
  } catch (error) {
    toast.error("Error previewing question: ", error);
    throw new Error(error);
  }
};

/**
 * addQuestion
 *
 * Function to add a new question
 *
 * @param {Object} data - Data object containing the question details
 *
 * @returns {boolean|Response} - True if question addition is successful, false
 * otherwise
 *
 * @author - Gaurav
 */
export const editQuestion = async (data) => {
  try {
    const token = fetchToken();
    const url = `${baseUrl}/cms/v1/updateQuestion`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    });
    const result = await response.json();
    if (!response.ok || !result.success) {
      if (Array.isArray(result.message)) {
        return result.message;
      } else {
        toast.error(result.message);
        return;
      }
    }
    toast.success(editQuestionSuccess, {
      className: "custom-success-toast",
    });
    return result;
  } catch (error) {
    toast.error("Error updating data: ", error);
    throw new Error(error);
  }
};
