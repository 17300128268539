import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import PreviewQuestionComponent from "./PreviewQuestionComponent";
import PreviewOptionsComponent from "./PreviewOptionsComponent";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { FaCommentDots } from "react-icons/fa";
import flag from "../../../../Assests/Images/flag.svg";
import { Flag } from "Components/DataTable/DataTable.styled";
import SaveModal from "Components/Modals/SaveModal/SaveModal";
import {
    getpreviewQuestionById,
} from "Utils/ApiHandler/DatatableActionServices/DatatableActionServices";
import { setQuestionValue } from "../../../../redux/slices/SpellAndVocab/Spelling/questionSlice";
import {
    setOptions,
    setSolution,
} from "../../../../redux/slices/SpellAndVocab/Spelling/optionSlice";
import { useDispatch, useSelector } from "react-redux";
import CommentsModal from "Controller/Programs/Modals/CommentsModal";
import FlagModal from "Controller/Programs/Modals/FlagModal";
import { openCommentsModal } from "../../../../redux/slices/ModalSlice/CommentsModalSlice";
import {
    addCommentsToSpelling,
    getAllCommentsForSpelling,
} from "Utils/ApiHandler/DatatableActionServices/DatatableActionServices";
import {
    openFlagModal,
    setIsFlaggedQuestion,
} from "../../../../redux/slices/DatatableActionSlices/flagUnflagSlice";
import { passFlagUnflagItemData, flagUnflagActions } from "../../../../redux/slices/DatatableActionSlices/flagUnflagSlice";
import { getQuestionById } from "Utils/ApiHandler/DatatableActionServices/DatatableActionServices";
import {
    setQtype,
} from "../../../../redux/slices/SpellAndVocab/qtypeSlice";
import { fetchAllSpellings } from "../../../../redux/slices/SpellAndVocab/Spelling/spellingSlice";
import { fetchAllVocabulary } from "../../../../redux/slices/SpellAndVocab/Vocabulary/vocabularySlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "Components/Loader/Loader";
import Title from "Components/Title/Title";
import { Card } from "react-bootstrap";
import { removeFlag } from "Utils/ApiHandler/QuestionApi";

function PreviewQuestion() {
    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search);
    const previewId = queryParams.get("preview-id");
    const questionId = queryParams.get("question-id");
    const activePage = queryParams.get("active-page");
    const mode = queryParams.get("mode");
    const levelName = queryParams.get("level-levelName");
    const { isCommentModal } = useSelector((state) => state.comments);
    const { showFlagModal, flagComment, isFlaggedQuestion } = useSelector(
        (state) => state.flagUnflag
    );
    const { spellings } = useSelector((state) => state.spelling);
    const { vocabulary } = useSelector((state) => state.vocabulary);
    const { currentStatus, isActive, searchKey } = useSelector(
        (state) => state.status
    );
    const { currentSelectedLevel, title } = useSelector((state) => state.level);
    const qtype = useSelector((state) => state.qtype.qtype);
    const [isClosing, setIsClosing] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showExplanation, setShowExplanation] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentId, setCurrentId] = useState(questionId);
    const [loading, setLoading] = useState(true);
    const questionNumber = currentIndex + (currentPage - 1) * rowsPerPage + 1;
    const handleAnswerExplanation = () => {
        setShowExplanation((prevState) => !prevState);
    };
    const dispatch = useDispatch();
    const [answerExplanation, setAnswerExplanation] = useState("");
    const [hasFetchedNextPage, setHasFetchedNextPage] = useState(false);

    const navigate = useNavigate();

    const handleActivateFlagModal = async () => {
        await dispatch(openFlagModal());
        await dispatch(
            passFlagUnflagItemData({
                ids: [currentId],
                text: flagComment,
                isFlag: true,
            }),
        )

    };

    const handleActivateCommentsModal = () => {
        dispatch(openCommentsModal());
    };

    const handleUnFlagAction = async () => {
        try {

            await dispatch(flagUnflagActions({ id: currentId }));
            dispatch(setIsFlaggedQuestion(false));
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleUnFlagModal = () => {
        setShowSaveModal(true);
    };

    useEffect(() => {
        async function fetchPreviewQuestionById() {
            try {
                if (previewId) {
                    const previewData = await getpreviewQuestionById(previewId);
                    dispatch(setQuestionValue(previewData.data.question.text));
                    dispatch(setOptions(previewData.data.options));
                    dispatch(setSolution(previewData.data.answer));
                    if (previewData.data.qtype) {
                        dispatch(
                            setQtype({
                                label:
                                    previewData.data.qtype === 26
                                        ? "Spelling"
                                        : "Vocabulary",
                                value: previewData.data.qtype,
                            })
                        );
                    }
                }
                if (questionId) {
                    const question = await getQuestionById(questionId);
                    dispatch(setQuestionValue(question.question.text));
                    dispatch(setOptions(question.options));
                    dispatch(setSolution(question.answer));
                    if (question.qtype) {
                        dispatch(
                            setQtype({
                                label:
                                    question.qtype === 26
                                        ? "Spelling"
                                        : "Vocabulary",
                                value: question.qtype,
                            })
                        );
                    }
                    dispatch(setIsFlaggedQuestion(question.isFlag));
                    const activeArray =
                        activePage === "spelling" ? spellings : vocabulary;
                    const currentQuestionIndex = activeArray.findIndex(
                        (q) => q._id === questionId
                    );
                    setCurrentIndex(currentQuestionIndex);
                }
            } catch (error) {
                console.error("Error fetching question:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchPreviewQuestionById();
    }, [previewId, questionId]);

    const fetchTableData = () => {
        if (currentSelectedLevel) {
            const endpoint =
                activePage === "vocab"
                    ? "getVocabularyQuestionsByLevel"
                    : "getSpellingQuestionsByLevel";

            const fetchAction =
                activePage === "vocab" ? fetchAllVocabulary : fetchAllSpellings;

            dispatch(
                fetchAction({
                    getApiEndPoint: endpoint,
                    status: currentStatus,
                    active: isActive,
                    levelId: currentSelectedLevel,
                    searchKey: searchKey,
                    page: currentPage + 1,
                    limit: rowsPerPage,
                })
            );
        }
    };

    useEffect(() => {
        const activeArray = activePage === "vocab" ? vocabulary : spellings;
        if (
            activeArray.length &&
            !activeArray.some((item) => item._id === currentId)
        ) {
            fetchNextQuestion();
        }
    }, [vocabulary, spellings, currentId]);

    const fetchNextQuestion = async () => {
        const arrayToUse = activePage === "vocab" ? vocabulary : spellings;

        if (arrayToUse.length > 0 && currentIndex < arrayToUse.length) {
            const currentQuestionIndex = arrayToUse.findIndex(
                (item) => item._id === currentId
            );
            if (currentQuestionIndex === -1) {
                const firstQuestionId = arrayToUse[0]._id;
                try {
                    const question = await getQuestionById(firstQuestionId);
                    dispatch(setQuestionValue(question.question.text));
                    dispatch(setOptions(question.options));
                    dispatch(setSolution(question.answer));
                    if (question.qtype) {
                        dispatch(
                            setQtype({
                                label:
                                    question.qtype === 26
                                        ? "Spelling"
                                        : "Vocabulary",
                                value: question.qtype,
                            })
                        );
                    }
                    setCurrentIndex(0);
                    setCurrentId(firstQuestionId);
                } catch (error) {
                    console.error("Failed to fetch first question:", error);
                }
            } else if (currentQuestionIndex < arrayToUse.length - 1) {
                const nextQuestionId = arrayToUse[currentQuestionIndex + 1]._id;
                try {
                    const question = await getQuestionById(nextQuestionId);
                    dispatch(setQuestionValue(question.question.text));
                    dispatch(setOptions(question.options));
                    dispatch(setSolution(question.answer));
                    if (question.qtype) {
                        dispatch(
                            setQtype({
                                label:
                                    question.qtype === 26
                                        ? "Spelling"
                                        : "Vocabulary",
                                value: question.qtype,
                            })
                        );
                    }
                    dispatch(setIsFlaggedQuestion(question.isFlag));
                    setCurrentIndex(currentQuestionIndex + 1);
                    setCurrentId(nextQuestionId);
                } catch (error) {
                    console.error("Failed to fetch next question:", error);
                }
            } else {
                if (!hasFetchedNextPage) {
                    fetchTableData();
                    toast.success("Fetching Next Page Questions");
                    setHasFetchedNextPage(true);
                }
            }
        }
    };


    return (
        <>
            <div>
                {loading && <Loader />}
                {!loading && (
                    <>
                        {mode === "preview" && <Title />}
                        <Card className="question-preview">
                            <Card.Header className="question-preview">
                                <div className="preview-container">
                                    <span className="preview-container-header">
                                        <Card.Text className="parent-skill">
                                            Level:{""}
                                            <span className="parent-skill-name">
                                                {levelName}
                                            </span>
                                        </Card.Text>
                                        <Card.Text className="question-number">
                                            Question.no.
                                            <span>{questionNumber}</span>
                                        </Card.Text>
                                    </span>

                                    <Card.Body className="question-preview-body">
                                        <Card.Title
                                            style={{ marginTop: "2rem" }}
                                        >
                                            <div className="question-preview-container">
                                                {" "}
                                                <PreviewQuestionComponent />
                                            </div>
                                        </Card.Title>
                                        <Card.Title>
                                            <div className="option-component">
                                                <PreviewOptionsComponent />
                                            </div>
                                        </Card.Title>
                                        <Card.Title>
                                            {mode === "edit" ||
                                                (mode === "preview" && (
                                                    <Card.Text className="icons">
                                                        {qtype === 11 && (
                                                            <button
                                                                className="show-answer"
                                                                onClick={
                                                                    handleAnswerExplanation
                                                                }
                                                            >
                                                                {showExplanation
                                                                    ? "Hide Answer"
                                                                    : "Show Answer"}
                                                            </button>
                                                        )}
                                                        <Card.Text
                                                            className={`action-buttons`}
                                                            style={{
                                                                marginLeft:
                                                                    "auto",
                                                            }}
                                                        >
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {isFlaggedQuestion
                                                                            ? "Unflag"
                                                                            : "Flag"}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <Flag
                                                                    src={flag}
                                                                    alt="Flagged"
                                                                    className={`flag-button ${isFlaggedQuestion
                                                                            ? "flag-icon"
                                                                            : "unFlag-icon"
                                                                        }`}
                                                                    onClick={
                                                                        isFlaggedQuestion
                                                                            ? handleUnFlagModal
                                                                            : !isFlaggedQuestion
                                                                                ? handleActivateFlagModal
                                                                                : null
                                                                    }
                                                                />
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Comments
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="comment-action-icon">
                                                                    <FaCommentDots
                                                                        onClick={
                                                                            handleActivateCommentsModal
                                                                        }
                                                                        className="comment-icon"
                                                                    />
                                                                </span>
                                                            </OverlayTrigger>
                                                            {isCommentModal && (
                                                                <CommentsModal
                                                                    show={true}
                                                                    questionId={
                                                                        questionId
                                                                    }
                                                                    fetchCommentsApi={
                                                                        getAllCommentsForSpelling
                                                                    }
                                                                    addCommentApi={
                                                                        addCommentsToSpelling
                                                                    }
                                                                />
                                                            )}
                                                            {showFlagModal && (
                                                                <div
                                                                    className={`modal-overlayFlag`}
                                                                >
                                                                    <FlagModal flagText="Question" />
                                                                </div>
                                                            )}

                                                            {showSaveModal && (
                                                                <SaveModal
                                                                    showModal={
                                                                        showSaveModal
                                                                    }
                                                                    onClose={() =>
                                                                        setShowSaveModal(
                                                                            false
                                                                        )
                                                                    }
                                                                    onSave={
                                                                        handleUnFlagAction
                                                                    }
                                                                    setShowContent={
                                                                        true
                                                                    }
                                                                />
                                                            )}
                                                        </Card.Text>
                                                    </Card.Text>
                                                ))}
                                            <Card.Text className="text-editor">
                                                {showExplanation && (
                                                    <>
                                                        {answerExplanation &&
                                                            answerExplanation.trim() !==
                                                            "" ? (
                                                            <>
                                                                <div
                                                                    className={`textEditor-title`}
                                                                // ${
                                                                //     questionType ===
                                                                //     "readingComprehension"
                                                                //         ? "readingComprehension"
                                                                //         : "default"
                                                                // }
                                                                >
                                                                    Answer
                                                                    Explanation:
                                                                </div>
                                                                <div>
                                                                    {parse(
                                                                        String(
                                                                            answerExplanation
                                                                        )
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className="no-answer">
                                                                <strong>
                                                                    No
                                                                    Explanation
                                                                    Found.
                                                                </strong>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </Card.Text>
                                        </Card.Title>
                                    </Card.Body>
                                </div>

                                {mode === "preview" && (
                                    <span className="nextQuestion-container">
                                        <button
                                            className={`next-button `}
                                            onClick={fetchNextQuestion}
                                        >
                                            Next Question
                                        </button>
                                    </span>
                                )}
                            </Card.Header>
                        </Card>
                    </>
                )}
            </div>
        </>
    );
}
export default PreviewQuestion;
