import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addQuestion, editQuestion } from 'Utils/ApiHandler/QuestionBuilderApi';
import { useDispatch, useSelector } from "react-redux";
import { openErrorModal } from '../../../../redux/slices/ValidationErrorSlices/validationErrorSlice';
import { openSaveModal } from '../../../../redux/slices/ModalSlice/SaveModalSlice';


export const addSpellingQuestionThunk = createAsyncThunk(
    'spellingQuestions/addSpellingQuestion',
    async (formData, { rejectWithValue,dispatch }) => {
      try {
        const response = await addQuestion(formData);
        if (response.success) {
          dispatch(openSaveModal())
          return response;
        } else {
          dispatch(openErrorModal([response || "Failed to add question"]));
          return rejectWithValue(response.message || "Failed to add question");
        }
      } catch (error) {
        dispatch(openErrorModal([error.message]));
        return rejectWithValue(error.message);

      }
    }
  );

  export const editSpellingQuestionThunk = createAsyncThunk(
    'spellingQuestions/editSpellingQuestion',
    async (formData, { rejectWithValue,dispatch }) => {
      try {
        const response = await editQuestion(formData);
        if (response.success) {
          return response;
        } else {
          dispatch(openErrorModal(response.message || ["Failed to edit question"]));
          return rejectWithValue(response.message || "Failed to edit question");
        }
      } catch (error) {
        dispatch(openErrorModal([error.message]));
        return rejectWithValue(error.message);
      }
    }
  );

  const spellingQuestionsSlice = createSlice({
    name: 'spellingQuestions',
    initialState: {
      loading: false,
      successMessage: '',
      errorMessage: '',
    },
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(addSpellingQuestionThunk.pending, (state) => {
          state.loading = true;
          state.successMessage = '';
          state.errorMessage = '';
        })
        .addCase(addSpellingQuestionThunk.fulfilled, (state) => {
          state.loading = false;
          state.successMessage = 'Question added successfully';
          state.errorMessage = '';
        })
        .addCase(addSpellingQuestionThunk.rejected, (state, action) => {
          state.loading = false;
          state.successMessage = '';
          state.errorMessage = action.payload || 'Failed to add question';
        })
        
        .addCase(editSpellingQuestionThunk.pending, (state) => {
          state.loading = true;
          state.successMessage = '';
          state.errorMessage = '';
        })
        .addCase(editSpellingQuestionThunk.fulfilled, (state) => {
          state.loading = false;
          state.successMessage = 'Question edited successfully';
          state.errorMessage = '';
        })
        .addCase(editSpellingQuestionThunk.rejected, (state, action) => {
          state.loading = false;
          state.successMessage = '';
          state.errorMessage = action.payload || 'Failed to edit question';
        })
  
    },
  });
  
  export default spellingQuestionsSlice.reducer;
  