import React, { useState, useRef, useEffect } from "react";
import { parseLatex, parseLatexText } from "Utils/LatexUtils";
import speaker from "Assests/Images/speaker.svg";
import activeSpeaker from "Assests/Images/active-speaker.svg";
import questionPreviewConfig from "./questionPreviewConfig";
import { Modal } from "react-bootstrap";
import CKEditorComponent from "../QuestionBuilder/CKEditorComponent";
import { balanceHtmlTags } from "Utils/StringUtilities";

const PreviewQuestionBuilder = (questionType) => {
    const inputRefs = useRef({});
    const answer = questionType.answer;
    const resource = questionType.resource;
    const questionContainerRef = useRef(null);
    const [imageStyle, setImageStyle] = useState({});
    const questionImage = questionType.questionImage;
    const speechSynthesisInstance = window.speechSynthesis;
    const [speakingIndex, setSpeakingIndex] = useState(null);
    const [marginBottom, setMarginBottom] = useState("1rem");
    const [containerHeight, setContainerHeight] = useState("auto");
    const currentQuestionIndex = questionType.childQuestion
        ? questionType.childQuestionCurrent
        : "";
    const type =
        questionType.childQuestion?.length &&
            questionType.childQuestion[currentQuestionIndex]
            ? questionType.childQuestion[currentQuestionIndex].qtype
            : questionType.questionType;
    var answerArr = Array.isArray(answer) && answer.length ? answer : [];
    const [ispdfShow, setPdfShow] = React.useState(false);

    var voice = speechSynthesisInstance.getVoices()[3];
    const [pitch, setPitch] = useState(1);
    const [rate, setRate] = useState(1);

    var answerCount = 0;
    useEffect(() => {
        if (questionImage) {
            const img = new Image();
            img.src = questionImage;
            img.onload = () => {
                if (img.width === 150 && img.height === 150) {
                    setImageStyle({
                        display: "block",
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                    });
                } else if (img.width === 400 && img.height === 400) {
                    setImageStyle({
                        display: "block",
                        width: "400px",
                        height: "400px",
                        objectFit: "cover",
                        marginTop: questionPreviewConfig.imageQuestion.includes(
                            questionType.questionType
                        )
                            ? "0"
                            : "15rem",
                        marginLeft: "1rem",
                        borderRadius: "0.5rem",
                    });
                    setContainerHeight("27rem");
                }
            };
        }
    }, [questionImage]);

    useEffect(() => {
        if (questionContainerRef.current) {
            const height = questionContainerRef.current.offsetHeight;
            const dynamicMarginBottom = `${height * 0.1}px`;
            setMarginBottom(dynamicMarginBottom);
        }
    }, [questionType]);

    const handleSpeak = (option, index) => {
        if ("speechSynthesis" in window) {
            const stripHTMLTags = (text) => {
                const div = document.createElement("div");
                div.innerHTML = text;
                return div.textContent || div.innerText || "";
            };
            const cleanOptionSegments = option.split(/<br\s*\/?>/i).map(stripHTMLTags);
            const utterances = cleanOptionSegments.map((segment) => {
                const processedSegment = segment.replace(/<#@blank#>/g, "blank");
                const utterance = new SpeechSynthesisUtterance(
                    questionPreviewConfig.fillInTheBlanks.includes(questionType.questionType)
                        ? parseLatexText(processedSegment.replace(/_/g, "blank"))
                        : parseLatexText(processedSegment)
                );
                utterance.voice = voice;
                utterance.pitch = pitch;
                utterance.rate = rate;
                return utterance;
            });

            if (speechSynthesisInstance.speaking) {
                speechSynthesisInstance.cancel();
                setSpeakingIndex(null);
            } else {
                setSpeakingIndex(index);
                const speakWithPause = (currentIndex) => {
                    if (currentIndex < utterances.length) {
                        speechSynthesisInstance.speak(utterances[currentIndex]);
                        utterances[currentIndex].onend = () => {
                            setTimeout(() => {
                                speakWithPause(currentIndex + 1); 
                            }, 500); 
                        };
                    } else {
                        setSpeakingIndex(null); 
                    }
                };

                speakWithPause(0); 
            }
        }
    };

    const handleInputChange = (event, index) => {
        const input = event.target;
        const span = inputRefs.current[index];

        if (span) {
            span.textContent = input.value || input.placeholder;
            input.style.width = `${span.offsetWidth + 10}px`;
        }
    };

    const handlepdfOpen = () => {
        setPdfShow(true);
    };

    const handlepdfClose = () => {
        setPdfShow(false);
    };
    return (
        <div style={{ marginBottom: marginBottom }}>
            {questionPreviewConfig.readingComprehension.includes(
                questionType.questionType
            ) && (
                    <div className="passage-container">
                        <div
                            className="speaker-icon"
                            style={{ zIndex: "1", margin: "1.2rem 0 0 0.5rem" }}
                        >
                            <img
                                src={speakingIndex === 1 ? activeSpeaker : speaker}
                                onClick={() =>
                                    handleSpeak(questionType.passageText, 1)
                                }
                                alt="Speaker"
                            />
                        </div>
                        <div className="passage-preview">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: parseLatex(questionType.passageText),
                                }}
                            />
                        </div>
                    </div>
                )}
            {questionPreviewConfig.textQuestion.includes(
                questionType.questionType
            ) &&
                !questionPreviewConfig.readingComprehension.includes(
                    questionType.questionType
                ) && (
                    <div
                        className={`question-preview-container ${questionPreviewConfig.readingComprehension.includes(
                            questionType.questionType
                        )
                            ? "RC"
                            : ""
                            }`}
                        style={{ height: containerHeight }}
                    >
                        <div className="speaker-icon">
                            <img
                                src={
                                    speakingIndex === 0
                                        ? activeSpeaker
                                        : speaker
                                }
                                onClick={() =>
                                    handleSpeak(questionType.questionText, 0)
                                }
                                style={{ marginLeft: "4rem" }}
                            />
                        </div>
                        <div
                            className={`text-question ${questionPreviewConfig.readingComprehension.includes(
                                type
                            )
                                ? "RC"
                                : ""
                                }`}
                        >
                            {questionPreviewConfig.fillInTheBlanks.includes(
                                type
                            ) ? (
                                questionType.questionText &&
                                questionType.questionText
                                    .split(/(<#@blank#>)/)
                                    .map((part, index) =>
                                        part === "<#@blank#>" ? (
                                            <div
                                                key={index}
                                                className="fill-in-the-blank-container"
                                            >
                                                <div
                                                    className="fill-in-the-blank-wrapper"
                                                    style={{
                                                        marginTop: "-7px",
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        className="fill-in-the-blank"
                                                        placeholder="Enter Answer"
                                                        value={
                                                            answerArr[
                                                            answerCount++
                                                            ]
                                                        }
                                                        // onChange={(event) => handleInputChange(event, index)}
                                                        style={{
                                                            width: "10ch",
                                                        }}
                                                        maxLength={48}
                                                    />
                                                    <span
                                                        ref={(el) =>
                                                        (inputRefs.current[
                                                            index
                                                        ] = el)
                                                        }
                                                        className="fill-in-the-blank-hidden"
                                                    />

                                                    <div className="fill-in-the-blank-highlight"></div>
                                                </div>
                                            </div>
                                        ) : (
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: parseLatex(balanceHtmlTags(part)),
                                                }}
                                            />
                                            // <span key={index} >{parseLatex(part)}</span>
                                        )
                                    )
                            ) : (
                                <div
                                    className="resource-container"
                                >
                                    <div
                                        style={{ flex: "1" }}
                                        dangerouslySetInnerHTML={{
                                            __html: parseLatex(
                                                questionType.questionText
                                            ),
                                        }}
                                    />
                                    {questionType.questionType === 25 &&
                                        questionType.resource && (
                                            <button
                                                className="resource-text"
                                                onClick={handlepdfOpen}
                                            >
                                                Resource
                                            </button>
                                        )}
                                    <Modal show={ispdfShow}>
                                        <Modal.Header
                                            closeButton
                                            onClick={() => handlepdfClose()}
                                        >
                                            <Modal.Title className="modal-title">
                                                Resource
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="show-grid">
                                            {resource ? (
                                                <iframe
                                                    id="iframe"
                                                    src={resource}
                                                    height="550rem"
                                                    width="100%"
                                                    frameBorder="0"
                                                    title="Preview Modal"
                                                ></iframe>
                                            ) : (
                                                <p>No resource available</p>
                                            )}
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            {questionType.questionType == 25 && (
                <div className="preview-editor">
                    <div className="jodit-editor">
                        <CKEditorComponent
                            disabled={true}
                            onReady={(editor) => {
                                editor.isReadOnly = true;
                            }}
                        />
                    </div>
                </div>
            )}
            {questionPreviewConfig.imageQuestion.includes(
                questionType.questionType
            ) && (
                    <div
                        className="question text-image-question"
                        style={{ height: containerHeight }}
                    >
                        <div
                            className="speaker-icon"
                            onClick={() =>
                                handleSpeak(
                                    questionType.questionText,
                                    "imageQuestion"
                                )
                            }
                        >
                            <img
                                src={questionImage}
                                style={imageStyle}
                                onLoad={questionType.onImageLoad}
                            />
                        </div>
                    </div>
                )}
            {questionPreviewConfig.textAndImageQuestion.includes(
                questionType.questionType
            ) && (
                    <>
                        <div
                            className="question-preview-container text-image-question"
                            style={{ height: containerHeight }}
                        >
                            <div className="text-question">
                                <img
                                    src={
                                        speakingIndex === 0
                                            ? activeSpeaker
                                            : speaker
                                    }
                                    onClick={() =>
                                        handleSpeak(questionType.questionText, 0)
                                    }
                                    alt="Speaker"
                                    className="speaker-button"
                                />
                                {questionPreviewConfig.fillInTheBlanks.includes(
                                    questionType.questionType
                                ) ? (
                                    questionType.questionText
                                        .split(/(<#@blank#>)/)
                                        .map((part, index) =>
                                            part === "<#@blank#>" ? (
                                                <div
                                                    key={index}
                                                    className="fill-in-the-blank-container"
                                                >
                                                    <div
                                                        className="fill-in-the-blank-wrapper"
                                                        style={{
                                                            marginTop: "-7px",
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="fill-in-the-blank"
                                                            placeholder="Enter Answer"
                                                            value={
                                                                answerArr[
                                                                answerCount++
                                                                ]
                                                            }
                                                            // onChange={(event) => handleInputChange(event, index)}
                                                            style={{
                                                                width: "10ch",
                                                            }}
                                                            maxLength={48}
                                                        />
                                                        <span
                                                            ref={(el) =>
                                                            (inputRefs.current[
                                                                index
                                                            ] = el)
                                                            }
                                                            className="fill-in-the-blank-hidden"
                                                        />
                                                        <div className="fill-in-the-blank-highlight"></div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: parseLatex(part),
                                                    }}
                                                />
                                            )
                                        )
                                ) : (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: parseLatex(
                                                questionType.questionText
                                            ),
                                        }}
                                        style={{
                                            marginTop: "-1.6rem",
                                            marginLeft: "0.75rem",
                                        }}
                                    />
                                )}
                                <div
                                    className="speaker-icon question"
                                // style={{
                                //     marginLeft: "1rem",
                                //     padding: "0",
                                //     marginTop: "0",
                                // }}
                                >
                                    <img
                                        src={questionImage}
                                        onLoad={questionType.onImageLoad}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
        </div>
    );
};

export default PreviewQuestionBuilder;
