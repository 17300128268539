import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

export const QuestionSaveStyledModal = styled(Modal)`
  .modal-content {
    display: flex;
    justify-content: center;
    width: 25rem;
    height: 12.25rem;
    flex-shrink: 0;
  }

  .modal-body {
    padding: 0 0 0 0;
    flex: 0 0 0;
    margin-left: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    line-height: 1.875rem;
    font-family: var(--font-family);
    width: 16.75rem;
  }
    .modal-card-body{
    border:none;
    }
  }
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 0.75969rem;
  height: 0.75969rem;
  flex-shrink: 0;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
`;

export const ModalFooter = styled.div`


  button {
    margin-left: 2rem;  
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    // gap: 0.625rem;
    border-radius: 0.25rem;
    border: 0px solid #D8D8D8;
    background: #016DD9;
    font-family: var(--font-family);
    font-size: var(--font-size-normal);
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
    height: 2.5rem;
    width: auto;
  }
`;

