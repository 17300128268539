import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

const DeleteStyledModal = styled(Modal)`
  .modal-content {
    width: 28.43rem;
    min-height: 15.5rem;
  }

  .modal-body {
    overflow-x: hidden;
    color: ${(props) => props.textColor || "#4B4B4B"};
    padding-left: 2.188rem;
    padding-top: 0.625rem;
    padding-right: 3.875rem;
    font-size: var(--font-size-regular);
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    font-family: var(--font-family);
    
  }

  .modal-title {
    color: var(--error-red-color);
    padding-left: 2.125rem;
    padding-top: 2rem;
    padding-left:2rem;
    padding-bottom: 0.738rem;
    font-family: var(--font-family);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
  }

  
  @media (max-width: 790px) {
    .modal-content {
      width: 70%;
    }
    .modal-title {
      padding-left: 1.85rem; 
      padding-top: 1.25rem;
      padding-bottom: 0.625rem;
      
    }
  }   
  
  }
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 0.75969rem;
  height: 0.75969rem;
  flex-shrink: 0;
  position: absolute;
  margin-right: 0.5rem;
  margin-top: 0.5rem;

  @media (min-width: 320px) {
    position: absolute;
    top: 0.725rem;
    right: 0.725rem;
    height: 0.7rem;
    width: 0.7rem;
    margin-left: 27rem;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: ${(props) => props.contentJustify || "space-between"};

  button {
    margin-right: 0.4rem;
    margin-bottom: 1.575rem;
    margin-left: 2.188rem;
    cursor: pointer;
    gap: 0.625rem;
    border-radius: 0.25rem;
    border: 0.063rem solid #cbd6e2;
    padding: 0.6875rem 1.25rem;
    font-family: var(--font-family);
    font-size: var(--font-size-normal);
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
    height: 2.5rem;
    width: 8.638rem;

    &:last-child {
      margin-left: 1.25rem;
      margin-right: 6.75rem;
      background-color: ${(props) => props.backgroundColor || "#CBD6E2"};
      color: ${(props) => props.textColor || "#506E91"};
      border-radius: 0.25rem;
      border: 0px solid #d8d8d8;
    }

    &:first-child {
      background-color: ${(props) => props.backgroundColor || "#E4434A"};
      color: ${(props) => props.textColor || "var(--white-color)"};
      border: 0px solid #d8d8d8;
      border-radius: 0.25rem;
      width: auto;
    }

    @media (max-width: 768px) {
      button {
        width: 7.5rem;
      }
    }
    @media (max-width: 320px) {
      button {
        width: 6rem;
      }
    }
  }
`;

export { DeleteStyledModal, ModalFooter };
