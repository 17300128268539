import "./styles.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { openModal } from '../../../redux/slices/FileUploadSlice/fileUploadSlice';
import { fetchAllLevelsAndQuestions, updateQuestionCount } from "../../../redux/slices/SpellAndVocab/levelSlice";
import { updateSearchKey, setCurrentStatus, toggleActiveInactive, } from '../../../redux/slices/SpellAndVocab/statusSlice';
import {
    Row,
    Col,
    Form,
    Button,
    Dropdown,
    ButtonGroup,
    ToggleButton,
    ToggleButtonGroup,
} from 'react-bootstrap';

function StatusHeader({ onSearch, handleAddWord }) {

    const dispatch = useDispatch();

    const { activeProgramDetails } = useSelector((state) => state.globalData);
    const { currentStatus, isActive, searchKey, statusOptions, statusCountArray } = useSelector((state) => state.status);

    const statusCountMap = statusCountArray || {};

    const activeProgramId = activeProgramDetails?._id;
    useEffect(() => {
        const fetchLevelsAndQuestions = async () => {
            try {
                const response = await dispatch(fetchAllLevelsAndQuestions({
                    programId: activeProgramId,
                    status: currentStatus,
                    active: isActive
                }));
                const newQuestionCount = await response.payload[0].questionCount;
                await dispatch(updateQuestionCount(newQuestionCount));
            } catch (error) {
                console.error("Error fetching levels and questions:", error);
            }
        };
        if (currentStatus !== null && activeProgramId) {
            dispatch(updateSearchKey(''));
            fetchLevelsAndQuestions();
        }
    }, [dispatch, currentStatus, activeProgramId, isActive, statusCountArray]);

    const handleStatusChange = async (newStatusId) => {
        const selectedStatusOption = statusOptions.find(option => option.id === newStatusId);
        if (selectedStatusOption && newStatusId === 9999) {
            dispatch(toggleActiveInactive(!isActive));
            dispatch(setCurrentStatus(9999));
        } else if (selectedStatusOption) {
            if (!isActive) dispatch(toggleActiveInactive(!isActive));
            dispatch(setCurrentStatus(selectedStatusOption.id));
        }
    };

    return (
        <Row>
            <Col md={6}>
                <ToggleButtonGroup
                    type="radio"
                    name="options"
                    value={currentStatus}
                    onChange={handleStatusChange}
                >
                    {statusOptions.map((statusOption) => {
                        const count = statusCountMap[statusOption.label] || 0;
                        return (
                            <ToggleButton
                                key={statusOption.id}
                                variant="light"
                                className={`status-btn mx-1 ${currentStatus === statusOption.id ? "active" : ""}`}
                                value={statusOption.id}
                                id={`status-${statusOption.id}`}
                            >
                                {statusOption.label} ({count})
                            </ToggleButton>
                        );
                    })}
                </ToggleButtonGroup>
            </Col>
            <Col md={2} className="d-flex justify-content-end">
                <Dropdown as={ButtonGroup} className="dropdown-button-spell-vocab">
                    <Button variant="primary" className="dropdown-button-text" onClick={handleAddWord}>Add Word</Button>
                    <Dropdown.Toggle split size="sm" variant="primary" id="dropdown-split-basic"
                        className="dropdown-split-basic" />
                    <Dropdown.Menu>
                        <Dropdown.Item as="button" className="dropdown-button-menu"
                            onClick={() => dispatch(openModal())}>Import Words</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col md={4}>  <Form >
                <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Search"
                    value={searchKey}
                    onChange={(event) => dispatch(updateSearchKey(event.target.value))}
                />
            </Form></Col>
        </Row>
    );
}

export default StatusHeader;