import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  DeleteStyledModal,
  ModalFooter,
  CloseIcon,
} from '../Modal.styled'
import closeIcon from "../../../Assests/Images/Vector.svg";

/**
 * ReactivateModal
 *
 * Component for displaying a delete confirmation modal.
 *
 * @param {object} props - Component props
 * @param {boolean} props.showModal - Flag indicating whether the modal should be displayed.
 * @param {string} props.role - Role for which the deletion is being confirmed
 * @param {function} props.onClose - Function to call when the modal is closed
 * @param {function} props.onSave - Function to call when the deletion is confirmed
 * @returns {JSX.Element} The rendered DeleteModal
 *
 * @author Gaurav
 */

function ReactivateModal({
  showModal,
  role,
  onClose,
  onSave,
  reactivateData,
  onClick,
}) {
  let modalTitle, modalContent;

  switch (role) {
    case "Organization":
    case "Program":
    case "Level":
    case "Domain":
    case "Topic":
    case "Skill":
    case "Question":
      let name = "";
      reactivateData.map((data) => {
        name = data.title;
      });
      modalTitle = ` Do you want to restore this ${role}?`;
      modalContent = `You are about to restore the ${role} "${name}". This action can't be undone.`;
      break;
    case "MultipleLevels":
      modalTitle = `Do you want to restore these levels?`;
      modalContent = `You are about to restore multiple levels. This action can't be undone.`;
      break;
    case "MultipleDomains":
      modalTitle = `Do you want to restore these domains?`;
      modalContent = `You are about to restore multiple domains. This action can't be undone.`;
      break;
    case "MultipleSkills":
      modalTitle = `Do you want to restore these skills?`;
      modalContent = `You are about to restore multiple skills. This action can't be undone.`;
      break;
    case "MultiplePrograms":
      modalTitle = `Do you want to restore these Programs?`;
      modalContent = `You are about to restore multiple programs. This action can't be undone.`;
      break;
    case "MultipleQuestions":
      modalTitle = `Do you want to restore these Questions?`;
      modalContent = `You are about to restore multiple questions. This action can't be undone.`;
      break;
    default:
      modalTitle = "Default Title";
      modalContent = "Default Content";
  }

  return (
    <DeleteStyledModal show={showModal} onHide={onClose} centered>
      <Modal.Title style = {{color:'#016DD9'}} >{modalTitle}</Modal.Title>
      <CloseIcon src={closeIcon} alt="Close" onClick={onClose} />
      <Modal.Body>{modalContent}</Modal.Body>
      <ModalFooter >
        <Button variant="secondary" color="red" onClick={onClick} style = {{backgroundColor:'#016DD9'}}>
          Reactivate
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </DeleteStyledModal>
  );
}

ReactivateModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

export default React.memo(ReactivateModal);
