import { Tab, Accordion } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import Loader from "../../Loader/Loader";
import useAccordionItems from 'Hooks/useAccordianItems';
import { getAllActivities } from 'Utils/ApiHandler/DatatableActionServices/DatatableActionServices';

/**
 * A Tab component for displaying version history of a given entity ID.
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the entity to which the version history belongs.
 * @param {string} props.activeTab - The currently active tab.
 * @param {string} props.email - The email of the user whose version history is being displayed.
 *
 * @returns {JSX.Element} A Tab component displaying the version history of the given entity ID.
 */
const VersionHistoryTab = ({ id, activeTab, email, pageTitle, rowCode }) => {
    const [loading, setLoading] = useState(true);
    const [versionHistoryData, setVersionHistoryData] = useState([]);

    const renderAccordionItems = useAccordionItems();

    useEffect(() => {
        if (activeTab === 'versionHistory') fetchVersionHistory();
    }, [activeTab]);

    /**
     * Fetches the version history for a given entity ID.
     *
     * Initiates a loading state, performs an API call to retrieve all activities
     * associated with the specified feature ID, and transforms the response into
     * a list of objects containing date and history data. Updates the version
     * history state with the fetched data after a delay.
     *
     * Logs an error message to the console if the fetch operation fails,
     * and ensures that the loading state is reset upon completion.
     */
    const fetchVersionHistory = async () => {
        try {
            setLoading(true);
            const response = await getAllActivities({ featureId: id });
            if (!response) throw new Error("No response from server");
            const result = Object.entries(response).map(([date, history]) => ({ date, history }));
            setTimeout(() => {
                setVersionHistoryData(result);
            }, 500);
        } catch (error) {
            console.error("Failed to fetch version history:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Tab.Pane eventKey="versionHistory">
            {loading ? (
                <div className="text-center my-3">
                    <Loader animation="border" role="status" />
                </div>
            ) : versionHistoryData.length > 0 ? (
                <Accordion className="version-history-accordion">
                    {versionHistoryData.map(({ date, history }, index) => (
                        <div key={index}>
                            <span className="parent-version-history-date mx-1">
                                {new Date(parseInt(date)).toLocaleDateString('en-US', {
                                    timeZone: 'UTC',
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit'
                                })}
                            </span>
                            {renderAccordionItems(history, index, pageTitle, rowCode)}
                        </div>
                    ))}
                </Accordion>
            ) : (
                <p>No version history data available.</p>
            )}
        </Tab.Pane>
    );
};

export default VersionHistoryTab;
