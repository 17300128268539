import { fetchToken } from "Constants/ApiUrls";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

/**
 * Function to fetch all organizations based on provided filters
 * 
 * @param {Array} filterData - Array of filter objects
 * 
 * @returns {Object} - Object containing organizations array and totalPages, 
 *  or empty organizations array and totalPages if fetch fails
 * 
 * @author - Gaurav
 */
export const getAllOrganization = async (filterData) => {
  try {
    const filterParams = filterData.map((filter) => {
      if (typeof filter.value == "object" && filter.value.length > 0) {
        let values = ''
        if(filter.name === "title" || filter.name === "code"){
          values = filter.value.map((value) => `${filter.name}[]=${value}`);
        } else{
          values = filter.value.map((value) => `${filter.name}[]=${value.value}`);
        }
        return values.join("&");
      } else {
        return `${filter.name}=${filter.value}`;
      }
    });
    const token = fetchToken();
    const url =
      `${baseUrl}/cms/v1/getAllOrganizations` + "?" + filterParams.join("&");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();
    if (!response.ok) {
      console.error(response);
      toast.error(result.message);
      return { organizations: [], totalPages: 0 };
    } else {
      return result.data;
    }
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};

/**
 * Function to update organization data
 * 
 * @param {Object} data - Data object containing the updated organization 
 *  details
 * 
 * @returns {boolean|Response} - True if organization update is successful, 
 *  false otherwise
 * 
 * @author - Gaurav
 */
export const updateOrganization = async (data) => {
  try {
    const token = fetchToken();
    const url = `${baseUrl}/cms/v1/updateOrganization`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    if (!response.ok || !result.success) {
      toast.error(result.message);
      return false;
    }
    toast.success("Organization Updated successfully");
    return result;
  } catch (error) {
    console.error("Error updating data: ", error);
    return false;
  }
};

/**
 * Function to add a new organization
 * 
 * @param {Object} data - Data object containing the details of the new 
 *  organization
 * 
 * @returns {boolean|Response} - True if organization addition is successful, 
 *  false otherwise
 * 
 * @author - Gaurav
 */
export const addOrganization = async (data) => {
  try {
    const token = fetchToken();
    const url = `${baseUrl}/cms/v1/insertOrganization`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    if (!response.ok || !result.success) {
      toast.error(result.message);
      return false;
    }
    toast.success("Organization Added Successfully");
    return result;
  } catch (error) {
    console.error("Error updating data: ", error);
    return false;
  }
};

/**
 * Function to delete an organization
 * 
 * @param {Object} data - Data object containing the details of the organization
 *  to be deleted
 * 
 * @returns {boolean|Response} - True if organization deletion is successful, 
 *  false otherwise
 * 
 * @author - Gaurav
 */
export const deleteOrganization = async (data) => {
  try {
    const token = fetchToken();
    const url = `${baseUrl}/cms/v1/deleteOrganization`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    if (!response.ok || !result.success) {
      toast.error(result.message);
      return false;
    }
    toast.success("Organization deleted successfully");
    return result;
  } catch (error) {
    console.error("Error updating data: ", error);
    return false;
  }
};

/**
 * Function to reactivate an organization
 * 
 * @param {Object} data - Data object containing the details of the organization
 *  to be reactivated
 * 
 * @returns {boolean|Response} - True if organization reactivation is successful,
 *  false otherwise
 * 
 * @author - Gaurav
 */
export const reactivateOrganization = async (data) => {
  try {
    const token = fetchToken();
    const url = `${baseUrl}/cms/v1/reactivateOrganization`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    if (!response.ok) {
      toast.error(result.message);
      return false;
    }
    toast.success("Organization Reactivated successfully");
    return result;
  } catch (error) {
    console.error("Error updating data: ", error);
    return false;
  }
};

/**
 * Function to fetch organization titles based on search key
 * 
 * @param {string} searchKey - Search key to filter organization titles
 * 
 * @returns {Array} - Array of objects containing organization titles
 * 
 * @author - Gaurav
*/
export const getOrganizationTitles = async () => {
  const token = fetchToken();
    const url = `${baseUrl}/cms/v1/getAllOrganizationTitles`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    if (!response.ok) {
        console.error(response);
        const errorData = await response.json();
        toast.error(errorData.message);
        return [];
    } else {
        let responseData = await response.json();
        return responseData.data.organizationTitles.map((item) => {
            return { label: item.title, value: item._id, id: item._id };
        });
    }
};

export const getOrganizationCodes = async (searchKey) => {
  const token = fetchToken();
  const url = `${baseUrl}/cms/v1/getOrganizationCodes/${searchKey}`;
  const response = await fetch(url, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
      },
  });
  if (!response.ok) {
      const errorData = await response.json();
      toast.error(errorData.message);
      return [];
  } else {
      let responseData = await response.json();
      return responseData.data.organizationCodes.map(item => {
          return { label: item.code, value: item.code }
      });;
  }
};
