import React, { useState, useRef, useEffect } from "react";
import { ImportExportQuestionWrapper, CloseIcon } from "./ImportExportQuestion.styled";
import closeIcon from "../../../Assests/Images/Vector.svg";
import Button from "react-bootstrap/Button";
import { BsFileEarmarkPlusFill } from "react-icons/bs";
import { videoUpload } from "Utils/ApiHandler/SkillsApi";
import videoicon from "../../../Assests/Images/video.png";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AddViewVideoExplanation = ({ showModal, onClose, onSave, skillId, videoUrl }) => {
    const [selectedFile, setSelectedFile] = useState();
    const [file, setFile] = useState();
    const inputFile = useRef(null);
    const [fileSize, setFileSize] = useState(0);
    const [onClickStatus, setOnClickStatus] = useState(true);
    const supportedFormat = ['mp4', 'avi', 'mpeg', 'mov'];
    const [uploadError, setUploadError] = useState([]);
    const [isUpload, setIsUpload] = useState(true);

    useEffect(() => {
        if (videoUrl) {
            setIsUpload(false);
        } else {
            setIsUpload(true);
        }
    }, []);

    const uploadDocument = () => {
        setUploadError([])
        inputFile.current.click();

    }

    const cancelUploadDocument = () => {
        setIsUpload(true);
    }

    const handleFileUpload = e => {
        const { files } = e.target;
        if (files && files.length) {
            const extension = files[0].name.split('.').pop();
            if (!supportedFormat.includes(extension.toLowerCase())) {
                setUploadError(["Invalid file format. Expected file format (MP4, AVI, MPEG, and MOV)"])
                return;
            }
            const fileSizeInBytes = files[0].size;
            const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
            const roundedFileSizeInMB = fileSizeInMB.toFixed(2)

            setSelectedFile(files[0].name);
            setFile(files[0]);
            setFileSize(roundedFileSizeInMB);
        }
    };

    const handleSubmit = async () => {
        await setOnClickStatus(false);
        try {
            const formData = new FormData();
            formData.append('files', file);
            formData.append('skillId', skillId);
            let response = await videoUpload(formData);
            if (response.status) {
                onSave();
                onClose();
            } else {
                setUploadError(response.msg);
                setOnClickStatus(true);
            }
        }
        catch (error) {
            console.error(error)
        }
    }



    return (
        <>
            <ImportExportQuestionWrapper show={showModal} onHide={onClose} centered >
                <div className="title-placement"><label>Add/View Video Explanation</label>
                    <CloseIcon src={closeIcon} alt="Close" onClick={onClose} />
                </div>
                {(uploadError.length) ? <div className="div-error-msg">
                    <div className="show-error-msg">
                        {uploadError.map((msg, index) => (
                            <>
                                <span key={index}><>&bull; </>{msg}</span><br />
                            </>
                        ))}
                    </div>
                </div> : ''}
                {(isUpload) ? <div className="uploadWrapper">
                    <div className="insideWrapper"><BsFileEarmarkPlusFill
                        style={{
                            width: "1.75rem",
                            height: "1.75rem",
                            color: "#016DC9",
                            marginRight: "0.25rem",
                            marginBottom: "0.5rem"
                        }} />
                        Drop files to begin Upload or <div className="redirectLink" onClick={uploadDocument}>Browse files</div></div>
                    <div className="belowContent">Supported file format : MP4, AVI, MPEG, and MOV</div>
                </div> : ''}
                {!isUpload && <>
                    <div className="uploadedSection">Uploaded  Video</div>
                    <div className="selectedSection position-relative">
                        <div className="insidescondWrapper">
                            <div style={{ marginRight: "0.5rem" }}>
                                <img
                                    src={videoicon}
                                    alt="file upload"
                                    style={{ width: "3rem" }}
                                />
                            </div>
                            <div className="uploadedfileName">
                                <a href={videoUrl} target="_blank">View Uploaded Video</a>
                            </div>
                        </div>
                        <div className="closeIcon">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Close this to upload new video file</Tooltip>}>
                                <span><IoIosCloseCircleOutline alt="Close" onClick={cancelUploadDocument} /></span>
                            </OverlayTrigger>
                        </div>
                    </div>
                </>}
                {selectedFile && isUpload && <>
                    <div className="uploadedSection">Upload Video</div>
                    <div className="selectedSection">
                        <div className="insidescondWrapper">
                            <div style={{ marginRight: "0.5rem" }}>
                                <img
                                    src={videoicon}
                                    alt="file upload"
                                    style={{ width: "3rem" }}
                                />
                            </div>
                            <div className="fileName">{selectedFile}
                                <div className="fileSize">{fileSize}MB</div>
                            </div>
                        </div>
                    </div>
                </>}
                <div style={{ display: "flex" }}>
                    {selectedFile && isUpload && <div className="flag-buttons" style={{ marginLeft: "auto" }}>
                        <Button style={{
                            marginRight: '10px',
                            backgroundColor: "white",
                            color: "#016DD9"
                        }} onClick={onClose}>Cancel</Button>
                        <Button onClick={(onClickStatus) ? handleSubmit : undefined}
                            disabled={!onClickStatus}
                        >Upload Video</Button>
                    </div>}
                </div>
                <input
                    style={{ display: "none" }}
                    ref={inputFile}
                    onChange={handleFileUpload}
                    type="file"

                />
            </ImportExportQuestionWrapper>
        </>

    )
}
export default AddViewVideoExplanation;