import "./App.css";
import React from "react";
import Routes from "./Routes/Routes.jsx";

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
