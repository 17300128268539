import React from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';

import "./styles.css"
import CloseIcon from "../../../../Assests/Icons/CloseIcon.svg";
import { statusCountAction } from '../../../../redux/slices/SpellAndVocab/statusSlice';
import {
    closeReactivateModal,
    reactivateActionCall
} from '../../../../redux/slices/DatatableActionSlices/reactivateActionSlice';

const ReactivateModal = () => {

    const dispatch = useDispatch();

    const { activeProgramDetails } = useSelector((state) => state.globalData);
    const { itemName, itemTitle, itemId, key, endPoint } = useSelector((state) => state.reactivate);

    const activeProgramId = activeProgramDetails?._id;

    const handleReactivateActions = async () => {
        const result = await dispatch(reactivateActionCall({ itemId, key, endPoint }));
        if (result) {
            dispatch(closeReactivateModal());
            await dispatch(statusCountAction(activeProgramId));
        }
    }

    return (
        <Card className='reactivate-modal '>
            <Card.Header className='reactivate-modal-header'>
                <span>Do you want to restore this {itemTitle}?</span>
                <img src={CloseIcon} alt="Close icon" onClick={() => dispatch(closeReactivateModal())} /></Card.Header>
            <Card.Body className='reactivate-modal-body'>
                <Card.Text>
                    You are about to restore the {itemTitle}" <span> {itemName}</span>". This action can't be undone.
                </Card.Text>
            </Card.Body>
            <Card.Footer className='reactivate-modal-footer'>
                <button onClick={() => handleReactivateActions()}>Reactivate</button>
                <button onClick={() => dispatch(closeReactivateModal())}>Cancel</button>
            </Card.Footer>
        </Card>
    )
}

export default ReactivateModal