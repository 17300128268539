import React, { useState } from "react";
import PropTypes from "prop-types";
import { FlagInappropriateStyledModal, CloseIcon, FlagInappropriateButton } from "./FlagInappropriateModal.styled";
import closeIcon from "../../../Assests/Images/cross_icon.svg";
import { toast } from 'react-toastify';

function FlagInappropriateModal({ showModal, onClose, onSave, flagComment, setFlagComment, flagText }) {
    const [reason,setReason] = useState(flagComment);
    const handleReasonChange = (event) => {
        setReason(event.target.value);
        setFlagComment(event.target.value);
    }

  const handleSave = () => {
    if(reason === ''){
        toast.error("Reason is mandatory");
        return;
    }
    onSave();
    onClose();
  };

  const modalContent = `Please explain briefly why this ${flagText} is being flagged. 
                        Your feedback is essential for maintaining the quality of our educational community.`;

  return (
    <>
      <FlagInappropriateStyledModal show={showModal} onHide={onClose} centered>
      <CloseIcon src={closeIcon} alt="Close" onClick={onClose} />
        <label>{modalContent}</label>
        <textarea
            placeholder="Write your reason..."
            name="reason"
            onChange = {handleReasonChange}
        />
        <div className="flag-buttons">
            <FlagInappropriateButton onClick = {handleSave}>Submit</FlagInappropriateButton>
        </div>
      </FlagInappropriateStyledModal>
    </>
  );
}

FlagInappropriateModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default React.memo(FlagInappropriateModal);
