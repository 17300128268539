import { toast } from 'react-toastify';
import { baseUrl, fetchToken, levelTitlesUrl } from '../../Constants/ApiUrls';

var token = fetchToken();

export const getAllLevels = async (filterData) => {
    token = fetchToken();
    try {
        const filterParams = filterData
            .filter(filter => filter.value !== null && filter.value !== undefined)
            .map(filter => {
                const { name, value } = filter;
                if (Array.isArray(value) && value.length > 0) {
                    const values = value.map(v => `${name}[]=${encodeURIComponent(name === "title" || name === "code" ||
                        name === "programIds" || name === "status" || name === "organizationIds" || name === "limit"
                        ? v : v.id)}`);
                    return values.join("&");
                }
                return `${name}=${encodeURIComponent(value)}`;
            })
            .join("&");
        const url = `${baseUrl}/cms/v2/getAllLevels?${filterParams}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return { levels: [], totalPages: 0 };
        }
        const { data } = result;
        return data;
    } catch (error) {
        toast.error('Failed to fetch levels. Please try again.');
        throw new Error(error);
    }
};

export const updateLevels = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/updateLevel`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Updated successfully");
        return result.data;
    } catch (error) {
        toast.error('Something went wrong!')
        throw new Error(error);
    }
};

export const addDomain = async (data) => {
    try {
        const url = `${baseUrl}/cms/v2/insertDomain`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Domain Added.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!')
        throw new Error(error);
    }
};

export const deleteLevel = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/deleteLevel`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            console.error(response)
            toast.error(result.message);
            return false;
        }
        toast.success("Level Deleted.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!')
        throw new Error(error);
    }
};

export const getLevelTitles = async (url) => {
    if (url === 'undefined') {
        url = levelTitlesUrl;
    }
    token = localStorage.getItem("token");
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to fetch domain titles");
            return [];
        }
        if (result.data?.levelTitles?.length) {
            return result.data.levelTitles.map(item => ({
                label: item.title,
                value: item.title,
                id: item._id,
            }));
        } else {
            return [];
        }
    } catch (error) {
        toast.error('Something went wrong!')
        throw new Error(error);
    }
};

export const getLevelCodes = async (url) => {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to fetch domain titles");
            return [];
        }
        if (result.data?.levelCodes?.length) {
            return result.data.levelCodes.map(item => ({
                label: item.code,
                value: item.code,
                id: item._id,
            }));
        } else {
            return [];
        }
    } catch (error) {
        toast.error('Something went wrong!')
        throw new Error(error);
    }
};

export const reactivateLevel = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/reactivateLevel`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data)
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Something went wrong!");
            return false;
        }
        toast.success("Level Reactivated.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error)
    }
};

export const updateBulkStatus = async (publish, data) => {
    try {
        let url = '';
        if (publish) {
            url = `${baseUrl}/cms/v1/publishLevel`;
        } else {
            url = `${baseUrl}/cms/v1/unPublishLevel`
        }
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Status Updated.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
}

export const publishLevels = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/publishLevel`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Level Published.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
}

export const unPublishLevels = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/unPublishLevel`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Level Unpublished.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
};

export const getById = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/getLevelById/${data}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        return responseData.data;
    } catch (error) {
        console.error("Error updating data: ", error);
        throw new Error(error);
    }
};
