import styled from "styled-components";
import { Modal, Form } from "react-bootstrap";

export const ImportExportQuestionWrapper = styled(Modal)`
.modal-content {
  width:38.875rem !important;
  height:32.065rem;
  font-family:var(--font-family)
    border-radius: 0.25rem;
    background: var(--white-color); 

    @media (max-width: 425px) {
      width: 90%;
      height: auto;
    }
  }
  .title-placement {
    width:38.875rem;
    height:4rem;
    display: flex;
    justify-content:space-between;
    background-color:rgba(1, 109, 217, 0.10);
    align-items:center;
  }
  .flag-buttons {
    display:flex;
    justify-content:right;
    padding:25px;
  }
  label{
    margin-left:2rem;
    color: var(--black-color);
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 2rem;
  }
  .uploadWrapper{
    border-radius: 0.75rem;
    border:0.78px dashed rgba(0, 108, 215, 0.30);
    margin:1rem 2rem 0rem 2.37rem;
  }

  .insideWrapper {
    display:flex;
    justify-content:space-between;
    padding:2.06rem 6.62rem 0rem 6.62rem;
    color: var(--black-color);
    font-size: var(--font-size-regular);
  }
  .redirectLink{
    color: #016DD9; 
    text-decoration: underline;
    cursor: pointer
  }
  .belowContent {
    color: #6F6F6F;
    font-size:0.6875rem;
    margin:0rem 0rem 2.06rem 12.06rem;
  }
  .uploadedSection {
    margin:2rem 0rem 0.25rem 2rem;
    font-size: var(--font-size-regular);
    color:#737373;
  }
  .selectedSection {
    border-radius: 0.5rem;
    border: 1px solid #F1F1F1;
    margin:0rem 2.38rem 0rem 2rem;
  }

  .insidescondWrapper{
    display:flex;
    padding:1rem 1rem 1.06rem 1rem;
    color: var(--black-color);
    font-size: var(--font-size-medium);
    font-weight:500;
  }
  .fileSize {
    color: #77889C;
    font-family: (--font-family);
    font-size: 0.75rem;
  }
  Button {
     width : 9.5625rem;
     height : 2.5rem;
     font-size: 1rem;
     margin-top:3rem;
  }

  .uploadtoggle {
    width: fit-content; 
    height: 3rem; 
    background: white; 
    border-radius: 100px; 
    border: 1px var(--platinum-gray-color) solid;
    display:flex;
    align-items: center;
    margin-top: 1rem;
    margin-left: 2rem;
  }

  .activeToggle{
    width: fit-content;
    height:2.5rem;
    display: inline-block;
    background: var( --blue-color);
    border-radius: 24px; 
    padding: 0.6rem;
    color: white;
    margin-left:0.25rem !important;
     margin-right: 0.255rem !important;
    padding:0.5rem 1rem 0.25rem 1rem;
  }
    
  .uploadtoggleHighlight{
    width: fit-content;
    height:2.5rem;
    display: inline-block;
    background: var( --blue-color);
    border-radius: 24px; 
    padding: 0.6rem;
    color: white;
    margin-left:0.25rem !important;
     margin-right: 0.255rem !important;
    padding:0.5rem 1rem 0.25rem 1rem;
  }
    .readingtoggleHighlight{
    width: fit-content;
    height:2.5rem;
    display: inline-block;
    background: var( --blue-color);
    border-radius: 24px; 
    padding: 0.6rem;
    color: white;
    margin-left:0.25rem !important;
    margin-right: 0.25rem !important;
     padding:0.5rem 1rem 0.25rem 1rem;
  }
  .uploadtoggleElement {
    margin-top: 1.65rem;
    margin-bottom: 1.65rem;
    font-size: var(--font-size-medium);
    margin-left: 1.25rem;
    margin-right: 1rem;
    cursor:pointer;
}
    .uploadedfileName{
      padding:10px
    }

    @media (max-width: 62rem) { 
      .modal-content {
        width: 80%;
      }
    }

    @media (max-width: 48rem) { 
      .modal-content {
        width: 90%;
      }
    }

    @media (max-width: 425px) {
      width: 90%;
      bottom: 2rem;
      left: 1.5rem;  
    }

    @media (max-width: 375px) {
      width: 80%;
    }

    @media (max-width: 320px) {
      width: 90%;
      left: 0.125rem;
    }
  }
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 0.75969rem;
  height: 0.75975rem;
  flex-shrink: 0;
  margin-right:1.68rem;
  // margin-top: 1.56rem;

  // @media (max-width: 425px) {
  //   margin-left: 21rem;
  // }

  // @media (max-width: 375px) {
  //   margin-left: 18rem;
  // }

  // @media (max-width: 325px) {
  //   margin-left: 15.5rem;
  // }
`;
