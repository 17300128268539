import _ from "lodash";
import { toast } from "react-toastify";
import { getQuestionTypes } from "./QuestionBuilderApi";
import { organizationTitlesUrl, getLevelUrl, getDomainUrl, getTopicUrl, getSkillUrl, getProgramUrl, fetchToken }
    from "Constants/ApiUrls";

var token = fetchToken();

const fetchTitleData = async (url, selectedFilters = {}, inputValue, key, token, limit = 20) => {
    token = fetchToken();
    try {
        if (!inputValue) return [];
        const params = new URLSearchParams();
        Object.entries(selectedFilters).forEach(([paramName, items]) => {
            items.forEach(item => {
                if (item) params.append(paramName, item);
            });
        });
        const fullUrl = `${url}${inputValue}?limit=${limit}&${params.toString()}`;
        const response = await fetch(fullUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok) {
            console.error(response);
            toast.error(result.message);
            return [];
        }
        if (result.success && result.data[key].length > 0) {
            return result.data[key].map(title => ({
                value: title._id,
                label: title.title,
                id: title._id,
            }));
        }
        return [];
    } catch (error) {
        toast.error(`An error occurred while fetching ${key.replace("Titles", "").toLowerCase()} titles.`);
        return [];
    }
};

export const fetchCodes = async (inputCode, codeUrl, getCodes, isActive, limit = 20) => {
    if (!inputCode) return;
    try {
        const url = `${codeUrl}${inputCode}?limit=${limit}&active=${isActive}`;
        const result = await getCodes(url);
        if (result) {
            return result;
        }
        return [];
    } catch (error) {
        toast.error("Error occured while fetching codes.")
        throw new Error(error);
    }
};

export const fetchOrganizationsTitle = async () => {
    try {
        const response = await fetch(organizationTitlesUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
        });
        const result = await response.json();
        if (!response.ok) {
            console.error(response);
            toast.error(result.message);
            return;
        }
        if (result.success && result.data.organizationTitles.length > 0) {
            const organizationTitle = result.data.organizationTitles.map((title) => ({
                value: title._id,
                label: title.title,
                id: title._id,
            }));
            return organizationTitle;
        }
    } catch (error) {
        toast.error("Error fetching Organization");
        return [];
    }
};

export const fetchProgramTitle = async (selectedOrganization = [], inputProgram) => {
    const selectedFilters = { organizationIds: selectedOrganization };
    return await fetchTitleData(getProgramUrl, selectedFilters, inputProgram, "programTitles");
};

export const fetchLevelTitle = async (selectedOrganization = [], selectedProgram = [], inputLevel) => {
    const selectedFilters = { organizationIds: selectedOrganization, programIds: selectedProgram };
    return await fetchTitleData(getLevelUrl, selectedFilters, inputLevel, "levelTitles");
};

export const fetchDomainTitle = async (selectedOrganization = [], selectedProgram = [], selectedLevel = [], inputDomain) => {
    const selectedFilters = { organizationIds: selectedOrganization, programIds: selectedProgram, levelIds: selectedLevel };
    return await fetchTitleData(getDomainUrl, selectedFilters, inputDomain, "domainTitles");
};

export const fetchTopicTitle = async (selectedOrganization = [], selectedProgram = [], selectedLevel = [],
    selectedDomain = [], inputTopic) => {
    const selectedFilters = {
        organizationIds: selectedOrganization,
        programIds: selectedProgram,
        levelIds: selectedLevel,
        domainIds: selectedDomain,
    };
    return await fetchTitleData(getTopicUrl, selectedFilters, inputTopic, "topicTitles");
};

export const fetchSkillTitle = async (selectedOrganization = [], selectedProgram = [], selectedLevel = [],
    selectedDomain = [], selectedTopic = [], inputSkill) => {
    const selectedFilters = {
        organizationIds: selectedOrganization,
        programIds: selectedProgram,
        levelIds: selectedLevel,
        domainIds: selectedDomain,
        topicIds: selectedTopic,
    };
    return await fetchTitleData(getSkillUrl, selectedFilters, inputSkill, "skillTitles");
};

export const fetchQuestionTypes = async () => {
    try {
        const types = await getQuestionTypes();
        const questionTypesData = types.questionTypes;
        const questionTypesOptions = Object.entries(questionTypesData).map(
            ([label, value]) => ({
                label: label,
                value: value,
            })
        );
        return questionTypesOptions;
    } catch (error) {
        toast.error("Error fetching question types");
        return [];
    }
};