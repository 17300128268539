import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function RolePermissions({ handleNext,
    settingsAccess,
    setSettingsAccess,
    curriculumAccess,
    setCurriculumAccess }) {
    const [showSettingAccess, setShowSettingAccess] = useState(true);

    return (
        <div>
            <div className="sub-title">Set Permissions</div>
            <div className="role-toggle">
                <div className={"toggleElement" + (showSettingAccess ? " toggleHighlight" : "")}
                    onClick={() => setShowSettingAccess(true)} style={{ marginLeft: "0.5rem" }}>
                    <div style={{ marginLeft: "0.75rem" }}>Settings Access</div></div>
                <div className={"toggleElement" + (showSettingAccess ? " " : " toggleHighlight")}
                    onClick={() => setShowSettingAccess(false)}>
                    <div style={{ marginLeft: "0.3rem" }}>Curriculum Access</div></div>
            </div>
            <div>
                {showSettingAccess && <SettingsAccess
                    setShowSettingAccess={setShowSettingAccess}
                    settingsAccess={settingsAccess}
                    setSettingsAccess={setSettingsAccess} />}
                {!showSettingAccess && <CurriculumAccess
                    handleCurriculumNext={handleNext}
                    curriculumAccess={curriculumAccess}
                    setCurriculumAccess={setCurriculumAccess} />}
            </div>
        </div>
    )
}

const SettingsAccess = ({ setShowSettingAccess, settingsAccess, setSettingsAccess }) => {
    const handleCheckboxChange = (category, action, value) => {
        setSettingsAccess(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [action]: value
            }
        }));
    };
    return (
        <>
            <div className="accessCards">
                <div className="accessTypes organizationAccess" >Organizations</div>
                <div className="accessOptions">View</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions"
                    checked={settingsAccess.organizations?.view || false}
                    onClick={(e) => handleCheckboxChange("organizations", "view", e.target.checked)} />
                <div className="accessOptions">Add</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkboxOptions"
                    checked={settingsAccess.organizations?.add || false}
                    onClick={(e) => handleCheckboxChange("organizations", "add", e.target.checked)} />
                <div className="accessOptions">Edit</div>
                <input type="checkbox" id="editCheckbox"
                    name="editCheckbox" className="checkboxOptions"
                    checked={settingsAccess.organizations?.edit || false}
                    onClick={(e) => handleCheckboxChange("organizations", "edit", e.target.checked)} />
                <div className="accessOptions">Delete</div>
                <input type="checkbox" id="deleteCheckbox"
                    name="deleteCheckbox" className="checkBoxLastOption"
                    checked={settingsAccess.organizations?.delete || false}
                    onClick={(e) => handleCheckboxChange("organizations", "delete", e.target.checked)} />
            </div>
            <div className="accessCards">
                <div className="accessTypes usersAccess" >Users</div>
                <div className="accessOptions">View</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions"
                    checked={settingsAccess.users?.view || false}
                    onClick={(e) => handleCheckboxChange("users", "view", e.target.checked)} />
                <div className="accessOptions">Add</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkboxOptions"
                    checked={settingsAccess.users?.add || false}
                    onClick={(e) => handleCheckboxChange("users", "add", e.target.checked)} />
                <div className="accessOptions">Edit</div>
                <input type="checkbox" id="editCheckbox"
                    name="editCheckbox" className="checkboxOptions"
                    checked={settingsAccess.users?.edit || false}
                    onClick={(e) => handleCheckboxChange("users", "edit", e.target.checked)} />
                <div className="accessOptions">Delete</div>
                <input type="checkbox" id="deleteCheckbox"
                    name="deleteCheckbox" className="checkBoxLastOption"
                    checked={settingsAccess.users?.delete || false}
                    onClick={(e) => handleCheckboxChange("users", "delete", e.target.checked)} />
            </div>
            <div className="accessCards">
                <div className="accessTypes teamsAccess">Teams</div>
                <div className="accessOptions">View</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions"
                    checked={settingsAccess.teams?.view || false}
                    onClick={(e) => handleCheckboxChange("teams", "view", e.target.checked)} />
                <div className="accessOptions">Add</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkboxOptions"
                    checked={settingsAccess.teams?.add || false}
                    onClick={(e) => handleCheckboxChange("teams", "add", e.target.checked)} />
                <div className="accessOptions">Edit</div>
                <input type="checkbox" id="editCheckbox"
                    name="editCheckbox" className="checkboxOptions"
                    checked={settingsAccess.teams?.edit || false}
                    onClick={(e) => handleCheckboxChange("teams", "edit", e.target.checked)} />
                <div className="accessOptions">Delete</div>
                <input type="checkbox" id="deleteCheckbox"
                    name="deleteCheckbox" className="checkBoxLastOption"
                    checked={settingsAccess.teams?.delete || false}
                    onClick={(e) => handleCheckboxChange("teams", "delete", e.target.checked)} />
            </div>
            <div className="accessCards" style={{ borderBottom: "1px solid #F1F1F1" }}>
                <div className="accessTypes rolesAccess">Roles</div>
                <div className="accessOptions">View</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions "
                    checked={settingsAccess.roles?.view || false}
                    onClick={(e) => handleCheckboxChange("roles", "view", e.target.checked)} />
                <div className="accessOptions">Add</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkboxOptions"
                    checked={settingsAccess.roles?.add || false}
                    onClick={(e) => handleCheckboxChange("roles", "add", e.target.checked)} />
                <div className="accessOptions">Edit</div>
                <input type="checkbox" id="editCheckbox"
                    name="editCheckbox" className="checkboxOptions"
                    checked={settingsAccess.roles?.edit || false}
                    onClick={(e) => handleCheckboxChange("roles", "edit", e.target.checked)} />
                <div className="accessOptions">Delete</div>
                <input type="checkbox" id="deleteCheckbox"
                    name="deleteCheckbox" className="checkBoxLastOption"
                    checked={settingsAccess.roles?.delete || false}
                    onClick={(e) => handleCheckboxChange("roles", "delete", e.target.checked)} />
            </div>
            <button type="button" className=" buttonRole settingAccessButton"
                onClick={() => setShowSettingAccess(false)} >Next</button>
        </>
    )
}

const CurriculumAccess = ({ handleCurriculumNext, curriculumAccess, setCurriculumAccess }) => {
    const handleCheckboxChange = (category, action, value) => {
        setCurriculumAccess(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [action]: value
            }
        }));

        console.log(curriculumAccess);
    };

    const validateAndProceed = () => {
        const { publishCurriculum, unPublishCurriculum, exportCurriculum } = curriculumAccess;
        const missingFields = [];
        if (publishCurriculum === undefined) missingFields.push("publish");
        if (unPublishCurriculum === undefined) missingFields.push("unpublish");
        if (exportCurriculum === undefined) missingFields.push("export");

        if (missingFields.length > 0) {
            toast.error(`Please select ${missingFields.join(", ")}.`);
        } else {
            handleCurriculumNext();
        }
    };

    return (
        <>
            <div className="accessCards">
                <div className="accessTypes programsAccess" >Programs</div>
                <div className="accessOptions">View</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.programs?.view || false}
                    onClick={(e) => handleCheckboxChange("programs", "view", e.target.checked)} />
                <div className="accessOptions">Add</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.programs?.add || false}
                    onClick={(e) => handleCheckboxChange("programs", "add", e.target.checked)} />
                <div className="accessOptions">Edit</div>
                <input type="checkbox" id="editCheckbox"
                    name="editCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.programs?.edit || false}
                    onClick={(e) => handleCheckboxChange("programs", "edit", e.target.checked)} />
                <div className="accessOptions">Delete</div>
                <input type="checkbox" id="deleteCheckbox"
                    name="deleteCheckbox" className="checkBoxLastOption"
                    checked={curriculumAccess.programs?.delete || false}
                    onClick={(e) => handleCheckboxChange("programs", "delete", e.target.checked)} />
            </div>
            <div className="accessCards">
                <div className="accessTypes levelsAccess">Levels</div>
                <div className="accessOptions">View</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.levels?.view || false}
                    onClick={(e) => handleCheckboxChange("levels", "view", e.target.checked)} />
                <div className="accessOptions">Add</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.levels?.add || false}
                    onClick={(e) => handleCheckboxChange("levels", "add", e.target.checked)} />
                <div className="accessOptions">Edit</div>
                <input type="checkbox" id="editCheckbox"
                    name="editCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.levels?.edit || false}
                    onClick={(e) => handleCheckboxChange("levels", "edit", e.target.checked)} />
                <div className="accessOptions">Delete</div>
                <input type="checkbox" id="deleteCheckbox"
                    name="deleteCheckbox" className="checkBoxLastOption"
                    checked={curriculumAccess.levels?.delete || false}
                    onClick={(e) => handleCheckboxChange("levels", "delete", e.target.checked)} />
            </div>
            <div className="accessCards">
                <div className="accessTypes domainsAccess">Domains</div>
                <div className="accessOptions">View</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.domains?.view || false}
                    onClick={(e) => handleCheckboxChange("domains", "view", e.target.checked)} />
                <div className="accessOptions">Add</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.domains?.add || false}
                    onClick={(e) => handleCheckboxChange("domains", "add", e.target.checked)} />
                <div className="accessOptions">Edit</div>
                <input type="checkbox" id="editCheckbox"
                    name="editCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.domains?.edit || false}
                    onClick={(e) => handleCheckboxChange("domains", "edit", e.target.checked)} />
                <div className="accessOptions">Delete</div>
                <input type="checkbox" id="deleteCheckbox"
                    name="deleteCheckbox" className="checkBoxLastOption"
                    checked={curriculumAccess.domains?.delete || false}
                    onClick={(e) => handleCheckboxChange("domains", "delete", e.target.checked)} />
            </div>
            <div className="accessCards">
                <div className="accessTypes topicsAccess" >Topics</div>
                <div className="accessOptions">View</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.topics?.view || false}
                    onClick={(e) => handleCheckboxChange("topics", "view", e.target.checked)} />
                <div className="accessOptions">Add</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.topics?.add || false}
                    onClick={(e) => handleCheckboxChange("topics", "add", e.target.checked)} />
                <div className="accessOptions">Edit</div>
                <input type="checkbox" id="editCheckbox"
                    name="editCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.topics?.edit || false}
                    onClick={(e) => handleCheckboxChange("topics", "edit", e.target.checked)} />
                <div className="accessOptions">Delete</div>
                <input type="checkbox" id="deleteCheckbox"
                    name="deleteCheckbox" className="checkBoxLastOption"
                    checked={curriculumAccess.topics?.delete || false}
                    onClick={(e) => handleCheckboxChange("topics", "delete", e.target.checked)} />
            </div>
            <div className="accessCards">
                <div className="accessTypes skillsAccess" >Skills</div>
                <div className="accessOptions">View</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.skills?.view || false}
                    onClick={(e) => handleCheckboxChange("skills", "view", e.target.checked)} />
                <div className="accessOptions">Add</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.skills?.add || false}
                    onClick={(e) => handleCheckboxChange("skills", "add", e.target.checked)} />
                <div className="accessOptions">Edit</div>
                <input type="checkbox" id="editCheckbox"
                    name="editCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.skills?.edit || false}
                    onClick={(e) => handleCheckboxChange("skills", "edit", e.target.checked)} />
                <div className="accessOptions">Delete</div>
                <input type="checkbox" id="deleteCheckbox"
                    name="deleteCheckbox" className="checkBoxLastOption"
                    checked={curriculumAccess.skills?.delete || false}
                    onClick={(e) => handleCheckboxChange("skills", "delete", e.target.checked)} />
            </div>
            <div className="accessCards">
                <div className="accessTypes questionsAccess" >Questions</div>
                <div className="accessOptions">View</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.questions?.view || false}
                    onClick={(e) => handleCheckboxChange("questions", "view", e.target.checked)} />
                <div className="accessOptions">Add</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.questions?.add || false}
                    onClick={(e) => handleCheckboxChange("questions", "add", e.target.checked)} />
                <div className="accessOptions">Edit</div>
                <input type="checkbox" id="editCheckbox"
                    name="editCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.questions?.edit || false}
                    onClick={(e) => handleCheckboxChange("questions", "edit", e.target.checked)} />
                <div className="accessOptions">Delete</div>
                <input type="checkbox" id="deleteCheckbox"
                    name="deleteCheckbox" className="checkBoxLastOption"
                    checked={curriculumAccess.questions?.delete || false}
                    onClick={(e) => handleCheckboxChange("questions", "delete", e.target.checked)} />
            </div>
            <div className="accessCards">
                <div className="accessTypes flaggingAccess">Flagging</div>
                <div className="accessOptions">View</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.flagging?.view || false}
                    onClick={(e) => handleCheckboxChange("flagging", "view", e.target.checked)} />
                <div className="accessOptions">Add</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.flagging?.add || false}
                    onClick={(e) => handleCheckboxChange("flagging", "add", e.target.checked)} />
                <div className="accessOptions">Edit</div>
                <input type="checkbox" id="editCheckbox"
                    name="editCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.flagging?.edit || false}
                    onClick={(e) => handleCheckboxChange("flagging", "edit", e.target.checked)} />
                <div className="accessOptions">Delete</div>
                <input type="checkbox" id="deleteCheckbox"
                    name="deleteCheckbox" className="checkBoxLastOption"
                    checked={curriculumAccess.flagging?.delete || false}
                    onClick={(e) => handleCheckboxChange("flagging", "delete", e.target.checked)} />
            </div>
            <div className="accessCards">
                <div className="accessTypes addCommentAccess" >Add Comment</div>
                <div className="accessOptions">View</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.comments?.view || false}
                    onClick={(e) => handleCheckboxChange("comments", "view", e.target.checked)} />
                <div className="accessOptions">Add</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.comments?.add || false}
                    onClick={(e) => handleCheckboxChange("comments", "add", e.target.checked)} />
                <div className="accessOptions">Edit</div>
                <input type="checkbox" id="editCheckbox"
                    name="editCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.comments?.edit || false}
                    onClick={(e) => handleCheckboxChange("comments", "edit", e.target.checked)} />
                <div className="accessOptions">Delete</div>
                <input type="checkbox" id="deleteCheckbox"
                    name="deleteCheckbox" className="checkBoxLastOption"
                    checked={curriculumAccess.comments?.delete || false}
                    onClick={(e) => handleCheckboxChange("comments", "delete", e.target.checked)} />
            </div>
            <div className="accessCards">
                <div className="accessTypes assignmentAccess" >Assignments</div>
                <div className="accessOptions">View</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.assignments?.view || false}
                    onClick={(e) => handleCheckboxChange("assignments", "view", e.target.checked)} />
                <div className="accessOptions">Add</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.assignments?.add || false}
                    onClick={(e) => handleCheckboxChange("assignments", "add", e.target.checked)} />
                <div className="accessOptions">Edit</div>
                <input type="checkbox" id="editCheckbox"
                    name="editCheckbox" className="checkboxOptions"
                    checked={curriculumAccess.assignments?.edit || false}
                    onClick={(e) => handleCheckboxChange("assignments", "edit", e.target.checked)} />
                <div className="accessOptions">Delete</div>
                <input type="checkbox" id="deleteCheckbox"
                    name="deleteCheckbox" className="checkBoxLastOption"
                    checked={curriculumAccess.assignments?.delete || false}
                    onClick={(e) => handleCheckboxChange("assignments", "delete", e.target.checked)} />
            </div>
            {/* <div className="accessCards">
                <div className="accessTypes assignmentAccess" >Assignments</div>
                <div className="accessOptions" style={{ marginLeft: "1.2rem" }}>Assign</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions checkboxAssignment"
                    style={{ marginLeft: "0.5rem" }}
                    checked={curriculumAccess.assignments?.assign || false}
                    onClick={(e) => handleCheckboxChange("assignments", "assign", e.target.checked)} />
                <div className="accessOptions" style={{ marginLeft: "0rem" }}>Unassign</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkBoxLastOption"
                    checked={curriculumAccess.assignments?.unAssign || false}
                    onClick={(e) => handleCheckboxChange("assignments", "unAssign", e.target.checked)} />
            </div> */}

            {/* <div className="accessCards">
                <div className="accessTypes testAccess" >Tests</div>
                <div className="accessOptions" style={{ marginLeft: "1.2rem" }}>Assign</div>
                <input type="checkbox" id="viewCheckbox"
                    name="viewCheckbox" className="checkboxOptions checkboxAssignment"
                    style={{ marginLeft: "0.5rem" }}
                    checked={curriculumAccess.tests?.assign || false}
                    onClick={(e) => handleCheckboxChange("tests", "assign", e.target.checked)} />
                <div className="accessOptions" style={{ marginLeft: "0rem" }}>Unassign</div>
                <input type="checkbox" id="addCheckbox"
                    name="addCheckbox" className="checkBoxLastOption"
                    checked={curriculumAccess.tests?.unAssign || false}
                    onClick={(e) => handleCheckboxChange("tests", "unAssign", e.target.checked)} />
            </div> */}
            <div className="accessCards" style={{ borderBottom: "1px solid #F1F1F1" }}>
                <div className="accessTypes publishAccess">Publish</div>
                <div style={{ display: "flex", alignContent: "center" }}>
                    <input type="radio" id="yesRadio" name="publishRadio"
                        value="yes" className="radioOptions"
                        checked={curriculumAccess.publishCurriculum === true}
                        onChange={() => setCurriculumAccess(prevState =>
                            ({ ...prevState, publishCurriculum: true }))} />
                    <div className="radioOptionsLabel publishRadioOption" >Yes</div>
                </div>
                <div style={{ display: "flex", alignContent: "center" }}>
                    <input type="radio" id="noRadio" name="publishRadio"
                        value="no" className="radioOptions"
                        checked={curriculumAccess.publishCurriculum === false}
                        onChange={() => setCurriculumAccess(prevState =>
                            ({ ...prevState, publishCurriculum: false }))} />
                    <div className="radioOptionsLabel">No</div>
                </div>
            </div>
            <div className="accessCards" style={{ borderBottom: "1px solid #F1F1F1" }}>
                <div className="accessTypes publishAccess">Unpublish</div>
                <div style={{ display: "flex", alignContent: "center", marginLeft: "-1.2rem" }}>
                    <input type="radio" id="yesRadio" name="unpublishRadio"
                        value="yes" className="radioOptions"
                        checked={curriculumAccess.unPublishCurriculum === true}
                        onChange={() => setCurriculumAccess(prevState =>
                            ({ ...prevState, unPublishCurriculum: true }))} />
                    <div className="radioOptionsLabel publishRadioOption" >Yes</div>
                </div>
                <div style={{ display: "flex", alignContent: "center" }}>
                    <input type="radio" id="noRadio" name="unpublishRadio"
                        value="no" className="radioOptions"
                        checked={curriculumAccess.unPublishCurriculum === false}
                        onChange={() => setCurriculumAccess(prevState =>
                            ({ ...prevState, unPublishCurriculum: false }))} />
                    <div className="radioOptionsLabel">No</div>
                </div>
            </div>
            <div className="accessCards" style={{ borderBottom: "1px solid #F1F1F1" }}>
                <div className="exportAccessTypes">Export</div>
                <div style={{ display: "flex", alignContent: "center" }}>
                    <input type="radio" id="yesRadio" name="exportRadio"
                        value="yes" className="radioOptions"
                        checked={curriculumAccess.exportCurriculum === true}
                        onChange={() => setCurriculumAccess(prevState =>
                            ({ ...prevState, exportCurriculum: true }))} />
                    <div className="radioOptionsLabel publishRadioOption" >Yes</div>
                </div>
                <div style={{ display: "flex", alignContent: "center" }}>
                    <input type="radio" id="noRadio" name="exportRadio"
                        value="no" className="radioOptions"
                        checked={curriculumAccess.exportCurriculum === false}
                        onChange={() => setCurriculumAccess(prevState =>
                            ({ ...prevState, exportCurriculum: false }))} />
                    <div className="radioOptionsLabel">No</div>
                </div>
            </div>
            <button type="button" className=" buttonRole curriculumButton" style={{ marginRight: "8.5rem" }}
                onClick={validateAndProceed}>Next</button>
        </>
    );
}

export default RolePermissions;