import { Table } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'Components/Loader/Loader';
import { selectedLevel } from '../../../redux/slices/SpellAndVocab/levelSlice';
import { updateSearchKey } from '../../../redux/slices/SpellAndVocab/statusSlice';

const SideTable = ({ setCurrentPage }) => {
    const dispatch = useDispatch();

    const { currentStatus } = useSelector((state) => state.status);
    const { levels, loading, error, currentSelectedLevel } = useSelector((state) => state.level);

    useEffect(() => {
        const fetchData = async () => {
            if (Array.isArray(levels) && levels.length > 0) {
                const matchingLevel = levels.find(level => level._id === currentSelectedLevel
                    || level.title === currentSelectedLevel);
                const levelToDispatch = matchingLevel || levels[0];
                if (levelToDispatch) {
                    dispatch(selectedLevel(levelToDispatch));
                } else {
                    console.error('No levels available.');
                }
            }
        };
        fetchData();
    }, [currentStatus, levels]);

    if (loading) {
        return (
            <div style={{
                display: "flex", alignItems: "center", justifyContent: "center", position: "relative",
                top: "40%", left: '2%'
            }}>
                <Loader />
            </div>
        );
    }

    if (error) return <div>Error: {error.message || 'An error occurred.'}</div>;

    const handleLevelClick = (levelData) => {
        if (levelData) {
            setCurrentPage(1);
            dispatch(selectedLevel(levelData));
            dispatch(updateSearchKey(''));
        } else {
            console.error('Invalid level data.');
        }
    };

    return (
        <Table responsive className='tabel-label-hover'>
            <tbody>
                {Array.isArray(levels) && levels.length > 0 ? (
                    levels.map((levelData) => (
                        <tr
                            key={levelData._id || levelData.title}
                            onClick={() => handleLevelClick(levelData)}
                            className={currentSelectedLevel === levelData._id ? 'active' : ''}
                        >
                            <td className='p-3 text-center'>
                                <span style={{ fontWeight: "600" }}>{levelData.title}</span>
                            </td>
                            <td className='p-3 text-center'>
                                <span style={{ fontWeight: "600" }}>{levelData.questionCount}</span>
                                <span style={{ fontWeight: "400", fontSize: "0.75rem" }}> Words</span>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="2" className="p-3 text-center">
                            No levels found.
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
};

export default SideTable;
