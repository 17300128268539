import { toast } from 'react-toastify';
import { baseUrl, fetchToken } from 'Constants/ApiUrls';

var token = fetchToken();

const buildFilterParams = (filterData) => {
    token = fetchToken();
    return filterData.map((filter) => {
        if (typeof filter.value == "object" && filter.value.length > 0) {
            let values = ''
            if (filter.name === "title" || filter.name === "code" || filter.name === "organizationIds"
                || filter.name === "programIds" || filter.name === "limit") {
                values = filter.value.map((value) => `${filter.name}[]=${value}`);
            } else {
                values = filter.value.map((value) => `${filter.name}[]=${value.id}`);
            }
            return values.join("&");
        } else {
            return `${filter.name}=${filter.value}`;
        }
    });
}

export const getAllPrograms = async (filterData) => {
    const filterParams = buildFilterParams(filterData);
    const url = `${baseUrl}/cms/v2/getAllPrograms` + "?" + filterParams.join("&");
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return { programs: [], totalPages: 0 };
        } else {
            return result.data;
        }
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
};
export const addProgram = async (data) => {
    try {
        const url = `${baseUrl}/cms/v2/insertProgram`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Program Added.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
}
export const updateProgram = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/updateProgram`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Updated successfully.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
};
export const addLevel = async (data) => {
    try {
        const url = `${baseUrl}/cms/v2/insertLevel`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Level Added.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
}
export const reactivateProgram = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/reactivateProgram`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Program Reactivated.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
}
export const deleteProgram = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/deleteProgram`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Program deleted.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
}
export const updateBulkProgramStatus = async (publish, data) => {
    try {
        let url = '';
        if (publish) {
            url = `${baseUrl}/cms/v1/publishProgram`;
        } else {
            url = `${baseUrl}/cms/v1/unPublishProgram`
        }
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Status updated.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
}
export const getProgramTitles = async (url) => {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return [];
        } else {
            return result.data.programTitles.map((item) => {
                return { label: item.title, value: item.title, id: item._id };
            });
        }
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};
export const getProgramCodes = async (url) => {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return [];
        } else {
            return result.data.programCodes.map((item) => {
                return { label: item.code, value: item.code, id: item._id };
            });
        }
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};
export const publishProgram = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/publishProgram`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Program published.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!');
        throw new Error(error);
    }
}
export const unPublishPrograms = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/unPublishProgram`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Program Unpublished.");
        return response;
    } catch (error) {
        toast.error('Something went wrong');
        throw new Error(error);
    }
}