import styled from "styled-components";

export const Error404Wrapper = styled.div`
  `;

export const Cont404 = styled.div`

  
  .img-404 {
    max-width: 50rem;
    max-height: 50rem;
  }
`;
