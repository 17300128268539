import { toast } from 'react-toastify';
import { Form, Card, Tab } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import Loader from "../../Loader/Loader";
import { Toggle } from './CommentsModal.styled';
import flag from "../../../Assests/Images/flag.svg";
import image from "../../../Assests/Images/user-profile.png";
import useRelativeTimeElapsed from 'Hooks/useRelativeTimeElapsed';
import { capitalizeString } from "Utils/StringUtilities";
import sendCommentIcon from "../../../Assests/Images/sendCommentIcon.svg";
import { getAllComments, insertComments } from 'Utils/ApiHandler/DatatableActionServices/DatatableActionServices';

/**
 * Renders a comment card with the commenter's name, text, and timestamp.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.comment - The comment data.
 * @param {string} props.comment.commentedBy - The name of the person who made the comment.
 * @param {string} props.comment.text - The text of the comment.
 * @param {boolean} props.comment.isFlag - Indicates if the comment is flagged.
 * @param {number} props.comment.timestamp - The timestamp of when the comment was made.
 * @param {Function} props.isAuthorCurrentUser - Function to check if the comment is by the logged-in user.
 *
 * @returns {JSX.Element} A styled card component displaying the comment details.
 */
const CommentCard = ({ comment, isAuthorCurrentUser }) => {

    const relativeTime = useRelativeTimeElapsed(comment.timestamp);

    return (
        <Card className="comments-card mt-2 card-anim">
            <Card.Body>
                <div className="d-flex align-items-center">
                    <img src={image} className="me-3     comments-profile-image" alt="Profile" />
                    <span className="commented-by">
                        {comment.commentedBy && isAuthorCurrentUser(comment.commentedBy)
                            ? `${capitalizeString(comment.commentedBy)} (you)`
                            : capitalizeString(comment.commentedBy || '')}
                    </span>
                    {comment.isFlag && <img src={flag} alt="Flagged" className="flag-icon" />}
                </div>
                <Card.Text>
                    <div className="comments-text">{comment.text}</div>
                    <div className="comments-time mt-2">{relativeTime}</div>
                </Card.Text>
            </Card.Body>
        </Card>

    );
}

/**
 * A Tab component for displaying comments and allowing the user to add new comments.
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the entity to which the comments belong.
 * @param {boolean} props.show - Whether the component should be shown.
 * @param {string} props.pageTitle - The pageTitle of the tab.
 * @param {string} props.access - The access level required to view the comments.
 * @param {Function} props.onSave - Callback function to call when the user submits a new comment.
 * @param {string} props.activeTab - The currently active tab.
 *
 * @returns {JSX.Element} A Tab component displaying the comments and a form to add new ones.
 */
const CommentsTab = ({ id, show, pageTitle, access, onSave, activeTab }) => {

    const fetchController = new AbortController();

    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const [isFlagged, setIsFlagged] = useState(false);
    const [formData, setFormData] = useState({ id, text: '', isFlag: false });

    useEffect(() => {
        if ((activeTab === 'comments' || show) && id) fetchComments(id, isFlagged);
        return () => fetchController.abort();
    }, [show, id, isFlagged, activeTab]);

    /**
     * Fetches comments for a given entity ID and flag status.
     *
     * @param {number} id - The ID of the entity to fetch comments for.
     * @param {boolean} isFlagged - Whether to fetch flagged comments.
     *
     * @returns {Promise<void>} A promise that resolves when the comments have been fetched.
     */
    const fetchComments = _.debounce(async (id, isFlagged) => {
        setLoading(true);
        try {
            const { comments: fetchedComments } = await getAllComments(id, isFlagged, pageTitle);
            setComments(fetchedComments || []);
        } catch (error) {
            console.error("Error fetching comments:", error);
        } finally {
            setLoading(false);
        }
    }, 500);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        setFormData((prev) => ({ ...prev, [name]: fieldValue }));

    };

    /**
     * Checks if the author of a comment is the currently logged-in user.
     * @param {string} authorName - The name of the comment author.
     * @returns {boolean} true if the comment author is the logged-in user, false otherwise.
     */
    const isAuthorCurrentUser = (authorName) => {
        const fullName = `${localStorage.getItem("first_name")} ${localStorage.getItem("last_name")}`;
        return fullName === authorName;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.text.trim()) {
            toast.error("Please drop a message");
            return;
        }
        try {
            await insertComments({ id, text: formData.text }, pageTitle);
            setComments((prev) => [
                ...prev,
                { ...formData, timestamp: new Date().toISOString() },
            ]);
            setFormData({ id, text: '', isFlag: false });
            fetchComments(id, isFlagged);
            onSave?.();
        } catch (error) {
            console.error("Error submitting comment:", error);
            toast.error("An error occurred while saving your comment.");
        }
    };

    return (
        <Tab.Pane eventKey="comments">
            {access && (
                <Form onSubmit={handleSubmit}>
                    <div className="position-relative d-flex align-items-center justify-content-end">
                        <Form.Control
                            as="textarea"
                            name="text"
                            placeholder="Drop a comment..."
                            value={formData.text}
                            onChange={handleInputChange}
                            required
                            className="comments-form-control"
                        />
                        <img
                            src={sendCommentIcon}
                            alt="Send"
                            onClick={handleSubmit}
                            className="send-comment-icon position-sticky"
                        />
                    </div>
                    <Form.Group>
                        <Toggle>
                            <span className="mx-1 me-2">Flagged</span>
                            <div className="form-switch filter-toggle">
                                <input
                                    id="toggle-flagged"
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={isFlagged}
                                    onChange={(e) => setIsFlagged(e.target.checked)}
                                    name="isFlagged"
                                />
                                <label htmlFor="toggle-flagged" className="label"></label>
                            </div>
                        </Toggle>
                    </Form.Group>
                </Form>
            )}
            {loading ? (
                <div className="text-center my-3"><Loader animation="border" role="status" /></div>
            ) : (
                comments.slice(0).reverse().map((comment, index) => (
                    <CommentCard key={index} comment={comment} isAuthorCurrentUser={isAuthorCurrentUser} />
                ))
            )}
        </Tab.Pane>
    );
};

export default CommentsTab;
