import { Card } from 'react-bootstrap';
import React, { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from "../../../../Assests/Icons/CloseIcon.svg";
import AddFilesIcon from "../../../../Assests/Icons/AddFilesIcon.svg";
import CSVFileIcon from "../../../../Assests/Icons/CSVFileIcon.svg";
import ExcelFileIcon from "../../../../Assests/Icons/ExcelFileIcon.svg";

import {
    uploadedFile,
    closeModal,
    setDragState,
    showInvalidFileMessage,
    bulkUploadCall
} from '../../../../redux/slices/FileUploadSlice/fileUploadSlice';
import { ThreeDots } from 'react-loader-spinner';
import { fetchAllLevelsAndQuestions } from '../../../../redux/slices/SpellAndVocab/levelSlice';
import { openErrorModal } from "../../../../redux/slices/ValidationErrorSlices/validationErrorSlice";
import { statusCountAction } from '../../../../redux/slices/SpellAndVocab/statusSlice';

const BulkImportModal = ({ fileTypes, endPoint }) => {

    const dispatch = useDispatch();
    const fileInputRef = useRef(null);

    const {
        file,
        error,
        loading,
        isDragging,
        uploadedFileName,
        uploadedFileSize,
        uploadedFileType,
        showValidationMessage,
    } = useSelector((state) => state.fileUpload);

    const { currentSelectedLevel } = useSelector((state) => state.level);
    const { currentStatus, isActive } = useSelector((state) => state.status);
    const { activeProgramDetails } = useSelector((state) => state.globalData);
    const activeProgramId = activeProgramDetails._id;

    const handleFileUpload = useCallback((file) => {
        if (!file) return;
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const isValidFileType = fileTypes.some((type) => type.replace('.', '').toLowerCase() === fileExtension);
        if (isValidFileType) {
            const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
            dispatch(uploadedFile({
                file: file,
                fileName: file.name,
                fileSize: fileSizeInMB,
                fileType: file.type,
            }));
        } else {
            dispatch(showInvalidFileMessage());
        }
    }, [dispatch, fileTypes]);

    const handleFileChange = useCallback((event) => {
        const file = event.target.files[0];
        handleFileUpload(file);
    }, [handleFileUpload]);

    const handleDragOver = (event) => {
        event.preventDefault();
        dispatch(setDragState(true));
    };

    const handleDrop = (event) => {
        event.preventDefault();
        dispatch(setDragState(false));
        const file = event.dataTransfer.files[0];
        handleFileUpload(file);
    };

    const handleImportData = async () => {
        const result = await dispatch(bulkUploadCall({ file, endPoint, currentSelectedLevel }));
        if (result.payload.success) {
            await dispatch(statusCountAction(activeProgramId));
            await dispatch(fetchAllLevelsAndQuestions({
                programId: activeProgramId,
                status: currentStatus,
                active: isActive
            }));
        } else {
            dispatch(openErrorModal(result.payload.message))
        }
    };

    const isButtonDisabled = !uploadedFileName || loading;

    const getFileIcon = () => {
        if (fileTypes[0] === '.csv') {
            return CSVFileIcon;
        } else if (fileTypes[0] === '.xlsx' || fileTypes[0] === '.xls') {
            return ExcelFileIcon;
        }
        return null;
    };

    return (
        <Card className='import-word-modal'>
            <Card.Header className='import-word-modal-header'>
                <span>Import Words</span>
                <img src={CloseIcon} alt="Close icon" onClick={() => dispatch(closeModal())} />
            </Card.Header>
            <Card.Body className="import-word-modal-body">
                <Card.Title
                    className={`import-word-modal-body-title ${isDragging ? 'dragging' : ''}`}
                    onClick={() => fileInputRef.current.click()}
                    onDragOver={handleDragOver}
                    onDragLeave={() => dispatch(setDragState(false))}
                    onDrop={handleDrop}
                >
                    <div>
                        <img src={AddFilesIcon} alt="Add files icon" />
                        Drop files to begin upload or
                        <span className="browse-files-text"> Browse files</span>
                    </div>
                    <div className='import-word-modal-body-sub-title'>
                        Supported file formats: {fileTypes.join(', ')}
                    </div>
                    {showValidationMessage && (
                        <div className='invalid-file-message'>
                            Only {fileTypes.join(', ')} files are acceptable
                        </div>
                    )}
                </Card.Title>
                {uploadedFileSize > 0 && (
                    <Card.Text className="import-word-modal-card-text">
                        <span className="import-word-modal-uploaded-text">Uploaded document</span>
                        <div className='import-modal-uploaded-files-container'>
                            <img src={getFileIcon()} />
                            <div>
                                <div className="uploaded-file-name">{uploadedFileName}</div>
                                <div className="uploaded-file-size">{uploadedFileSize} MB</div>
                            </div>
                        </div>
                    </Card.Text>
                )}
            </Card.Body>
            <Card.Footer className="import-word-modal-footer">
                <button onClick={() => dispatch(closeModal())}>Cancel</button>
                <button onClick={handleImportData} disabled={!uploadedFileName || loading}
                    className={!uploadedFileName || loading ? "disabled-button" : ""}>
                    {loading ? <ThreeDots
                        visible={true}
                        height="25"
                        width="25"
                        color="#fff"
                        radius="100"
                        ariaLabel="three-dots-loading"
                    /> : "Import Data"}
                </button>
            </Card.Footer>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </Card>
    );
};

export default BulkImportModal;
