import { bulkImport } from 'Utils/ApiHandler/BulkImport';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    file: null,
    error: null,
    insertedIds: 0,
    loading: false,
    isDragging: false,
    isModalOpen: false,
    uploadedFileSize: 0,
    uploadedFileName: "",
    uploadedFileType: null,
    showValidationMessage: false,
};

export const bulkUploadCall = createAsyncThunk(
    'fileUpload/bulkUpload',
    async ({ file, endPoint, currentSelectedLevel }, { rejectWithValue }) => {
        try {
            const response = await bulkImport(file, endPoint, currentSelectedLevel);
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred during upload');
        }
    }
);
const fileUploadSlice = createSlice({
    name: 'fileUpload',
    initialState,
    reducers: {
        openModal: (state) => {
            state.isModalOpen = true;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
        uploadedFile: (state, action) => {
            const { file, fileName, fileSize, fileType } = action.payload;
            state.file = file;
            state.uploadedFileName = fileName;
            state.uploadedFileSize = fileSize;
            state.uploadedFileType = fileType;
            state.showValidationMessage = false;
        },
        setDragState: (state, action) => {
            state.isDragging = action.payload;
        },
        showInvalidFileMessage: (state) => {
            state.showValidationMessage = true;
            state.file = null;
            state.uploadedFileName = '';
            state.uploadedFileSize = 0;
            state.uploadedFileType = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(bulkUploadCall.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(bulkUploadCall.fulfilled, (state, action) => {
                state.insertedIds = action.payload;
                state.loading = false;
                state.isModalOpen = false;
                state.file = null;
                state.uploadedFileName = '';
                state.uploadedFileSize = 0;
                state.uploadedFileType = null;
            })
            .addCase(bulkUploadCall.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.file = null;
                state.uploadedFileName = '';
                state.uploadedFileSize = 0;
                state.uploadedFileType = null;
            });
    },
});
export const {
    openModal,
    closeModal,
    uploadedFile,
    setDragState,
    showInvalidFileMessage
} = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
