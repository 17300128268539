import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { flagUnflagAction } from "Utils/ApiHandler/DatatableActionServices/DatatableActionServices";

const initialState = {
    ids: [],
    error: null,
    isFlag: false,
    loading: false,
    flagComment: '',
    isBulkAction:false,
    showFlagModal: false,
    isFlaggedQuestion: false,
}

export const flagUnflagActions = createAsyncThunk('flagUnflagAction/flagUnflag', async (params, { rejectWithValue }) => {
    try {
        const result = await flagUnflagAction(params);
        setIsFlaggedQuestion(true)
        return result.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const flagUnflagActionSlice = createSlice({
    name: 'flagUnflagActionsSlice',
    initialState: initialState,
    reducers: {
        openFlagModal: (state) => {
            state.showFlagModal = true;
        },
        closeFlagModal: (state) => {
            state.showFlagModal = false;
        },
        setFlagComment: (state, action) => {
            state.flagComment = action.payload;
        },
        resetFlagComment: (state) => {
            state.flagComment = '';
        },
        setIsFlaggedQuestion: (state, action) => {
            state.isFlaggedQuestion = action.payload;
        },
        passFlagUnflagItemData: (state, action) => {
            state.ids = action.payload.ids;
            state.isFlag = action.payload.isFlag;
            state.isBulkAction = action.payload.isBulkAction
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(flagUnflagActions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(flagUnflagActions.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.isFlaggedQuestion = true;
            })
            .addCase(flagUnflagActions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong!';
            });
    },
});
export const { openFlagModal, closeFlagModal, setFlagComment, resetFlagComment, passFlagUnflagItemData,setIsFlaggedQuestion } = flagUnflagActionSlice.actions;

export default flagUnflagActionSlice.reducer; 