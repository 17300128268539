import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Assests/Images/site-logo.png";
import image from "../../Assests/Images/user-profile.png";
import profile from "../../Assests/Images/user-profile.png";
import settingsIcon from "../../Assests/Images/settingsIcon.svg";
import { NavLink as RRNavLink } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import DbConfig from "Constants/db";
import {
  HeaderWrapper,
  Navbar,
  Logo,
  LogoImage,
  Navigation,
  NavLink,
  SettingsIcon,
  AvatarContainer,
  UserProfileCardContainer,
  SettingsPanel,
  ParentCard,
  ParentHeading,
  SubHeading,
  ChildCardContainer,
  ChildCard,
  ChildSubHeading,
  ProfileHeader,
  Gradiant,
  ProfileDown,
  ProfileImage,
  ProfileEmail,
  ProfileRole,
  ProfileTitle,
  Line,
  Options,
  Option,
  HalfLine,
  LogOut,
  CuriculumClipBoardIconContainer,
} from "./Header.styled";

/**
 * Header
 *
 * Header component for the site.
 *
 * @returns {JSX.Element} The rendered Header component.
 *
 * @author - Devisri
 */
const Header = () => {
  const profileRef = useRef(null);
  const settingsRef = useRef(null);
  const curriculumRef = useRef(null);
  const [userData, setUserData] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();
  const isCurriculumActive = location.pathname.startsWith("/curriculum");
  const isLessonPlanActive = location.pathname.startsWith("/lessonplan");
  const isSpellAndVocabActive = location.pathname.startsWith("/spellandvocab");
  const [showDropDown, setShowDropDown] = useState(false);
  const [skillsViewAccess, setSkillsViewAccess] = useState(false);
  const [programsViewAccess, setProgramsAccess] = useState(false);
  const [levelsViewAccess, setLevelsViewAccess] = useState(false);
  const [domainsViewAccess, setDomainsViewAccess] = useState(false);
  const [topicsViewAccess, setTopicsviewAccess] = useState(false);
  const [questionsViewAccess, setQuestionsViewAccess] = useState(false);
  const [organizationAccess, setOrganizationAccess] = useState(false);
  const [rolesAccess, setRolesAccess] = useState(false);
  const [teamsAccess, setTeamsAccess] = useState(false);
  const [usersAccess, setUsersAccess] = useState(false);

  /**
   * useEffect to Fetch User Data from Local Storage
   *
   * This useEffect hook fetches user data from local storage when the component mounts.
   * It retrieves the user's first name, last name, type, and email from local storage
   * and sets them into the userData state.
   *
   * @returns {void}
   * @author - Devisri
   */

  const fetchData = async () => {
    try {
      const details = await DbConfig.userDetails.toArray();
      const first_name = details[0].firstName;
      const last_name = details[0].lastName;
      const type = details[0].roleName;
      const user_email = details[0].email;
      const capitalizedFirstName =
        first_name.charAt(0).toUpperCase() + first_name.slice(1);
      const capitalizedLastName =
        last_name.charAt(0).toUpperCase() + last_name.slice(1);
      const user = {
        first_name: capitalizedFirstName,
        last_name: capitalizedLastName,
        type: type,
        user_email: user_email,
      };
      setUserData(user);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    DbConfig.curriculumAccess.toArray().then((curriculumAccessData) => {
      setSkillsViewAccess(curriculumAccessData.map((item) => item.skills));
      setProgramsAccess(curriculumAccessData.map((item) => item.programs));
      setLevelsViewAccess(curriculumAccessData.map((item) => item.levels));
      setDomainsViewAccess(curriculumAccessData.map((item) => item.domains));
      setTopicsviewAccess(curriculumAccessData.map((item) => item.topics));
      setQuestionsViewAccess(
        curriculumAccessData.map((item) => item.questions)
      );
    });
    DbConfig.settingsAccess.toArray().then((settingsAccessData) => {
      setOrganizationAccess(
        settingsAccessData.map((item) => item.organizations)
      );
      setRolesAccess(settingsAccessData.map((item) => item.roles));
      setTeamsAccess(settingsAccessData.map((item) => item.teams));
      setUsersAccess(settingsAccessData.map((item) => item.users));
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setShowProfile(false);
      }
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        setShowSettings(false);
      }
      if (
        curriculumRef.current &&
        !curriculumRef.current.contains(event.target)
      ) {
        setShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /**
   * Avatar
   *
   * Component responsible for rendering the user's avatar.
   *
   * @param {function} onClick - Function to be called when the avatar is clicked.
   * @returns {JSX.Element} - The rendered Avatar component.
   * @author - Devisri
   */
  const Avatar = ({ onClick }) => {
    return (
      <AvatarContainer src={image} alt="Profile Avatar" onClick={onClick} />
    );
  };

  /**
   * UserProfileCard
   *
   * Component responsible for rendering the user profile card.
   *
   * @param {boolean} showProfile - Flag indicating whether to show the user profile card or not.
   * @returns {JSX.Element | null} - The rendered UserProfileCard component or null if showProfile is false.
   * @author - Devisri
   */
  const UserProfileCard = ({ showProfile, userData }) => {
    return (
      <>
        {showProfile && (
          <UserProfileCardContainer ref={profileRef}>
            <ProfileHeader>
              <Gradiant />
              <ProfileDown>
                <ProfileImage src={profile} alt="" />
                <ProfileTitle>
                  {userData.first_name} {userData.last_name}
                </ProfileTitle>
                <ProfileRole>{userData.type}</ProfileRole>
                <ProfileEmail>{userData.user_email}</ProfileEmail>
                <Line />
                <Options>
                  <Option>Edit Profile</Option>
                  <Option>Settings</Option>
                  <HalfLine />
                  <LogOut as={Link} to="/logout">
                    Sign Out
                  </LogOut>
                </Options>
              </ProfileDown>
            </ProfileHeader>
          </UserProfileCardContainer>
        )}
      </>
    );
  };

  /**
   * toggleProfile
   *
   * Toggles the visibility of the user profile dropdown.
   *
   * @author - Devisri
   */
  const toggleProfile = () => {
    setShowProfile(!showProfile);
    setShowSettings(false); // Close settings panel
  };

  /**
   * toggleMenu
   *
   * Toggles the visibility of the mobile navigation menu.
   *
   * @author - Devisri
   */
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  /**
   * toggleSettings
   *
   * Toggles the visibility of the settings panel.
   *
   * @returns {void}
   * @author - Devisri
   */
  const [isPageFrame, setIsPageFrame] = useState(false);

  useEffect(() => {
    var pathName = location.pathname;
    if (pathName.includes('editQuestion')) {
      setIsPageFrame(true)
    }
    if (isPageFrame && !pathName.includes('editQuestion')) {
      setIsPageFrame(false)
      window.parent.location.href = window.location.href;
    }
  }, [location]);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    setShowProfile(false);
  };

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };

  const handleCurriculumDropdown = () => {
    setShowDropDown(!showDropDown);
  };

  return (
    <HeaderWrapper>
      <Navbar>
        <>
          <Logo as={Link} to="/">
            <LogoImage src={logo} alt="Site Logo" />
          </Logo>
        </>
        <Navigation>
          <NavLink
            onClick={handleCurriculumDropdown}
            active={isCurriculumActive}
          >
            Curriculum <MdKeyboardArrowDown className="curriculumDownArrow" />
          </NavLink>
          {showDropDown && (
            <div>
              <div className="curriculumDropDownOptions" ref={curriculumRef}>
                {programsViewAccess[0]?.view && (
                  <>
                    <NavLink
                      as={RRNavLink}
                      to="/curriculum/program"
                      onClick={handleCurriculumDropdown}
                    >
                      <div className="curriculumDropDownOption">
                        <CuriculumClipBoardIconContainer
                          style={{
                            backgroundColor: "rgba(253, 203, 108, 0.1)",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17 1.25H7C4.93 1.25 3.25 2.93 3.25 5V19C3.25 21.07 4.93 22.75 7 22.75H17C19.07 22.75 20.75 21.07 20.75 19V5C20.75 2.93 19.07 1.25 17 1.25ZM15.25 2.75V5C15.25 5.14 15.14 5.25 15 5.25H9C8.86 5.25 8.75 5.14 8.75 5V2.75H15.25ZM19.25 19C19.25 20.24 18.24 21.25 17 21.25H7C5.76 21.25 4.75 20.24 4.75 19V5C4.75 3.76 5.76 2.75 7 2.75H7.25V5C7.25 5.96 8.04 6.75 9 6.75H15C15.96 6.75 16.75 5.96 16.75 5V2.75H17C18.24 2.75 19.25 3.76 19.25 5V19ZM16.75 18C16.75 18.41 16.41 18.75 16 18.75H12C11.59 18.75 11.25 18.41 11.25 18C11.25 17.59 11.59 17.25 12 17.25H16C16.41 17.25 16.75 17.59 16.75 18ZM16.75 14C16.75 14.41 16.41 14.75 16 14.75H12C11.59 14.75 11.25 14.41 11.25 14C11.25 13.59 11.59 13.25 12 13.25H16C16.41 13.25 16.75 13.59 16.75 14ZM16.75 10C16.75 10.41 16.41 10.75 16 10.75H12C11.59 10.75 11.25 10.41 11.25 10C11.25 9.59 11.59 9.25 12 9.25H16C16.41 9.25 16.75 9.59 16.75 10ZM9.75 18C9.75 18.41 9.41 18.75 9 18.75H8C7.59 18.75 7.25 18.41 7.25 18C7.25 17.59 7.59 17.25 8 17.25H9C9.41 17.25 9.75 17.59 9.75 18ZM9.75 14C9.75 14.41 9.41 14.75 9 14.75H8C7.59 14.75 7.25 14.41 7.25 14C7.25 13.59 7.59 13.25 8 13.25H9C9.41 13.25 9.75 13.59 9.75 14ZM9.75 10C9.75 10.41 9.41 10.75 9 10.75H8C7.59 10.75 7.25 10.41 7.25 10C7.25 9.59 7.59 9.25 8 9.25H9C9.41 9.25 9.75 9.59 9.75 10Z"
                              fill="#B9882B"
                            />
                          </svg>
                        </CuriculumClipBoardIconContainer>
                        <div>
                          Programs
                          <div className="subDetail">
                            A list of all Programs
                          </div>
                        </div>
                      </div>{" "}
                    </NavLink>
                    <div style={{ borderBottom: "1px solid #D9E2ED" }}></div>
                  </>
                )}
                {levelsViewAccess[0]?.view && (
                  <>
                    <NavLink
                      as={RRNavLink}
                      to="/curriculum/level"
                      onClick={handleCurriculumDropdown}
                    >
                      <div
                        className="curriculumDropDownOption"
                        onClick={handleCurriculumDropdown}
                      >
                        <CuriculumClipBoardIconContainer
                          style={{ backgroundColor: "rgba(0, 128, 133, 0.1)" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17 1.25H7C4.93 1.25 3.25 2.93 3.25 5V19C3.25 21.07 4.93 22.75 7 22.75H17C19.07 22.75 20.75 21.07 20.75 19V5C20.75 2.93 19.07 1.25 17 1.25ZM15.25 2.75V5C15.25 5.14 15.14 5.25 15 5.25H9C8.86 5.25 8.75 5.14 8.75 5V2.75H15.25ZM19.25 19C19.25 20.24 18.24 21.25 17 21.25H7C5.76 21.25 4.75 20.24 4.75 19V5C4.75 3.76 5.76 2.75 7 2.75H7.25V5C7.25 5.96 8.04 6.75 9 6.75H15C15.96 6.75 16.75 5.96 16.75 5V2.75H17C18.24 2.75 19.25 3.76 19.25 5V19ZM16.75 18C16.75 18.41 16.41 18.75 16 18.75H12C11.59 18.75 11.25 18.41 11.25 18C11.25 17.59 11.59 17.25 12 17.25H16C16.41 17.25 16.75 17.59 16.75 18ZM16.75 14C16.75 14.41 16.41 14.75 16 14.75H12C11.59 14.75 11.25 14.41 11.25 14C11.25 13.59 11.59 13.25 12 13.25H16C16.41 13.25 16.75 13.59 16.75 14ZM16.75 10C16.75 10.41 16.41 10.75 16 10.75H12C11.59 10.75 11.25 10.41 11.25 10C11.25 9.59 11.59 9.25 12 9.25H16C16.41 9.25 16.75 9.59 16.75 10ZM9.75 18C9.75 18.41 9.41 18.75 9 18.75H8C7.59 18.75 7.25 18.41 7.25 18C7.25 17.59 7.59 17.25 8 17.25H9C9.41 17.25 9.75 17.59 9.75 18ZM9.75 14C9.75 14.41 9.41 14.75 9 14.75H8C7.59 14.75 7.25 14.41 7.25 14C7.25 13.59 7.59 13.25 8 13.25H9C9.41 13.25 9.75 13.59 9.75 14ZM9.75 10C9.75 10.41 9.41 10.75 9 10.75H8C7.59 10.75 7.25 10.41 7.25 10C7.25 9.59 7.59 9.25 8 9.25H9C9.41 9.25 9.75 9.59 9.75 10Z"
                              fill="#008085"
                            />
                          </svg>
                        </CuriculumClipBoardIconContainer>
                        <div>
                          Levels
                          <div className="subDetail">A list of all levels</div>
                        </div>
                      </div>
                    </NavLink>
                    <div style={{ borderBottom: "1px solid #D9E2ED" }}></div>
                  </>
                )}
                {domainsViewAccess[0]?.view && (
                  <>
                    <NavLink
                      as={RRNavLink}
                      to="/curriculum/domain"
                      onClick={handleCurriculumDropdown}
                    >
                      <div className="curriculumDropDownOption">
                        <CuriculumClipBoardIconContainer
                          style={{ backgroundColor: "rgba(240, 247, 255, 1)" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17 1.25H7C4.93 1.25 3.25 2.93 3.25 5V19C3.25 21.07 4.93 22.75 7 22.75H17C19.07 22.75 20.75 21.07 20.75 19V5C20.75 2.93 19.07 1.25 17 1.25ZM15.25 2.75V5C15.25 5.14 15.14 5.25 15 5.25H9C8.86 5.25 8.75 5.14 8.75 5V2.75H15.25ZM19.25 19C19.25 20.24 18.24 21.25 17 21.25H7C5.76 21.25 4.75 20.24 4.75 19V5C4.75 3.76 5.76 2.75 7 2.75H7.25V5C7.25 5.96 8.04 6.75 9 6.75H15C15.96 6.75 16.75 5.96 16.75 5V2.75H17C18.24 2.75 19.25 3.76 19.25 5V19ZM16.75 18C16.75 18.41 16.41 18.75 16 18.75H12C11.59 18.75 11.25 18.41 11.25 18C11.25 17.59 11.59 17.25 12 17.25H16C16.41 17.25 16.75 17.59 16.75 18ZM16.75 14C16.75 14.41 16.41 14.75 16 14.75H12C11.59 14.75 11.25 14.41 11.25 14C11.25 13.59 11.59 13.25 12 13.25H16C16.41 13.25 16.75 13.59 16.75 14ZM16.75 10C16.75 10.41 16.41 10.75 16 10.75H12C11.59 10.75 11.25 10.41 11.25 10C11.25 9.59 11.59 9.25 12 9.25H16C16.41 9.25 16.75 9.59 16.75 10ZM9.75 18C9.75 18.41 9.41 18.75 9 18.75H8C7.59 18.75 7.25 18.41 7.25 18C7.25 17.59 7.59 17.25 8 17.25H9C9.41 17.25 9.75 17.59 9.75 18ZM9.75 14C9.75 14.41 9.41 14.75 9 14.75H8C7.59 14.75 7.25 14.41 7.25 14C7.25 13.59 7.59 13.25 8 13.25H9C9.41 13.25 9.75 13.59 9.75 14ZM9.75 10C9.75 10.41 9.41 10.75 9 10.75H8C7.59 10.75 7.25 10.41 7.25 10C7.25 9.59 7.59 9.25 8 9.25H9C9.41 9.25 9.75 9.59 9.75 10Z"
                              fill="#008085"
                            />
                          </svg>
                        </CuriculumClipBoardIconContainer>
                        <div>
                          Domains
                          <div className="subDetail">A list of all domains</div>
                        </div>
                      </div>
                    </NavLink>
                    <div style={{ borderBottom: "1px solid #D9E2ED" }}></div>
                  </>
                )}
                {topicsViewAccess[0]?.view && (
                  <>
                    <NavLink
                      as={RRNavLink}
                      to="/curriculum/topic"
                      onClick={handleCurriculumDropdown}
                    >
                      <div className="curriculumDropDownOption">
                        <CuriculumClipBoardIconContainer
                          style={{ backgroundColor: "rgba(230, 39, 83, 0.1)" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17 1.25H7C4.93 1.25 3.25 2.93 3.25 5V19C3.25 21.07 4.93 22.75 7 22.75H17C19.07 22.75 20.75 21.07 20.75 19V5C20.75 2.93 19.07 1.25 17 1.25ZM15.25 2.75V5C15.25 5.14 15.14 5.25 15 5.25H9C8.86 5.25 8.75 5.14 8.75 5V2.75H15.25ZM19.25 19C19.25 20.24 18.24 21.25 17 21.25H7C5.76 21.25 4.75 20.24 4.75 19V5C4.75 3.76 5.76 2.75 7 2.75H7.25V5C7.25 5.96 8.04 6.75 9 6.75H15C15.96 6.75 16.75 5.96 16.75 5V2.75H17C18.24 2.75 19.25 3.76 19.25 5V19ZM16.75 18C16.75 18.41 16.41 18.75 16 18.75H12C11.59 18.75 11.25 18.41 11.25 18C11.25 17.59 11.59 17.25 12 17.25H16C16.41 17.25 16.75 17.59 16.75 18ZM16.75 14C16.75 14.41 16.41 14.75 16 14.75H12C11.59 14.75 11.25 14.41 11.25 14C11.25 13.59 11.59 13.25 12 13.25H16C16.41 13.25 16.75 13.59 16.75 14ZM16.75 10C16.75 10.41 16.41 10.75 16 10.75H12C11.59 10.75 11.25 10.41 11.25 10C11.25 9.59 11.59 9.25 12 9.25H16C16.41 9.25 16.75 9.59 16.75 10ZM9.75 18C9.75 18.41 9.41 18.75 9 18.75H8C7.59 18.75 7.25 18.41 7.25 18C7.25 17.59 7.59 17.25 8 17.25H9C9.41 17.25 9.75 17.59 9.75 18ZM9.75 14C9.75 14.41 9.41 14.75 9 14.75H8C7.59 14.75 7.25 14.41 7.25 14C7.25 13.59 7.59 13.25 8 13.25H9C9.41 13.25 9.75 13.59 9.75 14ZM9.75 10C9.75 10.41 9.41 10.75 9 10.75H8C7.59 10.75 7.25 10.41 7.25 10C7.25 9.59 7.59 9.25 8 9.25H9C9.41 9.25 9.75 9.59 9.75 10Z"
                              fill="#E62753"
                            />
                          </svg>
                        </CuriculumClipBoardIconContainer>
                        <div>
                          Topics
                          <div className="subDetail">A list of all topics</div>
                        </div>
                      </div>
                    </NavLink>
                    <div style={{ borderBottom: "1px solid #D9E2ED" }}></div>
                  </>
                )}
                {skillsViewAccess[0]?.view && (
                  <>
                    <NavLink
                      as={RRNavLink}
                      to="/curriculum/skills"
                      onClick={handleCurriculumDropdown}
                    >
                      <div className="curriculumDropDownOption">
                        <CuriculumClipBoardIconContainer
                          style={{ backgroundColor: "rgba(240, 247, 255, 1)" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17 1.25H7C4.93 1.25 3.25 2.93 3.25 5V19C3.25 21.07 4.93 22.75 7 22.75H17C19.07 22.75 20.75 21.07 20.75 19V5C20.75 2.93 19.07 1.25 17 1.25ZM15.25 2.75V5C15.25 5.14 15.14 5.25 15 5.25H9C8.86 5.25 8.75 5.14 8.75 5V2.75H15.25ZM19.25 19C19.25 20.24 18.24 21.25 17 21.25H7C5.76 21.25 4.75 20.24 4.75 19V5C4.75 3.76 5.76 2.75 7 2.75H7.25V5C7.25 5.96 8.04 6.75 9 6.75H15C15.96 6.75 16.75 5.96 16.75 5V2.75H17C18.24 2.75 19.25 3.76 19.25 5V19ZM16.75 18C16.75 18.41 16.41 18.75 16 18.75H12C11.59 18.75 11.25 18.41 11.25 18C11.25 17.59 11.59 17.25 12 17.25H16C16.41 17.25 16.75 17.59 16.75 18ZM16.75 14C16.75 14.41 16.41 14.75 16 14.75H12C11.59 14.75 11.25 14.41 11.25 14C11.25 13.59 11.59 13.25 12 13.25H16C16.41 13.25 16.75 13.59 16.75 14ZM16.75 10C16.75 10.41 16.41 10.75 16 10.75H12C11.59 10.75 11.25 10.41 11.25 10C11.25 9.59 11.59 9.25 12 9.25H16C16.41 9.25 16.75 9.59 16.75 10ZM9.75 18C9.75 18.41 9.41 18.75 9 18.75H8C7.59 18.75 7.25 18.41 7.25 18C7.25 17.59 7.59 17.25 8 17.25H9C9.41 17.25 9.75 17.59 9.75 18ZM9.75 14C9.75 14.41 9.41 14.75 9 14.75H8C7.59 14.75 7.25 14.41 7.25 14C7.25 13.59 7.59 13.25 8 13.25H9C9.41 13.25 9.75 13.59 9.75 14ZM9.75 10C9.75 10.41 9.41 10.75 9 10.75H8C7.59 10.75 7.25 10.41 7.25 10C7.25 9.59 7.59 9.25 8 9.25H9C9.41 9.25 9.75 9.59 9.75 10Z"
                              fill="#5090D3"
                            />
                          </svg>
                        </CuriculumClipBoardIconContainer>
                        <div>
                          Skills
                          <div className="subDetail">A list of all skills</div>
                        </div>
                      </div>
                    </NavLink>
                    <div style={{ borderBottom: "1px solid #D9E2ED" }}></div>
                  </>
                )}
                {questionsViewAccess[0]?.view && (
                  <>
                    <NavLink
                      as={RRNavLink}
                      to="/curriculum/questions"
                      onClick={handleCurriculumDropdown}
                    >
                      <div className="curriculumDropDownOption">
                        <CuriculumClipBoardIconContainer
                          style={{
                            backgroundColor: "rgba(253, 203, 108, 0.2)",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17 1.25H7C4.93 1.25 3.25 2.93 3.25 5V19C3.25 21.07 4.93 22.75 7 22.75H17C19.07 22.75 20.75 21.07 20.75 19V5C20.75 2.93 19.07 1.25 17 1.25ZM15.25 2.75V5C15.25 5.14 15.14 5.25 15 5.25H9C8.86 5.25 8.75 5.14 8.75 5V2.75H15.25ZM19.25 19C19.25 20.24 18.24 21.25 17 21.25H7C5.76 21.25 4.75 20.24 4.75 19V5C4.75 3.76 5.76 2.75 7 2.75H7.25V5C7.25 5.96 8.04 6.75 9 6.75H15C15.96 6.75 16.75 5.96 16.75 5V2.75H17C18.24 2.75 19.25 3.76 19.25 5V19ZM16.75 18C16.75 18.41 16.41 18.75 16 18.75H12C11.59 18.75 11.25 18.41 11.25 18C11.25 17.59 11.59 17.25 12 17.25H16C16.41 17.25 16.75 17.59 16.75 18ZM16.75 14C16.75 14.41 16.41 14.75 16 14.75H12C11.59 14.75 11.25 14.41 11.25 14C11.25 13.59 11.59 13.25 12 13.25H16C16.41 13.25 16.75 13.59 16.75 14ZM16.75 10C16.75 10.41 16.41 10.75 16 10.75H12C11.59 10.75 11.25 10.41 11.25 10C11.25 9.59 11.59 9.25 12 9.25H16C16.41 9.25 16.75 9.59 16.75 10ZM9.75 18C9.75 18.41 9.41 18.75 9 18.75H8C7.59 18.75 7.25 18.41 7.25 18C7.25 17.59 7.59 17.25 8 17.25H9C9.41 17.25 9.75 17.59 9.75 18ZM9.75 14C9.75 14.41 9.41 14.75 9 14.75H8C7.59 14.75 7.25 14.41 7.25 14C7.25 13.59 7.59 13.25 8 13.25H9C9.41 13.25 9.75 13.59 9.75 14ZM9.75 10C9.75 10.41 9.41 10.75 9 10.75H8C7.59 10.75 7.25 10.41 7.25 10C7.25 9.59 7.59 9.25 8 9.25H9C9.41 9.25 9.75 9.59 9.75 10Z"
                              fill="#B9882B"
                            />
                          </svg>
                        </CuriculumClipBoardIconContainer>
                        <div>
                          Questions
                          <div className="subDetail">
                            A list of all questions
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </>
                )}
              </div>
            </div>
          )}
          <NavLink
            as={RRNavLink}
            to="/spellandvocab"
            active={isSpellAndVocabActive}
                      >
            Spelling and Vocabulary
          </NavLink>
          <NavLink
            as={RRNavLink}
            to=""
            onClick={(e) => { e.preventDefault(); }}
            active={isLessonPlanActive}
            style={{
              textDecoration: "line-through",
              pointerEvents: "none",
              color: "gray",
              cursor: "not-allowed"
            }}
          >
            Lesson Plan
          </NavLink>
        </Navigation>
        <SettingsIcon
          src={settingsIcon}
          alt="Settings Icon"
          onClick={toggleSettings}
        />
        <Avatar onClick={toggleProfile} />
      </Navbar>
      {showSettings && (
        <SettingsPanel ref={settingsRef}>
          <ParentCard>
            <ParentHeading>Settings</ParentHeading>
            <SubHeading>Select an option below:</SubHeading>
            <ChildCardContainer>
              {organizationAccess[0]?.view ? (
                <ChildCard
                  as={Link}
                  to="/organization"
                  onClick={toggleSettings}
                  data-tooltip="Go to organizations"
                >
                  <ChildSubHeading>Organizations</ChildSubHeading>A list of all
                  the organizations
                </ChildCard>
              ) : (
                <ChildCard
                  disabled
                  data-tooltip="You don't have access to view this"
                >
                  <ChildSubHeading disabled>Organizations</ChildSubHeading>A
                  list of all the organizations
                </ChildCard>
              )}
              {rolesAccess[0]?.view ? (
                <ChildCard
                  as={Link}
                  to="/roles"
                  onClick={toggleSettings}
                  data-tooltip="Go to roles"
                >
                  <ChildSubHeading>Roles</ChildSubHeading>A list of all the
                  roles
                </ChildCard>
              ) : (
                <ChildCard
                  disabled
                  data-tooltip="You don't have access to view this"
                >
                  <ChildSubHeading>Roles</ChildSubHeading>A list of all the
                  roles
                </ChildCard>
              )}
            </ChildCardContainer>
            <ChildCardContainer>
              {teamsAccess[0]?.view ? (
                <ChildCard
                  as={Link}
                  to="/teams"
                  onClick={toggleSettings}
                  data-tooltip="Go to teams"
                >
                  <ChildSubHeading>Teams</ChildSubHeading>A list of all the
                  teams
                </ChildCard>
              ) : (
                <ChildCard
                  disabled
                  data-tooltip="You don't have access to view this"
                >
                  <ChildSubHeading>Teams</ChildSubHeading>A list of all the
                  teams
                </ChildCard>
              )}
              {usersAccess[0]?.view ? (
                <ChildCard
                  as={Link}
                  to="/users"
                  onClick={toggleSettings}
                  data-tooltip="Go to users"
                >
                  <ChildSubHeading>Users</ChildSubHeading>A list of all the
                  users
                </ChildCard>
              ) : (
                <ChildCard
                  disabled
                  data-tooltip="You don't have access to view this"
                >
                  <ChildSubHeading>Users</ChildSubHeading>A list of all the
                  users
                </ChildCard>
              )}
            </ChildCardContainer>
          </ParentCard>
        </SettingsPanel>
      )}
      <UserProfileCard showProfile={showProfile} userData={userData} />
    </HeaderWrapper>
  );
};

export default Header;
