import { fetchToken } from "Constants/ApiUrls";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;
const addQuestionSuccess = "Question Added Successfully";
const editQuestionSuccess = "Question Updated Successfully";

export const addVocabularyQuestion = async (formData) => {
    try {
      const url = `${baseUrl}/cms/v1/insertVocabularyQuestion`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${fetchToken() }`,
        },
        body: formData,
      });
      const result = await response.json();
      if (!response.ok || !result.success) {
        if (Array.isArray(result.message)) {
          return result.message;
        } else {
          toast.error(result.message);
          return false;
        }
      }
      toast.success(addQuestionSuccess);
      return result;
    } catch (error) {
      console.error("Error add question: ", error);
      return false;
    }
  };