import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getLevelsAndQuestions } from "Utils/ApiHandler/SpellAndVocabServices/levelService";

const initialState = {
    levels: [],
    error: null,
    loading: false,
    totalQuestionCount: 0,
    currentSelectedLevel: null,
    title: ''
}

export const fetchAllLevelsAndQuestions = createAsyncThunk('levelsAndQuestion/fetchAll',
    async (params, { rejectWithValue }) => {
        const response = await getLevelsAndQuestions(params);
        return response.levels;
    });

const levelSlice = createSlice({
    name: 'levelsAndQuestion',
    initialState: initialState,
    reducers: {
        selectedLevel: (state, action) => {
            state.currentSelectedLevel = action.payload._id;
            state.totalQuestionCount = action.payload.questionCount;
            state.title = action.payload.title;
        },
        updateQuestionCount: (state, action) => {
            state.totalQuestionCount = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllLevelsAndQuestions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllLevelsAndQuestions.fulfilled, (state, action) => {
                state.loading = false;
                state.levels = action.payload;
                state.error = null;
            })
            .addCase(fetchAllLevelsAndQuestions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong!';
            });
    },
});

export const { setLevels, selectedLevel, updateQuestionCount } = levelSlice.actions;
export default levelSlice.reducer;
