import questionPreviewConfig from "Controller/Curriculum/Questions/QuestionPreviewBuilder/questionPreviewConfig";
import React, { useState } from "react";
import { parseLatexText, parseLatex } from "Utils/LatexUtils";
import activeSpeaker from "../../../../Assests/Images/active-speaker.svg";
import speaker from "../../../../Assests/Images/speaker.svg";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";

function PreviewQuestionComponent() {
    const speechSynthesisInstance = window.speechSynthesis;
    const qtype = useSelector((state) => state.qtype.qtype);

    const [speakingIndex, setSpeakingIndex] = useState(null);
    const questionValue = useSelector((state) => state.question.questionValue);
    const [rate, setRate] = useState(1);
    const [pitch, setPitch] = useState(1);
    var voice = speechSynthesis.getVoices()[3];

    const handleSpeak = (option, index) => {
        if ("speechSynthesis" in window) {
            const stripHTMLTags = (text) => {
                const div = document.createElement("div");
                div.innerHTML = text;
                return div.textContent || div.innerText || "";
            };

            const cleanOption = stripHTMLTags(option);
            const processedOption = cleanOption.replace(/<#@blank#>/g, "blank");

            const utterance = new SpeechSynthesisUtterance(
                questionPreviewConfig.fillInTheBlanks.includes(22)
                    ? parseLatexText(processedOption.replace(/_/g, "blank"))
                    : parseLatexText(processedOption)
            );
            utterance.voice = voice;
            utterance.pitch = pitch;
            utterance.rate = rate;
            if (speechSynthesisInstance.speaking) {
                speechSynthesisInstance.cancel();
                setSpeakingIndex(null);
            } else {
                setSpeakingIndex(index);
                speechSynthesisInstance.speak(utterance);
                utterance.onend = () => setSpeakingIndex(null);
            }
        }
    };

    return (
        <div>
            {qtype.label === 'Spelling' && (
                <Card className="border-0">
                    <Card.Title
                        className="question-preview-container"
                        style={{ marginTop: "1.2rem", paddingLeft: "rem" }}
                    >
                        <Card.Text>
                            <span className="speaker-icon">
                                <img
                                    src={
                                        speakingIndex === 0
                                            ? activeSpeaker
                                            : speaker
                                    }
                                    onClick={() =>
                                        handleSpeak(questionValue, 0)
                                    }
                                    style={{ marginLeft: "2.5rem" }}
                                    alt="Speaker"
                                />
                            </span>
                            <span
                                className="text-question"
                                style={{ paddingLeft: "0.7rem" }}
                            >
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        document
                                            .querySelector(".speaker-icon img")
                                            .click();
                                    }}
                                >
                                    Play Audio
                                </a>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: parseLatex(),
                                    }}
                                />
                            </span>
                        </Card.Text>
                    </Card.Title>
                </Card>
            )}

            {qtype.label === 'Vocabulary' && (
                <Card className="border-0">
                    <Card.Title
                        className="question-preview-container"
                        style={{ marginTop: "1rem", marginBottom: "1rem" }}
                    >
                        <Card.Text>
                            <span className="speaker-icon">
                                <img
                                    src={
                                        speakingIndex === 1
                                            ? activeSpeaker
                                            : speaker
                                    }
                                    onClick={() =>
                                        handleSpeak(questionValue, 1)
                                    }
                                    style={{ marginLeft: "2rem" }}
                                    alt="Speaker"
                                />
                            </span>
                            <span
                                className="text-question"
                                style={{ paddingLeft: "0.8rem" }}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: parseLatex(questionValue),
                                    }}
                                />
                            </span>
                        </Card.Text>
                    </Card.Title>
                </Card>
            )}
        </div>
    );
}

export default PreviewQuestionComponent;
