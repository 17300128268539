import { toast } from "react-toastify";
import { fetchToken, topicTitlesUrl } from "Constants/ApiUrls";

var token = fetchToken();
const baseUrl = process.env.REACT_APP_BASE_URL;

const buildFilterParams = (filterData) => {
    token = fetchToken();
    return filterData
        .map((filter) => {
            if (typeof filter.value === "object" && filter.value.length > 0) {
                let values = "";
                if (filter.name === "title" || filter.name === "code" || filter.name === "domainIds"
                    || filter.name === "levelIds" || filter.name === "status" || filter.name === "limit"
                    || filter.name === 'programIds' || filter.name === 'organizationIds') {
                    values = filter.value.map(
                        (value) => `${filter.name}[]=${value}`
                    );
                } else {
                    values = filter.value.map(
                        (value) => `${filter.name}[]=${value.id}`
                    );
                }
                return values.join("&");
            } else {
                return `${filter.name}=${filter.value}`;
            }
        })
        .join("&");
};

export const getAllTopics = async (filterData) => {
    const filterParams = buildFilterParams(filterData);
    const url = `${baseUrl}/cms/v2/getAllTopics?${filterParams}`;
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return { topics: [], totalPages: 0 };
        }
        if (!responseData.success) {
            toast.error(responseData.message || "Error fetching topics");
            return { topics: [], totalPages: 0 };
        } else {
            return responseData.data;
        }
    } catch (error) {
        toast.error("An error occurred while fetching the data.");
        return { topics: [], totalPages: 0 };
    }
};

export const getTopicCodes = async (url) => {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message || "Failed to fetch topic codes");
            return [];
        }
        return responseData.data.topicCodes.map((item) => ({
            label: item.code,
            value: item.code,
        }));
    } catch (error) {
        toast.error("An error occurred while fetching the topic codes.");
        throw new Error(error);
    }
};

export const getTopicTitles = async (searchKey) => {
    const url = `${topicTitlesUrl}${searchKey}`;
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message || "Failed to fetch topic titles");
            return [];
        }
        return responseData.data.topicTitles.map(({ title, _id }) => ({
            value: title,
            label: title,
            id: _id,
        }));
    } catch (error) {
        toast.error("An error occurred while fetching the topic titles.");
        return [];
    }
};

export const unflagTopic = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/topicUnflag`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Flag removed successfully.");
        return responseData.data;
    } catch (error) {
        toast.error("An error occurred while removing flag.");
        throw new Error(error);
    }
};

export const unflagTopicForBulk = async (data) => {
    try {
        const url = `${baseUrl}/cms/v2/topicUnflag`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.message) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Flag removed successfully.");
        return responseData.data;
    } catch (error) {
        toast.error("An error occurred while removing flag.");
        throw new Error(error);
    }
};

export const updateTopic = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/updateTopic`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Updated successfully");
        return responseData.data;
    } catch (error) {
        toast.error("An error occurred while updating the topic.");
        throw new Error(error);
    }
};

export const publishTopics = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/publishTopic`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Topic Published.");
        return response;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const unPublishTopics = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/unPublishTopic`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Topic Unpublished");
        return response;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const addFlag = async (data, flag = true) => {
    try {
        const url = `${baseUrl}/cms/v1/addCommentToTopic?flag=${flag}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Flag added successfully");
        return responseData.data;
    } catch (error) {
        toast.error("An error occurred while adding flag.");
        throw new Error(error);
    }
};

export const addFlagForBulkForTopic = async (data, flag = true) => {
    try {
        const url = `${baseUrl}/cms/v2/addCommentToTopic?flag=${flag}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Bulk Flagged Succesfully.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!')
        throw new Error(error);
    }
};

export const deleteTopic = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/deleteTopic`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Deleted topic successfully.");
        return responseData.data;
    } catch (error) {
        toast.error("An error occurred while deleting the topic.");
        throw new Error(error);
    }
};

export const updateBulkStatus = async (publish, data) => {
    try {
        let url = "";
        if (publish) {
            url = `${baseUrl}/cms/v1/publishTopic`;
        } else {
            url = `${baseUrl}/cms/v1/unPublishTopic`;
        }
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Status updated successfully");
        return responseData.data;
    } catch (error) {
        toast.error("An error occurred while updating.");
        throw new Error(error);
    }
};

export const reactivateTopic = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/reactivateTopic`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Reactivated successfully");
        return responseData.data;
    } catch (error) {
        toast.error("An error occurred while reactivating the topic");
        throw new Error(error);
    }
};

export const getById = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/getTopicById/${data}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return { topics: [], totalPages: 0 };
        }
        return responseData.data;
    } catch (error) {
        toast.error("Error updating data: ", error);
        return false;
    }
};

export const addSkill = async (data) => {
    try {
        const url = `${baseUrl}/cms/v2/insertSkill`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        toast.success("Skill added successfully");
        return responseData.data;
    } catch (error) {
        toast.error("An error occurred while adding the skill.");
        return false;
    }
};