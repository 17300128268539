import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import _ from "lodash";
import DbConfig from "Constants/db";
import Title from "../../Components/Title/Title";
import Loader from "../../Components/Loader/Loader";
import pencil from "../../Assests/Images/pencil.svg";
import logo from "../../Assests/Images/empty-box.svg";
import deleteIcon from "../../Assests/Images/delete.svg";
import DataTable from "../../Components/DataTable/DataTable";
import EditUserModal from "Components/Modals/Users/EditUserModal";
import DeleteModal from "Components/Modals/DeleteModal/DeleteModal";
import FilterHeader from "../../Components/FilterHeader/FilterHeader";
import CreateUserModel from "../../Components/Modals/Users/CreateUserModal";
import ProgramsMoreFilters from "../../Components/FilterHeader/ProgramsMoreFilters";
import { deleteUser, getAllUsers, userTitlesUrl, teamsTitlesUrl, roleAndTemplateTitlesUrl, getTeamsTitles, getRolesTitles, getUserTitles } from "../../Utils/ApiHandler/UsersApi";

const columns = ["Name", "Organization", "Team", "Role",];

function Users() {

  const { page } = useParams();
  const navigate = useNavigate();

  const [isBlur, setIsBlur] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [inputUser, setInputUser] = useState("");
  const [inputRole, setInputRole] = useState("");
  const [actionData, setActionData] = useState("");
  const [editUser, showEditUser] = useState(false);
  const [deleteRole, setDeleteRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [userTitles, setUserTitles] = useState([]);
  const [inputTeams, setInputTeams] = useState("");
  const [roleTitles, setRoleTitles] = useState([]);
  const [teamsTitles, setTeamsTitles] = useState([]);
  const [userAccess, setUserAccess] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [deleteModel, showDeleteModel] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [searchOptions, setSearchOptions] = useState(true);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [dataFilterStatus, setDataFilterStatus] = useState(true);
  const [createUserModel, showCreateUserModel] = useState(false);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
  const [goToPage, setGoToPage] = useState(0);
  const [filter, setFilter] = useState([
    { name: "page", value: currentPage },
    { name: "limit", value: 10 },
    { name: "active", value: true },
    { name: "search", value: "" },
    { name: "name", value: [] },
  ]);

  const handlePageChange = useCallback((currentPage, itemsPerPage) => {
    setCurrentPage(currentPage);
    setGoToPage(0);
    setIsLoading(true);
    setIsBlur(true);
    const updatedFilter = filter
      .filter(({ name }) => name !== "page" && name !== "limit")
      .concat([
        { name: "page", value: currentPage },
        { name: "limit", value: itemsPerPage },
      ]);
    setFilter(updatedFilter);
    setIsBlur(false);
  }, []);

  const grantAccess = () => {
    DbConfig.settingsAccess.toArray().then(settingsAccessData => {
      setUserAccess(settingsAccessData.map(item => item.users));
    });
  }

  useEffect(() => {
    grantAccess();
  }, []);

  useEffect(() => {
    if (goToPage > 1) {
      setCurrentPage(goToPage);
    }
    else {
      setCurrentPage(1);
    }
    navigate(`/users/${currentPage}`)
  }, [filter, goToPage])

  useEffect(() => {
    if (inputUser) {
      fetchUserTitles(dataFilterStatus);
    } else if (inputTeams) {
      fetchTeamsTitles(dataFilterStatus);
    } else if (inputRole) {
      fetchRoleTitles(dataFilterStatus);
    }
  }, [inputUser, inputTeams, inputRole, dataFilterStatus]);

  useEffect(() => {
    refreshDatatable();
  }, [filter]);

  const fetchTeamsTitles = _.debounce(async (isActive) => {
    if (!inputTeams) return;
    try {
      const url = `${teamsTitlesUrl}${inputTeams}?active=${isActive}`
      const result = await getTeamsTitles(url);
      result.length > 0 ? setTeamsTitles(result) : setSearchOptions(false);
    } catch (error) {
      throw new Error(error);
    }
  }, 1000);

  const fetchRoleTitles = _.debounce(async (isActive) => {
    if (!inputRole) return;
    try {
      const url = `${roleAndTemplateTitlesUrl}${inputRole}?active=${isActive}`
      const result = await getRolesTitles(url);
      result.length > 0 ? setRoleTitles(result) : setSearchOptions(false);
    } catch (error) {
      throw new Error(error);
    }
  }, 1000);

  const fetchUserTitles = _.debounce(async (isActive) => {
    if (!inputUser) return;
    try {
      const url = `${userTitlesUrl}${inputUser}?active=${isActive}`
      const result = await getUserTitles(url);
      result.length > 0 ? setUserTitles(result) : setSearchOptions(false);
    } catch (error) {
      throw new Error(error);
    }
  }, 1000);

  const handleCreateUserAction = () => {
    showCreateUserModel(true);
    const createUserAction = actions.find((action) => action.name === "createUser");
    if (createUserAction) {
      setActionData(createUserAction.data);
    }
  };

  const handleEditUser = () => {
    showEditUser(true);
    const editUserAction = actions.find((action) => action.name === "editUser");
    if (editUserAction) {
      setActionData(editUserAction.data);
    }
  };

  const handleDelete = () => {
    showDeleteModel(true);
    setIsBlur(true);
    const deleteAction = actions.find((action) => action.name === "delete");
    if (deleteAction) {
      const data = [
        {
          title: deleteAction.data.firstName,
          id: deleteAction.data._id,
        },
      ];
      setActionData(data);
    }
    setDeleteRole("User");
  };

  const handleDeleteAction = async () => {
    try {
      const { id } = actionData[0];
      await deleteUser({ id });
      showDeleteModel(false);
      refreshDatatable();
    } catch (error) {
      throw new Error(error);
    }
  };

  const actions = [
    {
      Label: (
        <>
          <img src={pencil} alt="Edit Icon" className="action-icon" />
          Edit
        </>
      ),
      name: "editUser",
      clickAction: handleEditUser,
      access: !!userAccess[0]?.edit,
    }, {
      Label: (
        <>
          <img src={deleteIcon} alt="Delete Icon" className="action-icon" />
          Delete
        </>
      ),
      name: "delete",
      clickAction: handleDelete,
      class: "text-delete",
      access: !!userAccess[0]?.delete,
    },
  ];

  const handleInputChange = useCallback(_.debounce((input, type) => {
    if (type === 'teams') {
      setInputTeams(input || "");
    } else if (type === 'user') {
      setInputUser(input || "");
    } else if (type === 'role') {
      setInputRole(input || "");
    }
  }, 1000), []);

  const handleInputUserChange = ((input) => {
    handleInputChange(input, 'user');
  });

  const handleInputTeamsChange = ((input) => {
    handleInputChange(input, 'teams');
  });

  const handleInputRoleChange = ((input) => {
    handleInputChange(input, 'role');
  });

  const handleUserChange = (selectedOptions) => {
    handleFilterChange(selectedOptions, "name");
  };

  const handleRoleChange = (selectedOptions) => {
    handleFilterChange(selectedOptions, "roleIds");
  };

  const handleTeamsChange = (selectedOptions) => {
    handleFilterChange(selectedOptions, "teamIds");
  };

  const handleFilterChange = (selectedOptions, type) => {
    const selectedIds = selectedOptions.map((option) => option.id);
    const updatedFilter = filter
      .filter(({ name }) => name !== type && name !== "page")
      .concat([
        { name: type, value: selectedIds },
        { name: "page", value: 1 },
      ]);
    setFilter(updatedFilter);
  };

  const refreshDatatable = _.debounce(() => {
    setIsBlur(true);
    setIsLoading(true);
    getAllUsers(filter).then((data) => {
      setTableData(data.users.map(extractFields));
      setTotalPageCount(data.totalPages);
      if (data.users && data.users.length > 0) {
        setDataFetched(true);
      }
      setIsBlur(false);
      setIsLoading(false);
    });
  }, 1000);

  const extractFields = (obj) => {
    if (!obj) {
      return null;
    }
    return {
      name: `${obj.firstName} ${obj.lastName}`,
      organization: obj.organization ? obj.organization.title : "",
      teams: obj.teams ? obj.teams.map((team) => team.teamName).join(",") : "",
      roles: obj.roles ? obj.roles.map((role) => role.roleName).join(",") : "",
      // access: obj.access,
      _id: obj._id,
      workEmail: obj.workEmail,
      lastName: obj.lastName,
      teamIds: obj.teams ? obj.teams.map((team) => team._id) : [],
      organizationId: obj.organization ? obj.organization._id : "",
      userId: obj._id,
      roleIds: obj.roles ? obj.roles.map((role) => role._id) : [],
      firstName: obj.firstName,
    };
  };

  const handleResetFilter = useCallback(() => {
    setIsBlur(true);
    let updatedFilter = [
      { name: "page", value: 1 },
      { name: "limit", value: 10 },
      { name: "active", value: dataFilterStatus },
      { name: "search", value: "" },
      { name: "organizationIds", value: [] },
      { name: "name", value: [] },
      { name: "roleIds", value: [] },
      { name: "teamIds", value: [] }
    ];
    setFilter(updatedFilter);
    setTeamsTitles([]);
    setRoleTitles([]);
    setIsLoading(true);
    setIsBlur(false);
  },);

  const showModal = useCallback((modal) => {
    setIsBlur(true);
    modal(true);
  }, []);

  const closeModal = useCallback((modal) => {
    modal(false);
    setIsBlur(false);
  }, []);

  return (
    <>
      <Title titleContent="Users" />
      {dataFetched ? (
        <>
          <div className={`settings-Container ${isBlur ? 'blur' : ''}`}>
            <FilterHeader
              firstDropdown={userTitles}
              secondDropdown={teamsTitles}
              fourthDropdown={roleTitles}
              firstText="Name"
              secondText="Team"
              fourthText="Role"
              onInputCodeChange={handleInputUserChange}
              onInputTitleChange={handleInputTeamsChange}
              onInputRoleChange={handleInputRoleChange}
              onCodeChange={handleUserChange}
              onTitleChange={handleTeamsChange}
              onRoleChange={handleRoleChange}
              filter={filter}
              setFilter={setFilter}
              resetFilter={handleResetFilter}
              handleMoreFilter={() => showModal(setShowMoreFilters)}
              setDataFilterStatus={setDataFilterStatus}
              showMoreFilters={true}
              showTitleDropdown={true}
              showRoleDropdown={true}
              handleCreateProgram={handleCreateUserAction}
              pageName="Users"
              createButtonText="Create user"
              showCreateButton={userAccess[0]?.add && dataFetched}
              handleCreateButton={() => showModal(showCreateUserModel)}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
              setGoToPage={setGoToPage}
              isPageChange={goToPage}
            />
            <DataTable
              showCheckbox={false}
              columns={columns}
              data={tableData}
              actions={actions}
              totalCount={totalPageCount}
              filter={filter}
              setFilter={setFilter}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setGoToPage={setGoToPage}
            />
            {createUserModel && (
              <CreateUserModel
                show={() => showModal(showCreateUserModel)}
                handleClose={() => closeModal(showCreateUserModel)}
                parentIdentifier={actionData}
                onSave={refreshDatatable}
              />
            )}
            {deleteModel && (
              <DeleteModal
                showModal={deleteModel}
                role={deleteRole}
                onClick={handleDeleteAction}
                onClose={() => closeModal(showDeleteModel)}
                onSave={refreshDatatable}
                deleteData={actionData}
              />
            )}
            {editUser && (
              <EditUserModal
                show={editUser}
                handleClose={() => closeModal(showEditUser)}
                initialData={actionData}
                onSave={refreshDatatable}
              />
            )}
            {showMoreFilters && (
              <ProgramsMoreFilters
                show={showMoreFilters}
                handleClose={() => closeModal(setShowMoreFilters)}
                filter={filter}
                setFilter={setFilter}
                filterStatus={dataFilterStatus}
              />
            )}
          </div>
        </>
      ) :
        !isLoading && (
          <div className="empty-Container">
            <img src={logo} alt="Logo" style={{ display: "block", margin: "0 auto" }} />
            <p className="text-center" style={{ color: "green" }}>You haven't added any User yet.!</p>
            <p className="sub-text">Click below button to add your first User.</p>
            <button type="button" className="btn btn-primary" onClick={() => showModal(showCreateUserModel)}>+ Add User</button>
            {createUserModel && (
              <CreateUserModel
                show={showCreateUserModel}
                handleClose={() => closeModal(showCreateUserModel)}
                parentIdentifier={actionData}
                onSave={refreshDatatable}
              />
            )}
          </div>
        )}
      {isLoading && (<div className="spinner-overlay"><Loader /></div>)}
    </>
  );
}

export default Users;