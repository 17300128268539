import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { SaveStyledModal, ModalFooter, CloseIcon } from "./SaveModal.styled";
import closeIcon from "../../../Assests/Images/Vector.svg";

function SaveModal({ showModal, onClose, onSave, setShowContent }) {
  const [show, setShow] = React.useState(showModal);
  const handleClose = () => {
    setShow(false);
    if (onClose) {
      onClose();
    }
  };
  const handleSave = () => {
    handleClose();
    if (onSave) {
      onSave();
    }
  };

  const modalContent = setShowContent ?"Are you sure you want to unflag?" : "Are you sure you want to save changes?"

  return (
    <div aria-labelledby="save-modal-title" role="dialog" aria-modal="true">
      <SaveStyledModal show={show} onHide={handleClose} centered>
      <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
        <Modal.Body>{modalContent}</Modal.Body>
        <ModalFooter>
          <Button variant="secondary" onClick={handleSave}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </SaveStyledModal>
    </div>
  );
}

SaveModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default SaveModal;
