import { baseUrl, fetchToken } from "Constants/ApiUrls";
import { toast } from "react-toastify";

export const getStatusCountCall = async (params) => {
    const url = `${baseUrl}/cms/v1/statusCounts/${params}`;
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${fetchToken()}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to fetch the status counts.");
            return false;
        }
        return result.data;
    } catch (error) {
        toast.error("An error occurred while fetching the status counts.");
        console.error("Error in getStatusCountCall:", error);
        throw new Error(error);
    }
};
