import { fetchToken } from "Constants/ApiUrls";
import { jwtDecode } from "jwt-decode";

/**
 * isAuthenticated - Function to check if the user is authenticated.
 *
 * @returns {boolean} True if the user is authenticated, false otherwise.
 */
export const isAuthenticated = () => {
    const token = fetchToken();
    if(token) {
        const currentTs = Date.now();
        const decoded = jwtDecode(token);
        if (decoded.exp * 1000 < currentTs) {
            localStorage.removeItem("token");
            localStorage.removeItem("email");
            localStorage.removeItem("first_name");
            localStorage.removeItem("last_name");
            localStorage.removeItem("type");
            return false;
        }
        else {
            return true
        }
    }
    else {
        return false;
    }
};

/**
 * loginUser - Function to login the user.
 *
 * @param {string} email - The email address of the user.
 * @param {string} otp - The OTP sent to the user's email address.
 *
 * @returns {object} An object containing the success status and message.
 */
export const loginUser = async (email, otp) => {
    
    const verifyOtpUrl = process.env.REACT_APP_BASE_URL + "/cms/v1/auth/verify-otp";
    
    const response = await fetch(verifyOtpUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ pemail: email, otp: otp }),
    });

    if (!response.ok) {
        throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    if(responseData.success === true) {
        const token = responseData.data.access_token;
        const userEmail = responseData.data.email;
        const firstName = responseData.data.first_name;
        const lastName = responseData.data.last_name;
        const type = responseData.data.type;
        localStorage.setItem("token", token);
        localStorage.setItem("first_name", firstName);
        localStorage.setItem("last_name", lastName);
        localStorage.setItem("type", type);
        localStorage.setItem("email", userEmail);

        return {
            success: true,
            message: responseData.message,
            data: responseData.data
        }
    }
    else {
        return {
            success: false,
            message: responseData.message
        }
    }
};

export const loginVerifiedUser = async (email, first_name, name, timezone) => { 
    const verifyUserUrl = process.env.REACT_APP_BASE_URL + "/cms/v1/auth/verifyUser";
    const response = await fetch(verifyUserUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body:JSON.stringify({ email: email, first_name: first_name, name: name, timezone: timezone }),
    });
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    if(responseData.success === true) {
        const token = responseData.data.access_token;
        const userEmail = responseData.data.email;
        const firstName = responseData.data.first_name;
        const lastName = responseData.data.last_name;
        const type = responseData.data.type;
        localStorage.setItem("token", token);
        localStorage.setItem("first_name", firstName);
        localStorage.setItem("last_name", lastName);
        localStorage.setItem("type", type);
        localStorage.setItem("email", userEmail);

        return {
            success: true,
            message: responseData.message,
            data: responseData.data
        }
    }
    else {
        return {
            success: false,
            message: responseData.message
        }
    }
};

export const generateRandomStringForSSO = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomString = '';
    for (let i = 0; i < length; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomString;
  };
  