import Dexie from "dexie";

const DbConfig = new Dexie('ContentDB');

DbConfig.version(1).stores({
  accessToken: '++id, accessToken',
  userDetails: '++id, firstName, lastName, email, roleName',
  curriculumAccess: '++id, programs, levels, domains, topics, skills, questions, flagging, comments, assignments, tests, publishCurriculum, exportCurriculum',
  settingsAccess: '++id, organization, users, teams, roles',
  programDetails: '++id, programDetails'
});

export default DbConfig;