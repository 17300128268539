import styled, { keyframes } from "styled-components";
import { Modal, Form } from "react-bootstrap";

export const ModalButton = styled.button`
  height: 3rem;
  border-radius: 0.25rem;
  background-color: ${(props) => (props.isClose ? "var(--white-color)" : "var(--blue-color)")};
  color: ${(props) => (props.isClose ? "var(--blue-color)" : "var(--white-color)")};
  margin: 2px 5px 5px 25px;
  border: ${(props) =>
    props.isClose ? "1px solid var(--blue-color);" : "0px solid #D8D8D8;"};
  font-size: 1rem
  font-weight: 600;
  line-height: 1.125rem;
  margin-right: 0px;
  opacity: 1;
  font-weight: bold;
  display: inline-flex;
  padding: 0.9375rem 2rem;
  gap: 0.625rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;  
  flex-shrink: 0;
  white-space: nowrap;
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 0.8rem;
  height: 0.8rem;
  flex-shrink: 0;
  margin-left:auto;
  margin-right:-7rem;
  margin-top: 0.5rem;`
  ;

export const CustomModal = styled(Modal)`
  & .modal-content {
    flex: 1;
    display: flex;
    width: 34.375rem;
    height: 39.9375rem;
    flex-shrink: 0;
    flex-direction: column;
    border-radius: 0.25rem;
  }

  & .modal-body {
    overflow-y: auto;
    max-height: 100%;
  }

  & .modal-footer {
    padding: 10px 25px 0px 0px;
  }

  &.sidebarModal .modal-dialog {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 2560px) {
    & .modal-dialog {
      width: 50%;
    }
    &.sidebarModal .modal-dialog {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 1024px) {
    & .modal-dialog {
      width: auto;
    }
    &.sidebarModal .modal-dialog {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 728px) {
    & .modal-dialog {
      width: auto;
    }
    &.sidebarModal .modal-dialog {
      margin-left: auto;
      margin-right: auto;
    }

    & .modal-footer {
      padding: 20px;
    }
  }

  @media (max-width: 425px) {
    & .modal-dialog {
      width: auto;
    }
    &.sidebarModal .modal-dialog {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

export const CustomModalHeader = styled(Modal.Header)`
  color: var(--black-color);
  font-family: var(--font-family);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 435px;
  height: 63px;
  padding: 30px;
  flex-shrink: 0;
  border: none;
  border-radius: 0;
  .modal-title {
  color: var(--black-color);
  }
  }
`;

export const CustomModalFooter = styled(Modal.Footer)`
  height: 5.1875rem;
`;

export const CustomForm = styled(Form.Group)`
  color: var(--black-color);
  font-family: var(--font-family);
  font-size: var(--font-size-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 1rem;
`;

export const UsersAvatar = styled.img`
  width: 1.125rem; 
  height: 1.125rem;
  border-radius: 50%;
  background-image: url(${props => props.src});
  background-size: cover;
`;