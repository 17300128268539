import React from 'react';
import { Form } from 'react-bootstrap';
import Select, { components } from "react-select";

export const formatOptionLabel = ({ label }) => <span>{label}</span>;

export const CheckboxOption = (props) => (
    <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
            <input
                type={props.isMulti ? 'checkbox' : 'radio'}
                checked={props.isSelected}
                onChange={() => props.selectOption(props.data)}
                style={{
                    marginRight: "5px",
                    flexShrink: 0,
                }}
            />
            <span style={{ color: "#000" }}>{props.label}</span>
        </div>
    </components.Option>
);

export const RadioOption = (props) => (
    <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
            <input
                type="radio"
                checked={props.isSelected}
                onChange={() => props.selectOption(props.data)}
                style={{
                    marginRight: "5px",
                    flexShrink: 0,
                }}
            />
            <span style={{ color: "#000" }}>{props.label}</span>
        </div>
    </components.Option>
);

export const noOptionsMessage = (isTyping, searchOptions) => (
    <div style={{
        textAlign: "center", width: "auto", fontSize: "11.5px", fontWeight: "500",
        color: '#000'
    }}>
        <>
            {isTyping ? (
                <span style={{ color: "var(--sky-blue-color)" }}>Searching...</span>
            ) : (
                searchOptions ? "Type something..." : "No options"
            )}
        </>
    </div>
);

export const commonStyles = {
    option: (base) => ({
        ...base,
        backgroundColor: "var(--white-color) !important",
    }),
    control: (base) => ({
        ...base,
        borderColor: "#d8d8d8",
    }),
    placeholder: (base, isSelected) => ({
        ...base,
        color: isSelected ? "#347bfa " : "#000",
    }),
};

export const splitCamelCaseAndCapitalize = (label) => {
    const result = label.replace(/([a-z])([A-Z])/g, '$1 $2');
    return result.split(' ').map(word => capitalizeFirstLetter(word)).join(' ');
};

export const getPlaceholderWithCount = (label, selection = [], maxLength = 15) => {
    const formattedLabel = formatLabel(label);
    const truncatedLabel = formattedLabel.length > maxLength ? `${formattedLabel.slice(0, maxLength)}...` : formattedLabel;
    const countOfSelections = Array.isArray(selection) ? selection.length : (selection ? 1 : 0);
    const hasSelections = countOfSelections > 0;
    return {
        placeholder: hasSelections ? `${truncatedLabel} (${countOfSelections})` : truncatedLabel,
        hasSelections
    };
};

const formatLabel = (label) => { return label.replace(/([a-z])([A-Z])/g, '$1 $2'); };

const SingleValueComponent = (props) => {
    const { hasSelections, ...restProps } = props;

    return (
        <components.SingleValue {...restProps}>
            <span style={{ color: hasSelections ? "#000" : "#347bfa" }}>
                {props.data?.label}
            </span>
        </components.SingleValue>
    );
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const GenericDropdown = ({
    id,
    titles,
    menuState,
    setInputs,
    selections,
    setIsTyping,
    dropdownRef,
    isTyping = true,
    searchOptions,
    setSelections,
    setMenuStates,
    onChangeCallback,
    setSearchOptions,
    dropdownClassName,
    handleInputChange,
    isSearchable = false,
    handleSelectionChange,
    handleMenuStateChange,
    isMoreFilters = false,
    resetTrigger
}) => {
    const OptionComponent = ["questionType", "status", "flag"].includes(id) ? RadioOption : CheckboxOption;

    const getCombinedSelections = (id, selections) => {
        const relatedKeys = {
            organization: 'organizationIds',
            program: 'programIds',
            level: 'levelIds',
            domain: 'domainIds',
            topic: 'topicIds',
            skill: 'skillIds',
            difficulty: 'complexity',
        };
        if (relatedKeys[id]) {
            const primarySelections = selections[id] || [];
            const relatedSelections = selections[relatedKeys[id]] || [];
            const combinedSelections = [...new Set([...primarySelections, ...relatedSelections])];
            return combinedSelections;
        }
        return selections[id] || [];
    };

    const combinedSelections = getCombinedSelections(id, selections);

    const { placeholder, hasSelections } = getPlaceholderWithCount(capitalizeFirstLetter(id), combinedSelections);

    return (
        <Form.Group controlId={id}>
            <Select
                key={resetTrigger}
                ref={dropdownRef}
                className={dropdownClassName}
                placeholder={placeholder}
                options={titles}
                onChange={!isMoreFilters ? (selectedOptions) =>
                    handleSelectionChange(id, selectedOptions, setSelections, onChangeCallback)
                    : (selectedOptions) => handleSelectionChange(id, selectedOptions, setSelections)}
                closeMenuOnSelect={OptionComponent !== CheckboxOption}
                controlShouldRenderValue={false}
                menuPlacement="bottom"
                onInputChange={isSearchable ? (inputValue, { action }) => {
                    setSearchOptions(true);
                    setIsTyping(action === 'input-change');
                    handleInputChange(id, inputValue, setInputs);
                }
                    : undefined
                }
                onKeyDown={(e) => {
                    // if (e.code === "Enter" || e.code === "Space") {
                    if (e.code === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
                menuIsOpen={menuState}
                onMenuOpen={() => handleMenuStateChange(id, true, setMenuStates)}
                onMenuClose={() => handleMenuStateChange(id, false, setMenuStates)}
                formatOptionLabel={formatOptionLabel}
                isMulti={OptionComponent === CheckboxOption}
                isSearchable={isSearchable}
                isClearable={hasSelections}
                hideSelectedOptions={false}
                components={{
                    Option: OptionComponent,
                    SingleValue: OptionComponent === RadioOption ? (props) => (
                        <SingleValueComponent
                            {...props}
                            hasSelections={selections[id].length > 0}
                        />
                    ) : undefined,
                }}
                noOptionsMessage={() => noOptionsMessage(isTyping, searchOptions)}
                styles={{
                    ...commonStyles,
                    placeholder: (base) => ({
                        ...base,
                        color: hasSelections ? "#347bfa" : "#000",
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: "#347bfa",
                    }),
                }}
            />
        </Form.Group>
    )
};

export default GenericDropdown;