import { toast } from "react-toastify";
import { baseUrl, fetchToken } from "Constants/ApiUrls";

var token = fetchToken();

export const domainCodesUrl =
    process.env.REACT_APP_BASE_URL + "/cms/v1/getDomainCodes/";
export const domainTitlesUrl =
    process.env.REACT_APP_BASE_URL + "/cms/v2/getDomainTitles/";

export const getAllDomains = async (filterData) => {
    token = fetchToken();
    try {
        const filterParams = filterData.map((filter) => {
            if (typeof filter.value == "object" && filter.value.length > 0) {
                let values = "";
                if (filter.name === "title" || filter.name === "code" || filter.name === "programIds"
                    || filter.name === "organizationIds" || filter.name === "levelIds" || filter.name === "status"
                    || filter.name === "limit") {
                    values = filter.value.map(
                        (value) => `${filter.name}[]=${value}`
                    );
                } else {
                    values = filter.value.map(
                        (value) => `${filter.name}[]=${value.id}`
                    );
                }
                return values.join("&");
            } else {
                return `${filter.name}=${filter.value}`;
            }
        });
        const url =
            `${baseUrl}/cms/v2/getAllDomains` + "?" + filterParams.join("&");
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return { domains: [], totalPages: 0 };
        } else {
            return result.data;
        }
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const updateDomains = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/updateDomain`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            if (Array.isArray(result.message)) {
                toast.error("Something went wrong");
            } else {
                toast.error(result.message);
            }
            return false;
        }
        toast.success("Updated Successfully");
        return response;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const addTopic = async (data) => {
    const url = `${baseUrl}/cms/v2/insertTopic`;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!result.success || !response.ok) {
            return false;
        } else {
            toast.success("Topic Added.");
        }
        return response;
    } catch (error) {
        toast.error('Something went wrong!')
        throw new Error(error);
    }
}

export const deleteDomain = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/deleteDomain`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Deleted Successfully.");
        return response;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const addFlag = async (data, flag = true) => {
    try {
        const url = `${baseUrl}/cms/v1/addCommentToDomain?flag=${flag}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Flagged Succesfully.");
        return response;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const addFlagForBulkForDomain = async (data, flag = true) => {
    try {
        const url = `${baseUrl}/cms/v2/addCommentToDomain?flag=${flag}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Bulk Flagged Succesfully.");
        return response;
    } catch (error) {
        toast.error('Something went wrong!')
        throw new Error(error);
    }
}

export const removeFlag = async (domainId) => {
    try {
        const url = `${baseUrl}/cms/v1/domainUnflag`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id: domainId }),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Unflagged Successfully");
        return true;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const removeFlagForBulk = async (data) => {
    try {
        const url = `${baseUrl}/cms/v2/domainUnflag`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Unflagged Successfully");
        return true;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const updateBulkDomainStatus = async (publish, data) => {
    try {
        let url = "";
        if (publish) {
            url = `${baseUrl}/cms/v1/publishDomain`;
        } else {
            url = `${baseUrl}/cms/v1/unPublishDomain`;
        }
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Status Updated");
        return response;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const getDomainTitles = async (url) => {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to fetch domain titles");
            return [];
        }
        if (result.data?.domainTitles?.length) {
            return result.data.domainTitles.map((item) => ({
                label: item.title,
                value: item.title,
                id: item._id,
            }));
        } else {
            return [];
        }
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const getDomainCodes = async (url) => {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message || "Failed to fetch domain titles");
            return [];
        }
        if (result.data?.domainCodes) {
            return result.data.domainCodes.map((item) => ({
                label: item.code,
                value: item.code,
                id: item._id,
            }));
        } else {
            return [];
        }
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const reactivateDomain = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/reactivateDomain`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Domain Reactivated.");
        return response;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const publishDomains = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/publishDomain`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Domain Published.");
        return response;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const unPublishDomains = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/unPublishDomain`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        if (!response.ok || !result.success) {
            toast.error(result.message);
            return false;
        }
        toast.success("Domain Unpublished");
        return response;
    } catch (error) {
        toast.error("Something went wrong!");
        throw new Error(error);
    }
};

export const getById = async (data) => {
    try {
        const url = `${baseUrl}/cms/v1/getDomainById/${data}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = await response.json();
        if (!response.ok) {
            toast.error(responseData.message);
            return false;
        }
        if (!responseData.success) {
            toast.error(responseData.message);
            return false;
        }
        return responseData.data;
    } catch (error) {
        console.error("Error updating data: ", error);
        throw new Error(error);
    }
};
