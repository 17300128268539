import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, Form } from "react-bootstrap";
import closeIcon from "../../Assests/Images/closeIcon.svg";
import { toast } from "react-toastify";
import "./MoreFilters.css";
import "react-toastify/dist/ReactToastify.css";
import {
    CustomModalHeader,
    CustomModalFooter,
    CustomForm,
    ModalButton,
    CustomModal,
    CloseIcon,
} from "../Modals/AddEditModal.styled";
import Select, { components } from "react-select";
import _ from "lodash";
import _debounce from "lodash/debounce";
import { fetchToken } from "Constants/ApiUrls";

const ProgramsMoreFilters = ({
    show,
    handleClose,
    filter,
    setFilter,
    filterStatus,
}) => {
    useEffect(() => {
        filter.forEach((eachfilter) => {
            if (eachfilter.name === "organizationIds") {
                setSelectedOrganization(
                    eachfilter.value.map((org) => ({
                        value: org.id,
                        label: org.label,
                        id: org.id,
                    }))
                );
            }
        });
    }, [filter]);

    const [isOrganizationMenuOpen, setIsOrganizationMenuOpen] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [organizationTitle, setOrganizationTitle] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const moreFiltersRef = useRef(null);

    const token = fetchToken();
    const getOrganization =
        process.env.REACT_APP_BASE_URL + "/cms/v1/getAllOrganizationTitles";

    const formatOptionLabel = ({ label }) => <span>{label}</span>;

    const CheckboxOption = (props) => (
        <components.Option {...props}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => props.selectOption(props.data)}
                    style={{
                        marginRight: "5px",
                        flexShrink: 0,
                    }}
                />
                <span style={{ color: "Black" }}>{props.label}</span>
            </div>
        </components.Option>
    );

    const handleApplyFilters = async (e) => {
        e.preventDefault();
        if (selectedOrganization.length === 0) {
            toast.error("Please select an Organization");
            return;
        }

        let previousFilter = filter.filter((f) => f.name !== "organizationIds");

        const updatedFilter = [
            ...previousFilter,
            {
                name: "organizationIds",
                value: selectedOrganization,
            },
            {
                name: "page",
                value: 1,
            },
            { name: "limit", value: 10 }
        ];
        await setFilter(updatedFilter);
        toast.success("Filters applied");
        handleClose();
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (moreFiltersRef.current && !moreFiltersRef.current.contains(event.target) && !event.target.closest('.modal-footer') && !event.target.closest('.modal-header')) {
                handleClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        fetchOrganizationsTitle();
    }, []);

    const fetchOrganizationsTitle = _.debounce(async () => {
        try {
            const url = getOrganization;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            const result = await response.json();
            if (!response.ok) {
                console.error(response);
                toast.error(result.message);
                return false;
            }
            if (result.success && result.data.organizationTitles.length > 0) {
                const organizationTitle = result.data.organizationTitles.map((title) => ({
                    value: title._id,
                    label: title.title,
                    id: title._id,
                }));
                setOrganizationTitle(organizationTitle);
            }
        } catch (error) {
            toast.error("An error occurred while fetching the organization titles.");
            throw new Error(error);
        }
    }, 2000);

    const handleRemoveFilter = (type) => {
        let updatedFilter = [];
        if (type == 'organizationIds') {
            updatedFilter = filter.filter((f) => f.name !== "organizationIds");
            setSelectedOrganization([]);
        }
        if (!_.isEqual(filter, updatedFilter)) {
            setFilter(updatedFilter);
        }
    };

    const handleClearAllFilters = () => {
        const organizationFilter = filter.find((f) => f.name === "organizationIds");
        if (organizationFilter && organizationFilter.value.length > 0) {
            let updatedFilter = filter.filter((f) => f.name !== "organizationIds");
            setSelectedOrganization([]);
            if (!_.isEqual(filter, updatedFilter)) {
                setFilter(updatedFilter)
            }
        } else {
            setSelectedOrganization([]);
        }
    };

    const getDynamicPlaceholder = (label, count) => {
        return count > 0 ? (
            <span style={{ color: "var(--blue-color)" }}>
                {label} ({count})
            </span>
        ) : (
            label
        );
    };

    const onChangeOrganization = (selectedOptions) => {
        const organizationIds = "organizationIds";
        let filterWithoutOrganizationId = filter.filter(
            (f) => f.name !== organizationIds
        );
        const existingOrganizationFilter = filter.find(
            (f) => f.name === organizationIds
        );
        let updatedFilter = existingOrganizationFilter
            ? filterWithoutOrganizationId
            : [...filter];

        if (selectedOptions.length > 0 && existingOrganizationFilter) {
            setSelectedOrganization(selectedOptions);
            existingOrganizationFilter.value = [
                ...new Set([...existingOrganizationFilter.value, ...selectedOptions]),
            ];
            updatedFilter = [...filter];
        } else {
            setSelectedOrganization(selectedOptions);
            updatedFilter = [...filterWithoutOrganizationId];
        }
        if (!_.isEqual(filter, updatedFilter)) {
            setFilter(updatedFilter);
        }
    };

    return (
        <>
            <CustomModal show={show} onHide={handleClose}>
                <CustomModalHeader>
                    <Modal.Title>More Filters</Modal.Title>
                    <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
                </CustomModalHeader>
                <Modal.Body ref={moreFiltersRef}>
                    <div>
                        <span className="active-filters-header">Applied Filters</span>
                        <div className="active-filter-container">
                            {selectedOrganization && selectedOrganization.length > 0 && (
                                <div className="active-filters">
                                    <span>
                                        Organizations
                                        <span className="filter-cross" onClick={() => handleRemoveFilter("organizationIds")}>
                                            &#10006;
                                        </span>
                                    </span>
                                </div>
                            )}
                            {selectedOrganization.length > 0 && (
                                <div
                                    className="clear-all-filters"
                                    onClick={handleClearAllFilters}
                                >
                                    Clear All Filters
                                </div>
                            )}
                            <hr />
                        </div>
                    </div>
                    <CustomForm onSubmit={handleApplyFilters}>
                        <div className="filters-container">
                            <Form.Group controlId="organization">
                                <Select
                                    className="custom-select"
                                    placeholder={getDynamicPlaceholder(
                                        "Organizations",
                                        selectedOrganization.length
                                    )}
                                    menuIsOpen={isOrganizationMenuOpen}
                                    options={organizationTitle}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }
                                    }}
                                    onChange={onChangeOrganization}
                                    value={selectedOrganization}
                                    isMulti
                                    isClearable
                                    components={{
                                        Option: CheckboxOption,
                                        NoOptionsMessage: () => (
                                            <div style={{ textAlign: "center", padding: "0.4rem" }}>
                                                {isTyping ? (
                                                    <span style={{ color: "var(--sky-blue-color)" }}>
                                                        Searching...
                                                    </span>
                                                ) : organizationTitle.length === 0 ? (
                                                    "Please wait..."
                                                ) : (
                                                    "No options"
                                                )}
                                            </div>
                                        ),
                                    }}
                                    onMenuOpen={() => setIsOrganizationMenuOpen(true)}
                                    onMenuClose={() => setIsOrganizationMenuOpen(false)}
                                    formatOptionLabel={formatOptionLabel}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    controlShouldRenderValue={false}
                                    menuPlacement="bottom"
                                    styles={{
                                        width: "1.5rem",
                                        option: (base) => ({
                                            ...base,
                                            backgroundColor: "var(--white-color) !important",
                                        }),
                                        control: (base) => ({
                                            ...base,
                                            borderColor: "#d8d8d8",
                                        }),
                                        placeholder: (base) => ({
                                            ...base,
                                            color: "#000",
                                            width: "11rem",
                                        }),
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </CustomForm>
                </Modal.Body>
                <CustomModalFooter>
                    <ModalButton
                        isClose
                        onClick={handleClose}
                        style={{ width: "7.5rem" }}
                    >
                        Cancel
                    </ModalButton>
                    <ModalButton
                        type="submit"
                        style={{ width: "10.75rem" }}
                        onClick={handleApplyFilters}
                    >
                        Apply Filters
                    </ModalButton>
                </CustomModalFooter>
            </CustomModal>
        </>
    );
};

ProgramsMoreFilters.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    filter: PropTypes.array.isRequired,
    setFilter: PropTypes.func.isRequired,
};

export default ProgramsMoreFilters;
