import React from "react";
import { InfinitySpin } from 'react-loader-spinner'
const Loader = () => {
    return (
        <div className="loader-overlay">
            <InfinitySpin
                visible={true}
                width="150"
                color="#016DD9"
                ariaLabel="infinity-spin-loading"
            />
        </div>
    );
};

export default Loader;
