import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   qtype: {},
   questionType:''
};

const qtypeSlice = createSlice({
    name:"qtype",
    initialState,
    reducers: {
        setQtype:(state,action) => {
            state.qtype = action.payload
        },
        setQuestionType:(state,action) => {
            state.qtype = action.payload
        }
    }
})

export const {setQtype, setQuestionType} = qtypeSlice.actions;
export default qtypeSlice.reducer