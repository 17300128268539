import styled, { keyframes } from "styled-components";
import { Modal, Form, Card} from "react-bootstrap";

const slideInAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const CustomModal = styled(Modal)`
  & .modal-dialog {
    width: 24.1875rem;
    margin: 0;
    left: auto;
    right: 0;
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    position: fixed;
    overflow-x: visible;
    top: 0;
    right: 0;
    bottom: 0;
    animation: ${slideInAnimation} 0.3s ease-in-out;
  }

  & .modal-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 0;
  }

  & .modal-body {
    overflow-y: auto;
    max-height: 100%;
  }

  & .modal-footer {
    padding: 20px;
  }

  &.sidebarModal .modal-dialog {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 2560px) {
    & .modal-dialog {
      width: auto;
    }
    &.sidebarModal .modal-dialog {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 1024px) {
    & .modal-dialog {
      width: auto;
    }
    &.sidebarModal .modal-dialog {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 728px) {
    & .modal-dialog {
      width: auto;
    }
    &.sidebarModal .modal-dialog {
      margin-left: auto;
      margin-right: auto;
    }

    & .modal-footer {
      padding: 20px;
    }
  }

  @media (max-width: 425px) {
    & .modal-dialog {
      width: auto;
    }
    &.sidebarModal .modal-dialog {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

CustomModal.defaultProps = {
  backdrop: false,
};

export const CustomModalHeader = styled(Modal.Header)`
  color: var(--black-color);
  font-family: var(--font-family);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 7.9375rem;
  height: 1.8125rem;
  padding: 30px;
  flex-shrink: 0;
  border: none;
  border-radius: 0;
  padding-bottom: 0.5rem;
  margin-left: -5px;
  }
`;

export const CustomForm = styled(Form)`
  color: var(--black-color);
  font-family: var(--font-family);
  font-size: var(--font-size-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 1rem;
  position: relative;
  right: 5px;

  & > .form-group {
    position: relative;
    display: flex;
    align-items: center;
  }

  & .form-control {
    left: 15px;
    font-size: var(--font-size-small);
    width: auto;
    height: 3rem;
    border-radius: 0.5rem 0 0 0.5rem;
    border: 1px solid var(--pale-gray-color);
    border-right: none;
    flex-grow: 1;
    background: var(--white-color);
    font-family: var(--font-family);
    resize: none;
    overflow-x: hidden;
    max-height: 80vh;
    margin-right: -16px;
    padding-top: 0.9rem;
    padding-right: 48px;
    ::-webkit-scrollbar {
      width: 0;
    }
    scrollbar-width: none; 
    }
    
    & .form-control:focus {
      outline: none;
      box-shadow: none;
    }
    

  & .send-container{
    left: 15px;
    font-size: var(--font-size-small);
    width: 3rem;
    height: 3rem;
    border-radius: 0 0.5rem 0.5rem 0;
    margin-left: -1px;
    flex-shrink: 0; 
    border: 1px solid var(--pale-gray-color);
    border-left: none;
    background: var(--white-color);
    font-family: var(--font-family);
    resize: none;
    overflow-x: hidden;
    max-height: 80vh;
    margin-right: -16px;
    padding-right: 16px;
    ::-webkit-scrollbar {
      width: 0;
    }
    scrollbar-width: none; 
    }
  }

  & img.send-comment-icon {
    position: absolute;
    right: 5px;
    top: 48%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  & .form-control::placeholder {
    font-family: var(--font-family);
    font-size: var(--font-size-medium);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left:0px;
    color: var(--black-color);
    text-align:left !important
    vertical-align: left !important; 
  }

  @media (max-width: 1024px) {
    & img.send-comment-icon {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    & img.send-comment-icon {
      position: absolute;
      left: 18rem;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  `;

  export const CloseIcon = styled.img`
  cursor: pointer;
  width: 0.75969rem;
  height: 0.75975rem;
  flex-shrink: 0;
  margin-left: 12rem;
  `;

export const Toggle = styled.div`
  display: flex;
  align-items: center;

  & > span {
    margin-right: 10px;
    margin-top: 10px;
    color: var(--black-color);
    font-family: Inter;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .form-switch {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-left: -38px;
    margin-top: 24px;

  }

  .form-switch input {
    display: none;

  }

  .form-switch label {
    display: block;
    width: 36px;
    height: 20px;
    background-color: var(--desert-sand-color);
    border-radius: 20px;
    position: absolute;
    cursor: pointer;
    top: 0;
    transition: background-color 0.3s;
  }

  .form-switch label:after {
    content: "";
    width: 16px;
    height: 16px;
    background-color: var(--white-color);
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: left 0.3s;
  }

  .form-switch input:checked + label {
    background-color: var(--sky-blue-color);
  }

  .form-switch input:checked + label:after {
    left: 18px;
  }
  
`;
export const StyledCard = styled(Card)`
  width: 21.1875rem;
  height: auto;
  flex-shrink: 0;
  left: 10px;
  margin-bottom: 20px;
  border-radius: 0.25rem;
  border: 1px solid var(--subtle-gray-color);
  background: var(--white-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  & .card-title-container{
    max-width:18rem;
    margin-left: 0px;
    position: relative;
  }

  & .card-text-container{
    max-width:18rem;
    margin-left:0px;
  }

  .icons-container {  
    display: flex;
    align-items: center;
    margin-left: -3rem;
    margin-right: auto;
  }
  
`;

export const CardTitle = styled(Card.Title)`
  color: var(--black-color);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex-grow: 2;
  margin-right: 240px;
  white-space: nowrap;
`;

export const CardText = styled(Card.Text)`
  color: var(--graphite-gray-color);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
`;

export const CardBody = styled(Card.Body)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CardProfile = styled.img`
  width: 1.5rem; 
  height: 1.5rem;
  border-radius: 50%;
  margin-right: 20px; 
  background-image: url(${props => props.src});
  background-size: cover;
`;

export const FlagIcon = styled.img`
  width: 1rem; 
  height: 1rem;
  flex-shrink: 0;
  margin-right: 5rem;
`;

export const TimeDisplay = styled.span`
color: var(--storm-gray-color);
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: 0.75rem;
`;

export const CustomModalBody = styled(Modal.Body)`
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  &::-webkit-scrollbar {
    display: none;
  }
`;