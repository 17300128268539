import "./styles.css";
import React from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../../../Assests/Images/closeIcon.svg";
import { closeErrorModal } from "../../../../redux/slices/ValidationErrorSlices/validationErrorSlice";

const ValidationErrorModal = () => {
    const dispatch = useDispatch();
    const { isErrorModalOpen, messages } = useSelector(
        (state) => state.validationErrorModal
    );
    var messageArray = [];
    console.log('messages', messages)
    if (!Array.isArray(messages)) {
        messageArray = [messages]
    } else {
        messageArray = messages;
    }
    if (!isErrorModalOpen) return null;

    return (
        <Card className="validation-error-modal">
            <Card.Header className="validation-error-modal-header">
                <span>Validation falied</span>
                <img
                    src={CloseIcon}
                    alt="Close icon"
                    onClick={() => dispatch(closeErrorModal())}
                    style={{ color: "#fff !important" }}
                />
            </Card.Header>
            <Card.Body className="validation-error-modal-body">
                {messageArray.length > 0 ? (
                    <ol className="error-message-list">
                        {messageArray.map((message, index) => (
                            <li key={index} className="error-message">
                                {message}
                            </li>
                        ))}
                    </ol>
                ) : (
                    <div>No error messages to display.</div>
                )}
            </Card.Body>
            <Card.Footer className="validation-error-modal-footer">
                <button onClick={() => dispatch(closeErrorModal())}>OK</button>
            </Card.Footer>
        </Card>
    );
};

export default ValidationErrorModal;
