import React, { useState, useEffect } from "react";
import { useParams, useNavigate, redirect } from 'react-router-dom';

import _ from "lodash";
import DbConfig from "Constants/db";
import Title from "../../../Components/Title/Title";
import Loader from "../../../Components/Loader/Loader";
import logo from "../../../Assests/Images/empty-box.svg";
import DataTable from "../../../Components/DataTable/DataTable";
import TempFilterHeader from "Components/FilterHeader/OptimizedFilterHeader";
import AddDomainModal from "../../../Components/Modals/Level/AddDomainModal";
import EditLevelModal from "../../../Components/Modals/Level/EditLevelModal";
import DeleteModal from "../../../Components/Modals/DeleteModal/DeleteModal";
import ReactivateModal from "Components/Modals/ReactivateModal/ReactivateModal";
import {
    deleteLevel,
    getAllLevels,
    reactivateLevel,
    updateBulkStatus,
    publishLevels,
    unPublishLevels,
} from "../../../Utils/ApiHandler/LevelApi";
import ExportModal from "Components/Modals/ExportModal/ExportModal";
import Comment_VersionHistory from "../../../Components/Modals/Comments/Comment_VersionHistory";

const columns = ["Code", "Level Name", "Program", "Domains", "Description"];

const STATUS = {
    UNPUBLISH: -1,
    IN_REVIEW: 0,
    PUBLISH: 1,
    NO_IMAGE: 2,
};

const filtersDropdownStructure = [{
    "code": true,
    "organization": true,
    "program": true,
    "level": true,
    "flag": false,
    "status": true,
    "skill": false,
    "topic": false,
    "domain": false,
    "question": false,
    "flaggedBy": false,
    "complexity": false,
    "questionType": false,
}];

const exportColumns = [
    { key: "levelCode", label: "Level Code" },
    { key: "levelName", label: "Level Name" },
    { key: "programName", label: "Program Name" },
    { key: "numDomains", label: "Number of Domains" },
    { key: "numTopics", label: "Number of Topics" },
    { key: "numSkills", label: "Number of Skills" },
    { key: "numQuestions", label: "Number of Questions" },
    { key: "status", label: "Status" },
];

function CurriculumAllLevels() {

    const { page, id, filterKey } = useParams();
    const navigate = useNavigate();

    const [isBlur, setIsBlur] = useState(false);
    const [status, setStatus] = useState(false);
    const [levelId, setLevelId] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [actionData, setActionData] = useState("");
    const [deleteRole, setDeleteRole] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [addDomain, showAddDomain] = useState(false);
    const [editLevel, showEditLevel] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const [deleteModel, showDeleteModel] = useState(false);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [levelsAccess, setLevelsAccess] = useState(false);
    const [reactivateRole, setReactivateRole] = useState("");
    const [publishAccess, setPublishAccess] = useState(false);
    const [commentsAccess, setCommentsAccess] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [unpublishAccess, setUnpublishAccess] = useState(false);
    const [addDomainAccess, setAddDomainAccess] = useState(false);
    const [dataFilterStatus, setDataFilterStatus] = useState(true);
    const [showReactiveModel, setShowReactiveModel] = useState(false);
    const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
    const [displayVersionHistory, showDisplayVersionHistory] = useState(false);

    const [goToPage, setGoToPage] = useState(0);
    const [filter, setFilter] = useState([
        { name: "page", value: 1 },
        { name: "limit", value: 10 },
        { name: "active", value: true },
        { name: "status", value: "" },
        { name: "search", value: "" },
        { name: "title", value: [] },
        { name: "programIds", value: [] },
    ]);
    const [isHavingExportAccess, setIsHavingExportAccess] = useState(false);

    useEffect(() => {
        DbConfig.curriculumAccess.toArray().then(curriculumAccessData => {
            setLevelsAccess(curriculumAccessData.map(item => item.levels));
            setCommentsAccess(curriculumAccessData.map((item) => item.comments));
            setAddDomainAccess(curriculumAccessData.map(item => item.domains));
            setPublishAccess(curriculumAccessData.map(item => item.publishCurriculum));
            setUnpublishAccess(curriculumAccessData.map(item => item.unPublishCurriculum));
            setIsHavingExportAccess(curriculumAccessData[0].exportCurriculum);
        })
    }, []);

    useEffect(() => {
        if (goToPage > 1) {
            setCurrentPage(goToPage);
        }
        else {
            setCurrentPage(1);
        }
        // navigate(`/curriculum/level/${currentPage}`);
    }, [filter, goToPage]);

    useEffect(() => {
        refreshDatatable();
    }, [filter, status]);

    const handleCrudOperation = (actionName, data) => {
        setIsBlur(true);
        showCorrespondingModal(actionName);
        const action = actionName === 'reactivate' ? inActivateOptions.find(action => action.name === actionName) :
            actions.find(action => action.name === actionName);
        if (action && action.data !== data) {
            if (action.name === 'delete') {
                setActionData([{ title: action.data.level, levelIds: [action.data._id], child: action.data.domains }]);
                setDeleteRole("Level");
            } else if (action.name === 'reactivate') {
                setActionData([{ title: action.data.level, levelIds: action.data._id }]);
                setReactivateRole("Level")
            } else {
                setActionData(action.data);
            }
        }
    };

    const showCorrespondingModal = (actionName) => {
        if (actionName === 'delete') showDeleteModel(true);
        if (actionName === 'addDomain') showAddDomain(true);
        if (actionName === 'editlevel') showEditLevel(true);
        if (actionName === 'reactivate') setShowReactiveModel(true);
    };

    const handleReactivateAction = async () => {
        try {
            const { levelIds } = actionData[0];
            await reactivateLevel({ levelId: levelIds });
            setShowReactiveModel(false);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const handlePublishUnpublishStatus = async () => {
        try {
            let data = null;
            let isUnpublishAction = false;
            for (const action of actions) {
                if (action.name === "publishStatus") {
                    const newStatus = action.data.status === STATUS.PUBLISH ? STATUS.UNPUBLISH : STATUS.PUBLISH;
                    data = { levelIds: [action.data._id], status: newStatus, };
                    isUnpublishAction = action.data.status === STATUS.PUBLISH;
                    break;
                }
            }
            if (data) {
                isUnpublishAction ? await unPublishLevels(data) : await publishLevels(data);
                setStatus(prevStatus => !prevStatus);
                // refreshDatatable();
            }
        } catch (error) {
            throw new Error(error);
        }
    };

    const handlePageChange = (currentPage, itemsPerPage) => {
        setCurrentPage(currentPage);
        setGoToPage(0);
        setIsLoading(true);
        const updatedFilter = filter.reduce((acc, f) => {
            if (f.name !== 'page' && f.name !== 'limit') acc.push(f);
            return acc;
        }, []);
        updatedFilter.push(
            { name: "page", value: currentPage },
            { name: "limit", value: itemsPerPage }
        );
        setFilter(updatedFilter);
    };

    /**
     * Function to open the Version History modal with the selected Level data
     * @function
     */
    const handleDisplayVersionHistory = () => {
        setIsBlur(true);
        showDisplayVersionHistory(true);
        const displayAction = actions.find((action) => action.name === "displayVersionHistory");
        if (displayAction) {
            setActionData(displayAction.data.code);
            setLevelId(displayAction.data._id);
        }
    };

    const actions = [
        {
            Label: "publish/unpublish",
            name: "publishStatus",
            clickAction: handlePublishUnpublishStatus,
            access: publishAccess[0] || unpublishAccess[0],
            publishAccess: publishAccess[0],
            unPublishAccess: unpublishAccess[0]
        }, {
            Label: "Version History",
            name: "displayVersionHistory",
            clickAction: handleDisplayVersionHistory,
            access: commentsAccess[0]?.view,
        }, {
            Label: "Add domain",
            name: "addDomain",
            clickAction: () => handleCrudOperation('addDomain'),
            access: addDomainAccess[0]?.add
        }, {
            Label: "Edit",
            name: "editlevel",
            clickAction: () => handleCrudOperation('editlevel'),
            access: levelsAccess[0]?.edit
        }, {
            Label: "Delete",
            name: "delete",
            clickAction: () => handleCrudOperation('delete'),
            class: "text-delete",
            access: levelsAccess[0]?.delete
        },
    ];

    const handleDeleteAction = async () => {
        const levelIds = actionData?.levelIds || actionData[0]?.levelIds || [];
        try {
            if (levelIds.length === 0) return;
            await deleteLevel({ levelIds });
            showDeleteModel(false);
            setIsLoading(true);
            refreshDatatable();
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleBulkActions = (type) => {
        const levelIds = { levelIds: [] };
        BulkActionOptions.forEach(action => {
            if (action.name === type) action.data.forEach(eachAction => { levelIds.levelIds.push(eachAction.id); });
        });
        setActionData(levelIds);
        if (type === "publish" || type === "unpublish") {
            handleBulkPublishUnpublish(type === "publish", levelIds);
        } else if (type === "delete") {
            setIsBlur(true);
            showDeleteModel(true);
            setDeleteRole("MultipleLevels");
        }
    };

    const handleBulkPublishUnpublish = async (publish, data) => {
        try {
            await updateBulkStatus(publish, data);
            setStatus(!status);
            refreshDatatable();
            setIsLoading(true);
        } catch (error) {
            throw new Error(error);
        }
    };

    const BulkActionOptions = [
        {
            Label: "Publish All",
            name: "publish",
            clickAction: () => handleBulkActions("publish"),
            access: publishAccess[0]
        }, {
            Label: "Unpublish All",
            name: "unpublish",
            clickAction: () => handleBulkActions("unpublish"),
            access: unpublishAccess[0]
        }, {
            Label: "Delete All",
            name: "delete",
            clickAction: () => handleBulkActions("delete"),
            class: "text-delete",
            access: levelsAccess[0]?.delete
        },
    ];

    const inActivateOptions = [{
        Label: "Reactivate",
        name: "reactivate",
        clickAction: () => handleCrudOperation('reactivate'),
        access: publishAccess[0],
    }];

    const refreshDatatable = _.debounce(async () => {
        setIsLoading(true);
        if (filter.search === "") return;
        try {
            const updatedFilter = [
                ...filter.filter(f => f.name !== "active"),
                { name: "active", value: dataFilterStatus }
            ];
            if (id) {
                const programFilter = updatedFilter.find((f) => f.name === "programIds")
                if (programFilter) {
                    const data = id.split()
                    programFilter.value = data
                }
            }
            const { levels, totalPages } = await getAllLevels(updatedFilter);
            setTableData(levels.map(extractFields));
            setTotalPageCount(totalPages);
            if (levels && levels.length >= 0) setDataFetched(true);
            id && filterKey ? navigate(`/curriculum/level/${currentPage}/${id}/${filterKey}`) : navigate(`/curriculum/level/${currentPage}`)
        } catch (error) {
            throw new Error(error);
        } finally {
            setIsBlur(false);
            setIsLoading(false);
        }
    }, 1000);

    const extractFields = (obj) => {
        const filterKey = "level"
        const handleClick = async () => {
            window.open(`/curriculum/domain/${currentPage}/${obj._id}/${filterKey}`, '_blank');
        };

        const subRecordLink = (
            <span
                onClick={handleClick}
                className="record-count-link"
            >
                {obj.subRecordCount}
            </span>
        );

        const {
            code,
            title,
            program,
            subRecordCount,
            description,
            status,
            _id,
            parent
        } = obj;
        return {
            code,
            title,
            program,
            subRecordLink,
            description,
            status,
            _id,
            parent
        };
    };

    const closeModal = (setModal) => {
        setModal(false);
        setIsBlur(false);
    }

    return (
        <>
            <Title titleContent="Levels" />
            <div className={`curriculum-all-topics ${isBlur ? "blur" : ""}`}>
                {dataFetched ? (
                    <>
                        <TempFilterHeader
                            filter={filter}
                            pageName={"Levels"}
                            setFilter={setFilter}
                            setIsBlur={setIsBlur}
                            setIsLoading={setIsLoading}
                            dataFilterStatus={dataFilterStatus}
                            setShowExportModal={setShowExportModal}
                            setDataFilterStatus={setDataFilterStatus}
                            filtersDropdown={filtersDropdownStructure}
                            setGoToPage={setGoToPage}
                            isPageChange={goToPage}
                            isHavingExportAccess={isHavingExportAccess}
                        />
                        <DataTable
                            columns={columns}
                            data={tableData}
                            actions={actions}
                            totalCount={totalPageCount}
                            BulkActionOptions={BulkActionOptions}
                            filter={filter}
                            setFilter={setFilter}
                            inActivateOptions={inActivateOptions}
                            dataStatus={true}
                            handlePageChange={handlePageChange}
                            setGoToPage={setGoToPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                        {displayVersionHistory && (
                            <Comment_VersionHistory
                                show={displayVersionHistory}
                                handleClose={() => closeModal(showDisplayVersionHistory)}
                                id={levelId}
                                // onSave={refreshDatatable}
                                access={commentsAccess[0]?.add}
                                pageTitle="Level"
                                rowCode={actionData}
                            />
                        )}
                        {addDomain && (
                            <AddDomainModal
                                show={showAddDomain}
                                handleClose={() => closeModal(showAddDomain)}
                                parentIdentifier={actionData}
                                onSave={refreshDatatable}
                            />
                        )}
                        {editLevel && (
                            <EditLevelModal
                                show={editLevel}
                                handleClose={() => closeModal(showEditLevel)}
                                initialData={actionData}
                                onSave={refreshDatatable}
                            />
                        )}
                        {deleteModel && (
                            <DeleteModal
                                showModal={deleteModel}
                                role={deleteRole}
                                onClose={() => closeModal(showDeleteModel)}
                                onSave={refreshDatatable}
                                deleteData={actionData}
                                onClick={handleDeleteAction}
                            />
                        )}
                        {showReactiveModel && <ReactivateModal
                            showModal={showReactiveModel}
                            role={reactivateRole}
                            onClose={() => closeModal(setShowReactiveModel)}
                            reactivateData={actionData}
                            onClick={handleReactivateAction} />}
                    </>
                ) : !isLoading && (
                    <div className="empty-Container">
                        <img src={logo} alt="Logo" style={{ display: "block", margin: "0 auto" }} />
                        <b><p className="text-center" style={{ color: "green" }}>
                            You haven't created any levels yet.!</p></b>
                    </div>
                )}
            </div>
            {isLoading && (<div className="spinner-overlay"><Loader /></div>)}
            {showExportModal && (
                <ExportModal
                    title="Levels"
                    filter={filter}
                    exportColumns={exportColumns}
                    handleClose={() => closeModal(setShowExportModal)}
                />
            )}
        </>
    );
}

export default CurriculumAllLevels;
