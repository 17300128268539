import React, { useState, useEffect } from 'react';
import Select, { components } from "react-select";
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import closeIcon from "../../../Assests/Images/closeIcon.svg";
import SaveModal from '../SaveModal/SaveModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateDomains ,getById } from '../../../Utils/ApiHandler/DomainApi';
import { FaAsterisk } from "react-icons/fa";
import {
  CustomModalHeader,
  CustomModalFooter,
  CustomForm,
  ModalButton,
  CustomModal,
  CloseIcon,
  DisabledInput
} from '../AddEditModal.styled';

/**
 * EditDomainModal Component
 * 
 * This component represents a modal for editing an existing domain.
 * 
 * @param {boolean} show - Flag indicating whether the modal should be displayed.
 * @param {function} handleClose - Function to handle closing the modal.
 * @param {object} initialData - Initial data of the domain being edited.
 * @param {function} handleEditDomain - Function to handle editing the domain.
 * 
 * @returns {JSX.Element} - The rendered EditDomainModal component.
 * 
 * @author - Devisri
 */
const EditDomainModal = ({ show, handleClose, initialData, onSave }) => {
  const [formData, setFormData] = useState({
    organization: '',
    program: '',
    level: '',
    status: '',
    code: '',
    domain: '',
    description: ''
  });

  const [showSaveModal, setShowSaveModal] = useState(false);

  const statusOptions =
    [
      { value: -1, label: "Unpublish" },
      { value: 1, label: "Publish" }
    ]

  const RadioOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="radio"
          checked={props.isSelected}
          onChange={() => props.selectOption(props.data)}
          style={{
            marginRight: "5px",
            flexShrink: 0,
          }}
        />
        <span style={{ color: "Black" }}>{props.label}</span>
      </div>
    </components.Option>
  );

useEffect (() => {
  if(initialData){
    getById(initialData._id).then((data) => {
      const reqFields = data.map(each => ({
        organization: each.organization,
        program: each.program,
        level: each.level,
        status: each.status,
        code: each.code,
        domain: each.title,
        description: each.description,
        id: each._id,
        parentId: each.parent,
      }));
      setFormData(reqFields[0]);
    })
  }
} , [initialData])

  const handleChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, status: e.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSaveModal(true);
  };

  const handleSaveConfirm = () => {
    if (formData.domain === '') {
      toast.error("Domain field is required");
      return;
    }

    if (formData.description === '') {
      toast.error("Description is required");
      return;
    }

    try {
      const updateData = {
        domainId: formData.id,
        title: formData.domain,
        description: formData.description,
        parentId: formData.parentId,
        status: formData.status
      };
      updateDomains(updateData);
      setShowSaveModal(false);
      onSave();
      handleClose();
    }
    catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <CustomModal show={show} onHide={handleClose}>
        <CustomModalHeader >
          <Modal.Title>Edit Domain</Modal.Title>
          <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
        </CustomModalHeader>
        <Modal.Body>
          <CustomForm onSubmit={handleSubmit}>
            <Form.Group controlId="organization">
              <Form.Label>Organization</Form.Label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>This can't be edited</Tooltip>}
              >
                <DisabledInput
                  name="organization"
                  value={formData.organization}
                  onChange={handleChange}
                  required
                  disabled />

              </OverlayTrigger>
            </Form.Group>
            <Form.Group controlId="program">
              <Form.Label>Program</Form.Label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>This can't be edited</Tooltip>}
              >
                <DisabledInput
                  name="program"
                  value={formData.program}
                  onChange={handleChange}
                  required
                  disabled />
              </OverlayTrigger>
            </Form.Group>
            <Form.Group controlId="level">
              <Form.Label>Level</Form.Label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>This can't be edited</Tooltip>}
              >
                <DisabledInput
                  name="level"
                  value={formData.level}
                  onChange={handleChange}
                  required
                  disabled />
              </OverlayTrigger>
            </Form.Group>
            <Form.Group controlId="code">
              <Form.Label>Code</Form.Label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>This can't be edited</Tooltip>}
              >
                <DisabledInput
                  type="text"
                  placeholder="Enter Code"
                  name="code"
                  value={formData.code}
                  onChange={handleChange}
                  required
                  disabled />
              </OverlayTrigger>
            </Form.Group>
            <Form.Group controlId="domain">
              <Form.Label>Domain
                <FaAsterisk style={{color:"#E62753",
                    width:"0.4rem",
                    height:"0.4rem",
                    margin:"0rem 0rem 0.5rem 0.25rem"}}/>
              </Form.Label>
              <Form.Control
                className="editable"
                name="domain"
                placeholder="Enter domain"
                value={formData.domain}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="status">
              <Form.Label>Status
                <FaAsterisk style={{color:"#E62753",
                    width:"0.4rem",
                    height:"0.4rem",
                    margin:"0rem 0rem 0.5rem 0.25rem"}}/>
              </Form.Label>
              <Select
                className="editable"
                placeholder="Select Status"
                value={{ value: formData.status, label: formData.status === 1 ? "Publish" : "Unpublish" }}
                onChange={handleChange}
                required
                options={statusOptions}
                components={{
                  Option: RadioOption,
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    width: "22.9375rem",
                    height: "3rem",
                    marginBottom: "1rem",
                  }),
                  option: (base) => ({
                    ...base,
                    backgroundColor: "var(--white-color) !important",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#000000",
                  }),
                }}
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description
                <FaAsterisk style={{color:"#E62753",
                    width:"0.4rem",
                    height:"0.4rem",
                    margin:"0rem 0rem 0.5rem 0.25rem"}}/>
              </Form.Label>
              <Form.Control
                className="editable textareaStyle"
                as="textarea"
                placeholder="Enter Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </CustomForm>
        </Modal.Body>
        <CustomModalFooter>
          <ModalButton isClose onClick={handleClose} style={{ width: "7.5rem" }}>
            Cancel
          </ModalButton>
          <ModalButton type="submit" style={{ width: "10.75rem" }} onClick={handleSubmit}>
            Save changes
          </ModalButton>
        </CustomModalFooter>
      </CustomModal>
      {showSaveModal && (
        <SaveModal
          showModal={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          onSave={handleSaveConfirm}
        />
      )}
    </>
  );
};

EditDomainModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialData: PropTypes.object.isRequired,
  handleEditDomain: PropTypes.func.isRequired,
};

export default EditDomainModal;
