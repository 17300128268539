import "./Login.css";
import "../../App.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../Utils/AuthUtils";
import logo from "../../Assests/Images/site-logo.png";
import ErrorMessage from "../../Components/ErrorMessage/ErrorMessage";
import Loader from "../../Components/Loader/Loader";
import DbConfig from "Constants/db";
import { generateRandomStringForSSO } from 'Utils/AuthUtils';

function Login() {
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [expectedOtp, setExpectedOtp] = useState("");
    const [isOtpValid, setIsOtpValid] = useState(false);
    const [isInvalidOtp, setIsInvalidOtp] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isOtpGenerated, setIsOtpGenerated] = useState(false);
    const [isEmailNotFound, setIsEmailNotFound] = useState(false);
    
    const sendOtpFlowUrl = process.env.REACT_APP_FLOW_CONTENT_USER_LOGIN;
    const authBaseUrl = process.env.REACT_APP_98_BASE_URL;
    const clientId = process.env.REACT_APP_98_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_REDIRECT_URI

    const stateParam = generateRandomStringForSSO(40);
    const ssoUrl = `${authBaseUrl}/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=&state=${stateParam}`;

    const handleSSOLogin = () => {
        window.location.href = ssoUrl;
    }
    
    const storeUserData = async (userData) => {
        await DbConfig.accessToken.clear();
        await DbConfig.accessToken.add({
            accessToken: userData.access_token,
        });

        await DbConfig.userDetails.clear();
        await DbConfig.userDetails.add({
            firstName: userData.first_name,
            lastName: userData.last_name,
            email: userData.email,
            roleName: userData.role_name,
        });

        await DbConfig.curriculumAccess.clear();
        await DbConfig.curriculumAccess.add({
            programs: userData.userAccess.curriculum[0].programs,
            levels: userData.userAccess.curriculum[0].levels,
            domains: userData.userAccess.curriculum[0].domains,
            topics: userData.userAccess.curriculum[0].topics,
            skills: userData.userAccess.curriculum[0].skills,
            questions: userData.userAccess.curriculum[0].questions,
            flagging: userData.userAccess.curriculum[0].flagging,
            comments: userData.userAccess.curriculum[0].comments,
            assignments: userData.userAccess.curriculum[0].assignments,
            tests: userData.userAccess.curriculum[0].tests,
            publishCurriculum: userData.userAccess.curriculum[0].publishCurriculum,
            unPublishCurriculum: userData.userAccess.curriculum[0].unPublishCurriculum,
        });
        await DbConfig.settingsAccess.clear();
        await DbConfig.settingsAccess.add({
            organization: userData.userAccess.settings[0].organization,
            users: userData.userAccess.settings[0].users,
            teams: userData.userAccess.settings[0].teams,
            roles: userData.userAccess.settings[0].roles,
        });
        // console.log(JSON.stringify(userData, null, 2));

    };
    
    /**
     * handleEmailChange
     *
     * Handles changes in the email input field.
     *
     * @param {Object} event - The event object triggered by the email input field.
     *
     * @returns {void}
     *
     * @author Gaurav
     */
    const handleEmailChange = (event) => {
        const { value } = event.target;
        setEmail(value);
        setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
        setIsEmailNotFound(false);
    };

    /**
     * Generates OTP (One-Time Password) for the provided email address.
     *
     * @returns void
     *
     * @author - Gaurav
     */
    const handleGenerateOtp = async () => {
        if (!email) {
            setIsEmailNotFound(true);
            return;
        }

        try {
            setIsLoading(true);
            const response = await fetch(sendOtpFlowUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ pemail: email }),
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            if (data.message === "not exists" && data.otp === "0") {
                setIsEmailNotFound(true);
            } else {
                setIsOtpGenerated(true);
                setExpectedOtp(data.otp);
            }
        } catch (error) {
            console.error("Error:", error);
            setIsEmailNotFound(true);
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Handles changes in the OTP (One-Time Password) input field.
     *
     * @param { Object } event The event object triggered by the OTP input field.
     *
     * @returns void
     *
     * @author - Gaurav
     */
    const handleOtpChange = (event) => {
        const { value } = event.target;
        setOtp(value);
        setIsOtpValid(value.length === 6);
        setIsInvalidOtp(false);
    };

    /**
     * handleSubmit
     *
     * Handles the submission of the form.
     *
     * @param { Object } event The event object triggered by the form submission.
     *
     * @returns void
     *
     * @author - Gaurav
     *
     */
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const response = await loginUser(email, otp);
        await storeUserData(response.data);
        setIsLoading(false);

        if (response.success) {
            navigate("/curriculum/program");
        } else {
            setIsInvalidOtp(true);
        }
    }

    return (
        <div className="login">
            <form className="login-container">
                <div>
                    <div className="logo">
                         {/* <img className="login-logo" src={logo} alt="Logo" />  */}
                        <img src={logo} alt="Logo" style={{
                            position:"absolute",
                            top:"3rem",
                            left:"9rem"
                        }}/>
                         <h2 style={{display:'flex',justifyContent:'center'}}>Login</h2> 
                    </div>
                    {/* <div className="input-box">
                        <span className="icon">
                            <ion-icon name="mail"></ion-icon>
                        </span>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            readOnly={isOtpGenerated}
                            placeholder="Enter your email address"
                        />
                    </div> */}
                     {isEmailNotFound && (
                        <ErrorMessage className="error" message="Email Not Found" />
                    )} 
                    {/* {!isOtpGenerated && (
                        <div className="button">
                            <button
                                className="login-button"
                                type="button"
                                onClick={handleGenerateOtp}
                                disabled={!isEmailValid || isLoading || !email}
                            >
                                Generate OTP
                            </button>
                        </div>
                    )}  */}
                    {/* <hr />  */}
                    <div className="login-98th-percentile">
                        {!isOtpGenerated && (
                            <button
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // marginLeft: "3rem",
                                    marginTop: "4rem",
                                    padding: "5px 20px",
                                    fontSize: "16px",
                                    fontFamily: "var(--font-family)",
                                    fontWeight: "500",
                                    textAlign: "center",
                                    textDecoration: "none",
                                    border: "2px solid #4e92c4",
                                    color: "#ffffff",
                                    backgroundColor: "#4e92c4",
                                    borderRadius: "50px",
                                    cursor: "pointer",
                                    transition: "background-color 0.3s, color 0.3s, border-color 0.3s"
                                }}
                                type="button"
                                onClick={handleSSOLogin}
                            >
                                Login with 98thpercentile
                            </button>
                        )}
                    </div>
                     {isOtpGenerated && (
                        <div className="input-box">
                            <span className="icon">
                                <ion-icon name="lock-closed"></ion-icon>
                            </span>
                            <input
                                type="password"
                                value={otp}
                                onChange={handleOtpChange}
                                maxLength={6}
                                placeholder="Enter OTP"
                            />
                        </div>
                    )} 
                    {otp && isInvalidOtp && (
                        <ErrorMessage message="Invalid OTP. Please try again." />
                    )} 
                    {isOtpGenerated && (
                        <div className="button">
                            <button
                                type="submit"
                                className="login-button"
                                onClick={handleSubmit}
                                disabled={!isOtpValid || isLoading}
                            >
                                Submit
                            </button>
                        </div>
                    )} 
                    {isLoading && (
                        <div className="spinner-overlay">
                            <Loader />
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
}

export default Login;