import React from "react";
import { Modal, Card, Button } from "react-bootstrap";
import {
    QuestionSaveStyledModal,
    CloseIcon,
    ModalFooter,
} from "../../../../Components/Modals/Questions/QuestionSaveModal.styled";
import closeIcon from "../../../../Assests/Images/Vector.svg";
import { useSelector, useDispatch } from "react-redux";
import { closeSaveModal } from "../../../../redux/slices/ModalSlice/SaveModalSlice";
import { useNavigate } from "react-router-dom";

/**
 * QuestionSaveModal
 *
 * Modal component for saving a question using React Bootstrap Modal and Card
 *
 * @param {Object} props - Component props
 *
 * @returns {JSX.Element} - Rendered component
 */
function QuestionSaveModal() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const showModal = useSelector((state) => state.saveModal.showSaveModal);

    const handleClose = () => {
        dispatch(closeSaveModal());
    };

    return (
        <QuestionSaveStyledModal show={showModal} onHide={handleClose} centered>
           <CloseIcon src={closeIcon} alt="Close" onClick={() => dispatch(closeSaveModal())}/>
            <Modal.Body>
                <Card className="modal-card-body">
                    <Card.Title>Question Saved!</Card.Title>
                    <Card.Text>
                        A new question has been added successfully.
                    </Card.Text>
                </Card>
            </Modal.Body>
            <ModalFooter>
            <Button variant="primary" onClick={() => navigate(-1)}>
                    Go to questions
                </Button>
            </ModalFooter>
        </QuestionSaveStyledModal>
    );
}

export default QuestionSaveModal;
