import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import closeIcon from "../../../Assests/Images/Vector.svg";
import trashIcon from '../../../Assests/Images/trash-can.png';
import image from "../../../Assests/Images/user-profile.png";
import { getActiveUsersForTeam, removeUsersFromTeam } from 'Utils/ApiHandler/TeamsApi';
import {
  CustomModal,
  CustomModalHeader,
  CustomModalFooter,
  CustomTable,
  TableHeader,
  TableCell,
  TrashIcon,
  ModalButton,
  CloseIcon,
  UsersProfile
} from './RemoveUsersFromTheTeamModal.styled';
import ModalPagination from "../ModalPagination.js"

const RemoveUsersFromTheTeamModal = ({ show, handleClose, teamId, onSave }) => {
  const [activeUsers, setActiveUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentModalPage, setCurrentModalPage] = useState(1);
  const [totalModalPages, setTotalModalPages] = useState(0);

  useEffect(() => {
    if (show) {
      fetchActiveUsers();
    }
  }, [show, teamId, currentModalPage]);

  const fetchActiveUsers = async () => {
    setIsLoading(true)
    try {
      const usersData = await getActiveUsersForTeam(teamId, currentModalPage);
      if ("users" in usersData) {
        setActiveUsers(usersData.users);
        setTotalModalPages(usersData.totalPages);
      }
    } catch (error) {
      console.error("Error fetching active users: ", error);
    } finally {
      setIsLoading(false)
    }
  };

  const handleUserSelect = (index) => {
    if (selectedUsers.includes(index)) {
      setSelectedUsers(selectedUsers.filter((selectedIndex) => selectedIndex !== index));
    } else {
      setSelectedUsers([...selectedUsers, index]);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await removeUsersFromTeam(teamId, [userId]);
      fetchActiveUsers();
      onSave();
      handleClose();
    } catch (error) {
      console.error('Error removing user from team:', error);
    }
  };

  const handleRemoveSelected = async () => {
    try {
      const userIds = selectedUsers.map(index => activeUsers[index]._id);
      await removeUsersFromTeam(teamId, userIds);
      fetchActiveUsers();
      setSelectedUsers([]);
      onSave();
      handleClose();
    } catch (error) {
      console.error('Error removing selected users:', error);
    }
  };

  const handleModalPagination = async (pageNumber) => {
    setCurrentModalPage(pageNumber);
  };


  return (
    <CustomModal show={show} onHide={handleClose}>
      <CustomModalHeader>
        <Modal.Title>Team members</Modal.Title>
        <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
      </CustomModalHeader>
      <Modal.Body>
        <CustomTable bordered hover>
          <thead>
            <tr>
              <TableHeader></TableHeader>
              <TableHeader>Name</TableHeader>
              <TableHeader>Email</TableHeader>
              <TableHeader>Role</TableHeader>
              <TableHeader>Action</TableHeader>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <TableCell colSpan="5" style={{ textAlign: 'center' }}>
                  Loading...
                </TableCell>
              </tr>) : activeUsers.length > 0 ? (
                activeUsers.map((user, index) => (
                  <tr key={user.id}>
                    <TableCell>
                      <input
                        type="checkbox"
                        onChange={() => handleUserSelect(index)}
                        checked={selectedUsers.includes(index)}
                      />
                    </TableCell>
                    <TableCell>
                      <UsersProfile src={image} style={{ marginRight: '1rem', marginLeft: '-1rem' }} />
                      {user.firstName}
                    </TableCell>
                    <TableCell>
                      {user.workEmail}
                    </TableCell>
                    <TableCell>
                      {user.roles.map(role => role.roleName).join(', ')}
                    </TableCell>
                    <TableCell>
                      <TrashIcon
                        src={trashIcon}
                        onClick={() => handleDeleteUser(user._id)}
                      />
                    </TableCell>
                  </tr>
                ))
              ) : (
              <tr>
                <TableCell colSpan="5" style={{ textAlign: 'center' }}>
                  No active users found
                </TableCell>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <TableCell colspan="5">
                {totalModalPages > 0 ? <ModalPagination currentModalPage={currentModalPage} totalModalPages={totalModalPages} handleModalPagination={handleModalPagination} /> : ''}
              </TableCell>
            </tr>
          </tfoot>
        </CustomTable>
      </Modal.Body>
      <CustomModalFooter>
        {selectedUsers.length > 0 && (
          <ModalButton type="submit" onClick={handleRemoveSelected}>
            Remove selected
          </ModalButton>
        )}
      </CustomModalFooter>
    </CustomModal>
  );
};

RemoveUsersFromTheTeamModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
};

export default RemoveUsersFromTheTeamModal;
