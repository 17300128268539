import _ from 'lodash';
import React, { useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import image from "../../../Assests/Images/user-profile.png";
import flag from "../../../Assests/Images/flag.svg";
import sendCommentIcon from '../../../Assests/Images/sendCommentIcon.svg';
import closeIcon from "../../../Assests/Images/Vector.svg";
import { 
  CustomModalHeader,
  CustomForm,
  CustomModal,
  Toggle,
  StyledCard,
  CardBody,
  CardText,
  CardTitle,
  CardProfile,
  TimeDisplay,
  CustomModalBody,
  FlagIcon,
  CloseIcon
 } from '../../../Components/Modals/Comments/CommentsModal.styled';
import { useDispatch, useSelector } from 'react-redux';
import { setFormData, setComments, closeCommentsModal,setIsFlagged, clearFormData } from '../../../redux/slices/ModalSlice/CommentsModalSlice';

const CommentsModal = ({ 
  show, 
  handleClose, 
  onSave, 
  questionId, 
  fetchCommentsApi, 
  addCommentApi 
}) => {
  const dispatch = useDispatch();
  const { formData, comments, isFlagged, isCommentModal } = useSelector((state) => state.comments);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setFormData({ ...formData, [name]: value }));
  };

  useEffect(() => {
    if (show && questionId) { 

      fetchCommentsForQuestion(questionId, isFlagged);
    }
  }, [show, questionId, isFlagged]);

  useEffect(() => {
    dispatch(setIsFlagged(false));
    dispatch(setComments([]));
    dispatch(clearFormData())
  },[isCommentModal])

  const fetchCommentsForQuestion = async (questionId,isFlagged) => {
    try {
      const commentsData = await fetchCommentsApi(questionId, isFlagged);
      if (commentsData.comments) {
        dispatch(setComments(commentsData.comments));
      } else {
        console.error("No comments received.");
      }
    } catch (error) {
      console.error("Error fetching comments: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.text.trim()) {
        toast.error("Please drop a message");
        return;
      }

      const insertData = {
        id: questionId,
        text: formData.text,
      };
      await addCommentApi(insertData); 

      const newComment = { ...formData, timestamp: new Date().toISOString() };
      dispatch(setComments([...comments, newComment]));
      dispatch(setIsFlagged(false));
      dispatch(setFormData({ ...formData, text: '' }));
      fetchCommentsForQuestion(questionId);
      onSave();
    } catch (error) {
      console.error("Error in updating data: ", error);
    }
  };

  const getTimeDifference = (commentTime) => {
    const currentTime = new Date();
    const timeDifference = currentTime - new Date(commentTime);
    const seconds = Math.floor(timeDifference / 1000);
    
    if (seconds < 60) {
      return "just now";
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(seconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  };

  return (
    <CustomModal show={isCommentModal} onHide={() => dispatch(closeCommentsModal())}>
      <CustomModalHeader>
        <Modal.Title>Comments</Modal.Title>
        <CloseIcon src={closeIcon} alt="Close" onClick={() => dispatch(closeCommentsModal())} />
      </CustomModalHeader>
      <CustomModalBody>
        <CustomForm>
          <div className="form-group">
            <Form.Control
              as="textarea"
              name="text"
              placeholder="Drop a comment... "
              value={formData.text}
              onChange={handleChange}
              required
            />
            <div className="send-container">
              <img
                src={sendCommentIcon}
                alt="Send"
                onClick={handleSubmit}
                className="send-comment-icon"
              />
            </div>
          </div>
          <Form.Group>
            <Toggle>
              <span>Flagged</span>
              <div className="form-switch filter-toggle">
                <input
                  id="toggle-flagged"
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={isFlagged}
                   onChange={() => dispatch(setIsFlagged(!isFlagged))}
                />
                <label htmlFor="toggle-flagged" className="label"></label>
              </div>
            </Toggle>
          </Form.Group>
        </CustomForm>
        {comments.slice(0).reverse().map((comment, index) => (
          <StyledCard key={index}>
            <CardBody>
              <CardProfile src={image} />
              <div>
                <div className='card-title-container'>
                  <CardTitle>
                    {comment.commentedBy ? comment.commentedBy : ''}
                  </CardTitle>
                </div>
                <div className='card-text-container'>
                  <CardText>
                    {comment.text}
                  </CardText>
                </div>
                <TimeDisplay>{getTimeDifference(comment.timestamp)}</TimeDisplay>
              </div>
              <div className="icons-container">
                {comment.isFlag && <FlagIcon src={flag} alt="Flagged" className="flag-icon" />}
              </div>
            </CardBody>
          </StyledCard>
        ))}
      </CustomModalBody>
    </CustomModal>
  );
};

export default CommentsModal;
