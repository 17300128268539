import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showSaveModal: false, 
};

const saveModalSlice = createSlice({
    name: 'saveModal',
    initialState,
    reducers: {
      openSaveModal: (state) => {
        state.showSaveModal = true;
      },
      closeSaveModal: (state) => {
        state.showSaveModal = false;
      },
    },
  });

export const { openSaveModal, closeSaveModal } = saveModalSlice.actions;

export default saveModalSlice.reducer;