import styled from "styled-components";
import { NavLink as RRNavLink } from 'react-router-dom';

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0rem;
  left: 0;
  width: 100%;
  height: 64px;
  flex-shrink: 0;
  z-index: 1009;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);
  color: ${(props) => props.textColor || "var(--white-color)"};
  padding: ${(props) => props.padding || "10px"};
  margin-top: 0rem;
  padding: ${(props) => props.padding || "0.8rem 1rem"};
  margin-bottom: 1rem;
  border-bottom: 1px solid #e6e6e6;

  @media screen and (max-width: 1440px) {
    padding: 10px;
  }

  @media screen and (max-width: 1024px) {
    padding: 5px;
  }

  @media screen and (max-width: 768px) {
    padding: 0.8rem;
    margin-top: 0;
    flex-direction: row;
  }

  @media screen and (max-width: 425px) {
    padding: 0.5rem;
  }

  @media screen and (max-width: 375px) {
    padding: 0.3rem;
  }

  @media screen and (max-width: 320px) {
    padding: 0.2rem;
  }

  .curriculumDropDownOptions {
    position:absolute;
    margin-top:1rem;
  width: 25.125rem;
  height: auto;
    background: white; 
    box-shadow: 0px 2px 10px rgba(117.43, 117.43, 117.43, 0.10); 
    border-radius: 4px; border: 1px #E2E2E2 solid;
  }
  .curriculumDropDownOption {
    padding-left:1.13rem;
    color:black;
    display:flex;
    align-items:center;
    color: #2A2E34;
    font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .subDetail{
    color: #434D5B;
margin-top:0.25rem;
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
  }

  
`;

export const Logo = styled.div`
  font-size: ${(props) => props.fontSize || "1.5rem"};
  margin-left: 40px;
  margin-top: auto;
  margin-bottom: auto; 

  @media (max-width: 768px) {
    max-width: 15%;
  }

  @media (max-width: 576px) {
    margin: auto;
    max-width: 30%;
  }
`;

export const LogoImage = styled.img`
  width: 62px;
  height: 32px;
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 120px;
  color: var(--white-color);
  font-family: var(--font-family);
  font-size:  0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 768px) {
    margin-left: 70px;
  }
`;

export const Navbar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const NavLink = styled(RRNavLink)`
  color: ${(props) => (props.active ? "var(--black-color)" : "var(--slate-gray-color)")};
  font-weight: ${(props) => (props.active ? 700 : 400)};
  text-decoration: none;
  cursor: pointer;
  padding: 10px;
  margin-left: 60px;
  margin-right: -40px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 10px;

    &:nth-child(1) {
      margin-right: 20px;
    }
  }

  @media (max-width: 576px) {
    display: block;
    margin: 5px 0;
    margin-top: 10px;
  }

  .curriculumDownArrow {
    width:0.75rem;
    height:0.75rem;
  }
  .backgroundImage{
    width: 2.5rem;
  height: 2.5rem;
  background-color: yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  }
`;

export const ProfileDropdown = styled.div`
  font-size: 22px;
  padding: 10px;
  margin-right: 60px;
  @media (max-width: 768px) {
    margin-right: 10px;
  }
`;

export const AvatarContainer = styled.img`
  margin-top :0px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  margin-right: 40px;

  @media (max-width: 768px) {
    margin-left: -80px;
  }
`;

export const UserProfileCardContainer = styled.div`
  position: absolute;
  top: 70%;
  right: 90px;
  width: 288px;
  height: 434px;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    left: 24rem;
  }

  @media (max-width: 576px) {
    left: -38px;
  }

  @media (max-width: 425px) {
    
  }

  @media (max-width: 375px) {
   
  }
`;

export const SettingsPanel = styled.div`
  position: absolute;
  top: 62px;
  right: 90px;
  background-color: var(--white-color);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  padding-left: 23px;
  z-index: 1010;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 506px;
  height: 465px;
  flex-shrink: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    top: 70px;
    right: 10rem;
    max-width: calc(90% - 18rem);
    width: 100%;
  }

  @media (max-width: 576px) {
    top: 60px;
    right: 40px;
    max-width: calc(90% - 90px);
    width: 70%;
  }

  @media (max-width: 425px) {
    top: 60px;
    right: 5px;
    max-width: calc(100% - 10px);
    width: 100%;
  }

  @media (max-width: 375px) {
    top: 60px;
    right: 30px;
    max-width: calc(100% - 10px);
    width: 80%;
  }
`;

export const SettingsIcon = styled.img`
  margin-left: auto;
  margin-right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-left: 18rem;
  }
`;

export const ChildCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ChildCard = styled.div`
  font-size: var(--font-size-small);
  width: 203px;
  height: 131px;
  flex-shrink: 0;
  background-color: ${({ disabled }) => disabled ? 'var(--gainsboro-gray-color)' : 'var(--white-color)'};
  padding: 15px;
  margin: 13px;
  border-radius: 6px;
  border: 1px solid #E9E9E9;
  border-bottom: 4px solid #0078d4;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  text-decoration: none;
  box-shadow: 0px 4px 0px 0px var(--blue-color);
  transition: box-shadow 0.3s ease;
  color:#676767;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  position: relative;

   &:hover::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%; 
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 1;
    visibility: visible;
    z-index: 1000;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    transition: opacity 0.2s, visibility 0.2s;
    display: block;

    &::after {
      content: '';
      position: relative;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 8px;
      border-style: solid;
      border-color: #000 transparent transparent transparent; 

    }

  }

  &::before {
    content: '';
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: 1000;
  }
                          
  @media (max-width: 768px) {
    width: calc(50% - 40px);
  }

  @media (max-width: 576px) {
    width: calc(100% - 40px);
  }

  @media (max-width: 425px) {
    width: calc(100% - 10px);
    margin: 5px;
  }

  @media (max-width: 375px) {
    width: calc(100% - 10px);
    margin: 5px;
  }

  &:hover {
    background-color: #e0e0e0;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

export const ParentCard = styled.div`
  display: flex;
  flex-direction: column;
  width : 500px;
 
  @media (max-width: 576px) {
    top: 60px;
    right: 10px;
    max-width: calc(100% - 50px);
  }
`;

export const ParentHeading = styled.div`
  color: var(--black-color);
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 15px;
  margin-bottom: 18px;
  padding-top: 15px;
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;

export const SubHeading = styled.div`
  margin-left: 15px;
  margin-bottom: 15px;
  color: var(--black-color);
  font-family: var(--font-family);
  font-size: var(--font-size-small);
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 768px) {
    margin-left: 10px;
    margin-top: -10px;
  }
`;

export const ChildSubHeading = styled.div`
  margin-bottom: 8px;
  margin-right: 20px;
  color: var(--blue-color);
  font-family: var(--font-family);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ProfileHeader = styled.div`
  width: 300px;
  height: 440px;
  margin: 20px 40px;
  border-radius: 12px;
  padding-bottom: 20px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--black-color);
  border: 1px solid #DADADA;
  background-color: var(--white-color);
  box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.08);
  `;

export const Gradiant = styled.div`
  background-image: linear-gradient(#0d6efd, #0d6efd);
  height: 76px;
  flex-shrink: 0;
  border-radius: 12px 12px 0px 0px;
  width: 100%;
`;

export const ProfileDown = styled.div`
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ProfileImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 80px;
  margin-top: -70px;
  background: white;
  padding: 5px;
`;

export const ProfileTitle = styled.div`
  font-weight: bold;
  margin-top: 5px;
  color: var(--black-color);
  text-align: right;
  font-family: var(--font-family);
  font-size: var(--font-size-normal);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ProfileRole = styled.div`
  opacity: 0.7;
  color: var(--dark-gray-color);
  padding: 4px;
  text-align: right;
  font-family: var(--font-family);
  font-size: var(--font-size-small);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ProfileEmail = styled.div`
  border-radius: 4px;
  background: rgba(1, 109, 217, 0.20);
  padding: 4px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
  margin: 25px 10px;
  margin-top: 10px;
  color: var(--black-color);
  font-family: var(--font-family);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Line = styled.div`
  margin-top: 5px;
  margin-bottom: 8px;
  width: 295px;
  height: 1px;
  border-radius: 12px 12px 0px 0px;
  border: 1px solid var(--pale-gray-color);
  background: var(--teal-green-color);
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  
`;

export const Option = styled.div`
  margin-right: 150px;
  margin-top: 20px;
  color: var(--black-color);
  font-family: var(--font-family);
  font-size: var(--font-size-regular);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const HalfLine = styled.div`
  margin-bottom: 10px;
  width: 95%;
  height: 1px;
  border-radius: 12px 12px 0px 0px;
  border: 1px solid var(--pale-gray-color);
  background: var(--snow-white-color);
  margin-right: 30px;
  margin-top: 30px;
`;

export const LogOut = styled.div`
  color: var(--alert-red-color);
  font-family: var(--font-family);
  font-size: var(--font-size-regular);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 2px;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
`;

export const CuriculumClipBoardIcon = styled.img`
  width:1.5rem;
  height:1.5rem;
  flex-shrink: 0;
`;
export const CuriculumClipBoardIconContainer = styled.div`
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 1.5rem;
  margin-right: 0.75rem;
`;