import "./questionPreview.css";
import DbConfig from "Constants/db";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import PreviewSidebar from "./PreviewSidebar";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { FaCommentDots } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import flag from "../../../../Assests/Images/flag.svg";
import Loader from "../../../../Components/Loader/Loader";
import PreviewOptionBuilder from "./PreviewOptionBuilder";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import questionPreviewConfig from "./questionPreviewConfig";
import { Flag } from "Components/DataTable/DataTable.styled";
import PreviewQuestionBuilder from "./PreviewQuestionBuilder";
import SaveModal from "Components/Modals/SaveModal/SaveModal";
import { getpreviewQuestionById } from "../../../../Utils/ApiHandler/QuestionBuilderApi";
import Comment_VersionHistory from "../../../../Components/Modals/Comments/Comment_VersionHistory";
import FlagInappropriateModal from "Components/Modals/FlagInappropriateModal/FlagInappropriateModal";
import { getQuestionById, addFlag, removeFlag, getAllQuestions, } from "Utils/ApiHandler/QuestionApi";

const QuestionPreview = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search);
    const questionId = queryParams.get("question-id");
    const isQuestionsPage = queryParams.get("isQuestionsPage");
    const skill = queryParams.get("skill-skillName");
    const filterData = queryParams.get("filter");
    const skillId = queryParams.get("skillId");
    let initialFilter = [];
    if (filterData) {
        try {
            initialFilter = JSON.parse(filterData);
        } catch (error) {
            console.error("Error parsing filter data:", error);
        }
    }


    const fetchPageFromFilter = (filter) => {
        const pageFilter = filter.find((f) => f.name === "page");
        return pageFilter ? parseInt(pageFilter.value, 10) || 1 : 1;
    };

    const [filter, setFilter] = useState(initialFilter);
    const [currentPage, setCurrentPage] = useState(
        fetchPageFromFilter(initialFilter)
    );
    const [previewId, setPreviewId] = useState(questionId);
    const [showExplanation, setShowExplanation] = useState(false);
    const [formData, setFormData] = useState(null);
    const [optionText, setOptionText] = useState([]);
    const [passageText, setPassageText] = useState("");
    const [optionImage, setOptionImage] = useState([]);
    const [questionType, setQuestionType] = useState(null);
    const [questionText, setQuestionText] = useState(null);
    const [questionImage, setQuestionImage] = useState(null);
    const [childQuestionCount, setChildQuestionCount] = useState(0);
    const [childQuestionCurrent, setChildQuestionCurrent] = useState(0);
    const [childQuestions, setChildQuestions] = useState([]);
    const [childQuestionType, setChildQuestionType] = useState(null);
    const [isReadingComprehesionQuestion, setIsReadingComprehesionQuestion] =
        useState(false);
    const [loading, setLoading] = useState(true);
    const [imagesLoaded, setImagesLoaded] = useState(0);
    const [imagesToLoad, setImagesToLoad] = useState(0);
    const [correctOptions, setCorrectOptions] = useState([]);
    const [answer, setAnswer] = useState();
    const [answerExplanation, setAnswerExplanation] = useState("");
    const [isFlagged, setIsFlagged] = useState(false);
    const [isFlaggedRC, setIsFlaggedRC] = useState(false);
    const [showFlagInAppropriate, setShowFlagInAppropriate] = useState(false);
    const [flagComment, setFlagComment] = useState("");
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [displayComments, showDisplayComments] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentId, setCurrentId] = useState(questionId);
    const [questionIds, setQuestionIds] = useState([]);
    const [isBlur, setIsBlur] = useState(false);
    const [selectedPage, setSelectedPage] = useState(0);
    const [commentsAccess, setCommentsAccess] = useState(false);
    const [flaggingAccess, setFlaggingAccess] = useState(false);
    const [resource, setResource] = useState(null);
    const [skillTitle, setSkillTitle] = useState(skill);
    const [childQuestionId, setChildQuestionId] = useState(null);
    const flagging = [];
    const comments = [];
    const grantAccess = () => {
        DbConfig.curriculumAccess.toArray().then((curriculumAccessData) => {
            curriculumAccessData.forEach((item) => {
                flagging.push(item.flagging);
                comments.push(item.comments);
            });
            setFlaggingAccess(flagging);
            setCommentsAccess(comments);
        });
    };

    useEffect(() => {
        grantAccess();
    }, []);

    useEffect(() => {
        const previewIdFromUrl = queryParams.get("preview-id");
        setPreviewId(previewIdFromUrl);
    }, [queryParams]);

    useEffect(() => {
        fetchAllQuestions();
    }, [currentPage]);

    const handleQuestionCall = (index) => {
        setSelectedPage(index);
        setChildQuestionCurrent(index);
    };

    function decodeHtmlEntities(str) {
        const textArea = document.createElement("textarea");
        textArea.innerHTML = str;
        return textArea.value;
    }

    function formatMathExpressions(htmlString) {
        const decodedString = decodeHtmlEntities(htmlString);

        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = decodedString;

        function formatMathField(element) {
            const textContent = element.innerHTML;
            let formattedMath = textContent;

            if (formattedMath.includes("\\frac")) {
                const match = formattedMath.match(/\\frac{(.*?)}{(.*?)}/);
                if (match) {
                    formattedMath = `${match[1]} / ${match[2]}`;
                }
            }

            if (formattedMath.includes("\\sqrt")) {
                const matchCubeRoot = formattedMath.match(
                    /\\sqrt\[(\d+)\]{(.*?)}/
                );
                if (matchCubeRoot) {
                    formattedMath = `√<span style="vertical-align: super;">${matchCubeRoot[1]}</span>${matchCubeRoot[2]}`;
                } else {
                    const matchSquareRoot =
                        formattedMath.match(/\\sqrt{(.*?)}/);
                    if (matchSquareRoot) {
                        formattedMath = `√${matchSquareRoot[1]}`;
                    }
                }
            }

            if (formattedMath.includes("^{")) {
                const match = formattedMath.match(/(.*?)\^{(.*?)}/);
                if (match) {
                    formattedMath = `${match[1]}<sup>${match[2]}</sup>`;
                }
            }

            return formattedMath;
        }

        function processNode(node) {
            if (node.nodeType === Node.ELEMENT_NODE) {
                if (node.tagName.toLowerCase() === "math-field") {
                    return formatMathField(node);
                } else {
                    const innerHTML = Array.from(node.childNodes)
                        .map(processNode)
                        .join("");

                    return `<${node.tagName.toLowerCase()}${getAttributes(
                        node
                    )}>${innerHTML}</${node.tagName.toLowerCase()}>`;
                }
            } else if (node.nodeType === Node.TEXT_NODE) {
                return node.textContent;
            }
        }

        function getAttributes(element) {
            return Array.from(element.attributes)
                .map((attr) => ` ${attr.name}="${attr.value}"`)
                .join("");
        }

        const formattedOutput = Array.from(tempDiv.childNodes)
            .map(processNode)
            .join("");
        return formattedOutput;
    }

    function updateFilterWithPage(newPage) {
        const updatedFilter = filter.map((f) => {
            if (f.name === "page") {
                return { ...f, value: newPage };
            }
            return f;
        });
        setFilter(updatedFilter);
    }

    useEffect(() => {
        if (questionIds.length && !questionIds.includes(currentId)) {
            fetchNextQuestion();
        }
    }, [questionIds]);

    async function fetchAllQuestions() {
        const validFilter = Array.isArray(filter) ? filter : [];
        const updatedFilter = validFilter.map((f) => {
            if (f.name === "page") {
                return { ...f, value: currentPage };
            }
            if (skillId && skillId !== "undefined") {
                if (f.name === "skillIds") {
                    const data = skillId.split() || [];
                    return { ...f, value: data };

                }
            }
            return f;
        });

        try {
            const result = await getAllQuestions(updatedFilter);
            if (result && Array.isArray(result.questions)) {
                const ids = result.questions.map((question) => question._id);
                if (ids.length > 0) {
                    setQuestionIds(ids);
                }
            }
        } catch (error) {
            console.error("Error fetching all question IDs:", error);
        }
    }

    async function fetchNextQuestion() {
        if (!questionIds || questionIds.length === 0 || currentId === null) {
            return;
        }
        try {
            let previewData;
            const currentIndex = questionIds.indexOf(currentId);
            if (currentIndex === -1) {
                setCurrentQuestionIndex(0);
                setCurrentId(questionIds[0]);
                previewData = await getQuestionById(questionIds[0]);
            } else {
                const nextQuestionIdIndex = currentIndex + 1;
                if (nextQuestionIdIndex < questionIds.length) {
                    const nextQuestionId = questionIds[nextQuestionIdIndex];
                    setCurrentId(nextQuestionId);
                    setCurrentQuestionIndex(nextQuestionIdIndex);
                    previewData = await getQuestionById(nextQuestionId);
                } else {
                    toast.success("Fetching Next Page Questions");
                    setCurrentPage((prevPage) => {
                        const newPage = prevPage + 1;
                        updateFilterWithPage(newPage);
                        return newPage;
                    });
                }
            }

            if (previewData) {
                const data = Array.isArray(previewData)
                    ? previewData[0]
                    : previewData.data || previewData;
                setQuestionType(data.qtype);
                let totalImages = 0;

                if (
                    questionPreviewConfig.readingComprehension.includes(
                        data.qtype
                    )
                ) {
                    const fetchedChildQuestions = data.childQuestions || []
                    if (fetchedChildQuestions.length > 0) {
                        const firstChild = fetchedChildQuestions[childQuestionCurrent];
                        setIsFlaggedRC(firstChild.isFlag);
                        setChildQuestionType(firstChild.qtype);
                        setAnswerExplanation(firstChild.solution);
                        setChildQuestionId(firstChild._id);
                        setQuestionText(firstChild.question.text);
                        setOptionText(firstChild.options.map((option) => option.text));
                        setCorrectOptions(firstChild.options.map((option) => option.isCorrect));
                    }
                    setChildQuestions(fetchedChildQuestions);
                    setChildQuestionType(data.childQuestions[0].qtype);
                    setChildQuestionCount(data.childQuestions.length);
                    setPassageText(data.question.text);
                    setIsFlagged(false)
                    setSkillTitle(data.skill);
                    setQuestionText(data.childQuestions[0].question.text);
                    const formatExplnanation = formatMathExpressions(
                        data.childQuestions[0].solution
                    );
                    setAnswerExplanation(formatExplnanation);
                    setIsReadingComprehesionQuestion(true);
                    setOptionText(
                        data.childQuestions[0].options.map(
                            (option) => option.text
                        )
                    );
                    const optionCorrect = data.childQuestions[0].options.map(
                        (option) => option.isCorrect
                    );
                    setCorrectOptions(optionCorrect);
                    const answer = data.answer;
                    setAnswer(answer);
                    totalImages = data.childQuestions.reduce((count, child) => {
                        if (child.question.image) count += 1;
                        count += child.options.filter(
                            (option) => option.image
                        ).length;
                        return count;
                    }, 0);
                } else {
                    setQuestionText(data.question.text);
                    const formatExplnanation = formatMathExpressions(
                        data.solution
                    );
                    setAnswerExplanation(formatExplnanation);
                    setSkillTitle(data.skill);
                    setIsFlagged(data.isFlag);
                    setIsFlaggedRC(false);
                    setQuestionImage(data.question.image || null);
                    const optionTexts = data.options.map(
                        (option) => option.text
                    );
                    setOptionText(optionTexts);
                    const optionCorrect = data.options.map(
                        (option) => option.isCorrect
                    );
                    setCorrectOptions(optionCorrect);
                    const answer = data.answer;
                    setAnswer(answer);
                    const optionImages = data.options.map(
                        (option) => option.image
                    );
                    setOptionImage(optionImages);
                    setIsReadingComprehesionQuestion(false);
                    if (data.question.image) totalImages += 1;
                    totalImages += optionImages.filter((img) => img).length;
                }

                setImagesToLoad(totalImages);
            } else {
                console.error("Error fetching question preview data in next.");
            }
        } catch (error) {
            console.error("Error fetching question preview data:", error);
        }
    }

    useEffect(() => {
        async function fetchQuestionPreviewId() {
            setLoading(true);
            try {
                let previewData;
                if (questionId) {
                    previewData = await getQuestionById(questionId);
                } else if (previewId) {
                    previewData = await getpreviewQuestionById(previewId);
                }
                if (previewData) {
                    const data = Array.isArray(previewData)
                        ? previewData[0]
                        : previewData.data || previewData;
                    setQuestionType(data.qtype);
                    let totalImages = 0;
                    if (
                        questionPreviewConfig.readingComprehension.includes(
                            data.qtype
                        )
                    ) {
                        const fetchedChildQuestions = data.childQuestions || [];
                        if (fetchedChildQuestions.length > 0) {
                            const firstChild = fetchedChildQuestions[0];
                            setIsFlaggedRC(firstChild.isFlag);
                            setChildQuestionType(firstChild.qtype);
                            setAnswerExplanation(firstChild.solution);
                            setChildQuestionId(firstChild._id);
                            setQuestionText(firstChild.question.text);
                            setOptionText(firstChild.options.map((option) => option.text));
                            setCorrectOptions(firstChild.options.map((option) => option.isCorrect));
                        }

                        setChildQuestions(fetchedChildQuestions);
                        setResource(data.resource);
                        setChildQuestionType(data.childQuestions[0].qtype);
                        setChildQuestionCount(data.childQuestions.length);
                        setPassageText(data.question.text);
                        setQuestionText(data.childQuestions[0].question.text);
                        const formatExplnanation = formatMathExpressions(
                            data.childQuestions[0].solution
                        );
                        setAnswerExplanation(formatExplnanation);
                        setIsReadingComprehesionQuestion(true);
                        setOptionText(
                            data.childQuestions[0].options.map(
                                (option) => option.text
                            )
                        );
                        const optionCorrect =
                            data.childQuestions[0].options.map(
                                (option) => option.isCorrect
                            );
                        setCorrectOptions(optionCorrect);
                        const answer = data.answer;
                        setAnswer(answer);
                        totalImages = data.childQuestions.reduce(
                            (count, child) => {
                                if (child.question.image) count += 1;
                                count += child.options.filter(
                                    (option) => option.image
                                ).length;
                                return count;
                            },
                            0
                        );
                    } else {
                        setQuestionText(data.question.text);
                        const formatExplnanation = formatMathExpressions(
                            data.solution
                        );
                        setAnswerExplanation(formatExplnanation);
                        setIsFlagged(data.isFlag);
                        setResource(data.resource);
                        setQuestionImage(data.question.image || null);
                        const optionTexts = data.options.map(
                            (option) => option.text
                        );
                        setOptionText(optionTexts);
                        const optionCorrect = data.options.map(
                            (option) => option.isCorrect
                        );
                        setCorrectOptions(optionCorrect);
                        const answer = data.answer;
                        setAnswer(answer);
                        const optionImages = data.options.map(
                            (option) => option.image
                        );
                        setOptionImage(optionImages);
                        if (data.question.image) totalImages += 1;
                        totalImages += optionImages.filter((img) => img).length;
                    }
                    setImagesToLoad(totalImages);
                } else {
                    console.error("Error fetching question preview data.");
                }
            } catch (error) {
                console.error("Error fetching question preview data:", error);
            } finally {
                setLoading(false);
            }
        }

        if (questionId || previewId) {
            fetchQuestionPreviewId();
        }
    }, [questionId, previewId]);

    useEffect(() => {
        if (childQuestions.length > childQuestionCurrent) {
            setQuestionText(childQuestions[childQuestionCurrent].question.text);
            setOptionText(
                childQuestions[childQuestionCurrent].options.map(
                    (option) => option.text
                )
            );
            setCorrectOptions(
                childQuestions[childQuestionCurrent].options.map(
                    (option) => option.isCorrect
                )
            );
            setChildQuestionType(childQuestions[childQuestionCurrent].qtype);
            setAnswerExplanation(childQuestions[childQuestionCurrent].solution);
            setChildQuestionId(childQuestions[childQuestionCurrent]._id);
            setIsFlaggedRC(childQuestions[childQuestionCurrent].isFlag);
        }
    }, [childQuestionCurrent]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const encodedFormData = queryParams.get("formData");
        if (encodedFormData) {
            const decodedFormData = JSON.parse(
                decodeURIComponent(encodedFormData)
            );
            setFormData(decodedFormData);
        }
    }, []);

    window.addEventListener("DOMContentLoaded", function () {
        const previewContainer = document.querySelector(".preview-container");
        if (previewContainer) {
            const children = previewContainer.children;
            let totalHeight = 0;
            for (let i = 0; i < children.length; i++) {
                totalHeight += children[i].offsetHeight;
            }
            totalHeight += 20;
            previewContainer.style.minHeight = totalHeight + "px";
        }
    });

    const closeModal = (modal) => {
        setIsBlur(false);
        modal(false);
    };

    const handleActivateFlagModal = () => {
        setShowFlagInAppropriate(true);
    };

    const handleActivateCommentsModal = () => {
        showDisplayComments(true);
    };

    const handleUnFlagModal = () => {
        setShowSaveModal(true);
    };
    const handleUnFlagAction = async () => {
        try {
            let ids = []
            ids = childQuestionId ? childQuestionId : currentId;
            const data =
            {
                ids: [ids]
            }

            await removeFlag(data);
            if (childQuestionId) {
                childQuestions[childQuestionCurrent].isFlag = false;
                setIsFlaggedRC(false);
            } else {
                setIsFlagged(false);
            }
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleFlagAction = async () => {
        try {
            const data = {
                ids: [childQuestionId ? childQuestionId : currentId],
                text: flagComment,
            };
            await addFlag(data, true);
            if (childQuestionId) {
                childQuestions[childQuestionCurrent].isFlag = true;
                setIsFlaggedRC(true);
            } else {
                setIsFlagged(true);
            }
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleImageLoad = () => {
        setImagesLoaded((prev) => prev + 1);
    };

    const handleAnswerExplanation = () => {
        setShowExplanation((prevState) => !prevState);
    };
    useEffect(() => {
        if (imagesLoaded === imagesToLoad) {
            setLoading(false);
        }
    }, [imagesLoaded, imagesToLoad]);
    return (
        <div className="question-preview">
            {loading && <Loader />}
            {!loading && (
                <div
                    className="question-preview-body"
                    style={{
                        marginLeft: isQuestionsPage ? "auto" : "",
                        marginRight: isQuestionsPage ? "auto" : "",
                    }}
                >
                    <div
                        className={`preview-container ${questionType &&
                            questionPreviewConfig.readingComprehension.includes(
                                questionType
                            )
                            ? `wide ${displayComments ? "comments-open" : ""
                            }`
                            : ""
                            }`}
                    >
                        <span className="preview-container-header"></span>
                        <div className="parent-skill">
                            Skill:{" "}
                            <span className="parent-skill-name">
                                {skillTitle}
                            </span>
                        </div>
                        <div
                            className={`question-preview-body ${questionPreviewConfig.readingComprehension.includes(
                                questionType
                            )
                                ? "passage"
                                : ""
                                }`}
                        >
                            <div
                                className={`${questionPreviewConfig.readingComprehension.includes(
                                    questionType
                                )
                                    ? "RC-child-component"
                                    : ""
                                    }`}
                            ></div>
                            <div className="question-preview-container">
                                <PreviewQuestionBuilder
                                    questionType={questionType}
                                    childQuestionType={childQuestionType}
                                    questionText={questionText}
                                    questionImage={questionImage}
                                    passageText={passageText}
                                    childQuestion={childQuestions}
                                    childQuestionCurrent={childQuestionCurrent}
                                    setChildQuestionCurrent={
                                        setChildQuestionCurrent
                                    }
                                    onImageLoad={handleImageLoad}
                                    answer={answer}
                                    resource={resource}
                                />
                            </div>

                            <div
                                className="option-component"
                                style={{
                                    margin: "1.5rem 0",
                                }}
                            >
                                <PreviewOptionBuilder
                                    questionType={
                                        questionPreviewConfig.readingComprehension.includes(
                                            questionType
                                        )
                                            ? childQuestionType
                                            : questionType
                                    }
                                    optionText={optionText}
                                    optionImage={optionImage}
                                    questionText={
                                        questionPreviewConfig.readingComprehension.includes(
                                            questionType
                                        )
                                            ? questionText
                                            : ""
                                    }
                                    childQuestionCount={childQuestionCount}
                                    childQuestionCurrent={childQuestionCurrent}
                                    setChildQuestionCurrent={
                                        setChildQuestionCurrent
                                    }
                                    isReadingComprehesionQuestion={
                                        isReadingComprehesionQuestion
                                    }
                                    onImageLoad={handleImageLoad}
                                    correctOptions={correctOptions}
                                    answer={answer}
                                    answerExplanation={answerExplanation}
                                />

                                <div
                                    className="icons"
                                    style={{
                                        marginLeft:
                                            questionType == 25 ? "21rem" : "",
                                    }}
                                >
                                    {questionType !== 25 && (
                                        <button
                                            className="show-answer"
                                            onClick={handleAnswerExplanation}
                                        >
                                            {showExplanation
                                                ? "Hide Answer"
                                                : "Show Answer"}
                                        </button>
                                    )}
                                    {isQuestionsPage && (
                                        <div
                                            className={`action-buttons ${questionPreviewConfig.readingComprehension.includes(
                                                questionType
                                            )
                                                ? "rc-action-buttons"
                                                : ""
                                                }`}
                                        >
                                            {flaggingAccess.length && (
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            {isFlagged ||
                                                                (isFlaggedRC &&
                                                                    flaggingAccess[0]
                                                                        ?.delete)
                                                                ? "Unflag"
                                                                : !isFlagged &&
                                                                    flaggingAccess[0]
                                                                        ?.add
                                                                    ? "Flag"
                                                                    : "No Access"}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Flag
                                                        src={flag}
                                                        alt="Flagged"
                                                        className={`flag-button ${isFlagged ||
                                                            isFlaggedRC
                                                            ? "flag-icon"
                                                            : "unFlag-icon"
                                                            }`}
                                                        onClick={
                                                            (isFlagged ||
                                                                isFlaggedRC) &&
                                                                flaggingAccess[0]
                                                                    ?.delete
                                                                ? handleUnFlagModal
                                                                : !isFlagged &&
                                                                    !isFlaggedRC &&
                                                                    flaggingAccess[0]
                                                                        ?.add
                                                                    ? handleActivateFlagModal
                                                                    : null
                                                        }
                                                    />
                                                </OverlayTrigger>
                                            )}
                                            {commentsAccess.length && (
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            {commentsAccess[0]
                                                                .view
                                                                ? "Comments"
                                                                : ""}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="comment-action-icon">
                                                        <FaCommentDots
                                                            onClick={
                                                                handleActivateCommentsModal
                                                            }
                                                            className="comment-icon"
                                                        />
                                                    </span>
                                                </OverlayTrigger>
                                            )}
                                            {showFlagInAppropriate && (
                                                <FlagInappropriateModal
                                                    showModal={
                                                        showFlagInAppropriate
                                                    }
                                                    onClose={() =>
                                                        setShowFlagInAppropriate(
                                                            false
                                                        )
                                                    }
                                                    onSave={handleFlagAction}
                                                    flagComment={flagComment}
                                                    setFlagComment={
                                                        setFlagComment
                                                    }
                                                    flagText="question"
                                                />
                                            )}
                                            {showSaveModal && (
                                                <SaveModal
                                                    showModal={showSaveModal}
                                                    onClose={() =>
                                                        setShowSaveModal(false)
                                                    }
                                                    onSave={handleUnFlagAction}
                                                    setShowContent={true}
                                                />
                                            )}
                                            {displayComments && (
                                                <Comment_VersionHistory
                                                    show={displayComments}
                                                    handleClose={() =>
                                                        closeModal(
                                                            showDisplayComments
                                                        )
                                                    }
                                                    intitialData={questionId}
                                                    id={questionId}
                                                    access={
                                                        commentsAccess[0]?.add
                                                    }
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="text-editor"
                                    style={{
                                        marginRight:
                                            questionType ===
                                                "readingComprehension"
                                                ? "5.2rem"
                                                : "3.8rem",
                                        marginBottom:
                                            questionType ===
                                                "readingComprehension"
                                                ? "-6rem"
                                                : "",
                                    }}
                                >
                                    {showExplanation && (
                                        <>
                                            {answerExplanation &&
                                                answerExplanation.trim() !== "" ? (
                                                <>
                                                    <div
                                                        className={`textEditor-title ${questionType ===
                                                            "readingComprehension"
                                                            ? "readingComprehension"
                                                            : "default"
                                                            }`}
                                                    >
                                                        Answer Explanation:
                                                    </div>
                                                    <div>
                                                        {parse(
                                                            answerExplanation
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="no-answer">
                                                    <strong>
                                                        No Explanation Found.
                                                    </strong>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {questionPreviewConfig.readingComprehension.includes(
                        questionType
                    ) && (
                            <div className="preview-container-footer-RC">
                                <span className="pagination-line"></span>
                                <div className="pagination-RC">
                                    <div className="pagination-number-container">
                                        {Array.from({
                                            length: childQuestionCount,
                                        }).map((_, i) => (
                                            <span
                                                key={i + 1}
                                                className={`pagination-number ${selectedPage === i
                                                    ? "selected"
                                                    : ""
                                                    }`}
                                                onClick={() =>
                                                    handleQuestionCall(i)
                                                }
                                            >
                                                {i + 1}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    {isQuestionsPage && (
                        <div className="nextQuestion-container">
                            <button
                                className={`next-button ${questionType &&
                                    questionPreviewConfig.readingComprehension.includes(
                                        questionType
                                    )
                                    ? "rc-next-button"
                                    : "default-next-button"
                                    }`}
                                onClick={fetchNextQuestion}
                            >
                                Next Question
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default QuestionPreview;
