export const questionTypes = {
    1: "Multi Select - Text Question with Image Answer",
    2: "Multi Select - Text Question with Text Answer",
    3: "Multi Select - Text Question with Text & Image Answer",
    4: "Multi Select - Image Question with Image Answer",
    5: "Multi Select - Image Question with Text Answer",
    6: "Multi Select - Image Question with Text & Image Answer",
    7: "Multi Select - Text & Image Question with Image Answer",
    8: "Multi Select - Text & Image Question with Text Answer",
    9: "Multi Select - Text & Image Question with Text & Image Answer",
    10: "Single Select - Text Question with Image Answer",
    11: "Single Select - Text Question with Text Answer",
    12: "Single Select - Text Question with Text & Image Answer",
    13: "Single Select - Image Question with Image Answer",
    14: "Single Select - Image Question with Text Answer",
    15: "Single Select - Image Question with Text & Image Answer",
    16: "Single Select - Text & Image Question with Image Answer",
    17: "Single Select - Text & Image Question with Text Answer",
    18: "Single Select - Text & Image Question with Text & Image Answer",
    19: "Text Answer - Text Question",
    20: "Text Answer - Image Question",
    21: "Text Answer - Text & Image Question",
    22: "Text Question Fill in the blank",
    23: "Text & Image Question Fill in the blank",
    24: "Reading Comprehension",
    25: "Writing",
    26: "Audio Question - Text Answer"
};

export const bTaxonomy = {
    "1": "Remembering",
    "2": "Understanding",
    "3": "Applying",
    "4": "Analyzing",
    "5": "Creating",
    "6": "Evaluating",
};

export const complexity = {
    'l': "Low",
    'm': "Medium",
    'h': "High",
    'c': "Challenging",
};
