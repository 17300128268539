import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CardWrapper } from "./Card.styled";

/**
 * Card Component
 *
 * Represents a card element that contains other components or elements.
 * The height of the card is dynamically calculated based on the number of
 *  children elements.
 *
 * @param { Object } props - The properties passed to the Input component.
 * @param { ReactNode } props.children - The child elements or components to be rendered
 *  within the card.
 * 
 * @returns { JSX.Element } - A JSX element representing the card with dynamically
 *  calculated height.
 *
 * @author Gaurav
 */

const Card = ({ children }) => {
  const [cardHeight, setCardHeight] = useState("auto");

  useEffect(() => {
    const cardElement = document.getElementById('card');
    if (cardElement) {
      const cardHeight = cardElement.scrollHeight;
      setCardHeight(`${cardHeight}px`);
    }
  }, [children]);

  return <CardWrapper style={{ height: cardHeight }}>{children}</CardWrapper>;
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Card;
