import { bulkImport } from 'Utils/ApiHandler/BulkImport';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { deleteAction } from 'Utils/ApiHandler/DatatableActionServices/DatatableActionServices';

const initialState = {
    key: null,
    error: null,
    endPoint:null,
    loading: false,
    itemName: null,
    itemTitle: null,
    isDeleteModalOpen: false,
};

export const deleteActionCall = createAsyncThunk(
    'deleteAction/delete',
    async (params, { rejectWithValue }) => {
        try {
            const result = await deleteAction(params);
            return result.success
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
const deleteActionSlice = createSlice({
    name: 'deleteAction',
    initialState,
    reducers: {
        openDeleteModal: (state) => {
            state.isDeleteModalOpen = true;
        },
        closeDeleteModal: (state) => {
            state.isDeleteModalOpen = false;
        },
        passDeleteItemData: (state, action) => {
            state.itemTitle = action.payload.title;
            state.itemName = action.payload.name;
            state.itemId = action.payload.id;
            state.key = action.payload.key;
            state.endPoint = action.payload.endPoint;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteActionCall.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteActionCall.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteActionCall.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});
export const { openDeleteModal, closeDeleteModal, passDeleteItemData } = deleteActionSlice.actions;

export default deleteActionSlice.reducer;
