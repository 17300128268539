import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form,} from 'react-bootstrap';
import closeIcon from "../../../Assests/Images/closeIcon.svg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaAsterisk } from "react-icons/fa";
import { 
  CustomModalHeader,
  CustomModalFooter, 
  CustomForm, 
  ModalButton,
  CustomModal,
  CloseIcon 
} from '../AddEditModal.styled';
import { addDomain } from '../../../Utils/ApiHandler/LevelApi';

/**
 * AddDomainModal Component
 * 
 * This component represents a modal for adding a new domain.
 * 
 * @param {boolean} show - Flag indicating whether the modal should be displayed.
 * @param {function} handleClose - Function to handle closing the modal.
 * 
 * @returns {JSX.Element} - The rendered AddDomainModal component.
 * 
 * @author - Devisri
 */
const AddDomainModal = ({ show, 
    handleClose, 
    parentIdentifier, 
    onSave}) => {
  const [formData, setFormData] = useState({
    code: '',
    domainName: '',
    description: ''
  });

  // Function to handle changes in form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.domainName.trim()) {
        toast.error("Please enter a Domain name");
        return;
      }
  
      if (!formData.description.trim()) {
        toast.error("Please enter  description");
        return;
      }
      const insertData = {
        title: formData.domainName,
        description: formData.description,
        parentId: parentIdentifier._id
      };
      addDomain(insertData);
      onSave();
      handleClose();
      
    } catch (error) {
      console.error("Error in updating data: ", error);
    }
      
  };

  return (
    <CustomModal show={show} onHide={handleClose}>
      <CustomModalHeader >
        <Modal.Title>Add Domain</Modal.Title>
        <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
      </CustomModalHeader>
      <Modal.Body>
        <CustomForm onSubmit={handleSubmit}>
        <div >
          <div style={{fontSize:"0.95rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>Level : </div>
            <p >{parentIdentifier.level}</p></div>
          <div className="input-fields" >
          <Form.Group controlId="domainName">
            <Form.Label style={{fontSize: "0.95rem"}}>Domain
              <FaAsterisk style={{color:"#E62753",
                  width:"0.4rem",
                  height:"0.4rem",
                  margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Form.Control
              type="text"
              className="placeholder-Text editable"
              name="domainName"
              value={formData.domainName}
              onChange={handleChange}
              placeholder="Enter domain name"
              required
              style={{padding:"0.2rem 0.1rem"}}
            />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label style={{fontSize: "0.95rem"}}>Description
              <FaAsterisk style={{color:"#E62753",
                  width:"0.4rem",
                  height:"0.4rem",
                  margin:"0rem 0rem 0.5rem 0.25rem"}}/></Form.Label>
            <Form.Control
            className='editable textareaStyle'
              as="textarea"
              placeholder="Enter Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </Form.Group>
          </div>
        </CustomForm>
      </Modal.Body>
      <CustomModalFooter>
            <ModalButton isClose onClick={handleClose} style={{ width: "7.5rem" }}>
              Cancel
            </ModalButton>
            <ModalButton type="submit" style={{ width: "10.75rem" ,paddingLeft:"39px" }} onClick={handleSubmit}>
              Add Domain
            </ModalButton>
          </CustomModalFooter>
    </CustomModal>
  );
};

AddDomainModal.propTypes = {
  // show: PropTypes.bool.isRequired,
  // handleClose: PropTypes.func.isRequired,
  onSave:PropTypes.func.isRequired,
};

export default AddDomainModal;
