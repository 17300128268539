import React from "react";
import PropTypes from "prop-types";
import { ErrorMessageWrapper } from "./ErrorMessage.styled";

/**
 * ErrorMessage Component
 *
 * Represents an error message to be displayed in the UI.
 * 
 * @param { Object } props - The properties passed to the Input component.
 * @param { string } props.message - The error message content to be displayed.
 *
 * @returns { JSX.Element } - A JSX element representing the error message.
 *
 * @author Gaurav
 */

const ErrorMessage = ({ message }) => (
  <ErrorMessageWrapper>{message}</ErrorMessageWrapper>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorMessage;
