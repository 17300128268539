import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import closeIcon from "../../../Assests/Images/closeIcon.svg";
import { toast } from 'react-toastify';
import { FaAsterisk } from "react-icons/fa";
import { 
  CustomModalHeader,
  CustomModalFooter,
  CustomForm, 
  ModalButton,
  CustomModal,
  CloseIcon
 } from '../AddEditModal.styled';
import { addSkill } from 'Utils/ApiHandler/TopicApi';

/**
 * AddSkillModal Component
 * 
 * This component represents a modal for adding a new skill.
 * 
 * @param {boolean} show - Flag indicating whether the modal should be displayed.
 * @param {function} handleClose - Function to handle closing the modal.
 * @param {function} handleAddSkill - Function to handle adding a new skill.
 * 
 * @returns {JSX.Element} - The rendered AddSkillModal component.
 * 
 * @author - Devisri
 */
const AddSkillModal = ({ show, handleClose, handleAddSkill, parentIdentifier }) => {
  // State to manage form data
  const [formData, setFormData] = useState({
    code: '',
    skillName: '',
    description: ''
  });
  
  // Function to handle changes in form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.skillName.trim()) {
        toast.error("Please enter a Skill name");
        return;
      }

      if (!formData.description.trim()) {
        toast.error("Please enter  description");
        return;
      }
      const insertData = {
        title: formData.skillName,
        description: formData.description,
        parentId: parentIdentifier._id
      };
      const response = await addSkill(insertData);
      handleAddSkill();
      handleClose();

    } catch (error) {
      console.error("Error in updating data: ", error);
    }

  };

  return (
    <CustomModal show={show} onHide={handleClose}>
      <CustomModalHeader >
        <Modal.Title>Add Skill</Modal.Title>
        <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
      </CustomModalHeader>
      <Modal.Body>
        <CustomForm onSubmit={handleSubmit}>
        <div >
          <div style={{fontSize:"0.95rem", marginRight:"0.5rem",marginBottom:"0.5rem"}}>Topic : </div>
            <p >{parentIdentifier.title}</p></div>
            <Form.Group controlId="topicName">
            <Form.Label>Skill
              <FaAsterisk style={{color:"#E62753",
                width:"0.4rem",
                height:"0.4rem",
                margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Form.Control
              className="editable"
              type="text"
              name="skillName"
              placeholder="Enter skill"
              value={formData.skillName}
              onChange={handleChange}
              required
            />
            </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description
            <FaAsterisk style={{color:"#E62753",
              width:"0.4rem",
              height:"0.4rem",
              margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Form.Control
              className="editable textareaStyle"
              as="textarea"
              placeholder="Enter Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </CustomForm>
      </Modal.Body>
      <CustomModalFooter>
            <ModalButton isClose onClick={handleClose} style={{ width: "7.5rem" }}>
              Cancel
            </ModalButton>
            <ModalButton type="submit" style={{ width: "10.75rem" }} onClick={handleSubmit}>
              Add Skill
            </ModalButton>
          </CustomModalFooter>
    </CustomModal>
  );
};

AddSkillModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAddSkill: PropTypes.func.isRequired,
};

export default React.memo(AddSkillModal);
