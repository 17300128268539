import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import closeIcon from "../../../Assests/Images/closeIcon.svg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select, { components } from "react-select";
import { createTeams } from '../../../Utils/ApiHandler/TeamsApi';
import _ from "lodash";
import _debounce from "lodash/debounce";
import { FaAsterisk } from "react-icons/fa";
import {
  CustomModalHeader,
  CustomModalFooter,
  CustomForm,
  ModalButton,
  CustomModal,
  CloseIcon
} from '../AddEditModal.styled';
import { fetchToken } from 'Constants/ApiUrls';

const CreateTeamModal = ({ show, handleClose, onSave }) => {
  const [formData, setFormData] = useState({
    organizationId: '',
    teamName: '',
    description: ''
  });

  const [inputOrganization, setInputOrganization] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [organizationTitles, setOrganizationTitles] = useState([]);
  const [isOrganizationMenuOpen, setIsOrganizationMenuOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const token = fetchToken();
  const getOrganizationTitles = process.env.REACT_APP_BASE_URL + "/cms/v1/getAllOrganizationTitles";
  const formatOptionLabel = ({ label }) => <span>{label}</span>;

  const CheckboxOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="radio"
          checked={props.isSelected}
          onChange={() => props.selectOption(props.data)}
          style={{
            marginRight: "5px",
            flexShrink: 0,
          }}
        />
        <span style={{ color: "Black" }}>{props.label}</span>
      </div>
    </components.Option>
  );

  useEffect(() => {
    fetchOrganizationTitles();
  }, []);

  const fetchOrganizationTitles = _.debounce(async (isActive) => {
      try {
        const activeParam = isActive ? "true " : "false";
        const url = getOrganizationTitles
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      const result = await response.json();
      if (!response.ok) {
        console.error(response);
        toast.error(result.message);
        return;
      }
      if (result.success && result.data.organizationTitles.length > 0) {
        const organizationTitles = result.data.organizationTitles.map((title) => ({
          value: title._id,
          label: title.title,
          id: title._id,
        }));
        setOrganizationTitles(organizationTitles);
      }
      } catch (error) {
        toast.error("Error fetching Organization titles: ", error);
        throw new Error(error);
      }
  }, 1000);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnchangeOrganization = (selectedOption) => {
    setSelectedOrganization(selectedOption);
    setFormData((prevFormData) => ({
      ...prevFormData,
      organizationId: selectedOption ? selectedOption.id : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      if (!formData.organizationId.trim()) {
        toast.error("Please select an organization");
        return;
      }

      if (!formData.teamName.trim()) {
        toast.error("Please enter a team name");
        return;
      }

      if (!formData.description.trim()) {
        toast.error("Please enter description");
        return;
      }

      const insertData = {
        teamName: formData.teamName,
        description: formData.description,
        organizationId: formData.organizationId
      };
       await createTeams(insertData);
      onSave();
      handleClose();

    } catch (error) {
      console.error("Error in creating team: ", error);
      toast.error("Error in creating team");
    }
  };

  return (
    <CustomModal show={show} onHide={handleClose}>
      <CustomModalHeader>
        <Modal.Title>Create Team</Modal.Title>
        <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
      </CustomModalHeader>
      <Modal.Body>
        <CustomForm onSubmit={handleSubmit}>
          <Form.Group controlId="organization">
            <Form.Label style={{ marginBottom: '1.49rem' }}>Organization
              <FaAsterisk style={{color:"#E62753",
                width:"0.4rem",
                height:"0.4rem",
                margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Select
              className="custom-select"
              placeholder="Select Organization"
              menuIsOpen={isOrganizationMenuOpen}
              options={organizationTitles}
              onChange={handleOnchangeOrganization}
              value={selectedOrganization}
              isClearable
              components={{
                Option: CheckboxOption,
                NoOptionsMessage: () => (
                  <div style={{ textAlign: "center", padding: "0.4rem" }}>
                    {isTyping ? (
                      <span style={{ color: "var(--sky-blue-color)" }}>
                        Searching...
                      </span>
                    ) : organizationTitles.length === 0 ? (
                      "Please wait..."
                    ) : (
                      "No options"
                    )}
                  </div>
                ),
              }}
              onMenuOpen={() => setIsOrganizationMenuOpen(true)}
              onMenuClose={() => setIsOrganizationMenuOpen(false)}
              formatOptionLabel={formatOptionLabel}
              closeMenuOnSelect={true}
              menuPlacement="bottom"
              styles={{
                menu: (base) => ({
                  ...base,
                  marginTop: "-1.1rem",
                  width: "22.9375rem",
                  height:'auto'
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "var(--white-color) !important",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "var(--dark-gray-color)",
                  paddingLeft:"3px",
                  fontWeight:"400",
                  fontSize:"var(--font-size-small)"
                }),
                control: (base) => ({
                  ...base,
                  borderColor: "#d8d8d8",
                  width: "22.9375rem",
                  height: "3rem",
                  bottom: "1rem"
                }),
              }}
            />
          </Form.Group>
          <Form.Group controlId="teamName">
            <Form.Label>Team Name
              <FaAsterisk style={{color:"#E62753",
                width:"0.4rem",
                height:"0.4rem",
                margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Form.Control
              className='editable'
              type="text"
              name="teamName"
              placeholder="Enter team name"
              value={formData.teamName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description
              <FaAsterisk style={{color:"#E62753",
                width:"0.4rem",
                height:"0.4rem",
                margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Form.Control
              className='editable textareaStyle'
              as="textarea"
              placeholder="Enter description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </CustomForm>
      </Modal.Body>
      <CustomModalFooter>
        <ModalButton onClick={handleClose} style={{ width: "7.5rem" }}>
          Cancel
        </ModalButton>
        <ModalButton type="submit" style={{ width: "10.75rem", paddingLeft: "39px" }} onClick={handleSubmit}>
          Create Team
        </ModalButton>
      </CustomModalFooter>
    </CustomModal>
  );
};

CreateTeamModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default CreateTeamModal;
