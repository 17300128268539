import "./styles.css"
import React from 'react'

import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from "../../../../Assests/Icons/CloseIcon.svg";
import { statusCountAction } from "../../../../redux/slices/SpellAndVocab/statusSlice";
import { fetchAllLevelsAndQuestions, updateQuestionCount } from '../../../../redux/slices/SpellAndVocab/levelSlice';
import { closeDeleteModal, deleteActionCall } from '../../../../redux/slices/DatatableActionSlices/deleteActionSlice';

const DeleteModal = () => {

    const dispatch = useDispatch();

    const { activeProgramDetails } = useSelector((state) => state.globalData);
    const { itemName, itemTitle, itemId, key, endPoint } = useSelector((state) => state.delete);

    const activeProgramId = activeProgramDetails?._id;

    const handleDeleteAction = async () => {
        const result = await dispatch(deleteActionCall({ itemId, key, endPoint }));
        if (result) {
            dispatch(closeDeleteModal());
            await dispatch(statusCountAction(activeProgramId));
        }
    }

    return (
        <Card className='delete-modal '>
            <Card.Header className='delete-modal-header'>
                <span>Do you want to remove {itemId?.length > 1 ? 'bulk delete?' : ` this ${itemTitle}?`}</span>
                <img src={CloseIcon} alt="Close icon" onClick={() => dispatch(closeDeleteModal())} /></Card.Header>
            <Card.Body className='delete-modal-body'>
                <Card.Text>
                    You are about to remove {itemId?.length > 1 ? 'multiple entries' : <span>"{itemName}"</span>}.
                    This action can't be undone.
                </Card.Text>
            </Card.Body>
            <Card.Footer className='delete-modal-footer'>
                <button onClick={() => handleDeleteAction()}>Yes,Remove</button>
                <button onClick={() => dispatch(closeDeleteModal())}>No,cancel</button>
            </Card.Footer>
        </Card>
    )
}

export default DeleteModal