import React, { useState, useEffect } from "react";
import './stylesRoles.css';
import RoleInfo from "../../Components/Modals/Roles/RoleInfo";
import { FaArrowLeft } from "react-icons/fa6";
import RolePermissions from "../../Components/Modals/Roles/RolePermissions";
import RolePermissionReview from "../../Components/Modals/Roles/RolePermissionReview";
import { useNavigate } from "react-router-dom";
import { insertRole, updateRole } from "Utils/ApiHandler/RolesApi";
import _ from "lodash";
import _debounce from "lodash/debounce";
import { toast } from "react-toastify";
import { fetchToken } from "Constants/ApiUrls";

function CreateRole() {
    const queryParams = new URLSearchParams(window.location.search);
    const data = queryParams.get('data');
    const editData = JSON.parse(data);
    const edit = data ? true : false;
    const [showRoleInfo, setRoleInfo] = useState(true);
    const [showSetPermissions, setShowSetPermissions] = useState(false);
    const [showReviewPage, setShowReviewPage] = useState(false);
    const [roleName, setRoleName] = useState(editData?.role ?? '');
    const [organization, setOrganization] = useState(editData?.organizationName ?? '');
    const [description, setDescription] = useState(editData?.description ?? '');
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const token = fetchToken();
    const getOrganization =
        process.env.REACT_APP_BASE_URL + "/cms/v1/getAllOrganizationTitles";
    const navigate = useNavigate();
    const defaultSettings = {
        organizations: { add: false, edit: false, view: false, delete: false },
        users: { add: false, edit: false, view: false, delete: false },
        teams: { add: false, edit: false, view: false, delete: false },
        roles: { add: false, edit: false, view: false, delete: false }
    }
    const defaultCurriculumAccess = {
        programs: { add: false, edit: false, view: false, delete: false },
        levels: { add: false, edit: false, view: false, delete: false },
        domains: { add: false, edit: false, view: false, delete: false },
        topics: { add: false, edit: false, view: false, delete: false },
        skills: { add: false, edit: false, view: false, delete: false },
        questions: { add: false, edit: false, view: false, delete: false },
        flagging: { add: false, edit: false, view: false, delete: false },
        addComment: { add: false, edit: false, view: false, delete: false },
        assignments: { add: false, edit: false, view: false, delete: false },
        // tests: { assign: false, unAssign: false},
        publishCurriculum: '',
        unPublishCurriculum: '',
        exportCurriculum: ''
    }
    const [settingsAccess, setSettingsAccess] = useState(editData?.settings[0] ?? defaultSettings);
    const [curriculumAccess, setCurriculumAccess] = useState(editData?.curriculum[0] ?? defaultCurriculumAccess);
    useEffect(() => {
        fetchOrganization();
    }, []);
    const handleRoleInfo = () => {
        setRoleInfo(true);
        setShowSetPermissions(false);
        setShowReviewPage(false);
    }
    const handleSetPermissions = () => {
        setRoleInfo(false);
        setShowSetPermissions(true);
        setShowReviewPage(false);
    }
    const handleReviewPage = () => {
        setRoleInfo(false);
        setShowSetPermissions(false);
        setShowReviewPage(true);
    }
    const handlePreviousAction = () => {
        if (showSetPermissions) {
            handleRoleInfo();
        } else if (showReviewPage) {
            handleSetPermissions();
        } else if (showRoleInfo) {
            navigate(`/roles`)
        }
    }
    const handleInsertion = async () => {
        let response = '';
        if (edit) {
            let updateData = {
                roleId: editData?.roleId,
                organizationId: editData?.organization,
                roleName: roleName,
                description: description,
                access: "India",
                curriculum: [curriculumAccess],
                settings: [settingsAccess]
            }
            response = await updateRole(updateData);
        } else {
            let insertionData = {
                organizationId: organization,
                roleName: roleName,
                description: description,
                access: "India",
                curriculum: [curriculumAccess],
                settings: [settingsAccess]
            }
            response = await insertRole(insertionData);
        }
        if (response) {
            return true;
        }
    }

    const fetchOrganization = _.debounce(async () => {
        try {
            const url = getOrganization
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            const result = await response.json();
            if (!response.ok) {
                console.error(response);
                toast.error(result.message);
                return;
            }
            if (result.success && result.data.organizationTitles.length > 0) {
                const organizationTitle = result.data.organizationTitles.map((title) => ({
                    value: title._id,
                    label: title.title,
                    id: title._id,
                }));
                setOrganizationOptions(organizationTitle);
            }
        } catch (error) {
            toast.error("Error fetching Organization: ", error);
            throw new Error(error);
        }
    }, 1000);

    return <>
        <div style={{ marginTop: "5.5rem" }}>
            <div className="title content-start" style={{ marginLeft: "4rem", marginBottom: "1.25rem" }}>
                <FaArrowLeft style={{ marginRight: "1rem", marginTop: "0.25rem", cursor: "pointer" }}
                    onClick={handlePreviousAction} />
                {edit ? (<div> Edit Role</div>) : (<div> Creating new Role</div>)}</div>
            <hr style={{ margin: "0rem" }}></hr>
            <div className="Roleoptions content-start">
                <div className={`createRoleOption ${showRoleInfo ? 'highlight-role-options' : ''}`}
                    onClick={handleRoleInfo}>Role Info</div>
                <div className={`createRoleOption ${showSetPermissions ? 'highlight-role-options' : ''}`}>
                    Set Permissions</div>
                <div className={`createRoleOption ${showReviewPage ? 'highlight-role-options' : ''}`} >Review</div>
            </div>
            <hr style={{ margin: "0rem" }}></hr>
            <div className="content-start roles-content">
                {showRoleInfo && <RoleInfo edit={edit} handleNext={handleSetPermissions}
                    organization={organization} role={roleName}
                    description={description} setOrganization={setOrganization}
                    setRole={setRoleName} setDescription={setDescription}
                    organizationOptions={organizationOptions}
                />}
                {showSetPermissions && <RolePermissions handleNext={handleReviewPage}
                    settingsAccess={settingsAccess} setSettingsAccess={setSettingsAccess}
                    curriculumAccess={curriculumAccess} setCurriculumAccess={setCurriculumAccess} />}
                {showReviewPage && <RolePermissionReview roleName={roleName}
                    description={description} settingsAccess={settingsAccess}
                    curriculmAccess={curriculumAccess} handleInsertRole={handleInsertion}
                    edit={edit} />}
            </div>
        </div>
    </>
}

export default CreateRole;