import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getSpellAndVocab } from "Utils/ApiHandler/SpellAndVocabServices/spellAndVocabServices";

const initialState = {
    spellings: [],
    error: null,
    loading: true,
}

export const fetchAllSpellings = createAsyncThunk('spellings/fetchAll', async (params, { rejectWithValue }) => {
    try {
        const response = await getSpellAndVocab(params);
        if (response.questions.length > 0) {
            return response.questions;
        }
        return [];
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const spellingSlice = createSlice({
    name: 'spellings',
    initialState: initialState,
    reducers: {
        object1: (state) => {

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllSpellings.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllSpellings.fulfilled, (state, action) => {
                state.loading = false;
                state.spellings = action.payload;
                state.error = null;
            })
            .addCase(fetchAllSpellings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong!';
            });
    },
});

export const { object1 } = spellingSlice.actions;

export default spellingSlice.reducer;