import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Form } from "react-bootstrap";
import closeIcon from "../../../Assests/Images/closeIcon.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import _debounce from "lodash/debounce";
import Select, { components } from "react-select";
import { FaAsterisk } from "react-icons/fa";
import {
  CustomModalHeader,
  CustomModalFooter,
  CustomForm,
  ModalButton,
  CustomModal,
  CloseIcon,
} from "../AddEditModal.styled";
import { createUser } from "../../../Utils/ApiHandler/UsersApi";
import { fetchToken } from "Constants/ApiUrls";

const CreateUserModal = ({ show, handleClose, onSave }) => {
  const [formData, setFormData] = useState({
    organizationId: "",
    firstName: "",
    lastName: "",
    workEmail: "",
    teamIds: [],
    roleIds: [],
  });

  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [organizationTitles, setOrganizationTitles] = useState([]);
  const [isOrganizationMenuOpen, setIsOrganizationMenuOpen] = useState(false);
  const [inputTeam, setInputTeam] = useState("");
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [teamTitles, setTeamTitles] = useState([]);
  const [inputRole, setInputRole] = useState("");
  const [selectedRole, setSelectedRole] = useState([]);
  const [roleTitles, setRoleTitles] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const token = fetchToken();
  const getOrganizationTitles =
    process.env.REACT_APP_BASE_URL + "/cms/v1/getAllOrganizationTitles";
  const getTeamTitles =
    process.env.REACT_APP_BASE_URL + "/cms/v1/getTeamTitles/";
  const getRoleTitles =
    process.env.REACT_APP_BASE_URL + "/cms/v1/getRoleAndTemplateTitles/";

  const formatOptionLabel = ({ label }) => <span>{label}</span>;

  const CheckboxOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => {
            props.selectOption(props.data); 
          }}
          style={{
            marginRight: "5px",
            flexShrink: 0,
          }}
        />
        <span style={{ color: "Black" }}>{props.label}</span>
      </div>
    </components.Option>
  );

  const RadioboxOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="radio"
          checked={props.isSelected}
          onChange={() => {
            props.selectOption(props.data); 
          }}
          style={{
            marginRight: "5px",
            flexShrink: 0,
          }}
        />
        <span style={{ color: "Black" }}>{props.label}</span>
      </div>
    </components.Option>
  );

  useEffect(() => {
    fetchOrganizationTitles();
  }, []);

  useEffect(() => {
    fetchTeamTitles();
  }, [inputTeam]);

  useEffect(() => {
    fetchRoleTitles();
  }, [inputRole]);

  const fetchOrganizationTitles = _.debounce(async () => {
      try {
        const url = getOrganizationTitles
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const result = await response.json();
        if (!response.ok) {
          console.error(response);
          toast.error(result.message);
          return;
        }
        if (result.success && result.data.organizationTitles.length > 0) {
        const organizationTitles = result.data.organizationTitles.map(
          (title) => ({
            value: title._id,
            label: title.title,
            id: title._id,
          })
        );
        setOrganizationTitles(organizationTitles);
      }
      } catch (error) {
        toast.error("Error fetching Organization titles: ", error);
        throw new Error(error);
      }
  },1000);

  const fetchTeamTitles = _.debounce(async () => {
    if (inputTeam.length > 0) {
      try {
        const url = inputTeam
          ? `${getTeamTitles}${inputTeam}`
          : `${getTeamTitles}`;
        if (!inputTeam) {
          return;
        }
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const teamTitles = result.data.teamTitles.map((teamName) => ({
          value: teamName._id,
          label: teamName.teamName,
          id: teamName._id,
        }));
        setTeamTitles(teamTitles);
      } catch (error) {
        console.error("Error fetching Team titles: ", error);
      }
    }
  },1000);

  const handleInputTeamChange = _debounce((input) => {
    if (!input) {
      setInputTeam("");
    } else {
      setInputTeam(input);
    }
  },1000);

  const fetchRoleTitles = _.debounce(async () => {
    if (inputRole.length > 0) {
      try {
        const url = inputRole
          ? `${getRoleTitles}${inputRole}`
          : `${getRoleTitles}`;
        if (!inputRole) {
          return;
        }
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const roleTitles = result.data.roleAndTemplateTitles.map(
          (roleName) => ({
            value: roleName._id,
            label: roleName.roleName,
            id: roleName._id,
          })
        );
        setRoleTitles(roleTitles);
      } catch (error) {
        console.error("Error fetching role titles: ", error);
      }
    }
  },1000);

  const handleInputRoleChange = _.debounce((input) => {
    if (!input) {
      setInputRole("");
    } else {
      setInputRole(input);
    }
  },1000);

  const handleOnchangeTeam = (selectedOptions) => {
    setSelectedTeam(selectedOptions);
    const ids = selectedOptions.map((option) => option.id);
    setFormData((prevState) => ({
      ...prevState,
      teamIds: ids,
    }));
  };

  const handleOnchangeRole = (selectedOptions) => {
    if (!selectedOptions) {
      setSelectedRole(null);
      setFormData((prevState) => ({
        ...prevState,
        roleIds: [], 
      }));
      return;
    }

    const optionsArray = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
    setSelectedRole(optionsArray);
    const ids = optionsArray.map((option) => option.id);
    setFormData((prevState) => ({
      ...prevState,
      roleIds: ids,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnchangeOrganization = (selectedOption) => {
    setSelectedOrganization(selectedOption);
    setFormData((prevFormData) => ({
      ...prevFormData,
      organizationId: selectedOption ? selectedOption.id : "",
    }));

    console.log("updated formData", {
      ...formData,
      organizationId: selectedOption ? selectedOption.id : "",
    });
  };

  const handleSubmit = async (e) => {
    var validRegex = /^\w+([\.-]?\w+)*@(\w+([\.-]?\w+)*)\.(com|org|net)$/i;
    e.preventDefault();
    
    try {
      if (!formData.organizationId || formData.organizationId.length === 0) {
        toast.error("Please select organization");
        return;
      }
      if (!formData.firstName.trim()) {
        toast.error("Please enter First Name");
        return;
      }

      if (!formData.lastName.trim()) {
        toast.error("Please enter Last Name");
        return;
      }
      if (!validRegex.test(formData.workEmail.trim())) {
        toast.error("Please enter Work Mail");
        return;
      }
      if (!formData.roleIds || formData.roleIds.length === 0) {
        toast.error("Please select role");
        return;
      }
      const insertData = {
        organizationId: formData.organizationId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        workEmail: formData.workEmail,
        teamIds: formData.teamIds,
        roleIds: formData.roleIds,
      };
      await createUser(insertData);
      onSave();
      handleClose();
    } catch (error) {
      toast.error("Error in creating user");
    }
  };

  return (
    <CustomModal show={show} onHide={handleClose}>
      <CustomModalHeader>
        <Modal.Title>Create User</Modal.Title>
        <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
      </CustomModalHeader>
      <Modal.Body>
        <CustomForm onSubmit={handleSubmit}>
        <Form.Group controlId="organization">
            <Form.Label style={{ marginBottom: '1.49rem' }}>Organization
              <FaAsterisk style={{color:"#E62753",
                width:"0.4rem",
                height:"0.4rem",
                margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Select
              className="custom-select"
              placeholder="Select Organization"
              menuIsOpen={isOrganizationMenuOpen} 
              options={organizationTitles}
              onChange={handleOnchangeOrganization}
              value={selectedOrganization}
              isClearable
              components={{
                Option: RadioboxOption,
                NoOptionsMessage: () => (
                  <div style={{ textAlign: "center", padding: "0.4rem" }}>
                    {isTyping ? (
                      <span style={{ color: "var(--sky-blue-color)" }}>
                        Searching...
                      </span>
                    ) : organizationTitles.length === 0 ? (
                      "Please wait..."
                    ) : (
                      "No options"
                    )}
                  </div>
                ),
              }}
              onMenuOpen={() => setIsOrganizationMenuOpen(true)}
              onMenuClose={() => setIsOrganizationMenuOpen(false)}
              formatOptionLabel={formatOptionLabel}
              closeMenuOnSelect={true}
              menuPlacement="bottom"
              styles={{
                menu: (base) => ({
                  ...base,
                  marginTop: "-1.1rem",
                  width: "22.9375rem",
                  height:"auto"
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "var(--white-color) !important",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "var(--dark-gray-color)",
                  paddingLeft:"1px !important",
                  fontWeight:"400",
                  fontSize:"var(--font-size-small)"
                }),
                control: (base) => ({
                  ...base,
                  borderColor: "#d8d8d8",
                  width: "22.9375rem",
                  height: "3rem",
                  bottom: "1rem"
                }),
              }}
            />
          </Form.Group>
          <Form.Group controlId="firstName">
            <Form.Label>First Name
              <FaAsterisk style={{color:"#E62753",
                width:"0.4rem",
                height:"0.4rem",
                margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Form.Control
              className="editable"
              type="text"
              name="firstName"
              placeholder="Enter First Name"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Label>Last Name
              <FaAsterisk style={{color:"#E62753",
                width:"0.4rem",
                height:"0.4rem",
                margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Form.Control
              className="editable"
              type="text"
              placeholder="Enter Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="workEmail">
            <Form.Label>Work Email
              <FaAsterisk style={{color:"#E62753",
                width:"0.4rem",
                height:"0.4rem",
                margin:"0rem 0rem 0.5rem 0.25rem"}}/>
            </Form.Label>
            <Form.Control
              className="editable"
              type="email"
              placeholder="Enter Work Email"
              name="workEmail"
              value={formData.workEmail}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="teams">
            <Form.Label style={{ marginBottom: "0.7rem" }}>Team
            </Form.Label>
            <Select
              className="custom-select"
              placeholder="Select Team"
              onInputChange={(inputValue, { action }) => {
                if (action === 'input-change') {
                  setIsTyping(true);
                } else if (action === 'menu-close' || action === 'input-blur') {
                  setIsTyping(false);
                }
                handleInputTeamChange(inputValue);
              }}
              options={teamTitles}
              onChange={handleOnchangeTeam}
              value={selectedTeam}
              isMulti
              components={{
                Option: CheckboxOption,
                NoOptionsMessage: () => (
                  <div style={{ textAlign: "center", padding:"0.4rem" }}>
                      {isTyping ? <span style={{ color: "var(--sky-blue-color)" }}>Searching...</span> : (teamTitles.length === 0 ? "Type something..." : "No options")}
                  </div>
                ),
              }}
              formatOptionLabel={formatOptionLabel}
               closeMenuOnSelect={false}
               hideSelectedOptions={false}
               styles={{
                menu: (base) => ({
                  ...base,
                  marginTop: "0.7rem",
                  width: "22.9375rem",
                }),
                width: "1.5rem",
                option: (base) => ({
                  ...base,
                  backgroundColor: "var(--white-color) !important",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "var(--dark-gray-color)",
                  paddingLeft:"1px !important",
                  fontWeight:"400",
                  fontSize:"var(--font-size-small)"
                }),
                control: (base) => ({
                  ...base,
                  borderColor: "#d8d8d8",
                  minWidth: "22.9375rem",
                  minHeight: "3rem",
                }),
               }}
            />
          </Form.Group>
          <Form.Group controlId="roles">
            <Form.Label style={{ marginTop: "1.49rem" }}>Role
              <FaAsterisk style={{color:"#E62753",
                width:"0.4rem",
                height:"0.4rem",
                margin:"0rem 0rem 0.5rem 0.25rem"}}/></Form.Label>
            <Select
              className="custom-select"
              placeholder="Select Role"
              onInputChange={(inputValue, { action }) => {
                if (action === 'input-change') {
                  setIsTyping(true);
                } else if (action === 'menu-close' || action === 'input-blur') {
                  setIsTyping(false);
                }
                handleInputRoleChange(inputValue);
              }}
              options={roleTitles}
              onChange={handleOnchangeRole}
              //isMulti={false}
              value={selectedRole}
              components={{
                Option: RadioboxOption,
                NoOptionsMessage: () => (
                  <div style={{ textAlign: "center", padding:"0.4rem" }}>
                      {isTyping ? <span style={{ color: "var(--sky-blue-color)" }}>Searching...</span> : (roleTitles.length === 0 ? "Type something..." : "No options")}
                  </div>
                ),
              }}
            //   formatOptionLabel={formatOptionLabel}
            //    closeMenuOnSelect={false}
            //    hideSelectedOptions={false}
            //  menuPlacement="bottom"
             isClearable
              styles={{
                menu: (base) => ({
                  ...base,
                  marginTop: "0.7rem",
                  width: "22.9375rem",
                }),
                width: "1.5rem",
                option: (base) => ({
                  ...base,
                  backgroundColor: "var(--white-color) !important",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "var(--dark-gray-color)",
                  paddingLeft:"1px !important",
                  fontWeight:"400",
                  fontSize:"var(--font-size-small)"
                }),
                 control: (base) => ({
                   ...base,
                   borderColor: "#d8d8d8",
                   minWidth: "22.9375rem",
                   minHeight: "3rem",
                 }),
              }}
            />
          </Form.Group>
        </CustomForm>
      </Modal.Body>
      <CustomModalFooter>
        <ModalButton isClose onClick={handleClose} style={{ width: "7.5rem" }}>
          Cancel
        </ModalButton>
        <ModalButton
          type="submit"
          style={{ width: "10.75rem", paddingLeft: "39px" }}
          onClick={handleSubmit}
        >
          Create User
        </ModalButton>
      </CustomModalFooter>
    </CustomModal>
  );
};

CreateUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default CreateUserModal;
