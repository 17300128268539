import { fetchToken } from "Constants/ApiUrls";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_BASE_URL;
const getToken = () => fetchToken();
const fetchData = async (url, options = {}) => {
  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        ...options.headers,
      },
    });
    const responseData = await response.json();
    if (!response.ok || !responseData.success) {
      toast.error(responseData.message || 'Something went wrong!');
      return null;
    }
    return responseData.data;
  } catch (error) {
    toast.error("An error occurred. Please try again.");
    throw error;
  }
};
const buildFilterParams = (filterData)=>{
  return filterData.map(filter =>
    Array.isArray(filter.value) && filter.value.length > 0
      ? filter.value.map(value => `${filter.name}[]=${value}`).join("&")
      : `${filter.name}=${filter.value}`).join("&");
}

export const getAllRoles = async (filterData) => {
  const filterParams = buildFilterParams(filterData);
  const url = `${baseUrl}/cms/v1/getAllRolesAndTemplates?${filterParams}`;
  const data = await fetchData(url);
  return data || { roles: [], totalPages: 0 };
};

export const insertRole = async (data) => {
  const url = `${baseUrl}/cms/v1/insertRoleAndTemplate`;
  const response = await fetchData(url, {
    method: "POST",
    body: JSON.stringify(data),
  });
  if (response) {
    toast.success("Role added successfully");
    return true;
  }
  return false;
};

export const handleDeleteRole = async (data) => {
  const url = `${baseUrl}/cms/v1/deleteRoleAndTemplate`;
  const response = await fetchData(url, {
    method: "POST",
    body: JSON.stringify(data),
  });
  if (response) {
    toast.success("Deleted successfully");
    return true;
  }
  return false;
};

export const updateRole = async (data) => {
  const url = `${baseUrl}/cms/v1/updateRoleAndTemplate`;
  const response = await fetchData(url, {
    method: "POST",
    body: JSON.stringify(data),
  });
  if (response) {
    toast.success("Role updated successfully");
    return true;
  }
  return false;
};

export const getRoles = async (url) => {
  const data = await fetchData(url);
  return data.roleAndTemplateTitles.map(({ _id, roleName }) => ({
    value: _id,
    label: roleName,
    id: _id,
  }));
}

export const getOrganizationTitles = async (url) => {
  const data = await fetchData(url);
  return data.organizationTitles.map(({ _id, organizationTitle }) => ({
    value: _id,
    label: organizationTitle,
    id: _id,
  }));
};