import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    questionValue: "",
    errorMessage: "",
};

const questionSlice = createSlice({
    name: "question",
    initialState,
    reducers: {
        setQuestionValue: (state, action) => {
            state.questionValue = action.payload;
        },
        clearQuestionValue: (state) => {
            state.questionValue = "";
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        clearErrorMessage: (state) => {
            state.errorMessage = "";
        },
    },
});

export const { setQuestionValue, clearQuestionValue, setErrorMessage, clearErrorMessage } = questionSlice.actions;
export default questionSlice.reducer;
