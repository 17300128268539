import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import closeIcon from "../../../Assests/Images/Vector.svg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import image from "../../../Assests/Images/user-profile.png";
import Select, { components } from "react-select";
import {
  CustomModalHeader,
  CustomModalFooter,
  CustomForm,
  ModalButton,
  CustomModal,
  CloseIcon,
  UsersAvatar
} from './AddUsersToTheTeam.styled';
import { addUsersToTeam } from '../../../Utils/ApiHandler/TeamsApi';
import _ from "lodash";
import _debounce from "lodash/debounce";

const AddUsersToTheTeamModal = ({ show,
  handleClose,
  onSave,
  onUserInputChange,
  userTitles,
  onUserChange,
  placeholderText,
  teamIds,
  searchOptions,
  setSearchOptions
}) => {
  const [formData, setFormData] = useState({
    teamId: teamIds,
    userIds: ''
  });
  const [isUserMenuOpen, setIsUsersMenuOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  const formatOptionLabel = ({ label }) => <span>{label}</span>;

  useEffect(() => {
    if(!searchOptions) {
      setIsTyping(false);
    }
  },[searchOptions])

  const CheckboxOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => props.selectOption(props.data)}
          style={{
            marginRight: "5px",
            flexShrink: 0,
          }}
        />
        <UsersAvatar src={image} style={{ marginRight: '1rem', marginLeft: '0.5rem' }} />
        <span style={{ color: "#000" }}>{props.label}</span>
      </div>
    </components.Option>
  );

  const handleUserChange = (selectedOptions) => {
    const selectedUserIds = selectedOptions.map((option) => option.id);
    setSelectedUser(selectedUserIds);
    // onUserChange(selectedOptions);
  };

  const userPlaceholderWithCount = () => {
    if (selectedUser.length === 0) {
      return placeholderText;
    } else {
      return `${placeholderText}(${selectedUser.length})`;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedUser.length === 0) {
        toast.error("Please select user");
        return;
      }
      const insertData = {
        teamId: formData.teamId,
        userIds: selectedUser.filter(Boolean)
      };
      const response = await addUsersToTeam(insertData);

      if (response.successMsg !==""){
        const existingUserCount = response.existingUserIds.length;
        const newUserCount = selectedUser.filter(id => response.newUserIds.includes(id)).length;
        if (existingUserCount > 0 && newUserCount > 0) {
          toast.success(`${existingUserCount} user(s) already exist in this team and ${newUserCount} user(s) added to team successfully`);
        } else if (existingUserCount > 0) {
          toast.error(`${existingUserCount} user(s) already exist in this team`);
        } else if (newUserCount > 0) {
          toast.success(`${newUserCount} user(s) added to team successfully`);
        }

        onSave();
        handleClose();
      } else {
        toast.error(response.errorMsg );
      }
    } catch (error) {
      console.error("Error in adding user: ", error);
      toast.error("Error in adding user");
    }
  };
  
  return (
    <CustomModal show={show} onHide={handleClose}>
      <CustomModalHeader>
        <Modal.Title>Add members to the team</Modal.Title>
        <CloseIcon src={closeIcon} alt="Close" onClick={handleClose} />
      </CustomModalHeader>
      <Modal.Body>
        <CustomForm onSubmit={handleSubmit}>
          <Form.Group controlId="users">
            <Select
              className="filter-dropdowns"
              placeholder={userPlaceholderWithCount()}
              menuIsOpen={isUserMenuOpen}
              onInputChange={(inputValue, { action }) => {
                setSearchOptions(true);
                if (action === 'input-change') {
                  setIsTyping(true);
                } else if (action === 'menu-close' || action === 'input-blur') {
                  setIsTyping(false);
                }
                onUserInputChange(inputValue);
              }}
              options={userTitles}
              onChange={handleUserChange}
              isMulti
              closeMenuOnSelect={false}
              components={{
                Option: CheckboxOption,
                NoOptionsMessage: () => (
                  <div style={{ textAlign: "center", padding: "0.4rem", width: "100%" }}>
                    {isTyping ? (
                        <span style={{ color: "var(--sky-blue-color)" }}>Searching...</span>
                      ) : (
                        searchOptions ? (
                          "Type something..."
                        ) : (
                          "No options"
                        )
                      )}
                  </div>
                ),
              }}
              onMenuOpen={() => setIsUsersMenuOpen(true)}
              onMenuClose={() => setIsUsersMenuOpen(false)}
              formatOptionLabel={formatOptionLabel}
              hideSelectedOptions={false}
              controlShouldRenderValue={false}
              menuPlacement="bottom"
              styles={{
                option: (base) => ({
                  ...base,
                  backgroundColor: "var(--white-color) !important",
                }),
                menu: (base) => ({
                  ...base,
                  marginTop: '-4px',
                  zIndex: 9999,
                  width: "30.3125rem",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: selectedUser.length > 0 ? "#347bfa" : "#000",
                }),
                control: (base) => ({
                  ...base,
                  borderColor: "#d8d8d8",
                  width: "30.3125rem",
                  borderRadius: "0.375rem",
                  height: "3rem",
                  bottom: "1rem"
                }),
              }}
            />
          </Form.Group>
        </CustomForm>
      </Modal.Body>
      <CustomModalFooter>
        <ModalButton  onClick={handleClose} style={{ width: "7.5rem" }}>
          Cancel
        </ModalButton>
        <ModalButton type="submit" style={{ width: "7.5rem", paddingLeft: "39px" }} onClick={handleSubmit}>
          Save
        </ModalButton>
      </CustomModalFooter>
    </CustomModal>
  );
};

AddUsersToTheTeamModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AddUsersToTheTeamModal;
